import React from 'react';

import {
    Link,
} from 'react-router-dom';

import ReactGA from 'react-ga';

// import Grid from '@material-ui/core/Grid';
import {
    Typography,
    Grid,
    // Button,
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
/* import {
    CreditCard,
    AttachMoney,
    MonetizationOn,
    Schedule,
    CalendarToday,
} from '@material-ui/icons';
import MdCreditCard from 'react-icons/lib/md/credit_card';
import MdAttachMoney from 'react-icons/lib/md/attach_money';
import MdMonetizationOn from 'react-icons/lib/md/monetization_on';
import MdSchedule from 'react-icons/lib/md/schedule';
import MdCalendarToday from 'react-icons/lib/md/calendar_today'; */

import {
    // UtilFX,
    // DataFX,
    Colors,
    // GlobalContext,
    // GlobalStyles,
    // AlertBox,
} from '../utils';

// import Button from '@material-ui/core/Button';


const LearnMore = (props) => {
    const { userAuthenticated } = props;
    window && ReactGA.pageview(window.location.pathname + window.location.search);

    window && window.fbq && window.fbq('track', 'ViewContent', {
        content_ids: 'learn-more',
    });

    // const { history, theme } = props;
    //console.log("incoming props:", props);
    return (
        <Grid container justify="center">
            <Grid item xs={12}>
                <h2 className="page-title" style={{ marginTop: 65 }}>Learn More</h2>
                <div
                    className="explore-wrapper"
                    style={{
                        backgroundColor: '#fff',
                        color: Colors.pri_color,
                        marginTop: 40,
                        borderRadius: 18,
                        paddingRight: 15,
                        paddingLeft: 15,
                        paddingBottom: 35,
                        paddingTop: 40,
                    }}
                >
                    {/* <div>
                        <Typography variant="display1" color="primary" >Simplify your yoga</Typography>
                        <span>with the FavYogis app.</span>
                    </div> */}

                    <div
                        className="learn-more-box" >
                        <div className="top-half-image-wrapper">
                            <img
                                className="top-half-image"
                                src="https://firebasestorage.googleapis.com/v0/b/encoded-ensign-158201.appspot.com/o/assets%2Flearn_more_class.png?alt=media&token=c3179e1e-e055-4df7-b517-877dad1f094b"
                                alt=""
                                style={{

                                }} />
                        </div>
                        <div className="bottom-half-wrapper">
                            <Typography
                                className="learn-more-subheader" style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                variant="subtitle2"
                                color="primary">
                                Stay in the loop.
                                <Icon style={{ marginRight: 5, marginLeft: 5, }}>notifications</Icon>
                            </Typography>
                            <Typography variant="caption" color="textSecondary">Students get notified when instructors make updates. If desired, sync your schedule with Google Calendar.</Typography>
                        </div>
                        {/* <img
                            className="in-app-screenshot"
                            src="http://placehold.it/194x421"
                            alt=""
                            style={{

                            }} /> */}
                    </div>

                    <div
                        className="learn-more-box" >
                        <div className="top-half-image-wrapper">
                            <img
                                className="top-half-image"
                                src="https://firebasestorage.googleapis.com/v0/b/encoded-ensign-158201.appspot.com/o/assets%2Flearn_more_dollar_in_notebook.png?alt=media&token=f8712bc9-3b7e-48b7-8033-63e165e8306c"
                                alt=""
                                style={{

                                }} />
                        </div>
                        <div className="bottom-half-wrapper">
                            <Typography
                                className="learn-more-subheader" style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                variant="subtitle1"
                                color="primary">
                                <Icon style={{ marginRight: 5, marginLeft: 5, }}>monetization_on</Icon>
                                Simplify payments.</Typography>
                            <Typography variant="caption" color="textSecondary" >Easily send and receive repeating payments for classes. We also offer one-time payments for classes or donations.</Typography>
                        </div>
                    </div>

                    {
                        //!userAuthenticated
                        // &&
                        <Typography variant="body1" color="textSecondary">
                            <b>Are you a teacher?&nbsp;</b>
                            <Link
                                style={{
                                    color: Colors.pri_color,
                                    // borderColor: Colors.pri_color,
                                    // borderWidth: 2,
                                    // borderStyle: "solid",
                                    // borderRadius: 20,
                                    // textDecorationLine: "none",
                                    fontSize: '1rem',
                                    // fontWeight:"bolder",
                                    // display: "block",
                                    // margin: 20,
                                    // padding: 10,
                                    backgroundColor: 'transparent',
                                }}
                                to="/learn-more-teacher"
                            >Read more here.
                            </Link>
                        </Typography>
                    }

                    {
                        !userAuthenticated
                        &&
                        <Link
                            style={{
                                color: Colors.pri_color,
                                borderColor: Colors.pri_color,
                                borderWidth: 2,
                                borderStyle: 'solid',
                                borderRadius: 20,
                                textDecorationLine: 'none',
                                fontSize: '2rem',
                                // fontWeight:"bolder",
                                display: 'block',
                                margin: 20,
                                padding: 10,
                                backgroundColor: 'transparent',
                            }}
                            to="/signup"
                        >Get Started.
                    </Link>
                    }

                </div>
            </Grid>
        </Grid>
    );
}



export default LearnMore;
