import React, { useState } from 'react';
import ButtonComponent from './common/ButtonComponent';
import { Colors } from '../utils';
import { firestore, firebase } from '../fire';
import PendingInvite from './common/PendingInvite';

const Alerts = ({ data, props }) => {
  const [acceptedConvoIds, setAcceptedConvoId] = useState([]);
  const [hideConvoIds, setHideConvoId] = useState([]);

  return data.map((item, key) => {
    const uid = item.status === 'invited' ? item.teacher_id : item.uid;
    const documentId = item.documentId;
    return (
      <React.Fragment key={key}>
        {!hideConvoIds.includes(documentId) && (
          <div
            style={{
              border: `1px solid ${Colors.white}`,
              borderRadius: '10px',
              marginTop: '20px',
            }}
          >
            <PendingInvite
              uid={uid}
              accepted={acceptedConvoIds.includes(documentId)}
              item={item}
            />
            {!acceptedConvoIds.includes(documentId) && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  margin: '16px 20px 10px 20px',
                }}
              >
                <ButtonComponent
                  value={ item.status === 'invited' ? 'Join' : 'Accept'}
                  onClick={async () => {
                    if (item.documentId) {
                      const docRef = await firestore
                        .collection('ConvoMembers')
                        .doc(item.documentId);
                      docRef.update({ status: 'accepted' });
                      setAcceptedConvoId([...acceptedConvoIds, documentId]);
                    }
                  }}
                  buttonStyle={{
                    backgroundColor: Colors.white,
                    color: Colors.pri_color,
                    marginRight: '7px',
                  }}
                />

                <ButtonComponent
                  value="Ignore"
                  onClick={async () => {
                    if (item.documentId) {
                      await firestore
                      .collection('ConvoMembers')
                      .doc(item.documentId).delete().then(() => {
                        const updateData = {};
                        updateData[`convobyusers/${uid}/${documentId}`] = null;
                        firebase.database()
                        .ref()
                        .update(updateData);
                      });
                      setHideConvoId([...hideConvoIds, documentId]);
                    }
                  }}
                  buttonStyle={{
                    backgroundColor: 'transparent',
                    color: Colors.white,
                    border: '1px solid white',
                  }}
                />
              </div>
            )}
            {(acceptedConvoIds.includes(documentId)) && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  margin: '16px 20px 10px 20px',
                }}
              >
                <ButtonComponent
                  value="View group"
                  onClick={() => {
                    if (item.documentId) {
                      props.history.push(`/chat/convo/${documentId}`);
                    }
                  }}
                  buttonStyle={{
                    backgroundColor: Colors.white,
                    color: Colors.pri_color,
                    marginRight: '7px',
                  }}
                />

                <ButtonComponent
                  value="done"
                  onClick={() => {
                    if (item.documentId) {
                      setHideConvoId([...hideConvoIds, documentId]);
                    }
                  }}
                  buttonStyle={{
                    backgroundColor: 'transparent',
                    color: Colors.white,
                    border: '1px solid white',
                  }}
                />
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  });
};

export default Alerts;
