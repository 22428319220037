import React, { Component } from 'react';

import {
  Link,
} from 'react-router-dom';
// import Helmet from 'react-helmet';
import {
  InstantSearch,
  // SearchBox,
  Highlight,
  Snippet,
  // Stats,
  // SortBy,
  // Pagination,
  Configure,
  Index,
} from 'react-instantsearch/dom';
import {
  connectHits,
  // connectSearchBox,
  // connectHighlight
} from 'react-instantsearch/connectors';

import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';

// import { withStyles, withTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
// import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
// import SaveIcon from '@material-ui/icons/Save';
// import MdStarBorder from '@material-ui/icons/StarBorder';
// import MdEmail from '@material-ui/icons/Email';
// import MdLock from '@material-ui/icons/Lock';


// import { SocialIcon } from 'react-social-icons';
// import TextField from '@material-ui/core/TextField';

import axios from 'axios';
// import moment from 'moment';
import {
  UtilFX,
  // DataFX,
  Colors,
  // GlobalContext,
  GlobalStyles,
  // AlertBox,
} from '../utils';
import * as Algolia from '../utils/algolia';
import AvatarDisplay from './AvatarDisplay';

// import ProfileOutside from "./ProfileOutside";
// import { Colors } from '../utils/index';
// import { index } from '../utils/algolia';

export const HitsYogis = connectHits((props) => {
  // console.log("Props for Jits places", props);
  const { hits, theme, location, onSelected } = props;
  return (<div>
    {
      hits
        .filter(v => !!v.username)
        .map(hit => (
          <Grid key={hit.objectID} container justify="center">
            <Grid xs item
              className="teacher-search-result"
              style={{
                // padding: 0,
                backgroundColor: 'white',
                textAlign: 'center',
                border: '2px solid rgba(0, 0, 0, 0.10)',
                borderRadius: 18,
                padding: '10px',
                marginBottom: 10,
                // marginRight: activeQuery ? "30px" : "-40px",
                // width: (teacherSearchQuery && teacherSearchQuery.length) ? 30 : 30,
              }}>
              <Link
                to={
                  {
                    pathname: `/${hit.username || hit.name}`,
                    state: {
                      userData: hit,
                      from: location.pathname,
                    },
                  }
                }
                style={{ display: 'block' }}
                onClick={(e) => {
                  if (onSelected) {
                    e && e.preventDefault();
                    onSelected(hit);
                  }
                }}
              >
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                 
                 <AvatarDisplay
                    imgSrc={hit.profile_photo_url}
                    imgAlt={'teacher'}
                    imgClassName={'box-shadow'}
                    imgStyles={{ height: 60, borderRadius: '50%', }}
                    userName={hit.fullname}
                    textStyles={{
                      ...GlobalStyles.bodyMedium,
                      color: 'rgb(23, 155, 247)',
                      textAlign: 'center',
                      borderRadius: '50px',
                      border: '2px solid',
                      display: 'flex',
                      alignItems: 'center',
                      height: '30px',
                      width: '30px',
                      justifyContent: 'center',
                      margin: 'auto',
                    }}
              />

                  <div style={{
                    textAlign: 'left',
                    flex: 1,
                    paddingLeft: 20,
                  }}>
                    <Typography
                      style={{ fontSize: 18, color: theme.palette.text.primary, display: 'inline-block', width: '100%' }}
                    >
                      <Highlight attribute="fullname" hit={hit} />

                    </Typography>
                    <Typography
                      style={{ fontSize: 16, color: theme.palette.text.secondary, display: 'inline-block', width: '100%', fontStyle: 'italic', fontWeight: 100, }}
                    >
                      @<Highlight attribute="username" hit={hit} />
                    </Typography>
                    <Typography
                      style={{ fontSize: 14, color: Colors.pri_color, display: 'inline-block', width: '100%', fontStyle: 'italic', fontWeight: 100, }}
                    >
                      <Snippet attribute="bio" hit={hit} />...
                    </Typography>
                  </div>
                </div>

              </Link>
            </Grid>
          </Grid>
        ))
    }
          </div>);
});

export const HitsPlaces = connectHits((props) => {
  // console.log("Props for Jits places", props);
  const { hits, theme, location } = props;
  /* if there are still results, you can
          call the refine function to load more */
  // const hitsTrimmed = hits.slice(0, 10);
  return (<div>

    {
      hits.map(hit => {
        // console.log("place hit to log", hit);
        const { _rankingInfo: { geoDistance } = {} } = hit;
        const geoDistance_km = geoDistance && Number(geoDistance / 1000).toFixed(2);
        const geoDistance_miles = geoDistance && Number(geoDistance_km * 0.621371).toFixed(2);
        // const yogiCount = Object.keys(hit.teachers || {}).length;
        // const classCount = Object.keys(hit.classes || {}).length;

        return (
          <Grid
            container
            // wrap="nowrap"
            key={hit.objectID}
            justify="center"
            className="place-search-result"
            style={{
              // padding: 0,
              backgroundColor: 'white',
              textAlign: 'center',
              border: '2px solid rgba(0, 0, 0, 0.10)',
              borderRadius: 18,
              padding: 10,
              marginBottom: 10,
              overflow: 'hidden',
              // textOverflow: "ellipsis",
              // width: "90%",
              // marginRight: activeQuery ? "30px" : "-40px",
              // width: (teacherSearchQuery && teacherSearchQuery.length) ? 30 : 30,
            }}>
            <Link
              to={
                {
                  pathname: `/place/${hit.objectID}`,
                  state: {
                    placeData: hit,
                    from: location.pathname,
                  },
                }
              }
              style={{ display: 'block' }}
            >
              <Grid item xs zeroMinWidth>
                <Typography
                  // noWrap
                  style={{
                    fontSize: 18,
                    color: theme.palette.text.primary,
                    display: 'inline-block',
                    // width: "90%",
                    // whiteSpace: "nowrap",
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}>
                  <Snippet attribute="name" hit={hit} />
                </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography
                  // noWrap
                  style={{
                    fontSize: 14,
                    color: theme.palette.text.secondary,
                    display: 'inline-block',
                    // width: "90%",
                    // whiteSpace: "nowrap",
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}>
                  <span><Icon style={{ verticalAlign: 'text-bottom', fontSize: '16px' }}>location_on</Icon><Snippet attribute="address" hit={hit} /></span>
                </Typography>
              </Grid>
              {
                geoDistance &&
                (
                  <Grid xs item zeroMinWidth>
                    <Typography
                      style={{
                        fontSize: 14,
                        color: Colors.pri_color,
                        display: 'inline-block',
                        // width: "100%",
                        fontStyle: 'italic',
                        fontWeight: 100,
                      }}>{`${geoDistance_miles} miles away`}
                    </Typography>
                  </Grid>
                )
              }
              {/* <div>
                                <p
                                    style={{ display: "inline-block" }}>Yogis: {yogiCount}</p>
                                <br />
                                <p
                                    style={{ display: "inline-block" }}>Classes: {classCount}</p>
                            </div> */}

            </Link>
          </Grid>
        );
      })
    }
          </div>);
});


export const HitsYogisMini = connectHits((props) => {
  const { hits, theme, location, onSelected, limit } = props;
  return (<div style={{ display: 'flex', flexDirection: 'row' }}>

    {
      hits
        .filter(v => !!v.profile_photo_url && !!v.username)
        .sort(() => 0.5 - Math.random())
        .slice(0, limit)
        .map(hit => {

          return (
            <Grid key={hit.objectID} container justify="center">
              <Grid xs item
                // className="teacher-search-result"
                style={{
                  // padding: 0,
                  backgroundColor: 'white',
                  textAlign: 'center',
                  border: '2px solid rgba(0, 0, 0, 0.10)',
                  borderRadius: 18,
                  padding: '10px',
                  margin: 5,
                  display: 'flex'
                  // marginRight: activeQuery ? "30px" : "-40px",
                  // width: (teacherSearchQuery && teacherSearchQuery.length) ? 30 : 30,
                }}>
                <Link
                  to={
                    {
                      pathname: `/${hit.username || hit.name}`,
                      state: {
                        userData: hit,
                        from: location.pathname,
                      },
                    }
                  }
                  style={{ display: 'block' }}
                  onClick={(e) => {
                    if (onSelected) {
                      e && e.preventDefault();
                      onSelected(hit);
                    }
                  }}
                >
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>

                <AvatarDisplay
                    imgSrc={hit.profile_photo_url}
                    imgAlt={'teacher featured'}
                    imgClassName={'box-shadow'}
                    imgStyles={{ height: 120, width: 120, borderRadius: '50%' }}
                    userName={hit.fullname}
                    textStyles={{
                      ...GlobalStyles.bodyMedium,
                      color: 'rgb(23, 155, 247)',
                      textAlign: 'center',
                      borderRadius: '50px',
                      border: '2px solid',
                      display: 'flex',
                      alignItems: 'center',
                      height: '30px',
                      width: '30px',
                      justifyContent: 'center',
                      margin: 'auto',
                    }}
              />

                    <div style={{
                      textAlign: 'center',
                      flex: 1,
                      paddingLeft: 20,
                    }}>
                      <Typography
                        style={{ fontSize: 18, color: theme.palette.text.primary, display: 'inline-block', width: '100%' }}
                      >
                        <Highlight attribute="fullname" hit={hit} />
                      </Typography>
                      <Typography
                        style={{ fontSize: 16, color: theme.palette.text.secondary, display: 'inline-block', width: '100%', fontStyle: 'italic', fontWeight: 100, }}
                      >
                        @<Highlight attribute="username" hit={hit} />
                      </Typography>
                      <Typography
                        style={{ fontSize: 14, color: Colors.pri_color, display: 'inline-block', width: '100%', fontStyle: 'italic', fontWeight: 100, }}
                      >
                        <Snippet attribute="bio" hit={hit} />...
                      </Typography>
                    </div>
                  </div>

                </Link>
              </Grid>
            </Grid>
          );
        })
    }
          </div>);
});
export default class ExploreTeachers extends Component {
  state = {
    teacherSearchResults: [],
    geoSuccess: null,
    geoError: null,
    // algoliaAppId: (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_DEV_ALGOLIA_applicationid : process.env.REACT_APP_PROD_ALGOLIA_applicationid,
    // algoliaApiKey: (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_DEV_ALGOLIA_searchapikey : process.env.REACT_APP_DEV_ALGOLIA_searchapikey,
  };

  componentDidMount() {
    this._mounted = true;
    // console.log("algo app id", algoliaAppId);
    // console.log("algo searchAPI key", algoliaApiKey);
    // let geoSuccess = window && window.localStorage && window.localStorage.getItem("CURRENT_LOCATION_SUCCESS");
    // let geoError = window && window.localStorage && window.localStorage.getItem("CURRENT_LOCATION_ERROR");
    // geoSuccess = geoSuccess && JSON.parse(geoSuccess);
    // geoError = geoError && JSON.parse(geoError);
    // console.log("geo Succ, then error", geoSuccess, geoError);
    // this.setState({
    //   ...this.state,
    //   // geoSuccess,
    //   // geoError,
    // });
    this.getTeacherSearch();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  getTeacherSearch = (query) => {
    let q = query || Math.random().toString(36).substr(2, 3) || 'm';
    let currComp = this;
    axios.post(UtilFX.getApiBaseUrl() + `searchTeachers?q=${q}`, {
      q: q,
    })
      .then((response) => {

        const { data } = response;

        this._mounted && currComp.setState({
          teacherSearchResults: data || [],
        });
      })
      .catch((error) => {
        console.warn('ax err', error);
      });
  }

  render() {
    // const { history, userAuthenticated = {} } = this.props;
    // const { geoSuccess, geoError, ipLookupData: { city, region, region_code, country_name } = {} } = this.state || {};
    // console.log("state before rend", this.state);
    const isAlgoliaReady = Boolean(Algolia && Algolia.ApplicationId && Algolia.SearchApiKey);
    return (
      <div>
        <h1 className="page-title" >Find yoga teachers</h1>
        <div
          className="explore-wrapper"
          style={{
            backgroundColor: '#fff',
            borderRadius: 18,
            padding: 15,
            color: Colors.pri_color,
            textAlign: 'center',
          }}
        >
          {
            isAlgoliaReady
              ?
              <InstantSearch
                appId={Algolia.ApplicationId}
                apiKey={Algolia.SearchApiKey}
                indexName={'places'}
                onSearchStateChange={(ossc_props) => {
                  // console.log("osearcgstatechanged", ossc_props);
                  const { query } = ossc_props;

                  window && window.fbq && window.fbq('track', 'Search', {
                    search_string: query,
                  });

                  this.setState({
                    ...this.state,
                    algoliaSearchState: ossc_props,
                  })

                }}
              >
                {/* Use widgets here */}




                <Index indexName="yogis">
                  <Configure
                    hitsPerPage={500}
                    filters="classes_count > 0"
                  />
                  <div style={{ marginTop: 15 }}>
                    <HitsYogis {...this.props} limit={500} />
                  </div>
                </Index>

              </InstantSearch>
              : null
          }

        </div>
      </div>
    );
  }
}
