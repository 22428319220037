import React, { Component } from 'react';

import {
    Link,
    //     Redirect,
} from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

// import { withStyles, withTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import axios from 'axios';

import {
    UtilFX,
    Loading,
    // DataFX,
    // Colors,
    // GlobalContext,
    GlobalStyles,
    // AlertBox,
    Colors,
} from '../utils';

import {
    // auth,
    // database
} from '../fire';
import AvatarDisplay from './AvatarDisplay';


export default class Students extends Component {
    // constructor(props, context) {
    //     super(props, context);
    //     this.state = {};
    // }
    state = {
        isLoadingData: true,
    };

    componentDidUpdate(oldProps, oldState) {
        const { context = {} } = this.props || {};
        const { userAuthenticated } = context;
        const oldUA = oldProps && oldProps.context && oldProps.context.userAuthenticated;
        if (!oldUA && userAuthenticated) {
            this.refreshData();
            return;
        }
        if (oldUA && userAuthenticated) {
            const oldStudentIds = Object.keys(oldUA.students || {});
            const newStudentIds = Object.keys(userAuthenticated.students || {});
            if (oldStudentIds.length !== newStudentIds.length) {
                console.warn('not same length!!', oldStudentIds, newStudentIds);
                this.refreshData();
                return;
            }

            const isSame = !newStudentIds.filter(id => (oldStudentIds.indexOf(id) < 0)).length;

            if (!isSame) {
                console.warn('not same from reduce');
                this.refreshData();
            }
        }
    }

    componentDidMount() {
        this._mounted = true;
        this.refreshData();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    refreshData = () => {
        const { context: { userAuthenticated = {} } = {} } = this.props || {};
        const studentIds = Object.keys(userAuthenticated.students || {}).join(',');
        if (studentIds.length > 1) {
            axios.post(UtilFX.getApiBaseUrl() + 'getApiData', {
                action: 'getPublicYogiData',
                uids: studentIds,
            })
                .then(({ data }) => {
                    const { yogis } = data || [];
                    this._mounted && this.setState({
                        listData: yogis,
                        isLoadingData: false
                    });
                }).catch((err) => {
                    console.warn('error fetching data', err);
                    this.setState({ isLoadingData: false });
                });
        } else {
            console.warn('no logged in user here');
            this.setState({ isLoadingData: false });
        }
    }

    render() {
        const { listData, isLoadingData } = this.state;
        if (isLoadingData) {
            return (
                <Loading />
            );
        }
        return (
            <Grid container justify="center">
                <Grid item xs={12} style={{ maxWidth: '90%' }}>
                    <div>
                        {
                            !listData && (
                                <div style={{ justifyContent: 'center', textAlign: 'center' }}>
                                    <Typography variant="body2" style={{ padding: 20 }}>When students add you as a favorite, they will be listed here.</Typography>
                                </div>
                            )
                        }
                        {
                            listData && listData.length && (
                                <div
                                    style={{ position: 'relative' }}
                                >
                                    <div style={{
                                        borderRadius: 10,
                                        marginTop: '30px',
                                        paddingTop: '20px',
                                        backgroundColor: '#fff',
                                        color: Colors.pri_color,
                                    }}
                                    >
                                        <div
                                            style={{
                                                marginBottom: 20,
                                            }}
                                        >
                                            <Typography variant="display1">Student Yogis</Typography>
                                        </div>

                                        <Grid container>
                                            {listData.map(yogi => {
                                                const yogiInitials = (yogi.username || 'N A').split().map(v => v[0]).join('');
                                                return (
                                                    <Grid style={{ marginBottom: 25, }}
                                                        item
                                                        xs={6}
                                                        key={yogi.uid}
                                                    >
                                                        <div style={{ display: 'inline-block' }}>
                                                            <Link
                                                                to={`/student/${yogi.uid}`}
                                                                style={{textDecoration:'none'}}
                                                            >
                                                               
                                                                <AvatarDisplay
                                                                imgSrc={yogi.profile_photo_url || `http://placehold.it/100?text=${yogiInitials}`}
                                                                imgClassName={'yogi-photo'}
                                                                imgStyles={{ borderRadius: '50%', height: 80, }}
                                                                userName={yogi.fullname}
                                                                textStyles={{
                                                                    ...GlobalStyles.bodyMedium,
                                                                    color: 'rgb(23, 155, 247)',
                                                                    textAlign: 'center',
                                                                    borderRadius: '50px',
                                                                    border: '2px solid',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    height: '30px',
                                                                    width: '30px',
                                                                    justifyContent: 'center'
                                                                  }}
                                                                />
                                                                <Typography variant="body1" color="primary">{yogi.fullname || 'Unavailable'}</Typography>
                                                                <Typography variant="body1" color="primary">
                                                                    @
                                                                {yogi.username || ''}
                                                                </Typography>
                                                            </Link>
                                                        </div>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </Grid>
            </Grid>
        );
    }
}
