import React from "react";
import { Typography } from "../../node_modules/@material-ui/core/index";
import { getNameIntials, DefaultProfilePhotoUrl } from "../utils/index";

const AvatarDisplay = ({imgSrc, imgAlt, imgStyles, imgClassName, userName, textStyles, onError, imgTitle }) => {
    const onSignUpPhotoUrl = imgSrc && imgSrc === DefaultProfilePhotoUrl;

    return (
        <div>
            {
                !onSignUpPhotoUrl ?
                    <img src={imgSrc}
                    className={imgClassName}   
                    alt={imgAlt ? imgAlt : ''} 
                    style={imgStyles}
                    onError={onError}
                    title={imgTitle}

                    />
                    : <Typography style={textStyles}>
                        {getNameIntials(userName)}
                    </Typography>
            }
        </div>
    )
}

export default AvatarDisplay;
