import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {
    UtilFX,
    Colors,
    GlobalStyles,
  } from '../../../utils';
import { firebase } from '../../../fire';
import ClearCard from '../ClearCard';
import ImgInviteGroupChat from '../../../cta_invite_group_chat.svg';
import ButtonComponent from '../ButtonComponent';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SnackBarComponent from '../SnackBarComponent';

const FirstTimeChatVisitModal = ({ uid, convoId, SetFirstTimeChatUrlVisit }) => {
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const baseUrl = UtilFX.getWebsiteBaseUrl();
    const url = baseUrl + `join/${convoId}`;
    const copyUrlToClipboard = () => {
      UtilFX.copyToClipboard(url);
    };
    return (
        <Grid container className='custom-modal'>
              <Grid item xs={12} md={12} className="custom-modal-wrapper" style={{
                margin: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}>
                <div style={{
                  width: '416px',
                  margin: 'auto',
                  borderRadius: '10px',
                  backgroundColor: Colors.white,
                }}>
                  <ClearCard cancelEvent={ async () => {
                    await firebase.database()
                    .ref(`convobyusers/${uid}/${convoId}`)
                    .update({
                        first_time_chat_url_visit: true,
                    });
                    SetFirstTimeChatUrlVisit();
                  }}/>
                  <img src={ImgInviteGroupChat} style={{ margin: 0, width: '416px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                  alt='cta-share-a-class' />
                  <Typography style={{
 ...GlobalStyles.titleBold,
color: Colors.grey1,
                    marginTop: '20px'
                  }}>
                    Invite people to this group
                  </Typography>
                  <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center',marginTop: '14px' }}>
                    <Typography style={{ ...GlobalStyles.bodyRegular, color: Colors.green1, backgroundColor: Colors.lightGreen, borderRadius: '12px', alignSelf: 'center', padding: '5px 10px', marginRight: '10px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '239px' }}>{url}</Typography>
                    <ButtonComponent
                      onClick={() => {
                        copyUrlToClipboard();
                        setOpenSnackBar(true);
                      }}
                      value="Copy" buttonStyle={{ ...GlobalStyles.bodyMedium, backgroundColor: Colors.blueOnlineClass, borderRadius: '12px', border: `1px solid ${Colors.blueOnlineClass}`, boxShadow: 'none', color: Colors.pri_color, width: '70px' }}
                    />
                  </div>
                <Typography style={{ ...GlobalStyles.bodyRegular, marginTop: '14px', color: Colors.grey3, marginBottom: '22px' }}>Discover more options to invite people to your group <br />
using the  <span style={{ border: `1px solid ${Colors.grey3}`, paddingTop: '5px' }}><MoreVertIcon style={{ fontSize: '15px', marginTop: '3px' }} /></span>   icon at the top-right corner <br/>
and then choosing “Add member”.</Typography>
                </div>
                {
                    openSnackBar && (
                        <SnackBarComponent text='Invite link copied!' closeSnackBar={() => setOpenSnackBar(false)} />
                    )
                }
              </Grid>
            </Grid>
    );
};

export default FirstTimeChatVisitModal;
