import React from 'react';
import muteIcon from './svg/mute_icon_grey.svg';

const MuteIcon = () => {
    return (
        <div style={{ marginTop: '4px' }}>
            <img src={muteIcon} alt="pin-con" />
        </div>
    );
};

export default MuteIcon;
