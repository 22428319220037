import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Alerts from '../Alerts';
import { firestore } from '../../fire';
import { Colors, ConvoMetadata, GlobalStyles, Loading } from '../../utils/index';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

class AlertsContainer extends Component {
  constructor(props) {
    super(props);
    this.auth_user = props.context.userAuthenticated;
    this.state = {
      pendingGroupChatMembers: [],
      pendingGroupChatInvites: [],
      filter: 'All',
      hideFilterContent: false
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const locState = location && location.state;
    const pendingGroupChatMembers = locState?.pendingGroupChatMembers || [];
    const pendingGroupChatInvites = locState?.pendingGroupChatInvites || [];
    if (!pendingGroupChatMembers.length) {
      this.setState({ loading: true });
      this.getPendingGroupMembers();
    }
    if (!pendingGroupChatInvites.length) {
      this.setState({ loading: true });
      this.getGroupChatInvites();
    }
    this.setState({ pendingGroupChatMembers, pendingGroupChatInvites, });
  }

  getPendingGroupMembers = async () => {
    const convos = [];
    const pendingMembers = [];
    let query = await firestore.collection(ConvoMetadata);
    query = query.where('teacher_id', '==', this.auth_user.uid);
    const response = await query.get();
    response.forEach(doc => {
      convos.push(doc.data());
    });
    Promise.all(convos.map(async convo => {
      let query = await firestore.collection('ConvoMembers');
      query = query.where('convo_id', '==', convo.convo_id);
      query = query.where('status', '==', 'pending');
      const res = await query.get();
      res.forEach((doc) => {
        pendingMembers.push({ documentId: doc.id, ...doc.data(), ...convo });
      });
    })).then(() => {
      this.setState({
        pendingGroupChatMembers: pendingMembers,
        pendingGroupChatMembersCount: pendingMembers.length,
        loading: false
      });
    });
  };

  getGroupChatInvites = async () => {
    const invites = [];
    const convos = [];
    let query = await firestore.collection('ConvoMembers');
    query = query.where('uid', '==', this.auth_user.uid);
    query = query.where('status', '==', 'invited');
    const response = await query.get();
    response.forEach(doc => {
      invites.push({ documentId: doc.id, ...doc.data() });
    });
    Promise.all(invites.map(async convo => {
      let query = await firestore.collection(ConvoMetadata);
      query = query.where('convo_id', '==', convo.convo_id);
      const res = await query.get();
      if (!res.empty) {
        const result = res.docs[0].data();
        convos.push({ ...result, ...convo });
      }
    })).then(() => {
      this.setState({
        pendingGroupChatInvites: convos,
        pendingGroupChatInvitesCount: convos.length,
        loading: false
      });
    });
  }

  render() {
    const { loading, pendingGroupChatMembers, filter, pendingGroupChatInvites, hideFilterContent } = this.state;
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '36px', marginTop: '47px', justifyContent: 'space-between', borderBottom: '1px solid white', paddingBottom: '20px' }}>
          <Typography
            style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '22px',
              alignSelf: 'center'
            }}
          >
            Alerts
          </Typography>
          <div>
            <div onClick={ () => this.setState({ hideFilterContent: true })} style={{ display: 'flex', flexDirection: 'row', border: '1px solid white', borderRadius: '10px', cursor: 'pointer', width: '143px', justifyContent: 'space-around' }}>
              <div></div>
              <Typography style={{ ...GlobalStyles.bodyMedium, fontColor: Colors.white, marginTop: '9px', marginBottom: '11px' }}>{filter}</Typography>
              <ArrowDropDownIcon style={{ alignSelf: 'center' }} />
            </div>
            {
              hideFilterContent &&
              <div style={{ backgroundColor: Colors.white, width: '143px', borderRadius: '10px', position: 'absolute', cursor: 'pointer' }}>
                <Typography onClick={() => this.setState({ filter: 'All', hideFilterContent: false })} style={{ ...GlobalStyles.bodyMedium, padding: '10px', backgroundColor: filter === 'All' ? Colors.blueOnlineClass : Colors.white, color: Colors.blueText, borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>All</Typography>
                <Typography onClick={() => this.setState({ filter: 'Requests', hideFilterContent: false })} style={{ ...GlobalStyles.bodyMedium, padding: '10px', backgroundColor: filter === 'Requests' ? Colors.blueOnlineClass : Colors.white, color: Colors.blueText }}>Requests</Typography>
                <Typography onClick={() => this.setState({ filter: 'Invites', hideFilterContent: false })} style={{ ...GlobalStyles.bodyMedium, padding: '10px', backgroundColor: filter === 'Invites' ? Colors.blueOnlineClass : Colors.white, color: Colors.blueText, borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }}>Invites</Typography>
              </div>
            }
          </div>
        </div>
        {
        loading
        ?
          <Loading />
        :
          <Alerts
            data= {filter === 'All' ? [...pendingGroupChatMembers, ...pendingGroupChatInvites] : filter === 'Requests' ? pendingGroupChatMembers : pendingGroupChatInvites}
            props={this.props}
          />
        }
      </div>
    );
  }
}

export default AlertsContainer;
