import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import { Colors } from '../../utils/index';
const ButtonStyle = {
    root: {
        backgroundColor: Colors.pri_color,
        color: '#FFFFFF',
        width: '100%',
        boxShadow: 'none',
        borderRadius: 50,
        border: `1px solid ${Colors.pri_color}`,
        '&:hover': {
            border: '1px solid white',
            backgroundColor: Colors.darkBlue
        },
    },

};

const ButtonComponent = (props) => {
    const {
        classes, value, onClick, id, type, iconName, buttonStyle = {}, iconStyle = {},
    startIcon = null
} = props;
    return (
        <Button startIcon={startIcon} id={id} type={type} className={classes.root} onClick={onClick} style={{ ...buttonStyle }}>
            {iconName && (
                <Icon
                    name={iconName}
                    type="material"
                    style={{ height: 30, width: 30, color: '#fff', alignSelf: 'center', alignItems: 'center', ...iconStyle }}
                >
                    {iconName}
                </Icon>
            )}
            {value}
        </Button>
    );
};

ButtonComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(ButtonStyle)(ButtonComponent);
