import React, { useCallback, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import ButtonComponent from './common/ButtonComponent';
import { Colors, GlobalStyles, DefaultProfilePhotoUrl, UtilFX } from '../utils';
import { firestore, firebase } from '../fire';
import AvatarDisplay from './AvatarDisplay';

const ChatInvite = (props) => {
    const { context: { userAuthenticated }, history, computedMatch, match } = props;
    const { convoid } = (computedMatch || match).params || {};
    const [groupChatRequestStatus, setGroupChatRequestStatus] = useState('');
    const [convoMemberDocumentId, setConvoMemberDocumentId] = useState('');
    const [convoMemberTeacherDetails, setConvoMemberTeacherDetails] = useState({ convoName: '', profilePhotoUrl: '' });
    const [groupMembers, setGroupMembers] = useState([]);
    const [existingMemberStatus, setExistingMemberStatus] = useState('')

    const uid = userAuthenticated && userAuthenticated?.uid;

    const getConvoMetaData = useCallback(async () => {
        let query = firestore.collection('ConvoMetadata');
        query = query.where('convo_id', '==', convoid);
        const res = await query.get();
        let convoName = '';
        let profilePhotoUrl = '';
        if (!res.empty) {
            convoName = res.docs[0].data().convo_name;
            profilePhotoUrl = res.docs[0].data().convo_profile_photo_url;
            setConvoMemberTeacherDetails({ convoName, profilePhotoUrl });
        }
    }, [convoid]);
    const getGroupChatRequestStatus = useCallback(async () => {
        let query = firestore.collection('ConvoMembers');
        query = query.where('convo_id', '==', convoid);
        const res = await query.get();
        const allGroupMembers = []
        if (!res.empty) {
            res.forEach((doc) => {
                allGroupMembers.push({docId: doc.id, ...doc.data()})
            })
            getConvoMetaData();
            setGroupMembers(allGroupMembers)
        }
    }, [convoid, getConvoMetaData]);
    useEffect(() => {
        if (convoid) {
            getGroupChatRequestStatus();
        }
    }, [convoid, getGroupChatRequestStatus, groupMembers.length]);
  
    const filterGroupMember = () => {
        return groupMembers?.map((member) => member?.uid);
    }

    const getMemberStatus = useCallback(() => {
        const member = groupMembers.find(member => member?.uid === uid);
        setExistingMemberStatus(member?.status)
        
    }, [groupMembers, uid])

    useEffect(() => {
        if(uid && groupMembers.length > 0){
            getMemberStatus()
        }
    }, [uid, groupMembers.length, getMemberStatus])

    return (
        <div style={{ backgroundColor: 'white', marginBottom: '-13px', height: '100vh' }}>
            <div>
                <br/>
                <br/>
                <br/>
                <br/>
               
                {uid && <AvatarDisplay
                    imgSrc={convoMemberTeacherDetails.profilePhotoUrl || DefaultProfilePhotoUrl }
                    imgStyles={{  width: '100px', height: '100px', borderRadius: '50%'}}
                    imgAlt={'teacher-profile-img'}
                    userName={convoMemberTeacherDetails.convoName}
                    textStyles={{
                    ...GlobalStyles.bodyMedium,
                    color: '#1fa3ff',
                    textAlign: 'center',
                    borderRadius: '50px',
                    border: '2px solid',
                    display: 'flex',
                    alignItems: 'center',
                    height: '60px',
                    width: '60px',
                    justifyContent: 'center',
                    margin: 'auto',
                    marginTop: '5px',
                    fontSize: '24px'
          }}
          />}
                
                <Typography style={{ marginTop: '20px', fontFamily: 'Rubik', fontStyle: 'normal', fontWeight: '300', fontSize: '28px', color: Colors.grey1 }}>{convoMemberTeacherDetails.convoName}</Typography>
                <Typography style={{ ...GlobalStyles.bodyMedium, fontSize: '16px', marginTop: '34px', color: Colors.grey2 }}>About</Typography>
                <Typography style={{ ...GlobalStyles.bodyRegular, marginTop: '12px', color: Colors.grey2 }}>Yoga should be accessible to all! That’s why we’ve carefully crafted and curated the prefect mix of technology for all.</Typography>
              
                    <ButtonComponent value="JOIN GROUP" buttonStyle={{ backgroundColor: Colors.pri_color, marginTop: '34px', borderRadius: '12px', width: '158px' }} onClick={async() => {
                        if (!uid) {
                            window && window.localStorage.setItem('inviteConvoId', convoid);
                            history.push('/login');
                        } else {
                            if(filterGroupMember().includes(uid)){
                                if(existingMemberStatus === 'accepted'){
                                    history.push(`/chat/convo/${convoid}`);
                                } else if(existingMemberStatus === 'pending'){
                                    UtilFX.toastError('Your request to join this group is pending approval.', {toastId: 'pending approval request'})
                                } else {
                                    if(existingMemberStatus === 'invited'){
                                        history.push('/alerts')
                                    }
                                }
                            } else {
                                if (convoid) {
                                    const dataCbu = {};
                                    dataCbu[`convobyusers/${uid}/${convoid}`] = true;
                                    await firebase
                                      .database()
                                      .ref()
                                      .update(dataCbu);
                                    const memberDataCm = {
                                      uid: uid,
                                      date_added: Date.now(),
                                      convo_id: convoid,
                                      status: 'pending',
                                    };
                                    const docRef = await firestore
                                      .collection('ConvoMembers')
                                      .add(memberDataCm);
                                    setConvoMemberDocumentId(docRef.id);
                                    setGroupChatRequestStatus('pending');
                                    setExistingMemberStatus('pending')
                                    setGroupMembers([...groupMembers, uid])
                                }
                                                           
                            }
                          
                        }
                    }} />
                {
                    groupChatRequestStatus === 'pending' &&
                    <div style={{ marginTop: '20px' }}>
                        <hr style={{ borderTop: `1px solid ${Colors.grey4}`, marginTop: '20px', width: '434px' }} />
                        <Typography style={{ ...GlobalStyles.bodyRegular, color: Colors.grey1, }}>Your request to join this group is currently in review. You will be notified if accepted.</Typography>
                        <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              marginTop: '14px',
                            }}
                          >
                            <ButtonComponent value="Cancel request" buttonStyle={{ backgroundColor: Colors.lightRed, borderRadius: '12px', width: '165px', color: Colors.red, border: 0 }} iconStyle={{ color: Colors.red, marginRight: '15px' }} iconName='cancel' onClick={async() => {
                                if (convoid && convoMemberDocumentId) {
                                  await firestore
                                    .collection('ConvoMembers')
                                    .doc(convoMemberDocumentId).delete().then(() => {
                                        const updateData = {};
                                        updateData[`convobyusers/${uid}/${convoid}`] = null;
                                        firebase.database()
                                        .ref()
                                        .update(updateData);
                                    });
                                  return setGroupChatRequestStatus('');
                                }
                            }} />
                            <ButtonComponent value="Visit group" buttonStyle={{ backgroundColor: Colors.blueOnlineClass, borderRadius: '12px', width: '165px', color: Colors.pri_color, border: 0, marginLeft: '8px' }} iconStyle={{ color: Colors.pri_color, marginRight: '15px', }} iconName='person' onClick={() => history.push(`/chat/convo/${convoid}`)} />
                          </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ChatInvite;
