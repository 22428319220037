import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { GlobalStyles, UtilFX, Colors } from '../../../utils';
import GroupInfoPopover from '../TitleCardOptions';
import MuteIcon from './MuteIcon';
import AvatarDisplay from '../../AvatarDisplay';

const MemberCard = ({ recipientId, data, mutedMembers, handleMutedMembers, dmEntryPoint, uid, closeGroupChatProfileInfo, acceptedGroupChatMembers, setAcceptedGroupChatMembers, classId, teacherId }) => {
    const [user, setUser] = useState({});


    useEffect(() => {
        async function fetchData() {
            if (recipientId) {
                Promise.resolve(UtilFX.getFYuser(recipientId).then(res => {
                    setUser({ ...res });
                }));
            }
        }
        fetchData();
    }, [recipientId]);
    return (
        <div style={{ display: 'flex', flexDirection: 'row', padding: '16px', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
               
                <AvatarDisplay
                    imgSrc={user.profile_photo_url}
                    imgAlt={'profile'}
                    imgStyles={{ width: '48px', height: '48px', borderRadius: '50%' }}
                    userName={user.fullname}
                    textStyles={{ ...GlobalStyles.bodyMedium, color: Colors.grey2, textAlign: 'center' }}
                />
                <div style={{ marginLeft: '23px' }}>
                    <Typography style={{ ...GlobalStyles.bodyMedium, color: Colors.grey2, textAlign: 'left', alignSelf: 'center' }}>{user.fullname || user.username}</Typography>
                    {
                        recipientId === teacherId && (
                        <Typography style={{ ...GlobalStyles.bodyRegular, color: Colors.blue, textAlign: 'left' }}>Teacher</Typography>
                    )}
                </div>
            </div>
            <div style={{ alignSelf: 'center', flexDirection: 'row', display: 'flex' }}>
                {
                    mutedMembers.includes(data.documentId) && (
                    <MuteIcon />
                )}
                <GroupInfoPopover
                    closeGroupChatProfileInfo={closeGroupChatProfileInfo} user={user} newMutedMembers={mutedMembers} newHandleMutedMembers={handleMutedMembers} recipientId={recipientId} groupInfoPopover={true} groupMemberDocumentId={data.documentId} dmEntryPoint={dmEntryPoint} uid={uid} acceptedGroupChatMembers={acceptedGroupChatMembers} setAcceptedGroupChatMembers={setAcceptedGroupChatMembers} classId={classId} teacherId={teacherId}
                />
            </div>
        </div>
    );
};

export default MemberCard;
