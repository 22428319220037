import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import ReCAPTCHA from 'react-google-recaptcha';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import {
    UtilFX,
    Loading,
    // DataFX,
    Colors,
    // GlobalContext,
    GlobalStyles,
    AlertBox
} from '../utils';
import { firebase } from '../fire';
// // import { LoginSignupForgotAuthUI } from "../App";


export default class Conversation extends Component {
    state = {
        isLoading: false,
        pristine: false, //pristine view was causing odd flicker issues, so never set it to true and seems to work better now. holding off on full removal surgery just in case we are missing something else for now
        fyuser: null,
        convo_id: null,
        convo_height: 40,
        convo_text: '',
        convo_messages: null,
        recipient_id: null,
        recipient_photo_url: null,
        profile_complete: false
    };

    componentDidMount() {
        this._mounted = true;
        this.setState({
            recipient_id: this.props.recipientId,
            recipient_photo_url: this.props.recipientPhotoUrl,
        }, () => {
            this._getData();
        })
        this.recaptchaRef = React.createRef();

    }

    componentWillUnmount() {
        this._mounted = false;
    }

    componentDidUpdate(oldProps, oldState) {
        // this.determineYogiRelation();
        const { context = {} } = this.props || {};
        const newUserAuthenticated = (context && context.userAuthenticated) || {};
        const { context: oldContext = {} } = oldProps || {};
        const oldUserAuthenticated = (oldContext && oldContext.userAuthenticated) || {};

        // console.info("profile outisde cdu called. userAuthed? oldProps oldState, new Props & state", oldProps, oldState, this.props, this.state);

        const newUID = Object.keys(newUserAuthenticated || {}).uid;
        const oldUID = Object.keys(oldUserAuthenticated || {}).uid;
        if (!this.state.isLoading &&
            !this.state.error &&
            (oldUID !== newUID)
        ) {
            // console.log("MADe it to update here!");
            this._getData();
        }
    }

    _getConvoMessages = () => {
        const existing_convo_id = this.state.convo_id;


        if (existing_convo_id) {
            //setup to listen and retrieve the messages
            firebase.database()
                .ref(`convomessages/${existing_convo_id}`)
                .on('value', snap => {
                    let convo_messages = [];
                    snap.forEach(s => {
                        //console.log(s)
                        const c = s.val() || {};
                        convo_messages.push({
                            message: c.message,
                            time: c.date_created,
                            date_deleted: c.date_deleted,
                            uid: c.uid,
                            mid: s.key,
                            likes: c.likes,
                        });
                    });

                    convo_messages = convo_messages.sort((a, b) => {
                        //to make the larger time values the most recent so they show up first
                        return b.time - a.time;
                    });

                    // console.log("trigger refresh", { convo_messages })
                    this._mounted && this.setState(
                        {
                            convo_messages: [convo_messages[0]],
                            convo_messages_shadow: convo_messages,
                            pristine: false,
                            isLoading: false,
                        },
                        () => {
                            //this.refs.ti_message.focus()
                            //this.setState({pristine:false}) //could wrap this into above, but to keep it separate just do it here for now

                            //set last read to this time for this user
                            const { context } = this.props || {};
                            const { userAuthenticated: _u } = context || {};
                            firebase.database()
                                .ref(`convobyusers/${_u.uid}/${existing_convo_id}`)
                                .update({
                                    date_last_read: firebase.database.ServerValue.TIMESTAMP
                                }).then(async () => {
                                    const { context } = this.props;
                                    if (context && context.updateAppState) {
                                        const inbox_unread_count = await UtilFX.getInboxUnreadCount(_u.uid);
                                        context.updateAppState({ inbox_unread_count })
                                    }
                                });



                            //async staggered load for better performance
                            {

                                let ticker = () => {
                                    const tick = this.state.convo_messages.length;
                                    const tickGrab = tick;
                                    const tickItem = this.state.convo_messages_shadow[tickGrab];
                                    const oldConvoMessages = this.state.convo_messages;
                                    // console.info({ tick, tickItem });
                                    if (tickItem && !oldConvoMessages.filter(v => v.mid === tickItem.mid).length) {
                                        const newConvoMessages = oldConvoMessages || [];
                                        newConvoMessages.push(tickItem);
                                        this._mounted && this.setState({
                                            convo_messages: newConvoMessages
                                        }, () => {
                                            window && window.setTimeout(ticker, 250);
                                        })
                                    }
                                }
                                ticker();
                            }
                        }
                    );
                });
        }
    };

    _getData = async () => {
        const { context } = this.props || {};
        const { userAuthenticated: _u } = context || {};
        // console.log("_u", { _u, props: this.props });
        if (!(_u && _u.uid)) {
            return;
        }

        firebase.database()
            .ref('convomembers/')
            .orderByChild(_u.uid)
            .once('value', snap => {
                //var cm = snap.val()
                let existing_convo_id;
                //search for a convo with this recipient
                if (!this.state.recipient_id) { return console.warn('no recip id found!'); }

                snap.forEach(s => {
                    let convo = s.val();
                    let convoid = s.key;
                    if (convo[this.state.recipient_id] && convo[_u.uid]) {
                        //found
                        existing_convo_id = convoid;
                    }
                });

                if (existing_convo_id) {
                    this._mounted && this.setState({ convo_id: existing_convo_id }, () => {
                        this._getConvoMessages();
                    });
                } else {
                    this._mounted && this.setState({ pristine: false, isLoading: false });
                }
            });
    }


    _doDeleteMessage = messageid => {
        const doDelete = window && window.confirm('Are you sure you want to delete this message?');
        if (doDelete) {
            // console.log("Delete this message", messageid);

            firebase.database()
                .ref(
                    'convomessages/' +
                    this.state.convo_id +
                    '/' +
                    messageid +
                    '/date_deleted'
                )
                .set(firebase.database.ServerValue.TIMESTAMP)

        }
    };

    _renderRow = (raw_item) => {
        const { context } = this.props || {};
        const { userAuthenticated: _u } = context || {};

        const v = raw_item;
        const from_me = v.uid === _u.uid;
        const { likes,
            // message, mid, time, uid, date_deleted 
        } = v || {};

        return (
            <div
                className="convo-message-list-item"
                key={v.mid}
                style={{
                    //flexDirection:'row',
                    padding: 5, //v.date_deleted?5:10,
                    //paddingBottom:5,
                    marginBottom: 15,
                    marginHorizontal: 5,
                    marginRight: !from_me ? 100 : 5,
                    marginLeft: from_me ? 100 : 5,
                    backgroundColor: !from_me ? Colors.sec_color : '#ffffff',
                    borderRadius: 20,
                    borderColor: Colors.sec_color,
                    //borderWidth:from_me?1:0,
                    borderBottomRightRadius: from_me ? 0 : 10,
                    borderTopRightRadius: from_me ? 0 : 10,
                    borderTopLeftRadius: !from_me ? 0 : 10,
                    borderBottomLeftRadius: !from_me ? 0 : 10
                }}
            >
                {
                    Boolean(v.date_deleted)
                    &&
                    (<div
                        style={{
                            color: Colors.sec_color
                        }}>
                        <Typography color="inherit">message deleted</Typography>
                    </div>
                    )
                }
                {
                    !v.date_deleted
                    && (
                        <div
                            style={{
                                color: from_me ? Colors.sec_color : '#fff',
                                textAlign: 'left'
                            }}
                        >
                            <div>
                                {
                                    !from_me
                                    &&
                                    <img
                                        className="yogi-photo"
                                        style={{
                                            borderRadius: 25,
                                            height: 49,
                                            width: 49,
                                            border: '1px white solid',
                                            position: 'relative',
                                            display: 'inline-block',
                                            textAlign: 'left'
                                        }}
                                        src={(this.state && this.state.recipient_photo_url) || 'https://placehold.it/100'}
                                        alt="" />
                                }
                                <pre style={{ whiteSpace: 'pre-wrap' }}>{v.message}</pre>
                            </div>
                            <div
                                style={{
                                    position: 'relative',
                                    flexDirection: 'row',
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingTop: 5,
                                    marginTop: 5,
                                    marginBottom: 5,
                                    borderTopWidth: 1,
                                    borderColor: (from_me ? Colors.sec_color : '#ffffff') + '44',

                                }}
                            >
                                <Typography
                                    style={{
                                        textAlign: from_me ? 'right' : 'left',
                                        fontSize: 10,
                                        color: from_me ? Colors.sec_color : '#fff'
                                    }}
                                >
                                    {UtilFX.getLoquaciousDate(v.time)}
                                </Typography>
                                {
                                    from_me
                                    && Object.keys(likes || {}).length > 0
                                    &&
                                    <div style={{ flexDirection: 'row' }}>
                                        <Icon>thumb_up</Icon>
                                        <Typography style={{ color: Colors.sec_color, fontSize: 10 }}>{`(${Object.keys(likes || {}).length})`}</Typography>
                                    </div>
                                }
                                {
                                    from_me ?
                                        (
                                            //<View>
                                            <div
                                                onClick={() => {
                                                    // console.log("del me", v);
                                                    this._doDeleteMessage(v.mid);
                                                }}
                                                style={{
                                                    // position: "absolute",
                                                    // bottom: -5,
                                                    // right: -5,
                                                    cursor: 'pointer',
                                                    paddingLeft: 5,
                                                    paddingTop: 5,
                                                    paddingBottom: 5,
                                                    paddingRight: 5
                                                }}
                                            >
                                                <Icon>close</Icon>
                                            </div>
                                        )
                                        :
                                        (
                                            <div
                                                onClick={() => {
                                                    // console.log("like or unlike me", v);
                                                    // this._doDeleteMessage(v.mid);
                                                    const { convo_id } = this.state;
                                                    // console.log({ convo_id });
                                                    if (convo_id) {
                                                        //setup to listen and retrieve the messages
                                                        const userLikeData = likes && likes[_u.uid];
                                                        const likeData = { [_u.uid]: userLikeData ? null : moment().utc().valueOf() };
                                                        // console.log({ likeData });
                                                        firebase.database()
                                                            .ref(`convomessages/${convo_id}/${v.mid}/likes`)
                                                            .update(likeData);
                                                    }
                                                }}
                                                style={{
                                                    position: 'absolute',
                                                    bottom: -5,
                                                    right: 0,
                                                    paddingLeft: 5,
                                                    paddingTop: 5,
                                                    paddingBottom: 5,
                                                    paddingRight: 5,
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <div style={{ color: Object.keys(likes || {}).length ? '#fff' : '#00000055' }}>
                                                    <Icon>thumb_up</Icon>
                                                </div>
                                            </div>
                                        )
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }
    _doSendMessage = () => {
        // ReCaptcha start
        // const { recaptchaResult } = this.state;
        // if (!UtilFX.getIsDevEnv()) {
        //     if (!recaptchaResult) {
        //         console.warn("no result, probably not filled then.");
        //         return this.recaptchaRef.current.execute();
        //     }
        //     // return console.info({ recaptchaResult });
        //     if (recaptchaResult && !recaptchaResult.success) {
        //         this.setState({ error: "Unable to verify captcha." });
        //         return console.warn("We were unable to verify a valid human trace.");
        //         // return this.recaptchaRef.current.execute();
        //     }
        // }
        // ReCaptcha end

        const { context } = this.props || {};
        const { userAuthenticated: _u } = context || {};
        const { convo_text } = this.state || {};
        // console.log("_u", { _u, props: this.props });
        if (!(_u && _u.uid)) {
            return;
        }
        this.setState({ sending_message: true });

        //var sender_id = this.navigation.state.params.sid
        var recipient_id = this.state.recipient_id;
        if (!recipient_id) { return window && window.alert('no recipient specified'); }

        if (recipient_id === _u.uid) {
            // console.log("Sending to self");
            return window && window.alert('You cant send message to yourself at the moment');
        }
        if (!convo_text || (convo_text && convo_text.trim().length === 0)) {
            return window && window.alert('Please enter a message to send');
        }
        //this.FBA.database().ref("convomembers/").orderByChild(_u.uid).once('value',snap=>{
        //var cm = snap.val()
        var existing_convo_id = this.state.convo_id;
        //search for a convo with this recipient

        if (!existing_convo_id) {
            const data_cm = {};
            data_cm[_u.uid] = true;
            data_cm[this.state.recipient_id] = true;
            existing_convo_id = firebase.database()
                .ref('convomembers')
                .push(data_cm).key;
            const data_cbu = {};
            data_cbu[`convobyusers/${_u.uid}/${existing_convo_id}`] = true;
            data_cbu[`convobyusers/${this.state.recipient_id}/${existing_convo_id}`] = true;
            firebase.database()
                .ref()
                .update(data_cbu);

            this.setState({ convo_id: existing_convo_id }, () => {
                this._getConvoMessages(); //call this to turn on the monitorning of this node so udpates show up, not hat acutal messages would be loaded. excep the wone we are about to push there below
            });
        }

        //if current convo size is 0, then this will make 1. dismiss the keyboard so that the first message shows. user can reactivate keyboard. will only happen this one time. this is a hacky fix but a fix
        if ((this.state.convo_messages || []).length === 0) {
            //duplicate the message and mark it
            // Keyboard.dismiss();
        }

        //now we should have an existing_convo_id for sure no mattter what. retrieved or created
        var data_msg = {
            uid: _u.uid,
            message: this.state.convo_text,
            date_created: firebase.database.ServerValue.TIMESTAMP
        };
        //add a new message
        var message_id = firebase.database()
            .ref('convomessages/' + existing_convo_id)
            .push(data_msg).key;

        //update/set the data for last message
        data_msg['message_id'] = message_id;
        firebase.database()
            .ref('convometadata/' + existing_convo_id)
            .update(data_msg)
            .then(() => {
                // this.refs.convo_ScrollView && this.refs.convo_ScrollView.scrollToEnd();
                // this.refs.flatList1 && this.refs.flatList1.scrollToEnd();
                this.setState({
                    convo_text: '',
                    convo_height: 40, //could be anything low since heihgt gets et by a Math.Max function below
                    sending_message: false
                });
            })
            .catch(err => {
                window && window.alert('We encountered an error while saving your message.');
            });
    };

    _handleChange = (e) => {
        let finalVal = e.target.value;
        this.setState({ convo_text: finalVal });
    }
    render() {
        const { isLoading, convo_messages } = this.state;
        const { onGoBack } = this.props;

        if (isLoading) {
            return <Loading>Fetching conversation.</Loading>;
        }
        return (
            <div style={{ padding: '20px 0' }}>
                {
                    Boolean(onGoBack)
                    &&
                    <div style={{ backgroundColor: Colors.pri_color }}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => {
                                onGoBack();
                            }}
                        ><Icon>arrow_back</Icon>Go back</Button>
                    </div>
                }

                <div style={{
                    // ...GlobalStyles.textInputContainer,
                    margin: '20px 0 15px 0',
                }}>
                    <img
                        className="yogi-photo"
                        style={{
                            borderRadius: 25,
                            height: 49,
                            width: 49,
                            border: '1px white solid',
                            position: 'relative',
                            display: 'inline-block',
                            textAlign: 'left'
                        }}
                        src={(this.state && this.state.recipient_photo_url) || 'https://placehold.it/100'}
                        alt="" />
                    <div style={{ marginBottom: 10, marginTop: 5, backgroundColor: '#fff', display: 'flex' }}>
                        <textarea
                            id="convo-message-field"
                            placeholder="Enter a message"
                            style={{
                                ...GlobalStyles.textInputDefault,
                                display: 'block',
                                fontSize: '1rem',
                                height: '50px',
                                width: '280px',
                                // borderRadius: 10,
                                padding: '10px',
                                backgroundColor: ' #00000055'
                            }}
                            // type={(isEmail && "email") || "text"}
                            name="convo_text"
                            value={this.state.convo_text}
                            onChange={this._handleChange} />
                    </div>
                    {
                        Boolean(this.state.error)
                        && <AlertBox>
                            <strong>Error: </strong>{this.state.error}
                        </AlertBox>
                    }
                    <Button
                        id="convo-send-button"
                        fullWidth
                        onClick={() => {
                            this._doSendMessage();
                        }}>
                        Send&nbsp;<Icon>send</Icon></Button>
                    <ReCAPTCHA
                        ref={this.recaptchaRef}
                        size="invisible"
                        sitekey="6Le7vo4UAAAAAHJdIZiVMxxwI1rqAWsjxduc8CMs"
                        onChange={(val) => {
                            this._mounted && this.setState({ recaptchaClientOutput: val }, () => {
                                const { recaptchaClientOutput } = this.state;
                                if (!recaptchaClientOutput) {
                                    return console.warn('No state set. not sure enough to continue.');
                                    // return this.recaptchaRef.current.execute();
                                }

                                const requestOptions = {
                                    method: 'GET',
                                };
                                const target = `https://encoded-ensign-158201.appspot.com/recaptcha/${recaptchaClientOutput}?naked=yes`; // TODO: maybe use production google cloud service for reliability
                                return fetch(target, requestOptions)
                                    .then(res => res.json())
                                    .then(
                                        (result) => {
                                            console.info({ result });
                                            // this._mounted && 
                                            this._mounted && this.setState({
                                                // isLoaded: true,
                                                recaptchaResult: result
                                            }, () => { this._doSendMessage(); });
                                        }).catch(err => {
                                            // this._mounted && 
                                            console.warn('there was an error getting recaptcha response from appengine server', { err });
                                            this._mounted && this.setState({
                                                // isLoaded: true,
                                                recaptchaResult: null,
                                                error: err
                                            });
                                        });

                            })
                        }}
                    />
                </div>

                <hr />
                <div
                    id="convo-message-list"
                    style={{
                        maxHeight: 500,
                        overflowY: 'scroll',
                        borderBottom: '4px white double'
                    }}
                >
                    {
                        convo_messages && convo_messages.map(this._renderRow)
                    }
                </div>
            </div >
        );
    }
}

Conversation.propTypes = {
    recipientId: PropTypes.string,
    recipientPhotoUrl: PropTypes.string,
    onGoBack: PropTypes.func.isRequired,
}
