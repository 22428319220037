import React, { Component } from 'react';

// import {
//     Link,
//     Redirect,
// } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
// import Icon from '@material-ui/core/Icon';

// import { withStyles, withTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import Icon from '@material-ui/core/Icon';
// import SaveIcon from '@material-ui/icons/Save';
// import MdStarBorder from '@material-ui/icons/StarBorder';
// import MdEmail from '@material-ui/icons/Email';
// import MdLock from '@material-ui/icons/Lock';


// import { SocialIcon } from 'react-social-icons';
// import TextField from '@material-ui/core/TextField';

// import axios from 'axios';
// import moment from 'moment';
import {
    // UtilFX,
    // DataFX,
    Colors,
    // GlobalContext,
    GlobalStyles,
    // AlertBox,
} from '../utils';
import { firebase } from '../fire';
// import { Colors } from "../utils/index";
import moment from 'moment';
import ButtonComponent from './common/ButtonComponent';
import AvatarDisplay from './AvatarDisplay';

export default class Invites extends Component {
    state = {
        invites_obj: {},
        invites_arr: [],
        invites_count: 0,
        invites_unseen_count: 0,
        oldestInviteDateCreated: '',
        latestInvites: []
    };

    componentDidMount() {
        this._mounted = true;
        this.gatherInviteData();
    }

    componentWillUnmount() {
        this._mounted = false;
    }
    UNSAFE_componentWillUpdate(newProps, ) {

        // const { context: { userAuthenticated = {} } } = newProps;
        // const { uid } = userAuthenticated;
        // if (uid) {
        //     firebase.database()
        //         .ref(`invitesbystudentid/${uid}`)
        //         .orderByChild("date_created")
        //         .once("value", snap => {
        //             let invites_count = 0;
        //             let invites_unseen_count = 0;
        //             snap.forEach(v => {
        //                 const _i = v.val();
        //                 if (!(_i.date_registered || _i.date_deleted)) {
        //                     invites_count += 1;
        //                 }
        //                 if (_i.date_created > (userAuthenticated.date_invites_last_seen || 0)) {
        //                     invites_unseen_count += 1;
        //                 }
        //             });
        //             this.setState({ invites_count, invites_unseen_count }, () => { this.gatherInviteData(); });
        //         });
        // }
    }
    _markInvitesAsSeen = () => {
        const { context } = this.props || {};
        const { userAuthenticated: _u } = context || {};
        const uid = _u && _u.uid;

        uid && firebase.database().ref(`users/${uid}`)
            .update({ date_invites_last_seen: Date.now() });
    }
    gatherInviteData = () => {
        const { context } = this.props || {};
        const { userAuthenticated: _u } = context || {};
        const uid = _u && _u.uid;

        firebase.database()
            .ref(`invitesbystudentid/${uid}`)
            .orderByChild('date_created')
            .endAt(this.state.oldestInviteDateCreated || '')
            .limitToLast(20)
            .once('value', snap => {

                const _invs = snap.val() || {};
                const inviteTeacherPromises = Object.keys(_invs).map(_invId => {
                    const _inv = _invs[_invId];
                    return firebase.database()
                        .ref(`users/${_inv.teacherid}`)
                        .once('value', teacherUser_snap => {
                            const teacherUser = teacherUser_snap.val();

                            // return firebase.database()
                            //   .ref(`classesbyuserid/${_inv.teacherid}/${_inv.classid}`)
                            //   .once("value", class_snap => {
                            //     const classObj = class_snap.val();

                            // _invs[_invId]._class = classObj || {};
                            _invs[_invId]._teacher = teacherUser || {};
                            _invs[_invId].key = _invId;
                            // });
                        });
                });

                const inviteClassPromises = Object.keys(_invs).map(_invId => {
                    const _inv = _invs[_invId];
                    // console.log({ _inv });
                    return firebase.database()
                        .ref(`classesbyuserid/${_inv.teacherid}/${_inv.classid}`)
                        .once('value', class_snap => {
                            const classObj = class_snap.val();

                            _invs[_invId]._class = classObj || {};
                        });
                });

                Promise.all(
                    []
                        .concat(inviteClassPromises)
                        .concat(inviteTeacherPromises)
                )
                    .then((snap_arr) => {
                        // console.log({ _invs });
                        const invites_arr = Object.values(_invs).sort(
                            (a, b) => {
                                return (
                                    b.date_created - a.date_created
                                );
                            }
                        ).filter(inv => !(inv.date_deleted || inv.date_registered));
                        const lengthOfInviteArray = invites_arr.length;
                        const oldestInvite= invites_arr[lengthOfInviteArray - 1];
                        if(oldestInvite?.date_created){
                            invites_arr.splice(lengthOfInviteArray - 1, 1);
                        }
                        const previousInvites = this.state.invites_arr;
                        this.setState({
                            invites_obj: _invs,
                            invites_arr: [ ...previousInvites, ...invites_arr ],
                            latestInvites: invites_arr.length ? invites_arr : [],
                            oldestInviteDateCreated: oldestInvite?.date_created
                            // refreshing: false,
                            // pristine: false
                        }, () => {
                            this._markInvitesAsSeen();
                        });
                    });
            });
    }



    render() {
        const { history } = this.props;
        const {
            // invites_count,
            // invites_unseen_count,
            // invites_obj,
            invites_arr
        } = this.state || {};

        return (
            <Grid container justify="center">

                <Grid item xs={12}>
                    <Typography variant="h1" style={{fontSize:'1.5rem'}} className="page-title">Invites</Typography>
                    <div style={{}} className="invite-wrapper">
                        {
                            Boolean(invites_arr && invites_arr.length)
                            &&
                            (
                                invites_arr.map(inv => {
                                    const { _teacher, _class } = inv || {};
                                    return (
                                        <div
                                            key={inv.key}
                                            style={{
                                                flexDirection: 'row',
                                                display: 'flex',
                                                border: '1px #fff solid',
                                                borderRadius: 5,
                                                padding: 20,
                                                marginBottom: 10,
                                                marginLeft: 20,
                                                marginRight: 20,
                                                // width: "100vh",
                                            }}
                                            className="touchable-element"
                                            onClick={() => {
                                                //go to class
                                                const classUrl = `/${_teacher.username}/class/${inv.classid}`;
                                                history.push(classUrl);
                                            }}
                                        >
                                            

                    <AvatarDisplay
                        imgSrc={_teacher.profile_photo_url}
                        imgAlt={'instructor'}
                        imgClassName={'yogi-photo'}
                        imgStyles={{ width: 58, height: 58, borderRadius: '50%' }}
                        userName={_teacher.fullname}
                        textStyles={{
                      ...GlobalStyles.bodyMedium,
                      color: 'rgb(23, 155, 247)',
                      textAlign: 'center',
                      borderRadius: '50px',
                      border: '2px solid',
                      display: 'flex',
                      alignItems: 'center',
                      height: '30px',
                      width: '30px',
                      justifyContent: 'center',
                      margin: 'auto',
                    }}
              />
                                           
                                            <div style={{ textAlign: 'left', marginLeft: 10 }}>
                                                <Typography>@{_teacher.username} invited you to:</Typography>
                                                <Typography>{_class.title}</Typography>
                                                <Typography style={{ color: '#fff' }} variant="caption">created: {moment(inv.date_created).format('YYYY-MM-DD')}</Typography>
                                            </div>

                                        </div>
                                    );
                                })
                            )
                        }
                    </div>
                    <div style={{ margin: 'auto', width: '30%' }}>
                        {
                            Boolean(this.state.latestInvites.length) && (
                            <ButtonComponent
                                buttonStyle={{ marginBottom: 24, marginTop: 20, padding: '10px', backgroundColor: Colors.sec_color }}
                                type="submit"
                                value={'More'}
                                onClick={() => {
                                    this.gatherInviteData();
                                }}
                            />
                        ) }
                    </div>
                </Grid>
            </Grid>
        );
    }
}
