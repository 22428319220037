import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Colors, GlobalStyles } from '../../../utils';
import moment from 'moment';
import TitleCardOptions from '../TitleCardOptions';
import MuteIcon from './MuteIcon';
import PinIcon from './PinIcon';
import AvatarDisplay from '../../AvatarDisplay';

const ArchiveConversations = ({
  badge,
  setRecipientId,
  convos,
  setConvoId,
  setCurrentChatDetails,
  convoMembersUserObj,
  pinMessageHandler,
  recipientId,
  setupConvos,
  _u,
  archiveConvoIds,
  handleArchiveConvoIds,
  pinnedConvoIds,
  mutedConvoIds,
  handlePinnedConvoIds,
  handleMutedConvoIds,
  deleteDMConvo,
  deletedConvoIds
}) => {
  const [showIcon, setShowIcon] = useState({ mute: false, pin: false });
  const [currentId, setCurrentId] = useState('');

  return (
    convos &&
    convos
      .filter((convo) => archiveConvoIds.includes(convo.convo_id) && !deletedConvoIds.includes(convo.convo_id))
      .sort((a, b) => b.date_archived - a.date_archived)
      .map((convo, key) => {

       
        return (
          <div
            key={key}
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              cursor: 'pointer',
              padding: '13px 0',
              borderBottom: `1px solid ${Colors.brightGrey}`,
              backgroundColor:
                convo.other_uid === currentId || recipientId === convo.other_uid
                  ? Colors.whisper
                  : null,
            }}
          >
            <div style={{ marginLeft: '16px', marginRight: 0 }}>
              
             <AvatarDisplay
                imgSrc={(convoMembersUserObj[convo.other_uid] || {}).profile_photo_url}
                imgAlt={'title-avatar'}
                imgStyles={{ width: '48px', height: '48px', borderRadius: '24px' }}
                userName={convoMembersUserObj && (convoMembersUserObj[convo.other_uid]).fullname}
                textStyles={{
                  ...GlobalStyles.bodyMedium,
                  color: 'rgb(23, 155, 247)',
                  textAlign: 'center',
                  borderRadius: '50px',
                  border: '2px solid',
                  display: 'flex',
                  alignItems: 'center',
                  height: '30px',
                  width: '30px',
                  justifyContent: 'center'
            }}
             />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: '16px',
                width: '100%',
                marginTop: '4px',
              }}
            >
              <div>
                <Typography
                  style={{
                    ...GlobalStyles.bodyMedium,
                    color: Colors.grey1,
                    textAlign: 'left',
                  }}
                >
                  {(convoMembersUserObj[convo.other_uid] || {}).username || ''}
                  {/* {!from_me ? (`${((convoMembersUserObj[convo.other_uid] || {}).username || "")}:  `) : "You:  " } */}
                </Typography>
                {convo.message_text &&
                  <Typography
                    style={{
                      ...GlobalStyles.bodyRegular,
                      color: Colors.grey3,
                      textAlign: 'left',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '195px',
                    }}
                  >
                    {/* {(from_me ? 'You: ' : '') + (convo.date_last_message_deleted ? '[Not Available]' : convo.message_text)} */}
                    {convo.date_last_message_deleted
                      ? '[Not Available]'
                      : convo.message_text}
                  </Typography>}
              </div>
              <div>
                <Typography
                  style={{
                    ...GlobalStyles.bodyRegular,
                    color: Colors.grey3,
                    fontSize: '10px',
                  }}
                >
                  {moment(convo.date_created).format('hh:mm a')}
                </Typography>
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <MuteIcon handleMutedConvoIds={handleMutedConvoIds} mutedConvoIds={mutedConvoIds} convo={convo} />
                  {badge && (
                    <div
                      style={{
                        padding: '2px',
                        backgroundColor: Colors.primaryColor,
                        borderRadius: '10px',
                        marginTop: '3px',
                        marginLeft: '2px',
                        marginRight: '2px',
                      }}
                    >
                      <Typography
                        style={{
                          ...GlobalStyles.bodyRegular,
                          fontSize: '10px',
                          textAlign: 'center',
                        }}
                      >
                        {badge}
                      </Typography>
                    </div>
                  )}
                  <PinIcon handlePinnedConvoIds={handlePinnedConvoIds} pinnedConvoIds={pinnedConvoIds} convo={convo} />
                </div>
              </div>
            </div>
            <div style={{ marginTop: '5px' }}>
              <TitleCardOptions
                titlePopover
                setShowIcon={setShowIcon}
                showIcon={showIcon}
                handleArchiveConvoIds={handleArchiveConvoIds}
                archiveConvoIds={archiveConvoIds}
                convo={convo}
                setCurrentId={setCurrentId}
                uid={_u.uid}
                setupConvos={setupConvos}
                handlePinnedConvoIds={handlePinnedConvoIds}
                pinnedConvoIds={pinnedConvoIds}
                handleMutedConvoIds={handleMutedConvoIds}
                mutedConvoIds={mutedConvoIds}
                archive={true}
                deleteDMConvo={deleteDMConvo}
                deletedConvoIds={deletedConvoIds}
              />
            </div>
          </div>
        );
      })
  );
};

export default ArchiveConversations;
