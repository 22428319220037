import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import moment from 'moment-timezone';
// import PeopleIcon from "@material-ui/icons/People";
// import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Typography from '@material-ui/core/Typography';
// import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
// import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
// import EditIcon from "@material-ui/icons/Edit";
// import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
// import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import isEqual from 'lodash.isequal';
import imgRocket from '../rocket.svg';
import Button from '@material-ui/core/Button';
import ButtonComponent from './common/ButtonComponent';
import {
  UtilFX,
  Loading,
  Colors,
  GlobalStyles,
  NumberOfLimitedCommunityClasses,
  ConvoMetadata
} from '../utils';
import YogiPicker, { InviteActionComponent } from './YogiPicker';
import { firebase, firestore } from '../fire';
import '../App.css';
import { SingleCard, IconCard } from './common/ClassesManageCards';
import ClearCard from './common/ClearCard';
import ImgCommunity from '../community.png';
import ImgPersonal from '../personal.png';
import ImgClassesZeroState from '../manage_classes_zero_state.svg';
import ImgClassShareCTA from '../cta_share_a_class.svg';

const daysOfWeekItems =
  [
    { id: 0, value: 'Sunday', nickname: 'Su' },
    { id: 1, value: 'Monday', nickname: 'Mo' },
    { id: 2, value: 'Tueday', nickname: 'Tu' },
    { id: 3, value: 'Wednesday', nickname: 'We' },
    { id: 4, value: 'Thursday', nickname: 'Th' },
    { id: 5, value: 'Friday', nickname: 'Fr' },
    { id: 6, value: 'Saturday', nickname: 'Sa' }
  ];

class ClassesManage extends Component {
  constructor(props) {
    super(props);
    this.FBA = firebase;
    this.auth_user = props.context.userAuthenticated;
    this.user = null;
    this.max_length_bio = 300;
    this.state = {
      showStudents: false,
      openSearchModal: false,
      invitedStudents: [],
      selectedClassToShare: {},
      // showAddACLassModal: true
      teacherProUpgrade: false
    };
    this.original_state_object = {};
  }

  componentDidMount() {
    UtilFX.getFullCommunityClassesCount(this.auth_user.uid).then(response => this.setState({ communityClassCount: response }));
    UtilFX.getFullPersonalClassesCount(this.auth_user.uid).then(response => this.setState({ personalClassCount: response }));
    Promise.resolve(UtilFX.getDefaultTimeZoneValue()).then(result => this.setState({ defaultTimeZoneValue: result }));
    UtilFX.getUserContentShownHistory(this.auth_user.uid).then(response => this.setState({ shareAClassCTA: response && response.share_a_class }));
  }

  getDaysOfWeekItems = ({ nick }) => {
    const result = daysOfWeekItems.filter(i => i.nickname.toUpperCase() === nick.toUpperCase())[0];
    const new_result = result.value.substring(0, 2);
    return new_result;
  }

  _addNewClass = class_to_copy_id => {
    // if (class_to_copy_id) { this.setState({ class_to_copy_id: class_to_copy_id }); }
    //get the limit for this user
    // Analytics.logEvent("profile_addclass", {
    //   count: this.state.classes_count,
    // });
    // Analytics.logEvent("level_up", {
    //   level: this.state.classes_count,
    //   character: "profile_addclsass"
    // });
    this.props.history.push('/classesedit', {
      ctcid: class_to_copy_id || null,
    });
  };

  _handleCloseSearchModal = () => {
    this.setState({ openSearchModal: false });
  }

  _handleOpenSearchModal = () => {
    this.setState({ openSearchModal: true });
  }

  _getShareLink = () => {
    const { selectedClassToShare } = this.state;
    const { username } = this.props.initialData.fyUser;
    const { cid } = selectedClassToShare;
    const baseUrl = UtilFX.getWebsiteBaseUrl();
    const url = baseUrl + `${username}/class/${cid}`;
    UtilFX.copyToClipboard(url);
    UtilFX.toastSuccess('Copied to Clipboard.', { toastId: 'Copied to Clipboard.' });
  }

  onMark = (item) => {
    const { invitedStudents: tmpArr, selectedClassToShare: { cid, uid } } = this.state;
    const { objectID: studentid } = item || {};
    const isStudentInArray = !!tmpArr.filter(value => isEqual(value, item)).length;
    if (!isStudentInArray) {
      tmpArr.push(item);
      UtilFX.sendClassInvitationToStudent({ studentid, cid, uid });
      UtilFX.toastSuccess('Invite sent successfully', { toastId: 'Invite sent successfully' });
      this.setState({ invitedStudents: tmpArr });
    }
  }

  handleTicketPriceInfo = (item) => {
    const { fyUser } = this.props.initialData;
    if (!fyUser) return;
    if (item.price) {
      return UtilFX.getTicketDescriptionString({ class_type: item.class_type, recurrence_rule: item.recurrence_rule, price_billing_cycle: item.price_billing_cycle, quantity: item.quantity, price: item.price, fees_absorb: item.fees_absorb, teacher: fyUser });
    }
    if (item.quantity) {
      return `Free to register with ${item.quantity} tickets available`;
    }
    return 'Free to register';
  }

  newManageClassesList = ({ items: classItem }) => classItem[0].data.map((item, id) => {
    const { defaultTimeZoneValue } = this.state;
    const { fyUser } = this.props.initialData || {};
    const isTeacherPro = ((fyUser && fyUser.config && fyUser.config.paid_plan) || '').includes('teacherpro');
    return (
      <div key={id} style={{ marginBottom: '40px' }}>
        <Grid container
          style={{
            margin: '20px 0',
            backgroundColor: Colors.white,
            borderRadius: '12px',
            flex: 1
          }}
          className="cursor-pointer"
          // onClick={() => this.props.history.push(`/classesedit/${item.cid}`)}
        >
          <Grid item md={12} xs={12}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '20px' }}>
                <Typography style={{ ...GlobalStyles.bodyRegular, color: '#BDBDBD' }}>
                  {' '}
                  {
                    item.class_type === 'community' ?
                    <img alt="community icon" src={ImgCommunity} style={{ marginRight: '9px' }} />
                    :
                    <img alt="personal icon" src={ImgPersonal} style={{ marginRight: '9px' }} />
                  }
                  {' '}
                {
                    item.class_type === 'community' ?
                    'Community class' :
                    'Personal class'
                  }
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'row', border: `1px solid ${item.status ? (item.status === 'sub' ? '#FDF6E5' : '#FFEEEE') : '#E1F4FE'}`, padding: '5px', borderRadius: '12px', marginTop: '-5px' }}>
                  <span style={{ color: item.status ? (item.status === 'sub' ? Colors.yellow : Colors.red) : Colors.pri_color, fontSize: '20px', marginRight: '9px' }} className="material-icons">
                    { item.status === 'cancel' ? 'block' : 'error_outline'}
                  </span>
                  <Typography style={{ color: item.status ? (item.status === 'sub' ? Colors.yellow : Colors.red) : Colors.pri_color }}>{item.status ? (item.status === 'sub' ? 'Subbed out' : 'Cancelled') : 'Active'}</Typography>
                </div>
              </div>
              <Typography style={{ ...GlobalStyles.titleBold, color: Colors.grey1, textAlign: 'left', margin: '5px 20px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item.title}</Typography>
            <hr style={{ border: '1px solid #F6F6F6', }} />
          </Grid>
          <Grid item md={12} xs={12} style={{ margin: '16px 0' }}>
            <SingleCard iconName="watch_later" title={`${moment(item.calculated_class_start_time).format('h:mma')} - ${moment(item.calculated_class_start_time).add(item.duration, 'minutes').format('h:mma')} (${item.duration} min) ${UtilFX.checkTimeZoneValue({ timeZone: item.time_zone, defaultTimeZoneValue })}`} />
              <SingleCard iconName="location_on" title={(item.address && item.loc) || 'No physical class'} />
            {
              item.virtual_link && (
              <SingleCard iconName="videocam" title={item.virtual_link} color={Colors.primaryColor} />
              )
            }
            <SingleCard iconName={item.private ? 'visibility_off' : 'remove_red_eye'} title={item.private ? 'Visible to you and those you invite' : 'Visible to the Public'} />
            <SingleCard iconName="attach_money"
              title={this.handleTicketPriceInfo(item)}
            />
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', marginLeft: '23px', marginTop: '8px' }}>
              <span className="material-icons" style={{ color: Colors.grey4, marginRight: '17px', alignSelf: 'center', fontSize: 20 }}>
                date_range
              </span>
              {
              item.recurrence_rule && item.recurrence_rule.length ?
                (
                  <div>
                    {
                      UtilFX.getRecurrenceRuleParts(item.recurrence_rule).byday.split(',')
                        .map(nick => (
                            <Typography key={nick} style={{ color: Colors.grey1, marginLeft: '2px', display: 'inline-block', ...GlobalStyles.bodyRegular }}>
                              {
                                this.getDaysOfWeekItems({ nick })
                              }
                            </Typography>
                        ))
                    }
                    <Typography style={{ color: Colors.grey1, marginLeft: '5px', display: 'inline-block', ...GlobalStyles.bodyRegular }}>{`${Number(UtilFX.getRecurrenceRuleParts(item.recurrence_rule).interval) === 1 ? '/ week' : ' / 2 weeks'}`}</Typography>
                  </div>
                )
                :
                (
                  <Typography
                    style={{ ...GlobalStyles.bodyRegular, color: Colors.grey1, }}
                  >
                    {' '}
                    {
                      moment(item.calculated_class_start_time).format('dddd, MMMM Do YYYY ')
                    }
                  </Typography>
                )
            }
            </div>
          </Grid>
          <Grid item md={12} xs={12} style={{ margin: '15px 20px 15px 20px' }}>
            <IconCard iconName="people" eventHandler={async() => {
              let query = firestore.collection(ConvoMetadata);
              query = query.where('teacher_id', '==', this.auth_user.uid);
              query = query.where('class_id', '==', item.cid);
              query.get().then((res) => {
                let convoId = '';
                if (!res.empty) {
                  convoId = res.docs[0].data().convo_id;
                  return this.props.history.push(`/chat/convo/${convoId}`);
                }
              });
              await UtilFX.setupClassGroupChat({
                uid: this.auth_user.uid,
                classTitle: item.title,
                profilePhotoUrl: this.auth_user.profile_photo_url,
                classId: item.cid,
                navigation: (convoid) => this.props.history.push(`/chat/convo/${convoid}`)
              });
            }} title="Visit class group chat" size="100%"
           />
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '8px', justifyContent: 'space-around' }}>
              <IconCard iconName="share"
                eventHandler={() => {
                this.setState({ selectedClassToShare: item });
                this._handleOpenSearchModal();
              }}
              title="Share"
              />
              <IconCard iconName="edit" eventHandler={() => this.props.history.push(`/classesedit/${item.cid}`)} title="Edit" />
              <IconCard iconName="clone"
                eventHandler={() => {
                if (item.class_type === 'personal') {
                  if (this.state.personalClassCount === 0 || isTeacherPro) {
                    this._addNewClass(item.cid);
                  }
                } else {
                  if (this.state.communityClassCount < NumberOfLimitedCommunityClasses || isTeacherPro) { return this._addNewClass(item.cid); }
                  }
                  this.setState({ teacherProUpgrade: true });
                }
              }
              title="Clone"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  });

  gotoProfileUrl = (e) => {
    // e.preventDefault();
    this.props.history.push('/profile');
  }

  onMark = (item) => {
    const { invitedStudents: tmpArr, selectedClassToShare: { cid, uid } } = this.state;
    const { objectID: studentid } = item || {};
    const isStudentInArray = !!tmpArr.filter(value => isEqual(value, item)).length;
    if (!isStudentInArray) {
      tmpArr.push(item);
      UtilFX.sendClassInvitationToStudent({ studentid, cid, uid });
      UtilFX.toastSuccess('Invite sent successfully', { toastId: 'invite-to-class' });
      this.setState({ invitedStudents: tmpArr });
    }
  }

  inviteAllStudents = async () => {
    const { fyUser: { students = {} } } = this.props.initialData || {};
    const { selectedClassToShare: { cid, uid } } = this.state;
    await Promise.all(Object.keys(students).map(sid =>
      UtilFX.sendClassInvitationToStudent({ studentid: sid, cid, uid }))
    );
    UtilFX.toastSuccess('Invites sent successfully', { toastId: 'invite-all-students-to-class' });
  };

  loader = () => {
    return (
      <div style={{ flex: 1, backgroundColor: Colors.pri_color }}>
        <div style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Loading>
            ...
          </Loading>
        </div>
      </div>
    );
  }

  render() {
    const {
      pristine,
      classes_count,
      // events_count,
      // classes_array,
      // classes,
      show_classes,
      classes_array_categorized,
      fyUser,
      students_count,
      // shareAClassCTA
    } = this.props.initialData || {};
    if (pristine) {
      return this.loader();
    }
    return (
      <Grid container>
        <Grid item xs={12}>
        {
          this.state.show_share_text_confirm && (
            <div style={{
              backgroundColor: '#fff',
              padding: 10,
              width: '100%',
              opacity: 1
            }}
            >
              <Typography style={{ color: Colors.sec_color, textAlign: 'center', }}>{this.state.url}</Typography>
            </div>
          )
        }

        <Typography style={{ ...GlobalStyles.bodyRegular, fontSize: '28px', color: Colors.white }} className="page-title">Manage Classes</Typography>

        {
          <div style={{ margin: '0px 16px' }}>
            <ButtonComponent
              buttonStyle={{ backgroundColor: '#258DF0', padding: '18px', borderRadius: '10px', border: '1px solid #258DF0' }}
              value='Add a class'
              onClick={() => {
                if (fyUser.username && fyUser.profile_photo_url) {
                  this._addNewClass();
                } else {
                  UtilFX.toastSend(
                    <Typography style={{ padding: 20 }}>
                      You must have a username and photo to create a class. Tap  here to setup your profile info.
                    </Typography>, { toastId: 'username and photo required', autoClose: false, onClick: this.gotoProfileUrl });
                }
              }}
            />
          </div>
        }
        <hr style={{ margin: '32px 0px', border: '1px solid #258DF0' }} />
        {
          !show_classes && (
          <this.loader />
        )}
        {
          (show_classes && (classes_array_categorized || []).length > 0) && (
          <div style={{ margin: '0px 16px' }}>
            <this.newManageClassesList items={classes_array_categorized || []} />
          </div>
        )}
        {
          (show_classes && (classes_array_categorized || []).length === 0) && (
          <div style={{ marginBottom: '40px' }}>
            <img alt='default-state-img' src={ImgClassesZeroState} />
            <Typography style={{ ...GlobalStyles.bodyMedium, textAlign: 'center', marginTop: '28px' }}>You have not created any classes yet.</Typography>
          </div>
        )}
        <Modal
          aria-labelledby="share classes"
          aria-describedby="share your classes."
          className="center-modal"
          open={this.state.openSearchModal}
          onClose={() => this._handleCloseSearchModal()}
          disableAutoFocus
          disableEnforceFocus
        >
          <div className="modal-content-wrapper" style={{
            backgroundColor: Colors.white,
            width: '380px',
            borderRadius: '12px'
           }}>
            <ClearCard cancelEvent={() => this._handleCloseSearchModal()} />
            {
              this.state.showShareUrl && (
                <div style={{
                  backgroundColor: '#fff',
                }}
                >
                  <Typography style={{ color: Colors.sec_color, }}>{this.state.url}</Typography>
                </div>
              )
            }
            <div style={{ margin: '50px' }}>
              <ButtonComponent value="copy link"
                onClick={() => this._getShareLink()}
                iconStyle={{ marginTop: '5px' }}
                buttonStyle={{ backgroundColor: Colors.pri_color }}
                iconName="link"
              />
              <ButtonComponent value="invite students"
                iconName="people"
                iconStyle={{ marginTop: '5px', color: students_count ? Colors.white : Colors.grey4 }}
                buttonStyle={{ marginTop: '20px', backgroundColor: students_count ? Colors.pri_color : Colors.grey6, color: students_count ? Colors.white : Colors.grey4, border: `1px solid ${students_count ? Colors.pri_color : Colors.grey6}` }}
                onClick={() => students_count && this.setState({ showStudents: !(this.state.showStudents) })}
              />
            </div>
            {(this.state.showStudents) && (
              <div>
                <hr style={{ border: `1px solid${Colors.grey6}` }} />
                <YogiPicker
                  {...this.props}
                  maxResults={3}
                  actionComponent={InviteActionComponent}
                  actionData={this.state.selectedClassToShare}
                  onSelected={this.onMark}
                  invitedStudents={this.state.invitedStudents}
                  fyUser={fyUser}
                  persistedUserIdList= {Object.keys(fyUser.students || {})}
                  showInviteAllStudentsButton = {true}
                  inviteAllStudentsAction={this.inviteAllStudents}
                />
              </div>
            )}
            {!students_count && (
              <div style={{ textAlign: 'center' }}>
                <hr style={{ border: `1px solid${Colors.grey6}` }} />
                <Typography style={{ ...GlobalStyles.bodyMedium, color: Colors.grey1, fontSize: '16px', marginTop: '32px' }}>You have no students yet.</Typography>
                <Typography style={{ ...GlobalStyles.bodyRegular, color: Colors.grey1, fontFamily: 'Rubik', marginTop: '8px', marginBottom: '32px' }}>Students who have added you as one of their <br/>favorite teachers will appear here.</Typography>
              </div>
            )}
          </div>
        </Modal>
        <Modal
          disableAutoFocus
          disableEnforceFocus
          aria-labelledby="premium upgrade"
          aria-describedby="upgrade to teacherPro."
          className="center-modal"
          open={this.state.teacherProUpgrade}
          onClose={() => {
            this.setState({ teacherProUpgrade: false });
          }}
        >
          <div className="modal-upgrade-wrapper" style={{ backgroundColor: Colors.white }}>
            <span
              className="material-icons"
              style={{ color: Colors.grey1, fontSize: 20, display: 'flex', justifyContent: 'flex-end', margin: '20px', cursor: 'pointer' }}
              onClick={() => this.setState({ teacherProUpgrade: false })}
            >
              clear
            </span>
            <div style={{ marginLeft: '40px', marginRight: '40px', justifyContent: 'center', marginTop: '50px', marginBottom: '40px' }}>
              <img src={imgRocket} alt="premium upgrade path" />
              <Typography style={{ fontFamily: 'Rubik', fontStyle: 'normal', fontWeight: '500', fontSize: '30px', color: '#333333', marginTop: '32px' }}>Time to upgrade ?</Typography>
              <Typography style={{ ...GlobalStyles.bodyRegular, color: '#333333', marginTop: '16px' }}>
                {' '}
                Creating more than one personal class is a
                <br />
                {' '}
                premium feature.
                {' '}
              </Typography>
              <Button
                size="small"
                variant="contained"
                style={{ ...GlobalStyles.bodyMedium, backgroundColor: Colors.primaryColor, color: Colors.white, borderRadius: '8px', marginTop: '36px', boxShadow: '0 0 0 0', padding: '18px', fontWeight: '600', textTransform: 'none' }}
                onClick={() => this.props.history.push('/limitsandplans')}
              >
                Learn more about the premium
              </Button>
            </div>
          </div>
        </Modal>
        {
          ((classes_count === 1) && !this.state.shareAClassCTA
          ) &&
            (<Grid container className='custom-modal'>
              <Grid item xs={12} md={12} className="custom-modal-wrapper" style={{
                margin: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}>
                <div style={{
                  width: '350px',
                  margin: 'auto',
                  borderRadius: '10px',
                  backgroundColor: Colors.white,
                }}>
                  <ClearCard cancelEvent={ async () => {
                    await firebase.database()
                    .ref(`showcontentbyuserid/${this.auth_user.uid}/share_a_class`)
                    .set(true);
                    this.setState({ shareAClassCTA: true });
                  }}/>
                  <img src={ImgClassShareCTA} style={{ margin: 0, width: '350px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                  alt='cta-share-a-class' />
                  <Typography style={{
 ...GlobalStyles.titleBold,
color: Colors.grey1,
                    marginTop: '20px'
                  }}>
                    Hurray!!!!
                  </Typography>
                  <Typography style={{ ...GlobalStyles.bodyRegular, color: Colors.grey3, marginTop: '10px' }}>You just made your first class. Now, let the world <br/> know about it.</Typography>
                  <div style={{ margin: '20px' }}>
                    <ButtonComponent
                      iconName={'share'}
                      iconStyle={{ marginRight: '22px' }}
                      onClick={ async () => {
                        await firebase.database()
                        .ref('showcontentbyuserid/' + this.auth_user.uid + '/share_a_class')
                        .set(true);
                        this.setState({ shareAClassCTA: true, selectedClassToShare: classes_array_categorized[0].data[0] }, () => this._handleOpenSearchModal());
                      }}
                      value="SHARE YOUR CLASS" buttonStyle={{ ...GlobalStyles.bodyMedium, backgroundColor: '#258DF0', borderRadius: '10px', border: '1px solid #258DF0', padding: '10px', boxShadow: 'none', marginBottom: '20px' }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default ClassesManage;
