import React, { Component } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TextAndIcon } from './common/ComponentGallery';
import { UpgradeConfirmation } from './PaymentStuff';
// import imgFyLogo from '../fylogo_primary_color.svg';
import imgQuestion from '../downgrade.svg';
import imgDowngradeSuccess from '../downgrade_success.svg';
import { firebase } from '../fire';
import imgPremiumUpgradeSuccess from '../success_premium_upgrade.svg';
import {
    UtilFX,
    Colors,
    GlobalStyles,
    NumberOfLimitedCommunityClasses
} from '../utils';
import '../App.css';

class TeacherPro extends Component {
    constructor(props) {
        super(props);
        this.auth_user = this.props.context.userAuthenticated;
        this.state = {
            teacherProUpgradeSuccess: false,
            // private: false,
            fyUser: null,
            receiptFromDb: {},
            yearlyCycle: false,
            openPayModal: false,
            isTeacherPro: null,
            openDowngradeModal: false,
            openDowngradeSuccessModal: false,
        };
    }

    componentDidMount() {
        firebase.database().ref(`receipts/${this.auth_user.uid}`)
            .once('value')
            .then(snap => {
                const receiptFromDb = snap.val() || {};
                const { type } = receiptFromDb;
                // must be no type or stripe type.
                // cant manage mobile-based (apple/google) from the web. stripe-only. Go through their mobile app store platform to manage subscriptions.
                const canManagePlanFromWeb = !type || (type === 'stripe');
                return UtilFX.getFYuser(this.auth_user.uid).then(fyu => {
                    const { config } = fyu;
                    this.setState({
                        // fyUser: fyu,
                        receiptFromDb,
                        canManagePlanFromWeb,
                        isTeacherPro: config && config.paid_plan,
                        yearlyCycle: (config && config.billing_cycle === 'year')
                    });
                });
            });
    }

    _handlePremiumUpgradeSuccessModal = () => {
        this.setState({ teacherProUpgradeSuccess: false });
        // if (this.props.history.length) { this.props.history.goBack(); }
    }

    closePayModal = () => {
        this.setState({ openPayModal: false });
    }

    openPayModal = () => {
        const {
            canManagePlanFromWeb,
            receiptFromDb: { type },
            isTeacherPro
        } = this.state;
        if (!canManagePlanFromWeb) {
            return UtilFX.toastSend(`You must manage your subscription via the mobile app, or the ${type} app store.`);
        }
        if (!isTeacherPro) {
            this.setState({ openPayModal: true });
        }
    }

    onToken = async (token) => {
        try {
            await firebase.database()
                .ref('users/' + this.auth_user.uid + '/config')
                .update({
                    paid_plan: 'teacherpro',
                    billing_cycle: this.state.yearlyCycle ? 'year' : 'month',
                    limit_teachers: null,
                    limit_classes: null,
                });
            this.setState({ teacherProUpgradeSuccess: true, isTeacherPro: true });
        } catch (err) {
            console.log('error updating the config', err);
        }
    }

    handleToggler = (e) => {
        this.setState({ [e.target.name]: e.target.checked });
    }

    initiateDowngrade = () => {
        const {
            canManagePlanFromWeb,
            receiptFromDb: { type },
            isTeacherPro
        } = this.state;
        // must be no type or stripe type.
        // cant manage mobile-based (apple/google) from the web. stripe-only. Go through their mobile app store platform to manage subscriptions.
        if (!canManagePlanFromWeb) {
            return UtilFX.toastSend(`You must manage your subscription via the mobile app, or the ${type} app store.`);
        }

        if (isTeacherPro) {
            this.setState({ openDowngradeModal: true });
        }
    }

    _handleDowngrade = () => {
        return firebase.database()
            .ref('receipts/' + this.auth_user.uid)
            .update({
                downgrade: true,
                last_update_date: Date.now()
            }).then(res => {
                this.setState({ openDowngradeModal: false, openDowngradeSuccessModal: true });
                // this._handleDowngrade();
                // this.handleDowngradeSuccessModal();
            })
            .catch(err => console.error('error updating receipts', err));
    }

    handleDowngradeSuccessModal = () => {
        this.setState({ openDowngradeSuccessModal: false, isTeacherPro: false });
    }

    render() {
        return (
            <div className="premium-upgrade-container"
                style={{ marginBottom: '-13px' }}
            >
                <div>
                    <br />
                    <br />
                    <br />
                    <Typography style={{ ...GlobalStyles.upgradeHeader, textAlign: 'center', color: Colors.grey1 }}>
                        The right
                </Typography>
                    <Typography style={{ ...GlobalStyles.upgradeHeader, textAlign: 'center', color: Colors.grey1 }}>
                        plans for your needs.
                </Typography>
                </div>
                <Typography style={{ ...GlobalStyles.bodyMedium, textAlign: 'center', color: Colors.grey3, marginTop: '12px' }}>
                    Reach a wider audience and create more revenue with unlimited personal classes on our Teacher Pro plan.
            </Typography>
                <Typography style={{ ...GlobalStyles.bodyRegular, textAlign: 'center', color: Colors.grey4 }}>* There is a standard bank fee of 2.9% + 30 cents</Typography>
                <Grid container justify='center' style={{ justifyContent: 'center', marginTop: '48px' }}>
                    <Grid md={4} xs={12} item style={{ margin: '15px', }}>
                        <div style={{ border: '1px solid #E0E0E0', borderRadius: '15px', marginTop: '40px' }}>
                            <div style={{ margin: '40px', textAlign: 'left', }}>
                                <Typography style={{ ...GlobalStyles.titleBold, color: Colors.grey1 }}>
                                    Basic
                        </Typography>
                                <Typography style={{ ...GlobalStyles.bodyMedium, color: Colors.grey4, }}>
                                    Perfect for individuals just starting out
                        </Typography>
                                <Typography style={{ color: Colors.grey1, fontFamily: 'Rubik', fontStyle: 'normal', fontWeight: '500', fontSize: '44px', marginTop: '34px' }}>Free</Typography>
                                <TextAndIcon iconStyle={{ color: Colors.primaryColor }} iconName='check_circle_outline' title='Additional $2 transaction fee' textStyle={{ marginLeft: '15px', color: Colors.grey1 }} viewStyle={{ marginTop: '39px' }} />
                                <TextAndIcon iconStyle={{ color: Colors.primaryColor }} iconName='check_circle_outline' title='1 personal class' textStyle={{ marginLeft: '15px', color: Colors.grey1 }} viewStyle={{ marginTop: '32px' }} />
                                <TextAndIcon iconStyle={{ color: Colors.primaryColor }} iconName='check_circle_outline' title={`${NumberOfLimitedCommunityClasses} community classes`}textStyle={{ marginLeft: '15px', color: Colors.grey1 }} viewStyle={{ marginTop: '32px' }} />
                                <Button size="large"
                                    variant="contained"
                                    style={{ ...GlobalStyles.bodyMedium, backgroundColor: this.state.isTeacherPro ? Colors.primaryColor : '#ECF5FE', color: this.state.isTeacherPro ? '#FFFFFF' : Colors.pri_color, borderRadius: '8px', width: '100%', marginTop: '52px', boxShadow: '0 0 0 0', padding: '22px', fontWeight: '600' }}
                                    startIcon={this.state.isTeacherPro ? null : <CheckCircleIcon style={{ fontSize: '30px' }} />}
                                    onClick={this.initiateDowngrade}
                                >
                                    {this.state.isTeacherPro ? 'Choose plan' : 'Current plan'}
                                </Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid md={4} xs={12} item style={{ margin: '15px', }}>
                        <span style={{ ...GlobalStyles.titleBold, textAlign: 'center', backgroundColor: '#1CC363', borderRadius: '24px', padding: '6px 20px', }}>Recommended</span>
                        <div style={{ border: '1px solid #E0E0E0', borderRadius: '15px', marginTop: '12px' }}>
                            <div style={{ margin: '40px', textAlign: 'left', }}>

                                <Typography style={{ ...GlobalStyles.titleBold, color: Colors.grey1 }}>
                                    Teacher Pro
                        </Typography>
                                <Typography style={{ ...GlobalStyles.bodyMedium, color: Colors.grey4, }}>
                                    Perfect for individuals with multiple concurrent classes.
                        </Typography>
                                <div style={{ marginTop: '34px', display: 'flex', flexDirection: 'row' }}>
                                    <span style={{ color: Colors.grey1, fontFamily: 'Montserrat', fontStyle: 'normal', fontWeight: '600', fontSize: '24px', marginTop: '10px' }}>$</span>
                                    <Typography style={{ color: Colors.grey1, fontFamily: 'Rubik', fontStyle: 'normal', fontWeight: '500', fontSize: '44px', marginLeft: '8px' }}>{this.state.yearlyCycle ? '16.70/mo' : '20.00/mo'}</Typography>
                                </div>
                                <div style={{ marginTop: '34px', display: 'flex', flexDirection: 'row' }}>
                                    <Typography style={{ ...GlobalStyles.bodyMedium, color: Colors.grey1, marginTop: '10px' }}>Bill Monthly</Typography>
                                    <FormControlLabel
                                        control={(
                                            <Switch checked={this.state.yearlyCycle}
                                                onChange={this.handleToggler}
                                                name="yearlyCycle"
                                                color="primary"
                                            />
                                        )}
                                        style={{ marginLeft: '12px', marginRight: '12px' }}
                                    />
                                    <Typography style={{ ...GlobalStyles.bodyMedium, color: Colors.grey4, marginTop: '10px' }}>Bill Yearly</Typography>
                                </div>
                                <TextAndIcon iconStyle={{ color: Colors.primaryColor }} iconName='check_circle_outline' title='No extra charges' textStyle={{ marginLeft: '15px', color: Colors.grey1 }} viewStyle={{ marginTop: '39px' }} />
                                <TextAndIcon iconStyle={{ color: Colors.primaryColor }} iconName='check_circle_outline' title='Unlimited personal classes' textStyle={{ marginLeft: '15px', color: Colors.grey1 }} viewStyle={{ marginTop: '32px' }} />
                                <TextAndIcon iconStyle={{ color: Colors.primaryColor }} iconName='check_circle_outline' title='Unlimited community classes' textStyle={{ marginLeft: '15px', color: Colors.grey1 }} viewStyle={{ marginTop: '32px' }} />
                                <Button size="large"
                                    variant="contained"
                                    style={{ ...GlobalStyles.bodyMedium, backgroundColor: this.state.isTeacherPro ? '#ECF5FE' : Colors.primaryColor, color: this.state.isTeacherPro ? Colors.primaryColor : Colors.white, borderRadius: '8px', width: '100%', marginTop: '52px', boxShadow: '0 0 0 0', padding: '22px', fontWeight: '600' }}
                                    onClick={() => this.openPayModal()}
                                    startIcon={this.state.isTeacherPro ? <CheckCircleIcon style={{ fontSize: '30px' }} /> : null}
                                >
                                    {this.state.isTeacherPro ? 'Current plan' : 'Upgrade'}
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <React.Fragment>
                    <Button
                        size="large"
                        // fullWidth
                        style={{
                            ...GlobalStyles.bodyMedium,
                            backgroundColor: Colors.grey1,
                            color: Colors.white,
                            borderRadius: '8px',
                            margin: '50px 0',
                            boxShadow: '0 0 0 0',
                            padding: '22px',
                            fontWeight: '100'
                        }}
                        // startIcon={<CheckCircleIcon style={{ fontSize: '30px' }} />}
                        onClick={() => this.props.history.goBack()}
                    >Go Back</Button>
                </React.Fragment>
                <Modal
                    disableAutoFocus
                    disableEnforceFocus
                    aria-labelledby="confirm payment"
                    aria-describedby="Confirm payment to accept payment details"
                    className="center-modal"
                    open={this.state.openPayModal}
                    onClose={() => {
                        this.closePayModal();
                    }}
                >
                    <div style={{ position: 'absolute', ...UtilFX.getModalStyle() }}>
                        <UpgradeConfirmation
                            showSuccessMessage={this.onToken}
                            handleClosePayModal={this.closePayModal}
                            yearlyCycle={this.state.yearlyCycle}
                            amount={this.state.yearlyCycle ? 200.00 : 20.00}
                            payorUser={this.auth_user}
                        />
                    </div>
                </Modal>
                <Modal
                    disableAutoFocus
                    disableEnforceFocus
                    aria-labelledby="basic plan"
                    aria-describedby="are you sure you want to use basic plan"
                    className="center-modal"
                    open={this.state.openDowngradeModal}
                    onClose={() => {
                        // this.setState({})
                    }}
                >
                    <div className="modal-upgrade-wrapper" style={{ backgroundColor: Colors.white }}>
                        <span
                            className="material-icons"
                            style={{ color: Colors.grey1, fontSize: 20, display: 'flex', justifyContent: 'flex-end', margin: '20px', cursor: 'pointer' }}
                            onClick={() => this.setState({ openDowngradeModal: false })}
                        >
                            clear
                        </span>
                        <div style={{ marginLeft: '40px', marginRight: '40px', justifyContent: 'center', marginTop: '50px', marginBottom: '40px' }}>
                            <img src={imgQuestion} alt="downgrade path" />
                            <Typography style={{ marginTop: '68px', fontFamily: 'Rubik', fontStyle: 'normal', fontWeight: '500', fontSize: '30px', color: Colors.grey1 }}>Basic plan</Typography>
                            <Typography style={{ ...GlobalStyles.bodyRegular, color: Colors.grey1, marginTop: '16px', marginLeft: '50px', marginRight: '50px' }}>Are you sure you want to downgrade to the basic plan?</Typography>
                            <Button
                                size="small"
                                variant="contained"
                                style={{ ...GlobalStyles.bodyMedium, backgroundColor: Colors.primaryColor, color: Colors.white, borderRadius: '8px', width: '70%', marginTop: '36px', boxShadow: '0 0 0 0', padding: '18px', fontWeight: '600' }}
                                onClick={this._handleDowngrade}
                            >
                                Activate basic plan
                        </Button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    disableAutoFocus
                    disableEnforceFocus
                    aria-labelledby="congratulations"
                    aria-describedby="basic plan confirmed"
                    className="center-modal"
                    open={this.state.openDowngradeSuccessModal}
                    onClose={() => {
                        // this.handleDowngradeSuccessModal();
                        this.setState({ openDowngradeSuccessModal: false });
                    }}
                >
                    <div className="modal-upgrade-wrapper" style={{ backgroundColor: '#FFFFFF', }}>
                        <span
                            className="material-icons"
                            style={{ color: Colors.grey1, fontSize: 20, display: 'flex', justifyContent: 'flex-end', margin: '20px', cursor: 'pointer' }}
                            onClick={this.handleDowngradeSuccessModal}
                        >
                            clear
                        </span>
                        <div style={{ marginLeft: '40px', marginRight: '40px', justifyContent: 'center', marginTop: '50px', marginBottom: '40px' }}>
                            <img src={imgDowngradeSuccess} alt="downgrade_success" />
                            <Typography style={{ marginTop: '68px', fontFamily: 'Rubik', fontStyle: 'normal', fontWeight: '500', fontSize: '30px', color: Colors.grey1 }}>Congratulations!</Typography>
                            <Typography style={{ ...GlobalStyles.bodyRegular, color: Colors.grey1, marginTop: '16px' }}>You are now subscribed to our Basic plan.</Typography>
                            <Button
                                size="small"
                                variant="contained"
                                style={{ ...GlobalStyles.bodyMedium, backgroundColor: '#FBCD42', color: Colors.white, borderRadius: '8px', width: '70%', marginTop: '28px', boxShadow: '0 0 0 0', padding: '18px', fontWeight: '600' }}
                                onClick={this.handleDowngradeSuccessModal}
                            >
                                Continue
                            </Button>
                        </div>
                    </div>
                </Modal>
                <Modal
                    disableAutoFocus
                    disableEnforceFocus
                    aria-labelledby="teacher pro plan"
                    aria-describedby="teacher pro confirmed."
                    className="center-modal"
                    open={this.state.teacherProUpgradeSuccess}
                    onClose={() => {
                        this._handlePremiumUpgradeSuccessModal();
                    }}
                >
                    <div className="modal-upgrade-wrapper " style={{ backgroundColor: '#FFFFFF', borderRadius: '15px' }}>
                        <div style={{ marginLeft: '40px', marginRight: '40px', justifyContent: 'center', marginTop: '50px', marginBottom: '40px' }}>
                            <img src={imgPremiumUpgradeSuccess} alt='premium upgrade success' />
                            <Typography style={{ fontFamily: 'Rubik', fontStyle: 'normal', fontWeight: '500', fontSize: '30px', color: '#333333', marginTop: '28px' }} >Congratulations!</Typography>
                            <Typography style={{ ...GlobalStyles.bodyRegular, color: '#333333', marginTop: '24px' }}> You are now subscribed to our Teacher Pro plan. </Typography>
                            <Button
                                size="small"
                                variant="contained"
                                style={{ ...GlobalStyles.bodyMedium, backgroundColor: '#FBCD42', color: Colors.white, borderRadius: '8px', width: '70%', marginTop: '28px', boxShadow: '0 0 0 0', padding: '18px', fontWeight: '600' }}
                                onClick={this._handlePremiumUpgradeSuccessModal}
                            >
                                Continue
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div >
        );
    }
}

export default TeacherPro;
