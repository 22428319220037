import React, { Component } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  UtilFX,
  Colors,
  GlobalStyles,
  Loading,
  ConvoMessages,
  ConvoMetadata,
  onMessageListener,
} from '../utils';
import { firebase, firestore } from '../fire';
import Modal from '@material-ui/core/Modal';
import Helmet from 'react-helmet';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArchiveConversations from './common/GroupChat/ArchiveConversations';
import YogiPicker from './YogiPicker';
import BookmarkHeader from './common/GroupChat/BookmarkHeader';
import BookmarkMessage from './common/GroupChat/BookmarkMessage';
import MessageHeader from './common/GroupChat/MessageHeader';
import InboxHeader from './common/GroupChat/InboxHeader';
import ToggleHeader from './common/GroupChat/ToggleHeader';
import DmConversations from './common/GroupChat/DmConversations';
import ClassConversations from './common/GroupChat/ClassConversations';
import TeacherConversations from './common/GroupChat/TeacherConversations';
import DmZeroState from './common/GroupChat/DmZeroState';
import MessageZeroState from './common/GroupChat/MessageZeroState';
import InputMessage from './common/GroupChat/InputMessage';
import MessageCard from './common/GroupChat/MessageCard';
import ButtonComponent from './common/ButtonComponent';
import ClearCard from './common/ClearCard';
import FirstTimeChatVisitModal from './common/GroupChat/FirstTimeChatVisitModal';
import CancelChatRequestModal from './common/GroupChat/CancelChatRequestModal';
import SnackBarComponent from './common/SnackBarComponent';
import PinMessageCard from './common/GroupChat/PinMessageCard';

const ChatWrapperCalcHeightInner = styled.div`
  height: calc(
    100vh - 185px
  ); // 100vh minus header and the 2 toolbars above list of convos
  height: -moz-calc(100vh - 185px);
  height: -webkit-calc(100vh - 185px);
  position: relative;
  display: flex;
  flex: 1;
  border-bottom: 2px ${Colors.transparent} solid;
  flex-direction: column;
  overflow: auto;
`;

const CHATMESSAGEQUERYLIMIT = 20;
class NewInbox extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.input = React.createRef();
    this.messageScroll = React.createRef();
    this.oldestDisplayedConvoMessage = null;
    this.auth_user = props.context.userAuthenticated;
    this.lastPinDateChange = Date.now();
    this.lastMuteDateChange = Date.now();
    this.setupConvoWithMetadataListener = true;
    this.unSubscribe = () => {};
    this.convoMetadataListeners = {};
    this.state = {
      titleCard: 'dm',
      messageCard: null,
      messageHeaderTitle: null,
      replyInput: null,
      pinnedMessageId: null,
      pinMessageModal: false,
      mobileFirstView: false,
      convoText: '',
      convoType: null,
      convos: [],
      convoMessages: [],
      convoMessagesTime: [],
      currentChatDetails: {},
      replyMessageDetails: {
        message_id: null,
      },
      messageDetails: {},
      bookmarkMessages: [],
      openSearchModal: false,
      archiveConvoIds: [],
      mutedConvoIds: [],
      pinnedConvoIds: [],
      deletedConvoIds: [],
      unreadTeacherMessageIndicator: false,
      unreadClassMessageIndicator: false,
      unreadDmMessageIndicator: false,
      listOfBookmarkIds: [],
      isLoadingPage: true,
      isLoadingMessage: false,
      chatMembershipStatus: '',
      openNewMemberSnackBar: false,
      pinnedMessages: [],
      selectedGifUrl: '',
      listOfOwnerBlockedUsers: [],
      listOfRecipientBlockedUsers: [],
      recipientId: '',
      showLoadMoreMessages: true,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const locPath = location && location.pathname;
    if (locPath === '/chat' || locPath === '/chat/') {
      this.props.history.push('/chat/dm');
    }
    if (this.auth_user) {
      this.setupConvos();
      this.getCurrentChat();
    }
    onMessageListener(this.props);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (prevProps.location.pathname !== location.pathname) {
      this.unSubscribe();
      this.getCurrentChat();
    }
  }

  getCurrentChat = async () => {
    const { location } = this.props;
    const locState = location && location.state;
    const locPath = location && location.pathname;
    const { convo_id: id } =
      (this.props &&
        (this.props.computedMatch || this.props.match || {}).params) ||
      {};

    if (id) {
      let query = await firestore.collection(ConvoMetadata);
      query = query.where('convo_id', '==', id);
      query.get().then(async (res) => {
        if (!res.empty) {
          const result = res.docs[0].data();
          const convoUserName = await UtilFX.getUsername(result.teacher_id);
          if (result.class_id) {
            this.setState(
              {
                convoId: id,
                teacherId: result.teacher_id,
                convoProfilePhotoUrl: result.convo_profile_photo_url,
                convoName: result.convo_name,
                convoUserName: convoUserName,
                classId: result.class_id,
                titleCard: 'class',
                messageCard: true,
                mobileFirstView: true,
                currentChatToggleHeader: 'class',
                replyInput: null,
                messageHeaderTitle: null,
                selectedGifUrl: '',
                replyMessageDetails: {
                  message_id: '',
                  username: '',
                  message_text: '',
                },
              },
              async () => {
                if (this.state.teacherId === this.auth_user.uid) {
                  const chatInfo = await UtilFX.getConvoByUserIdInfo({
                    convoId: id,
                    uid: this.auth_user.uid,
                  });
                  this.setState({
                    firstTimeChatUrlVisit: !chatInfo?.first_time_chat_url_visit,
                  });
                }
                const member = await UtilFX.getChatMembershipDetails({
                  convoId: id,
                  uid: this.auth_user.uid,
                });
                const status = member.status;
                this.setState({
                  chatMembershipStatus: status || 'pending',
                  chatConvoMemberId: member.documentId,
                  showConvoBookmark: locPath.includes('/chat/bookmark'),
                });
                await this.getBookmarkedMessagesOfAConversation(id);
                await this.fetchMessagesOfAConvoId(id);
                await this.fetchPinnedMessagesOfAConvoId(id);
              }
            );
          } else if (result.teacher_id && !result.class_id) {
            const convoName = await UtilFX.getFullname(result.teacher_id);
            this.setState(
              {
                convoId: id,
                teacherId: result.teacher_id,
                convoProfilePhotoUrl: result.convo_profile_photo_url,
                convoName: convoName,
                convoUserName: convoUserName,
                titleCard: 'teacher',
                messageCard: true,
                mobileFirstView: true,
                currentChatToggleHeader: 'teacher',
                replyInput: null,
                messageHeaderTitle: null,
                selectedGifUrl: '',
                replyMessageDetails: {
                  message_id: '',
                  username: '',
                  message_text: '',
                  replyGif: '',
                },
              },
              async () => {
                if (this.state.teacherId === this.auth_user.uid) {
                  const chatInfo = await UtilFX.getConvoByUserIdInfo({
                    convoId: id,
                    uid: this.auth_user.uid,
                  });
                  this.setState({
                    firstTimeChatUrlVisit: !chatInfo?.first_time_chat_url_visit,
                  });
                }
                const member = await UtilFX.getChatMembershipDetails({
                  convoId: id,
                  uid: this.auth_user.uid,
                });
                const status = member.status;
                this.setState({
                  chatMembershipStatus: status || 'pending',
                  chatConvoMemberId: member.documentId,
                  showConvoBookmark: locPath.includes('/chat/bookmark'),
                });
                await this.getBookmarkedMessagesOfAConversation(id);
                await this.fetchMessagesOfAConvoId(id);
                await this.fetchPinnedMessagesOfAConvoId(id);
              }
            );
          } else if (result.dm_members) {
            let recipientId = '';
            const userIds = Object.keys(result.dm_members);
            if (userIds[0] === this.auth_user.uid) {
              recipientId = userIds[1];
            } else {
              recipientId = userIds[0];
            }
            Promise.resolve(UtilFX.getFYuser(recipientId)).then((data) =>
              this.setState({
                convoProfilePhotoUrl: data.profile_photo_url,
                convoUserName: data.username,
                convoName: data.fullname,
              })
            );
            const listOfRecipientBlockedUsers =
              await UtilFX.getListOfBlockedUsers(recipientId);
            const listOfOwnerBlockedUsers = await UtilFX.getListOfBlockedUsers(
              this.auth_user.uid
            );
            this.setState(
              {
                recipientId: recipientId,
                convoId: id,
                messageCard: true,
                mobileFirstView: true,
                currentChatToggleHeader: 'dm',
                titleCard: 'dm',
                messageHeaderTitle: null,
                replyInput: null,
                listOfOwnerBlockedUsers,
                listOfRecipientBlockedUsers,
                selectedGifUrl: '',
                replyMessageDetails: {
                  message_id: '',
                  username: '',
                  message_text: '',
                  replyGif: '',
                },
              },
              async () => {
                await this.getBookmarkedMessagesOfAConversation(id);
                await this.fetchMessagesOfAConvoId(id);
                await this.fetchPinnedMessagesOfAConvoId(id);
                const status = userIds.includes(this.auth_user.uid);
                this.setState({
                  chatMembershipStatus: status ? 'accepted' : 'pending',
                  showConvoBookmark: locPath.includes('/chat/bookmark'),
                });
              }
            );
          }
        }
      });
    }
    if (locPath === '/chat/dm') {
      this.setState({ titleCard: 'dm' });
    }
    if (locPath === '/chat/class') {
      this.setState({ titleCard: 'class' });
    }
    if (locPath === '/chat/teacher') {
      this.setState({ titleCard: 'teacher' });
    }
    if (locPath === '/chat/bookmark') {
      this.setState({
        messageHeaderTitle: { archive: null, bookmark: true },
        mobileFirstView: true,
        messageCard: true,
      });
      return this.getAllBookmarkMessages();
    }
    if (locPath === '/chat/archive') {
      return this.setState({
        messageHeaderTitle: { archive: true, bookmark: null },
        mobileFirstView: true,
        messageCard: true,
      });
    }
    if (locState && locState.recipientId) {
      const recipientId = locState.recipientId;
      const uid = this.auth_user.uid;
      this.dmEntryPoint({ uid, recipientId });
    }
    if (locState && locState.teacherId && !locState.classId) {
      const teacherId = locState.teacherId;
      let query = await firestore.collection(ConvoMetadata);
      query = query.where('teacher_id', '==', teacherId);
      query.get().then((res) => {
        if (!res.empty) {
          const convoId = res.docs[0].data().convo_id;
          return this.props.history.push(`/chat/convo/${convoId}`);
        }
      });
    }
    if (locState && locState.teacherId && locState.classId) {
      const teacherId = locState.teacherId;
      const classId = locState.classId;
      let query = await firestore.collection(ConvoMetadata);
      query = query.where('teacher_id', '==', teacherId);
      query = query.where('class_id', '==', classId);
      query.get().then(async (res) => {
        let convoId = '';
        if (!res.empty) {
          convoId = res.docs[0].data().convo_id;
          return this.props.history.push(`/chat/convo/${convoId}`);
        }
      });
    }
  };

  dmEntryPoint = async ({ uid, recipientId }) => {
    let query = await firestore.collection(ConvoMetadata);
    query = query.where(`dm_members.${recipientId}`, '==', true);
    query = query.where(`dm_members.${uid}`, '==', true);
    query.get().then((res) => {
      let convoId = '';
      if (!res.empty) {
        convoId = res.docs[0].data().convo_id;
      }
      this.setState({ convoId: convoId }, () => {
        const navigator = this.props.history;
        if (!this.state.convoId) {
          return UtilFX.setupDirectMessageConvo({
            recipientId,
            uid,
            navigator,
          });
        }
        return navigator.push(`/chat/convo/${convoId}`);
      });
    });
  };

  fetchMoreMessagesOfAConvoId = async (id) => {
    const previousMessages = this.state.convoMessages;
    const ref = firestore
      .collection(ConvoMessages)
      .where('convo_id', '==', id)
      .orderBy('date_created', 'desc')
      .startAfter(this.oldestDisplayedConvoMessage)
      .limit(CHATMESSAGEQUERYLIMIT);
    const data = await ref.get();
    const convoMessages = [];
    this.oldestDisplayedConvoMessage = data.docs[data.docs.length - 1];
    data.docs.forEach((doc) => {
      const messageDetails = doc.data();
      convoMessages.push({
        ...messageDetails,
        message_id: doc.id,
      });
    });
    const showLoadMoreMessages = !(convoMessages.length < CHATMESSAGEQUERYLIMIT);
    this.setState(
      {
        convoMessages: [...previousMessages, ...convoMessages],
        messagePaginationLoader: false,
        showLoadMoreMessages
      },
      () => {
        this.autoScrollToTop();
      }
    );
  };

  fetchPinnedMessagesOfAConvoId = async (id) => {
    this.unSubscribe = await firestore
      .collection(ConvoMessages)
      .where('convo_id', '==', id)
      .where('date_pinned', '!=', null)
      .orderBy('date_pinned')
      .onSnapshot((querySnapshot) => {
        const pinnedMessages = [];
        querySnapshot.forEach((doc) => {
          pinnedMessages.push(doc.id);
        });
        this.setState({ pinnedMessages });
      });
  };

  fetchMessagesOfAConvoId = async (id) => {
    this.unSubscribe = await firestore
      .collection(ConvoMessages)
      .where('convo_id', '==', id)
      .orderBy('date_created', 'desc')
      .limit(CHATMESSAGEQUERYLIMIT)
      .onSnapshot((querySnapshot) => {
        const convoMessages = [];
        this.oldestDisplayedConvoMessage =
          querySnapshot.docs[querySnapshot.docs.length - 1];
        querySnapshot.forEach((doc, index) => {
          const messageDetails = doc.data();
          convoMessages.push({
            ...messageDetails,
            message_id: doc.id,
          });
        });
        const oldMessages = this.state.convoMessages;
        const showLoadMoreMessages = !(convoMessages.length < CHATMESSAGEQUERYLIMIT);
        this.setState(
          {
            convoMessages,
            isLoadingMessage: false,
            showLoadMoreMessages,
          },
          () => {
            firebase
              .database()
              .ref(`convobyusers/${this.auth_user.uid}/${this.state.convoId}`)
              .update({
                date_last_read: firebase.database.ServerValue.TIMESTAMP,
              });
            if (oldMessages.length !== convoMessages.length) {
              this.autoScrollToBottom();
            }
          }
        );
      });
  };

  setupConvos = async () => {
    const convoRef = firebase
      .database()
      .ref(`convobyusers/${this.auth_user.uid}`);
    return convoRef.on('value', async (snapCbu) => {
      const fireStartTime = Date.now();
      this.setupConvoWithMetadataListener = true;
      const convoMembersIdArr = [];
      const convoMembersIdObj = {};
      const convosObj = {};
      const listOfArchiveConvoIds = [];
      const listOfMutedConvoIds = [];
      const listOfPinnedConvoIds = [];
      let unreadDmMessageIndicator = false;
      let unreadClassMessageIndicator = false;
      let unreadTeacherMessageIndicator = false;
      const convosByThisUserObj = snapCbu.val() || {};
      const convoIds = Object.keys(convosByThisUserObj);
      const promsConvomembers = convoIds.map(async (v) => {
        const docRef = await firestore.collection('ConvoMembers');
        const snapshot = docRef.where('convo_id', '==', v);
        const res = await snapshot.get();
        const members = {};
        res.forEach((doc) => {
          members[doc.id] = doc.data();
        });
        return members;
      });
      const promsConvometadata = convoIds.map(
        async (v) => await this.convoMetadataListener(v)
      );
      const convomembers = await Promise.all(promsConvomembers);
      const convometadata = await Promise.all(promsConvometadata);
      convometadata.forEach(async (v) => {
        if (v.data) {
          convosObj[v.data.convo_id] = {
            ...v.data,
            date_last_read:
              convosByThisUserObj[v.data.convo_id].date_last_read || null,
            pinned_message:
              convosByThisUserObj[v.data.convo_id].pinned_message || null,
            muted: convosByThisUserObj[v.data.convo_id].muted || null,
            date_archived:
              convosByThisUserObj[v.data.convo_id].date_archived || null,
            date_pinned:
              convosByThisUserObj[v.data.convo_id].date_pinned || null,
          };
          if (v.data.teacher_id) {
            convosObj[v.data.convo_id].other_uid =
              v.data.last_message_sender_uid || null;
          }
          if (convosByThisUserObj[v.data.convo_id].date_archived) {
            listOfArchiveConvoIds.push(v.data.convo_id);
          }
          if (convosByThisUserObj[v.data.convo_id].muted) {
            const muted = convosByThisUserObj[v.data.convo_id].muted;
            const muteEndDate = muted.end_date;
            if (muteEndDate > Date.now()) {
              listOfMutedConvoIds.push(v.data.convo_id);
            }
          }
          if (convosByThisUserObj[v.data.convo_id].date_pinned) {
            listOfPinnedConvoIds.push(v.data.convo_id);
          }
          if (v.data.dm_members) {
            if (
              (v.data.last_message_sender_uid !== this.auth_user.uid &&
                v.data.date_created >
                  convosByThisUserObj[v.data.convo_id].date_last_read) ||
              !convosByThisUserObj[v.data.convo_id].date_last_read
            ) {
              unreadDmMessageIndicator = true;
            }
          }
          if (v.data.teacher_id && !v.data.class_id) {
            if (
              (v.data.last_message_sender_uid !== this.auth_user.uid &&
                v.data.date_created >
                  convosByThisUserObj[v.data.convo_id].date_last_read) ||
              !convosByThisUserObj[v.data.convo_id].date_last_read
            ) {
              unreadTeacherMessageIndicator = true;
            }
            convosObj[v.data.convo_id].convo_name = await UtilFX.getFullname(
              v.data.teacher_id
            );
          }
          if (v.data.class_id) {
            if (
              (v.data.last_message_sender_uid !== this.auth_user.uid &&
                v.data.date_created >
                  convosByThisUserObj[v.data.convo_id].date_last_read) ||
              !convosByThisUserObj[v.data.convo_id].date_last_read
            ) {
              unreadClassMessageIndicator = true;
            }
          }
        }
      });

      convomembers.forEach((cmObject) => {
        Object.keys(cmObject).forEach((id) => {
          const uid = cmObject[id].uid;
          const key = cmObject[id].convo_id;
          const status = cmObject[id].status || null;
          if (convoMembersIdArr.indexOf(uid) < 0) {
            convoMembersIdArr.push(uid);
          }
          if (convosObj[key]) {
            if (uid !== this.auth_user.uid) {
              convosObj[key].other_uid = uid;
            } else {
              convosObj[key].status = status;
            }
          }
        });
      });
      for (const cmid of convoMembersIdArr) {
        const convomemebrsUserinfo = {
          fullname: await UtilFX.getFullname(cmid),
          username: await UtilFX.getUsername(cmid),
          profile_photo_url: await UtilFX.getProfilePhotoUrl(cmid),
        };
        convoMembersIdObj[cmid] = convomemebrsUserinfo;
      }
      const convosArr = Object.values(convosObj);
      if (
        fireStartTime < this.lastPinDateChange ||
        fireStartTime < this.lastMuteDateChange
      ) {
        return;
      }
      this.setState(
        {
          convos: convosArr,
          convoMembersUserObj: convoMembersIdObj,
          archiveConvoIds: listOfArchiveConvoIds,
          pinnedConvoIds: listOfPinnedConvoIds,
          mutedConvoIds: listOfMutedConvoIds,
          unreadClassMessageIndicator,
          unreadTeacherMessageIndicator,
          unreadDmMessageIndicator,
          isLoadingPage: false,
        },
        () => {
          this.setupConvoWithMetadataListener = false;
          this.autoScrollToBottom();
        }
      );
    });
  };

  convoMetadataListener = async (convoId) => {
    const metadata = {};
    const docRef = await firestore.collection(ConvoMetadata);
    const query = docRef.where('convo_id', '==', convoId);
    const res = await query.get();
    res.forEach((doc) => {
      metadata.data = doc.data();
    });
    const queryListener = this.convoMetadataListeners[convoId];
    queryListener && queryListener();
    this.convoMetadataListeners[convoId] = query.onSnapshot(() => {
      if (!this.setupConvoWithMetadataListener) {
        this.setupConvos();
      }
    });
    return metadata;
  };

  handleTitleCard = (value) => {
    this.setState({ titleCard: value });
  };

  handleReplyInput = (data) => {
    this.setState(
      { replyInput: true, replyMessageDetails: { ...data } },
      () => {
        this.inputRef.current.focus();
      }
    );
  };

  handlePinMessageModal = () => {
    this.setState({ pinMessageModal: !this.state.pinMessageModal });
  };

  sendMessage = () => {
    const {
      convoText,
      convoId,
      recipientId,
      replyMessageDetails,
      titleCard,
      teacherId,
      convoProfilePhotoUrl,
      convoName,
      convoUserName,
      classId,
      selectedGifUrl,
    } = this.state || {};

    if (recipientId === this.auth_user.uid) {
      return (
        window &&
        window.alert("You can't send message to yourself at the moment")
      );
    }
    if (
      (!convoText || (convoText && convoText.trim().length === 0)) &&
      !selectedGifUrl
    ) {
      return window && window.alert('Please enter a message to send');
    }

    const convoTextCopy = convoText;
    const selectedGifUrlCopy = selectedGifUrl;
    const message_idCopy = replyMessageDetails.message_id;
    const message_textCopy = replyMessageDetails.message_text;
    const usernameCopy = replyMessageDetails.username;

    this.setState({
      convoText: '',
      selectedGifUrl: '',
      replyMessageDetails: {
        message_id: '',
        message_text: '',
        username: '',
        replyGif: '',
      },
    });

    if ((titleCard === 'teacher' || titleCard === 'class') && teacherId) {
      return UtilFX.sendGroupMessage({
        convoText,
        uid: this.auth_user.uid,
        teacherId,
        convoId,
        repliedToMessageId: replyMessageDetails.message_id,
        convoName,
        convoUserName,
        convoProfilePhotoUrl,
        classId,
        gifUrl: selectedGifUrl,
      })
        .then(() => {
          this.autoScrollToBottom();
        })
        .catch((err) => {
          this.setState({
            convoText: convoTextCopy,
            selectedGifUrl: selectedGifUrlCopy,
            replyMessageDetails: {
              message_id: message_idCopy,
              message_text: message_textCopy,
              username: usernameCopy,
            },
          });
          window &&
            window.alert(
              'We encountered an error while saving your message.',
              err
            );
        });
    }

    return UtilFX.sendDirectMessage({
      convoText,
      uid: this.auth_user.uid,
      recipientId,
      convoId,
      repliedToMessageId: replyMessageDetails.message_id,
      gifUrl: selectedGifUrl,
    })
      .then(() => {
        this.autoScrollToBottom();
      })
      .catch((err) => {
        this.setState({
          convoText: convoTextCopy,
          selectedGifUrl: selectedGifUrlCopy,
          replyMessageDetails: {
            message_id: message_idCopy,
            message_text: message_textCopy,
            username: usernameCopy,
          },
        });
        window &&
          window.alert(
            'We encountered an error while saving your message.',
            err
          );
      });
  };

  handleKeyPress = (e, closeGifView, removeSelectedGif) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      const start = e.target.selectionStart;
      const end = e.target.selectionEnd;
      this.setState(
        {
          convoText:
            this.state.convoText.substring(0, start) +
            '\n' +
            this.state.convoText.substring(end),
        },
        () => {
          this.input.current.selectionStart = start + 1;
          this.input.current.selectionEnd = start + 1;
        }
      );
    } else if (e.key === 'Enter') {
      e.preventDefault();
      this.setState({ replyInput: null });
      this.sendMessage();
      closeGifView();
      removeSelectedGif();
    }
  };

  autoScrollToBottom = () => {
    if (this.messageScroll.current) {
      this.messageScroll.current.scrollTop =
        this.messageScroll.current.scrollHeight;
    }
  };

  autoScrollToTop = () => {
    if (this.messageScroll.current) {
      this.messageScroll.current.scrollBottom = 0;
    }
  };

  setBookmarkMessage = async (messageDetails) => {
    const listOfBookmarkIds = this.state.listOfBookmarkIds;
    const bookmarkMessage = {
      message_id: messageDetails.message_id,
      uid: messageDetails.uid,
      date_created: Date.now(),
      convo_id: messageDetails.convo_id,
    };
    const docRef = firestore.collection('Bookmarks');
    let query = docRef.where('uid', '==', messageDetails.uid);
    query = query.where('message_id', '==', messageDetails.message_id);
    query.get().then((res) => {
      if (res.empty) {
        firestore.collection('Bookmarks').add(bookmarkMessage);
      }
    });
    listOfBookmarkIds.push(messageDetails.message_id);
    this.setState({ listOfBookmarkIds });
  };

  getAllBookmarkMessages = async () => {
    this.setState({ bookmarkLoading: true });
    await firestore
      .collection('Bookmarks')
      .where('uid', '==', this.auth_user.uid)
      .orderBy('date_created')
      .get()
      .then((querySnapshot) => {
        const bookmarkmessages = [];
        querySnapshot.forEach((doc) => {
          bookmarkmessages.push({
            ...doc.data(),
          });
        });
        this.setState({
          bookmarkMessages: bookmarkmessages,
          bookmarkLoading: false,
        });
      });
  };

  getBookmarkedMessagesOfAConversation = async () => {
    const docRef = firestore.collection('Bookmarks');
    const query = docRef.where('convo_id', '==', this.state.convoId);
    const res = await query.get();
    const bookmarkMessageIds = [];
    res.forEach((doc) => {
      bookmarkMessageIds.push(doc.data().message_id);
    });
    this.setState({ listOfBookmarkIds: bookmarkMessageIds });
  };

  showBookmarkMessage = () => {
    return (
      this.state.bookmarkMessages &&
      this.state.bookmarkMessages.map((data, key) => {
        return (
          <BookmarkMessage
            messageId={data.message_id}
            key={key}
            uid={this.auth_user.uid}
            convoMembersUserObj={this.state.convoMembersUserObj}
          />
        );
      })
    );
  };

  clearAllBookmarks = async () => {
    const { bookmarkMessages } = this.state;
    let listOfBookmarkMessages = bookmarkMessages;
    if (listOfBookmarkMessages.length > 0) {
      listOfBookmarkMessages.forEach((bookmark) => {
        firestore
          .collection('Bookmarks')
          .where('message_id', '==', bookmark.message_id)
          .get()
          .then((querySnapshot) => {
            querySnapshot.docs[0].ref.delete();
          });

        listOfBookmarkMessages = listOfBookmarkMessages.filter(
          (message) => message.message_id === bookmark.message_id
        );
      });

      this.setState({ bookmarkMessages: listOfBookmarkMessages });
    }
  };

  handleArchiveConvoIds = (ids) => {
    this.setState({ archiveConvoIds: ids });
  };

  handleMutedConvoIds = (ids) => {
    this.lastMuteDateChange = Date.now();
    this.setState({ mutedConvoIds: ids });
  };

  handlePinnedConvoIds = (ids) => {
    this.lastPinDateChange = Date.now();
    this.setState({ pinnedConvoIds: ids });
  };

  deleteDMConvo = (ids, convoId = '') => {
    this.setState({ deletedConvoIds: [...ids, convoId] }, () => {
      if (convoId === this.state.convoId) {
        this.setState({ messageCard: false, messageHeaderTitle: null });
      }
    });
  };

  render() {
    const {
      titleCard,
      messageCard,
      messageHeaderTitle,
      replyInput,
      pinnedMessageId,
      pinMessageModal,
      mobileFirstView,
      convos,
      currentChatDetails,
      convoMembersUserObj,
      convoMessages,
      replyMessageDetails,
      convoName,
      convoUserName,
      convoProfilePhotoUrl,
      mutedConvoIds,
      archiveConvoIds,
      pinnedConvoIds,
      recipientId,
      deletedConvoIds,
      unreadTeacherMessageIndicator,
      unreadClassMessageIndicator,
      unreadDmMessageIndicator,
      listOfBookmarkIds,
      isLoadingPage,
      isLoadingMessage,
      convoId,
      chatMembershipStatus,
      showConvoBookmark,
      clearAllCurrentConversationChat,
      pinnedMessages,
      listOfRecipientBlockedUsers,
      listOfOwnerBlockedUsers,
    } = this.state;
    return (
      <Grid
        container
        style={{ backgroundColor: Colors.white, minHeight: '80vh' }}
      >
        <Helmet>
          <style type="text/css">{`
            html, body {
              margin: 0;
              height: 100%;
              overflow: hidden;
            }

            #footerContainer{
              display: none;
            }

            .chat-wrapper-outer{
              position: relative;
              height: calc(100vh - 65px); // 100vh minus the header stuck at top
              height: -moz-calc(100vh - 65px);
              height: -webkit-calc(100vh - 65px);
            }
          `}</style>
        </Helmet>
        <Grid
          item
          xs={12}
          md={4}
          className={`chat-wrapper-outer ${
            mobileFirstView ? 'chat-mobile-view' : ''
          }`}
        >
          <InboxHeader
            title="Inbox"
            archive={messageHeaderTitle?.archive}
            mobileFirstView={mobileFirstView}
            navigation={(url) => this.props.history.push(url)}
            goBack={() => this.props.history.goBack()}
          />
          {!messageHeaderTitle?.archive && (
            <ToggleHeader
              mobileFirstView={mobileFirstView}
              title={titleCard}
              navigation={(url) => this.props.history.push(url)}
              unreadDmMessageIndicator={unreadDmMessageIndicator}
              unreadTeacherMessageIndicator={unreadTeacherMessageIndicator}
              unreadClassMessageIndicator={unreadClassMessageIndicator}
            />
          )}
          <ChatWrapperCalcHeightInner>
            {titleCard === 'dm' &&
              !(messageHeaderTitle && messageHeaderTitle.archive) && (
                <div
                  className={(mobileFirstView && 'chat-mobile-view') || null}
                >
                  {!isLoadingPage ? (
                    <DmConversations
                      deletedConvoIds={deletedConvoIds}
                      deleteDMConvo={this.deleteDMConvo}
                      archiveConvoIds={archiveConvoIds}
                      handleArchiveConvoIds={this.handleArchiveConvoIds}
                      convos={convos.length ? convos : []}
                      _u={this.auth_user}
                      mutedConvoIds={mutedConvoIds}
                      pinnedConvoIds={pinnedConvoIds}
                      handleMutedConvoIds={this.handleMutedConvoIds}
                      handlePinnedConvoIds={this.handlePinnedConvoIds}
                      convoMembersUserObj={convoMembersUserObj}
                      setRecipientId={(id) =>
                        this.setState({
                          recipientId: id,
                          messageCard: true,
                          mobileFirstView: true,
                          classId: null,
                          teacherId: null,
                          messageHeaderTitle: null,
                        })
                      }
                      defaultConvoId={convoId}
                      recipientId={recipientId}
                      setConvoId={(id) => {
                        this.setState({
                          convoId: id,
                          isLoadingMessage: true,
                          currentChatToggleHeader: 'dm',
                        });
                        this.props.history.push(`/chat/convo/${id}`);
                      }}
                      setCurrentChatDetails={(details) => {
                        this.setState({ currentChatDetails: { ...details } });
                      }}
                    />
                  ) : (
                    <Loading />
                  )}
                </div>
              )}
            {messageHeaderTitle && messageHeaderTitle.archive && (
              <div className={(mobileFirstView && 'chat-mobile-view') || null}>
                <ArchiveConversations
                  deletedConvoIds={deletedConvoIds}
                  deleteDMConvo={this.deleteDMConvo}
                  convos={convos.length ? convos : []}
                  _u={this.auth_user}
                  archiveConvoIds={archiveConvoIds}
                  mutedConvoIds={mutedConvoIds}
                  pinnedConvoIds={pinnedConvoIds}
                  handleMutedConvoIds={this.handleMutedConvoIds}
                  handlePinnedConvoIds={this.handlePinnedConvoIds}
                  handleArchiveConvoIds={this.handleArchiveConvoIds}
                  convoMembersUserObj={convoMembersUserObj}
                  recipientId={recipientId}
                />
              </div>
            )}
            {titleCard === 'class' &&
              !(messageHeaderTitle && messageHeaderTitle.archive) && (
                <div
                  className={(mobileFirstView && 'chat-mobile-view') || null}
                >
                  {!isLoadingPage ? (
                    <ClassConversations
                      archiveConvoIds={archiveConvoIds}
                      handleArchiveConvoIds={this.handleArchiveConvoIds}
                      mutedConvoIds={mutedConvoIds}
                      pinnedConvoIds={pinnedConvoIds}
                      handleMutedConvoIds={this.handleMutedConvoIds}
                      handlePinnedConvoIds={this.handlePinnedConvoIds}
                      convos={convos.length ? convos : []}
                      _u={this.auth_user}
                      convoMembersUserObj={convoMembersUserObj}
                      setTeacherAndClassId={(
                        teacherId,
                        convoProfilePhotoUrl,
                        convoName,
                        convoUserName,
                        classId
                      ) => {
                        this.setState({
                          teacherId: teacherId,
                          convoProfilePhotoUrl,
                          convoName,
                          convoUserName,
                          messageCard: true,
                          mobileFirstView: true,
                          messageHeaderTitle: null,
                          classId: classId,
                        });
                      }}
                      defaultConvoId={convoId}
                      setConvoId={(id) => {
                        this.setState({
                          convoId: id,
                          isLoadingMessage: true,
                          currentChatToggleHeader: 'class',
                        });
                        this.props.history.push(`/chat/convo/${id}`);
                      }}
                      setCurrentChatDetails={(details) => {
                        this.setState({ currentChatDetails: { ...details } });
                      }}
                    />
                  ) : (
                    <Loading />
                  )}
                </div>
              )}
            {titleCard === 'teacher' &&
              !(messageHeaderTitle && messageHeaderTitle.archive) && (
                <div
                  className={(mobileFirstView && 'chat-mobile-view') || null}
                >
                  {!isLoadingPage ? (
                    <TeacherConversations
                      archiveConvoIds={archiveConvoIds}
                      handleArchiveConvoIds={this.handleArchiveConvoIds}
                      mutedConvoIds={mutedConvoIds}
                      pinnedConvoIds={pinnedConvoIds}
                      handleMutedConvoIds={this.handleMutedConvoIds}
                      handlePinnedConvoIds={this.handlePinnedConvoIds}
                      convos={convos.length ? convos : []}
                      _u={this.auth_user}
                      convoMembersUserObj={convoMembersUserObj}
                      setTeacherId={(
                        id,
                        convoProfilePhotoUrl,
                        convoName,
                        convoUserName
                      ) => {
                        this.setState({
                          teacherId: id,
                          classId: null,
                          convoProfilePhotoUrl,
                          convoName,
                          convoUserName,
                          messageCard: true,
                          mobileFirstView: true,
                          messageHeaderTitle: null,
                        });
                      }}
                      defaultConvoId={convoId}
                      setConvoId={(id) => {
                        this.setState({
                          convoId: id,
                          isLoadingMessage: true,
                          currentChatToggleHeader: 'teacher',
                        });
                        this.props.history.push(`/chat/convo/${id}`);
                      }}
                      setCurrentChatDetails={(details) => {
                        this.setState({ currentChatDetails: { ...details } });
                      }}
                    />
                  ) : (
                    <Loading />
                  )}
                </div>
              )}
          </ChatWrapperCalcHeightInner>
          {titleCard === 'dm' && (
            <div
              style={{
                position: 'absolute',
                zIndex: 100,
                right: 105,
                bottom: 120,
              }}
              onClick={() => this.setState({ openSearchModal: true })}
            >
              <div
                style={{
                  backgroundColor: Colors.pri_color,
                  width: 50,
                  height: 50,
                  borderRadius: '50%',
                  position: 'fixed',
                  boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.3)',
                }}
              >
                <span
                  className="material-icons"
                  style={{ cursor: 'pointer', padding: 10 }}
                >
                  edit
                </span>
              </div>
            </div>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            height: '100vh',
            paddingBottom: 60,
          }}
          className={'hide-border'}
        >
          <div className={(!mobileFirstView && 'chat-mobile-view') || null}>
            {!messageHeaderTitle && messageCard && !showConvoBookmark && (
              <MessageHeader
                mobileFirstView={mobileFirstView}
                eventHandler={() => this.setState({ mobileFirstView: false })}
                name={convoName || currentChatDetails?.username}
                username={convoUserName || currentChatDetails?.username}
                groupMessagePopover={
                  this.state.currentChatToggleHeader === 'class' ||
                  this.state.currentChatToggleHeader === 'teacher'
                }
                messageHeaderPopover={
                  this.state.currentChatToggleHeader === 'dm'
                }
                imgUrl={
                  convoProfilePhotoUrl || currentChatDetails?.profile_photo_url
                }
                handleGroupInfoModal={() =>
                  this.setState({ groupChatProfileInfo: true })
                }
                convoId={convoId}
                currentChatToggleHeader={this.state.currentChatToggleHeader}
                uid={this.auth_user.uid}
                convoMembersUserObj={convoMembersUserObj}
                dmEntryPoint={this.dmEntryPoint}
                mutedConvoIds={mutedConvoIds}
                handleMutedConvoIds={this.handleMutedConvoIds}
                props={this.props?.context.userAuthenticated}
                classId={this.state.classId}
                openNewMemberSnackBar={() =>
                  this.setState({ openNewMemberSnackBar: false })
                }
                chatBookmarkNavigator={() =>
                  this.props.history.push(`/chat/bookmark/${convoId}`)
                }
                listOfAllMessages={this.state.convoMessages}
                updateChatMembershipStatus={() =>
                  this.setState({ chatMembershipStatus: 'leaveGroup' })
                }
                updateClearAllCurrentConversationChat={() =>
                  this.setState({ clearAllCurrentConversationChat: true })
                }
                recipientId={recipientId}
                listOfRecipientBlockedUsers={listOfRecipientBlockedUsers}
                listOfOwnerBlockedUsers={listOfOwnerBlockedUsers}
                handleBlockedUsersModal={(ids) =>
                  this.setState({ listOfOwnerBlockedUsers: ids })
                }
              />
            )}
            {((messageHeaderTitle && messageHeaderTitle.bookmark) ||
              showConvoBookmark) && (
              <BookmarkHeader
                title="All bookmarks"
                goBack={() => this.props.history.goBack()}
                mobileFirstView={mobileFirstView}
                bookmarks={() => this.clearAllBookmarks()}
              />
            )}
          </div>
          <div
            className={
              (!mobileFirstView && 'chat-mobile-view') || 'chat-dekstop-view'
            }
            style={{
              margin:
                !messageCard ||
                (messageHeaderTitle && messageHeaderTitle.archive) ||
                clearAllCurrentConversationChat
                  ? 'auto'
                  : null,
              display: 'flex',
              flex: 1,
              overflow: 'auto',
            }}
            ref={this.messageScroll}
          >
            {messageCard &&
              !messageHeaderTitle &&
              chatMembershipStatus !== 'pending' &&
              chatMembershipStatus !== 'leaveGroup' && (
                <PinMessageCard
                  convoMessages={convoMessages}
                  handlePinMessageModal={this.handlePinMessageModal}
                  handlePinnedMessageId={(messageId) =>
                    this.setState({ pinnedMessageId: messageId })
                  }
                  teacherId={this.state.teacherId}
                  uid={this.auth_user.uid}
                />
              )}
            {!messageCard && !messageHeaderTitle && convos.length && (
              <MessageZeroState
                dm={titleCard === 'dm'}
                headline="Select a message or start a new chat to begin."
                onClick={() => this.setState({ openSearchModal: true })}
              />
            )}
            {messageHeaderTitle && messageHeaderTitle.archive && (
              <MessageZeroState
                headline="Select a message or start a new chat to begin."
                onClick={() => this.setState({ openSearchModal: true })}
              />
            )}
            {((!messageCard && !messageHeaderTitle && !convos.length) ||
              clearAllCurrentConversationChat) && (
              <DmZeroState
                dm={titleCard === 'dm'}
                headline1={
                  clearAllCurrentConversationChat
                    ? 'You have no messages here!'
                    : 'Start a new conversation with a member'
                }
                headline2={!clearAllCurrentConversationChat && 'of FavYogis.'}
                onClick={() => this.setState({ openSearchModal: true })}
              />
            )}
            {messageCard && (!messageHeaderTitle || showConvoBookmark) && (
              <div
                style={{
                  minHeight: '100%', // (replyInput && '50vh') || '59vh',
                  height: 'fit-content',
                  width: '100%',
                  margin: '0 18px',
                  padding: '10px 0',
                  display: 'flex',
                  justifyContent:
                    chatMembershipStatus !== 'accepted' ? 'center' : 'flex-end',
                  alignItems:
                    chatMembershipStatus !== 'accepted' ? 'center' : null,
                  flexDirection: 'column',
                }}
              >
                {isLoadingPage || isLoadingMessage ? (
                  <Loading />
                ) : chatMembershipStatus === 'pending' ? (
                  <div>
                    <Typography
                      style={{
                        ...GlobalStyles.bodyRegular,
                        color: Colors.grey2,
                      }}
                    >
                      Your request to join this group is in review. <br />
                      You can’t send messages yet.
                    </Typography>
                    {this.state.teacherId && (
                      <div
                        style={{
                          flexDirection: 'row',
                          display: 'flex',
                          marginTop: '20px',
                        }}
                      >
                        <ButtonComponent
                          value={
                            this.state.classId
                              ? 'Class profile'
                              : 'Teacher’s profile'
                          }
                          buttonStyle={{
                            backgroundColor: Colors.blueOnlineClass,
                            color: Colors.pri_color,
                            borderRadius: '12px',
                            marginRight: '10px',
                            border: 0,
                            width: '165px',
                          }}
                          onClick={() => {
                            if (this.state.classId) {
                              this.props.history.push(
                                `/${convoUserName}/class/${this.state.classId}`
                              );
                            } else {
                              this.props.history.push(`/${convoUserName}`);
                            }
                          }}
                        />
                        <ButtonComponent
                          value="Cancel request"
                          buttonStyle={{
                            backgroundColor: Colors.lightRed,
                            color: Colors.red,
                            borderRadius: '12px',
                            border: 0,
                            width: '165px',
                          }}
                          onClick={() =>
                            this.setState({ showCancelChatRequestModal: true })
                          }
                        />
                      </div>
                    )}
                  </div>
                ) : chatMembershipStatus === 'leaveGroup' ? (
                  <div>
                    <Typography
                      style={{
                        ...GlobalStyles.bodyRegular,
                        color: Colors.grey2,
                      }}
                    >
                      To join this group again, visit the{' '}
                      {this.state.currentChatToggleHeader === 'class'
                        ? 'class page'
                        : "teacher's profile"}{' '}
                      and click “Join group”.
                    </Typography>
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                        marginTop: '20px',
                      }}
                    >
                      <ButtonComponent
                        value="Join"
                        buttonStyle={{
                          backgroundColor: Colors.blueOnlineClass,
                          color: Colors.pri_color,
                          borderRadius: '12px',
                          marginRight: '10px',
                          width: '156px',
                          border: `1px solid ${Colors.blueOnlineClass}`,
                        }}
                        onClick={async () => {
                          const dataCbu = {};
                          dataCbu[
                            `convobyusers/${this.auth_user.uid}/${convoId}`
                          ] = true;
                          await firebase.database().ref().update(dataCbu);
                          const member = {
                            uid: this.auth_user.uid,
                            date_added: Date.now(),
                            convo_id: convoId,
                            status: 'accepted',
                          };
                          await firestore
                            .collection('ConvoMembers')
                            .add(member);
                          this.setState({ chatMembershipStatus: 'accepted' });
                        }}
                      />
                      <ButtonComponent
                        value={
                          this.state.classId
                            ? 'Class page'
                            : "Teacher's profile"
                        }
                        buttonStyle={{
                          backgroundColor: 'transparent',
                          color: Colors.pri_color,
                          borderRadius: '12px',
                          width: '156px',
                          border: `1px solid ${Colors.blueOnlineClass}`,
                        }}
                        onClick={() => {
                          if (this.state.classId) {
                            this.props.history.push(
                              `/${convoUserName}/class/${this.state.classId}`
                            );
                          } else {
                            this.props.history.push(`/${convoUserName}`);
                          }
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <PinMessageCard
                      convoMessages={convoMessages}
                      handlePinMessageModal={this.handlePinMessageModal}
                      handlePinnedMessageId={(messageId) =>
                        this.setState({ pinnedMessageId: messageId })
                      }
                      teacherId={this.state.teacherId}
                      uid={this.auth_user.uid}
                      hidePinnedMessageView={true}
                    />
                    {!this.state.messagePaginationLoader &&
                      this.state.showLoadMoreMessages && (
                        <div style={{ width: '100%' }}>
                          <div
                            onClick={() => {
                              this.fetchMoreMessagesOfAConvoId(convoId);
                              this.setState({ messagePaginationLoader: true });
                            }}
                            style={{
                              marginRight: 'auto',
                              marginLeft: 'auto',
                              backgroundColor: '#E0E0E0',
                              borderRadius: '20px',
                              width: '140px',
                              padding: '4px 12px',
                              cursor: 'pointer',
                            }}
                            className=""
                          >
                            <Typography
                              style={{ textAlign: 'center', color: '#000000' }}
                            >
                              Load more messages
                            </Typography>
                          </div>
                        </div>
                      )}
                    {this.state.messagePaginationLoader && (
                      <div style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                        <CircularProgress />
                      </div>
                    )}
                    <MessageCard
                      user={this.auth_user}
                      convoMessages={convoMessages}
                      replyHandler={(data) => this.handleReplyInput(data)}
                      convoMembersUserObj={convoMembersUserObj}
                      bookmark={this.state.messageHeaderTitle?.bookmark}
                      bookmarkMessageHandler={(data) =>
                        this.setBookmarkMessage(data)
                      }
                      showConvoBookmark={showConvoBookmark}
                      listOfBookmarkIds={listOfBookmarkIds}
                      teacherId={this.state.teacherId}
                      pinnedMessages={pinnedMessages}
                      dmChatType={this.state.currentChatToggleHeader === "dm"}
                      convos={convos}
                    />
                  </div>
                )}
                {this.state.firstTimeChatUrlVisit && (
                  <FirstTimeChatVisitModal
                    uid={this.auth_user.uid}
                    convoId={convoId}
                    SetFirstTimeChatUrlVisit={() =>
                      this.setState({ firstTimeChatUrlVisit: false })
                    }
                  />
                )}
                {this.state.showCancelChatRequestModal && (
                  <CancelChatRequestModal
                    closeModal={() =>
                      this.setState({ showCancelChatRequestModal: false })
                    }
                    isClassGroupChat={this.state.classId}
                    OnClick={async () => {
                      await firestore
                        .collection('ConvoMembers')
                        .doc(this.state.chatConvoMemberId)
                        .delete();
                      if (this.state.classId) {
                        const updateData = {};
                        updateData[
                          `convobyusers/${this.auth_user.uid}/${this.state.convoId}`
                        ] = null;
                        await firebase
                          .database()
                          .ref()
                          .update(updateData)
                          .then(() =>
                            this.props.history.push(
                              `/${convoUserName}/class/${this.state.classId}`
                            )
                          );
                      } else {
                        this.props.history.push(`/${convoUserName}`);
                      }
                    }}
                  />
                )}
                {this.state.openNewMemberSnackBar && (
                  <SnackBarComponent
                    text="New member added successfully"
                    closeSnackBar={() =>
                      this.setState({ openNewMemberSnackBar: false })
                    }
                    showCancelIcon={false}
                  />
                )}
              </div>
            )}
            {messageHeaderTitle && messageHeaderTitle.bookmark && (
              <div
                style={{
                  height: (replyInput && '50vh') || '59vh',
                  overflow: 'auto',
                  margin: '0px 18px',
                }}
              >
                {this.state.bookmarkLoading ? (
                  <Loading />
                ) : (
                  <this.showBookmarkMessage />
                )}
              </div>
            )}
          </div>
          <div className={(!mobileFirstView && 'chat-mobile-view') || null}>
            {messageCard &&
              !messageHeaderTitle &&
              chatMembershipStatus === 'accepted' &&
              !showConvoBookmark &&
              !listOfOwnerBlockedUsers.includes(recipientId) &&
              !listOfRecipientBlockedUsers.includes(this.auth_user.uid) && (
                <InputMessage
                  onChange={(e) =>
                    this.setState({
                      convoText: e.target.value,
                      clearAllCurrentConversationChat: false,
                    })
                  }
                  setGifUrl={(url) => this.setState({ selectedGifUrl: url })}
                  onKeyPress={(e, closeGifView, removeSelectedGif) =>
                    this.handleKeyPress(e, closeGifView, removeSelectedGif)
                  }
                  refFwd={this.input}
                  inputRef={this.inputRef}
                  reply={replyInput}
                  replyMessageDetails={replyMessageDetails}
                  cancelReplyMessage={() => this.setState({ replyInput: null })}
                  handleSendMessage={() => {
                    this.sendMessage();
                  }}
                  value={this.state.convoText}
                />
              )}
            {(listOfOwnerBlockedUsers.includes(recipientId) ||
              listOfRecipientBlockedUsers.includes(this.auth_user.uid)) && (
              <div
                style={{
                  display: 'flex',
                  backgroundColor: Colors.snow,
                  padding: '16px',
                }}
                className={(!mobileFirstView && 'chat-mobile-view') || null}
              >
                <Typography
                  style={{ ...GlobalStyles.bodyRegular, color: Colors.grey3 }}
                >
                  {listOfOwnerBlockedUsers.includes(recipientId)
                    ? 'You blocked this user. To keep on sending them messages unblock them.'
                    : 'This user blocked you. To keep on sending them messages they need to unblock you.'}
                </Typography>
              </div>
            )}
          </div>
        </Grid>
        <Modal
          aria-labelledby="search users"
          aria-describedby="Search for a user to send a message to."
          className="center-modal"
          open={this.state.openSearchModal}
          onClose={() => {
            this.setState({ openSearchModal: false });
          }}
          disableAutoFocus
          disableEnforceFocus
        >
          <div
            className="modal-content-wrapper"
            style={{
              backgroundColor: Colors.white,
              width: '380px',
              borderRadius: '12px',
            }}
          >
            <ClearCard
              cancelEvent={() => this.setState({ openSearchModal: false })}
            />
            <div style={{ marginTop: '20px' }}>
              <YogiPicker
                {...this.props}
                maxResults={3}
                onSelected={(item) => {
                  this.setState(
                    {
                      recipientId: item.objectID,
                      titleCard: 'dm',
                      currentChatDetails: { ...item },
                    },
                    async () => {
                      const recipientId = this.state.recipientId;
                      const uid = this.auth_user.uid;
                      this.dmEntryPoint({ uid, recipientId });
                      this.setState({ openSearchModal: false });
                    }
                  );
                }}
              />
            </div>
          </div>
        </Modal>
        <Modal
          disableAutoFocus
          disableEnforceFocus
          aria-labelledby="pin-message-modal"
          aria-describedby="pin-message-modal-or-cancel"
          className="center-modal"
          open={pinMessageModal}
          onClose={this.handlePinMessageModal}
        >
          <div
            style={{
              width: '356px',
              backgroundColor: Colors.white,
              margin: 'auto',
              marginTop: '70px',
              borderRadius: '8px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  margin: '20px 58px 24px 58px',
                  textAlign: 'center',
                }}
              >
                <Typography
                  style={{
                    ...GlobalStyles.titleBold,
                    fontSize: '18px',
                    color: Colors.grey1,
                  }}
                >
                  Unpin message?
                </Typography>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '24px',
                  }}
                >
                  <ButtonComponent
                    value="CANCEL"
                    buttonStyle={{
                      backgroundColor: Colors.grey6,
                      color: Colors.grey1,
                      borderRadius: '10px',
                      marginRight: '10px',
                      border: 0,
                    }}
                    onClick={this.handlePinMessageModal}
                  />
                  <ButtonComponent
                    value="UNPIN"
                    buttonStyle={{
                      backgroundColor: Colors.pri_color,
                      color: Colors.white,
                      borderRadius: '10px',
                      border: 0,
                    }}
                    onClick={() => {
                      firestore
                        .collection('ConvoMessages')
                        .doc(pinnedMessageId)
                        .update({
                          date_pinned: null,
                        })
                        .then(() => {
                          this.handlePinMessageModal();
                        })
                        .catch((err) => {
                          console.error('Error updating document: ', err);
                        });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </Grid>
    );
  }
}

export default NewInbox;
