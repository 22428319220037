import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
    // Colors,
    GlobalStyles
  } from '../../utils';

const FirstComponent = () => (
  <div>
    <Typography>making the first component gallery</Typography>
  </div>
);

const TextAndIcon = ({ iconName, title, viewStyle, iconStyle, textStyle }) => (
  <div
    style={{
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      ...viewStyle
    }}
  >
    <span
      className="material-icons"
      style={{ fontSize: 20, ...iconStyle }}
    >
      {iconName}
    </span>
    <Typography
      style={{
        ...GlobalStyles.bodyRegular,
        textAlign: 'left',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        ...textStyle
      }}
    >
      {' '}
      {title}
{' '}
    </Typography>
  </div>
);

export { FirstComponent, TextAndIcon };
