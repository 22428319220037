import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
// import TitleCardOptions from "../TitleCardOptions";
import ReplyMessage from './ReplyMessage';
import { Colors, GlobalStyles, Loading, ConvoMessages } from '../../../utils';
import { firestore } from '../../../fire';
import AvatarDisplay from '../../AvatarDisplay';

const BookmarkMessage = ({
  messageId,
  uid,
  convoMembersUserObj,
  bookmark = true,
}) => {
  const [messageDetails, setMessageDetails] = useState({});
  useEffect(() => {
    const docRef = firestore.collection(ConvoMessages).doc(messageId);
    docRef
      .get()
      .then(function (doc) {
        setMessageDetails({ ...doc.data() });
      })
      .catch(function (error) {
        console.log('Error getting document:', error);
      });
  }, [messageId]);
  const left = uid !== messageDetails.sender_uid;
  const reply = !!messageDetails.replied_to_message_id;

  
  return (
    <React.Fragment>
      {messageDetails.sender_uid ? (
        <div style={{ flex: 1 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: left ? 'row' : 'row-reverse',
            }}
          >
      
         
         <AvatarDisplay
            imgStyles={{ width: '48px', height: '48px', borderRadius: '24px' }}
            imgSrc={convoMembersUserObj &&
              (convoMembersUserObj[messageDetails.sender_uid]).profile_photo_url}
            imgAlt={'message-avatar'}
            userName={convoMembersUserObj && (convoMembersUserObj[messageDetails?.sender_uid]).fullname}
            textStyles={{
              ...GlobalStyles.bodyMedium,
              color: 'rgb(23, 155, 247)',
              textAlign: 'center',
              borderRadius: '50px',
              border: '2px solid',
              display: 'flex',
              alignItems: 'center',
              height: '30px',
              width: '30px',
              justifyContent: 'center',
              marginTop: '5px'
        }}
         />
            <Typography
              style={{
                ...GlobalStyles.bodyMedium,
                color: Colors.grey1,
                textAlign: 'left',
                marginLeft: left ? '10px' : 0,
                marginRight: left ? 0 : '10px',
                marginTop: '13px',
              }}
            >
              {convoMembersUserObj
                ? (convoMembersUserObj[messageDetails.sender_uid] || {})
                    .username
                : ''}
            </Typography>
            <Typography
              style={{
                ...GlobalStyles.bodyMedium,
                color: Colors.grey7,
                textAlign: 'left',
                marginLeft: left ? '6px' : 0,
                marginRight: left ? 0 : '6px',
                marginTop: '7px',
                fontSize: '15px',
              }}
            >
              .
            </Typography>
            <Typography
              style={{
                ...GlobalStyles.bodyRegular,
                color: Colors.grey3,
                textAlign: 'left',
                marginLeft: left ? '6px' : 0,
                marginRight: left ? 0 : '6px',
                marginTop: '15px',
                fontSize: '10px',
              }}
            >
              {moment(messageDetails.date_created).format('hh:mm a')}
            </Typography>
            {bookmark && (
              <span
                className="material-icons"
                style={{
                  color: Colors.grey4,
                  marginLeft: left ? '10px' : 0,
                  marginRight: left ? 0 : '10px',
                  fontSize: '15px',
                  marginTop: '14px',
                }}
              >
                star
              </span>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: left ? 'row' : 'row-reverse',
              marginLeft: left ? '60px' : 0,
              marginRight: left ? 0 : '60px',
            }}
          >
            <Typography
              style={{
                ...GlobalStyles.bodyRegular,
                padding: '10px',
                color: Colors.grey2,
                boxShadow:
                  '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 3px 5px rgba(0, 0, 0, 0.2)',
                borderRadius: '0px 10px 10px 10px',
                whiteSpace: 'pre-wrap',
                textAlign: 'left',
              }}
            >
              {reply && (
                <ReplyMessage
                  repliedToMessageId={messageDetails.replied_to_message_id}
                  left={left}
                  convoMembersUserObj={convoMembersUserObj}
                />
              )}
              {messageDetails.message_text}
            </Typography>
            {/* <div
                  style={{
                    color: Colors.grey2,
                    marginLeft: left ? "27px" : 0,
                    marginRight: left ? 0 : "27px",
                  }}
                >
                  <TitleCardOptions
                    messageDetails={{username:(convoMembersUserObj[messageDetails.sender_uid] || {}).username, uid: uid, ...messageDetails}}
                    pinMessageHandler={pinMessageHandler}
                    replyHandler={replyHandler}
                    setPerformAction={setPerformAction}
                    performAction={performAction}
                    bookmarkMessageHandler={bookmarkMessageHandler}
                    arrowDown
                    messagePopover
                  />
                </div> */}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </React.Fragment>
  );
};

export default BookmarkMessage;
