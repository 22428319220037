import React, { Component } from 'react';

import {
    Link,
    //     Redirect,
} from 'react-router-dom';
import FileUploader from './common/FileUploader';
import Grid from '@material-ui/core/Grid';

// import { withStyles, withTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

// import Icon from '@material-ui/core/Icon';
// import SaveIcon from '@material-ui/icons/Save';
import MdEdit from '@material-ui/icons/Edit';
import MdClose from '@material-ui/icons/Close';
// import MdEmail from '@material-ui/icons/Email';
// import MdLock from '@material-ui/icons/Lock';

import Modal from '@material-ui/core/Modal';

// import { SocialIcon } from 'react-social-icons';

// import TextField from '@material-ui/core/TextField';

import {
    // UtilFX,
    DataFX,
    // Colors,
    // GlobalContext,
    GlobalStyles,
    AlertBox,
    Loading,
    Colors,
    UtilFX,
    DefaultProfilePhotoUrl
} from '../utils/index.js';
import AvatarDisplay from './AvatarDisplay';


export default class Profile extends Component {
    // constructor(props, context) {
    //     super(props, context);
    //     this.state = {};
    // }

    state = {
        openModal: false,
    };

    componentDidUpdate(oldProps, oldState) {
        // this.determineYogiRelation();
        const { context: { userAuthenticated: newUserAuthenticated = {} } = {} } = this.props || {};
        const { context: { userAuthenticated: oldUserAuthenticated = {} } = {} } = oldProps || {};

        // console.info("profile outisde cdu called. userAuthed? oldProps oldState, new Props & state", oldProps, oldState, this.props, this.state);
        // const oldTeacherCount = Object.keys(oldUserAuthenticated.teachers || {}).length;
        // const newTeacherCount = Object.keys(newUserAuthenticated.teachers || {}).length;
        if (
            // this.state.username !== oldState.username
            oldUserAuthenticated.profile_photo_url !== newUserAuthenticated.profile_photo_url
            || oldUserAuthenticated.bio !== newUserAuthenticated.bio
            || oldUserAuthenticated.fullname !== newUserAuthenticated.fullname
            || oldUserAuthenticated.email !== newUserAuthenticated.email
            || oldUserAuthenticated.username !== newUserAuthenticated.username
            // || newTeacherCount !== oldTeacherCount
        ) {
            //this.setState({ userAuthenticated: newUserAuthenticated });
            this.fetchData();
        }
    }

    componentDidMount() {
        // const { userData } = (this.props.location && this.props.location.state) || this.props || {};
        // const { username, userid: yogiId } = (this.props && (this.props.computedMatch || this.props.match || {}).params) || {};
        // console.log("props on mount", this.props);

        this._mounted = true;
        this.fetchData();
        // this.setState({
        //     userAuthenticated,
        // }, () => {
        //     // already fetched
        //     this.fetchData();
        // });
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    closeModal = () => {
        this.setState({ openModal: false });
    }

    handleChange = (e) => {
        let incomingVal = e.target.value;
        const targetName = e.target.name;

        const isFullname = targetName === 'fullname';
        const isUsername = targetName === 'username';
        const isBio = targetName === 'bio';
        // const isWebsite = targetName === "website";
        // const isEmail = targetName === "email";

        // console.log("incoming data", targetName, finalVal);

        // console.log("OUTgoing data", targetName, finalVal);

        this.setState({
            [targetName]: UtilFX.handleProfileValidation({ isUsername, isFullname, isBio, incomingVal }),
        });
    }

    fetchData = () => {
        const { context: { userAuthenticated } = {} } = this.props || {};
        const user = userAuthenticated || {};
        this.setState({
            userAuthenticated,
            fullname: user.fullname, //these are used for field values
            email: user.email,
            bio: user.bio,
            username: user.username,
            website: user.website,
            profile_photo_url: user.profile_photo_url,
        });
    }

    render() {
        // console.log("would re render now", this.props, this.state);
        const { context: { refreshUserAuthenticated } = {}, history } = this.props || {};
        const { userAuthenticated: userData, openModal } = this.state;
        const onSignUpPhotoUrl = userData && userData.profile_photo_url === DefaultProfilePhotoUrl;
       
        return (
            <Grid container justify="center">
                <Grid item xs={12} style={{ maxWidth: '90%' }}>
                    <div>
                        {
                            !userData
                                ?
                                <Loading />
                                :
                                (
<div
                                    style={{ position: 'relative' }}
>
                                    <h2 className="page-title">Profile</h2>
                                    <div style={{
                                        borderRadius: 10,
                                        paddingTop: '40px',
                                        marginTop: 10,
                                        backgroundColor: '#fff',
                                        color: '#000'
                                    }}
                                    >
                                        <div
                                            style={{
                                                //top: 40,
                                                position: 'relative',
                                                width: 300,
                                                margin: '0 auto',
                                            }}
                                        >
                                            <Link
                                                to={userData.username || '/'}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (userData.username) {
                                                        history.push(`/${userData.username}`);
                                                    } else {
                                                        window && window.alert('You must create a username to have a dedicated profile page.');
                                                    }
                                                }}
                                                style={{ position: "relative", display: "inline-block" , textDecoration: 'none', color: 'rgb(23, 155, 247)', border: onSignUpPhotoUrl ? "2px solid": 'none', borderRadius: "50px", height:"60px", width:"60px"}}
                                            >
                                        
                                               <AvatarDisplay
                                                imgSrc={userData.profile_photo_url}
                                                imgAlt={''}
                                                imgClassName={'yogi-photo'}
                                                imgStyles={{
                                                    borderRadius: '50%',
                                                    height: 82,
                                                    border: '4px white solid',
                                                    position: 'relative',
                                                    display: 'inline-block',
                                                }}
                                                userName={userData.fullname}
                                                textStyles={{letterSpacing: "1px", marginTop: "17px", fontSize:'18px'}}
                                               />
                                            </Link>
                                            <br />
                                            <label className="cursor-pointer" style={{ display: 'inline-block', backgroundColor: 'white', color: Colors.pri_color, padding: 10, borderRadius: 5 }}>
                                                change photo
                                                <FileUploader userAuthenticated={userData} refreshUserAuthenticated={refreshUserAuthenticated} />
                                            </label>
                                        </div>
                                        <div
                                            style={{
                                                marginBottom: 20,
                                                padding: '10px 0 20px 0',
                                            }}
                                        >
                                            <div className="edit-item-wrapper">
                                                <Typography color="textSecondary" variant="body1">
Full Name:
<MdEdit
                                                    // className=""
                                                    onClick={(e) => {
                                                        // console.log("incoming e", e);
                                                        this.setState({
                                                            openModal: true,
                                                            errorMessage: null, //reset error message
                                                            editingProperty: 'fullname',
                                                            editingPropertyPlaceholder: 'Full Name'
                                                        });
                                                    }}
                                                    style={{ fontSize: '0.95em' }}
/>
                                                </Typography>
                                                <Typography color="textSecondary" variant="subtitle1">
                                                    {userData.fullname}
                                                </Typography>
                                            </div>
                                            <div className="edit-item-wrapper">
                                                <Typography color="textSecondary" variant="body1">
User Name:
<MdEdit
                                                    onClick={(e) => {
                                                        // console.log("incoming e", e);
                                                        this.setState({
                                                            openModal: true,
                                                            errorMessage: null, //reset error message
                                                            editingProperty: 'username',
                                                            editingPropertyPlaceholder: 'User Name'
                                                        });
                                                    }}
                                                    style={{ fontSize: '0.95em' }}
/>
                                                </Typography>
                                                <Typography color="textSecondary" variant="subtitle1">
                                                    @
{userData.username}
                                                </Typography>
                                            </div>
                                            <div className="edit-item-wrapper">
                                                <Typography color="textSecondary" variant="body1">
Bio:
<MdEdit
                                                    onClick={(e) => {
                                                        // console.log("incoming e", e);
                                                        this.setState({
                                                            openModal: true,
                                                            errorMessage: null, //reset error message
                                                            editingProperty: 'bio',
                                                            editingPropertyPlaceholder: 'Bio'
                                                        });
                                                    }}
                                                    style={{ fontSize: '0.95em' }}
/>
                                                </Typography>
                                                <Typography style={{ paddingRight: '10px', paddingLeft: '10px' }} color="textSecondary" variant="subtitle1">
                                                    {userData.bio}
                                                </Typography>
                                            </div>
                                            <div className="edit-item-wrapper">
                                                <Typography color="textSecondary" variant="body1">
Website:
<MdEdit
                                                    onClick={(e) => {
                                                        // console.log("incoming e", e);
                                                        this.setState({
                                                            openModal: true,
                                                            errorMessage: null, //reset error message
                                                            editingProperty: 'website',
                                                            editingPropertyPlaceholder: 'Web site url'
                                                        });
                                                    }}
                                                    style={{ fontSize: '0.95em' }}
/>
                                                </Typography>
                                                <Typography color="textSecondary" variant="subtitle1">
                                                    {userData.website}
                                                </Typography>
                                            </div>
                                            {/* <div className="edit-item-wrapper">
                                                <Typography color="textSecondary" variant="body1">Email:<MdEdit
                                                    onClick={(e) => {
                                                        // console.log("incoming e", e);
                                                        this.setState({
                                                            openModal: true,
                                                            errorMessage: null, //reset error message
                                                            editingProperty: "email",
                                                            editingPropertyPlaceholder: "Email"
                                                        });
                                                    }}
                                                    style={{ fontSize: "0.95em" }} />
                                                </Typography>
                                                <Typography color="textSecondary" variant="subtitle1">
                                                    {userData.email}
                                                </Typography>
                                            </div> */}

                                            <Typography variant="caption" color="textSecondary">Manage more details about your account inside the mobile app.</Typography>
                                        </div>
                                    </div>
</div>
)
                        }
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={openModal}
                            onClose={this.closeModal}
                        >
                            <Grid container justify="center">
                                <Grid item xs={10}>
                                    <MdClose
                                        style={{ position: 'absolute', right: 10, top: 10, }}
                                        onClick={() => this.setState({ openModal: false })}
                                    />
                                    <div style={{}} className="modal-content-wrapper">
                                        <form onSubmit={async (e) => {
                                            e.preventDefault();
                                            // console.log("now how to update Db with following info in state");

                                            const {
                                                editingProperty,
                                                fullname,
                                                username,
                                                bio,
                                                website,
                                                // email,
                                                userAuthenticated
} = this.state || {};
                                            const isFullname = editingProperty === 'fullname';
                                            const isUsername = editingProperty === 'username';
                                            const isBio = editingProperty === 'bio';
                                            const isWebsite = editingProperty === 'website';
                                            // const isEmail = editingProperty === "email";

                                            if (isFullname && (userAuthenticated.fullname !== fullname)) {
                                                //self-validation. cant be empty.
                                                const fullnameFinal = fullname && fullname.trim();
                                                if (!fullnameFinal.length) {
                                                    this.setState({
                                                        errorMessage: 'Full name cannot be empty.',
                                                    });
                                                    return null;
                                                }
                                                //lets try to update
                                                return DataFX.updateYogiProfileInfo({
                                                    uid: userAuthenticated.uid,
                                                    fullname: fullnameFinal
                                                })
                                                    .then(() => {
                                                        this._mounted && this.setState({ fullname, errorMessage: null, openModal: false }, () => {
                                                            refreshUserAuthenticated && refreshUserAuthenticated();
                                                        });
                                                    }).catch(err => {
                                                        this._mounted && this.setState({
                                                            errorMessage: `We encountered an error saving your info: ${JSON.stringify(err)}`
                                                        });
                                                    });
                                            } if (isBio && (userAuthenticated.bio !== bio)) {
                                                let bioFinal = bio && bio.trim();
                                                bioFinal = (bioFinal && bioFinal.length) ? bioFinal : ' ';
                                                //lets try to update
                                                return DataFX.updateYogiProfileInfo({ uid: userAuthenticated.uid, bio: bioFinal })
                                                    .then(() => {
                                                        this._mounted && this.setState({ bio, errorMessage: null, openModal: false }, () => {
                                                            refreshUserAuthenticated && refreshUserAuthenticated();
                                                        });
                                                    }).catch(err => {
                                                        this._mounted && this.setState({
                                                            errorMessage: `We encountered an error saving your info: ${JSON.stringify(err)}`
                                                        });
                                                    });
                                            } if (isWebsite && (userAuthenticated.website !== website)) {
                                                const websiteFinal = website && website.trim();
                                                //lets try to update
                                                return DataFX.updateYogiProfileInfo({ uid: userAuthenticated.uid, website: websiteFinal })
                                                    .then(() => {
                                                        this._mounted && this.setState({ website, errorMessage: null, openModal: false }, () => {
                                                            refreshUserAuthenticated && refreshUserAuthenticated();
                                                        });
                                                    }).catch(err => {
                                                        this._mounted && this.setState({
                                                            errorMessage: `We encountered an error saving your info: ${JSON.stringify(err)}`
                                                        });
                                                    });
                                            } if (isUsername && (userAuthenticated.username !== username)) {
                                                const usernameFinal = username && username.trim();
                                                //lets try to update
                                                const usernameCheck = await UtilFX.checkForExistingUsername(username);
                                                if(usernameCheck){
                                                    return UtilFX.toastError(<Typography>
                                                        Username already exists
                                                        </Typography>, { toastId: 'username exists' });
                                                }
                                                return DataFX.updateYogiProfileInfo({ uid: userAuthenticated.uid, username: usernameFinal, usernameOriginal: userAuthenticated.username })
                                                    .then(() => {
                                                        this._mounted && this.setState({ username, errorMessage: null, openModal: false }, () => {
                                                            refreshUserAuthenticated && refreshUserAuthenticated();
                                                        });
                                                    }).catch(err => {
                                                        this._mounted && this.setState({
                                                            errorMessage: `We encountered an error saving your info: ${JSON.stringify(err)}`
                                                        });
                                                    });
                                            }
                                        }}
                                        >
                                            {/* <Typography color="textSecondary" variant="subtitle1" id="modal-title">
                                    Text in a modal
                                </Typography>
                                <Typography color="textSecondary" variant="body1" id="simple-modal-description">
                                    Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                                </Typography> */}
                                            {(() => {
                                                const {
                                                    editingProperty,
                                                    editingPropertyPlaceholder
                                                } = this.state || {};
                                                const isFullname = editingProperty === 'fullname';
                                                const isUsername = editingProperty === 'username';
                                                const isBio = editingProperty === 'bio';
                                                const isWebsite = editingProperty === 'website';
                                                const isEmail = editingProperty === 'email';
                                                let outPut;
                                                if (isFullname || isWebsite || isEmail || isUsername) {
                                                    outPut = (
<div style={GlobalStyles.textInputContainer}>
                                                        <input
                                                            placeholder={editingPropertyPlaceholder}
                                                            style={GlobalStyles.textInputDefault}
                                                            type={(isEmail && 'email') || 'text'}
                                                            maxLength={(isFullname && this.max_length_fullname) || (isUsername && this.max_length_username) || ''}
                                                            name={editingProperty}
                                                            value={this.state[editingProperty]}
                                                            onChange={this.handleChange}
                                                        />
</div>
);
                                                } else if (isBio) {
                                                    outPut = (
<div style={GlobalStyles.textInputContainer}>
                                                        <textarea
                                                            placeholder={editingPropertyPlaceholder}
                                                            style={{ ...GlobalStyles.textInputDefault, fontSize: '0.65em', height: '70px' }}
                                                            // type={(isEmail && "email") || "text"}
                                                            maxLength={(isBio && this.max_length_bio) || ''}
                                                            name={editingProperty}
                                                            value={this.state[editingProperty]}
                                                            onChange={this.handleChange}
                                                        />
</div>
);
                                                }
                                                return outPut;
                                            })()}
                                            {
                                                this.state.errorMessage ? (
                                                    <AlertBox>
                                                        <strong>Error: </strong>
{this.state.errorMessage}
                                                    </AlertBox>
                                                  )
                                                    : null
                                            }
                                            <Button type="submit">Save</Button>
                                        </form>
                                    </div>
                                </Grid>
                            </Grid>
                        </Modal>
                    </div>
                </Grid>
            </Grid>
        );
    }
}
// const ProfileBase = withTheme()(withStyles(stylesProfile)(ProfileBaseX))
