import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import InboxHeader from './common/GroupChat/InboxHeader';
import ToggleHeader from './common/GroupChat/ToggleHeader';
import DmConversations from './common/GroupChat/DmConversations';
import MessageHeader from './common/GroupChat/MessageHeader';
import MessageCard from './common/GroupChat/MessageCard';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import {
  // UtilFX,
  Colors,
  GlobalStyles,
} from '../utils';
import ClassConversations from './common/GroupChat/ClassConversations';
import DmZeroState from './common/GroupChat/DmZeroState';
import MessageZeroState from './common/GroupChat/MessageZeroState';
import BookmarkHeader from './common/GroupChat/BookmarkHeader';
import InputMessage from './common/GroupChat/InputMessage';
const Title = styled.p`
  color: red;
  margintop: 30px;
`;

export default class ComponentGallery extends Component {
  render() {
    return (
      <div style={{ marginBottom: '-13px', backgroundColor: Colors.white }}>
        <div>
          <Typography
            style={{
              ...GlobalStyles.upgradeHeader,
              textAlign: 'center',
              color: Colors.grey1,
            }}
          >
            Component Gallery
          </Typography>
        </div>
        <Grid container style={{ justifyContent: 'center' }}>
          <Grid
            md={4}
            xs={12}
            item
            style={{ margin: '15px', justifyContent: 'center' }}
          >
            <Title>InboxHeader</Title>
            <InboxHeader title="Inbox" ArchiveStar />
            <Title>ArchivedHeader</Title>
            <InboxHeader archive="Archived chats" />
            <Title>ToggleHeader</Title>
            <ToggleHeader eventHandler={() => { }} />
            <Title>DmConversations</Title>
            <DmConversations
              convos={[
                {
                  convo_id: 'aM1XOGI5Zx2s7m8ijdM8',
                  convoid: 'aM1XOGI5Zx2s7m8ijdM8',
                  date_created: 1611787796076,
                  date_last_read: 1611788774335,
                  date_latest_activity: 1611787796076,
                  last_message_id: 'baa6Sm2u9l1Va3AqIB6V',
                  last_message_sender_uid: '6ZnnKahooEU2wrtLGRBkqKOtBv63',
                  message_text: 'is it working',
                  other_uid: 'DSY3OlfI5YdLeSTjpy4SFI1aXEP2',
                  sender_uid: '6ZnnKahooEU2wrtLGRBkqKOtBv63',
                },
              ]}
              _u={{ uid: '6ZnnKahooEU2wrtLGRBkqKOtBv63' }}
              convoMembersUserObj={{
                '6ZnnKahooEU2wrtLGRBkqKOtBv63': {
                  profile_photo_url:
                    'https://firebasestorage.googleapis.com/v0/b/encoded-ensign-158201.appspot.com/o/images%2Fprofilephoto_6ZnnKahooEU2wrtLGRBkqKOtBv63.jpg?alt=media&token=6b317028-0021-4531-9608-a2a327b3fcb0',
                  username: 'user_name',
                },
              }}
              setRecipientId={(id) => { }}
              setConvoId={(convoid) => { }}
              setCurrentChatDetails={(details) => { }}
            />
            <Title>MessageHeader</Title>
            <MessageHeader name="Clark Kent" listOfRecipientBlockedUsers={[]} listOfOwnerBlockedUsers={[]} />
            <Title>BookmarkHeader for All Bookmarks</Title>
            <BookmarkHeader title="All bookmarks" />
            <Title>MessageCard</Title>
            <MessageCard
              convomessages={[
                {
                  convo_id: 'fMh1bgn44DoT04gODWeb',
                  date_created: 1611157312683,
                  message_id: 'yHwdlEhKoPmTaoPJeyzl',
                  message_text: 'olorun ibere ati opin pari ishe re oo, ',
                  sender_uid: '6ZnnKahooEU2wrtLGRBkqKOtBv63',
                },
              ]}
              _u={{ uid: '6ZnnKahooEU2wrtLGRBkqKOtBv63' }}
              convoMembersUserObj={{
                '6ZnnKahooEU2wrtLGRBkqKOtBv63': {
                  profile_photo_url:
                    'https://firebasestorage.googleapis.com/v0/b/encoded-ensign-158201.appspot.com/o/images%2Fprofilephoto_6ZnnKahooEU2wrtLGRBkqKOtBv63.jpg?alt=media&token=6b317028-0021-4531-9608-a2a327b3fcb0',
                  username: 'user_name',
                },
              }}
              listOfBookmarkIds={[]}
            />
            <Title>MessageCardReverse</Title>
            <MessageCard
              left
              convomessages={[
                {
                  convo_id: 'fMh1bgn44DoT04gODWeb',
                  date_created: 1611157312683,
                  message_id: 'yHwdlEhKoPmTaoPJeyzl',
                  message_text: 'olorun ibere ati opin pari ishe re oo, ',
                  sender_uid: '6ZnnKahooEU2wrtLGRBkqKOtBv63',
                },
              ]}
              _u={{ uid: '' }}
              convoMembersUserObj={{
                '6ZnnKahooEU2wrtLGRBkqKOtBv63': {
                  profile_photo_url:
                    'https://firebasestorage.googleapis.com/v0/b/encoded-ensign-158201.appspot.com/o/images%2Fprofilephoto_6ZnnKahooEU2wrtLGRBkqKOtBv63.jpg?alt=media&token=6b317028-0021-4531-9608-a2a327b3fcb0',
                  username: 'user_name',
                },
              }}
              listOfBookmarkIds={[]}
            />
            <Title>MessageCardReply</Title>
            <MessageCard
              reply
              convomessages={[
                {
                  convo_id: 'fMh1bgn44DoT04gODWeb',
                  date_created: 1611157312683,
                  message_id: 'yHwdlEhKoPmTaoPJeyzl',
                  message_text: 'olorun ibere ati opin pari ishe re oo, ',
                  sender_uid: '6ZnnKahooEU2wrtLGRBkqKOtBv63',
                },
              ]}
              _u={{ uid: '6ZnnKahooEU2wrtLGRBkqKOtBv63' }}
              convoMembersUserObj={{
                '6ZnnKahooEU2wrtLGRBkqKOtBv63': {
                  profile_photo_url:
                    'https://firebasestorage.googleapis.com/v0/b/encoded-ensign-158201.appspot.com/o/images%2Fprofilephoto_6ZnnKahooEU2wrtLGRBkqKOtBv63.jpg?alt=media&token=6b317028-0021-4531-9608-a2a327b3fcb0',
                  username: 'user_name',
                },
              }}
              listOfBookmarkIds={[]}
            />
            <Title>MessageCardReverseReply</Title>
            <MessageCard
              left
              reply
              convomessages={[
                {
                  convo_id: 'fMh1bgn44DoT04gODWeb',
                  date_created: 1611157312683,
                  message_id: 'yHwdlEhKoPmTaoPJeyzl',
                  message_text: 'olorun ibere ati opin pari ishe re oo, ',
                  sender_uid: '6ZnnKahooEU2wrtLGRBkqKOtBv63',
                },
              ]}
              _u={{ uid: '6ZnnKahooEU2wrtLGRBkqKOtBv63' }}
              convoMembersUserObj={{
                '6ZnnKahooEU2wrtLGRBkqKOtBv63': {
                  profile_photo_url:
                    'https://firebasestorage.googleapis.com/v0/b/encoded-ensign-158201.appspot.com/o/images%2Fprofilephoto_6ZnnKahooEU2wrtLGRBkqKOtBv63.jpg?alt=media&token=6b317028-0021-4531-9608-a2a327b3fcb0',
                  username: 'user_name',
                },
              }}
              listOfBookmarkIds={[]}
            />
            <Title>MessageCardBookmarked</Title>
            <MessageCard
              bookmark
              convomessages={[
                {
                  convo_id: 'fMh1bgn44DoT04gODWeb',
                  date_created: 1611157312683,
                  message_id: 'yHwdlEhKoPmTaoPJeyzl',
                  message_text: 'olorun ibere ati opin pari ishe re oo, ',
                  sender_uid: '6ZnnKahooEU2wrtLGRBkqKOtBv63',
                },
              ]}
              _u={{ uid: '6ZnnKahooEU2wrtLGRBkqKOtBv63' }}
              convoMembersUserObj={{
                '6ZnnKahooEU2wrtLGRBkqKOtBv63': {
                  profile_photo_url:
                    'https://firebasestorage.googleapis.com/v0/b/encoded-ensign-158201.appspot.com/o/images%2Fprofilephoto_6ZnnKahooEU2wrtLGRBkqKOtBv63.jpg?alt=media&token=6b317028-0021-4531-9608-a2a327b3fcb0',
                  username: 'user_name',
                },
              }}
              listOfBookmarkIds={[]}
            />
            <Title>MessageCardReverseBookmarked</Title>
            <MessageCard
              left
              bookmark
              convomessages={[
                {
                  convo_id: 'fMh1bgn44DoT04gODWeb',
                  date_created: 1611157312683,
                  message_id: 'yHwdlEhKoPmTaoPJeyzl',
                  message_text: 'olorun ibere ati opin pari ishe re oo, ',
                  sender_uid: '6ZnnKahooEU2wrtLGRBkqKOtBv63',
                },
              ]}
              _u={{ uid: '6ZnnKahooEU2wrtLGRBkqKOtBv63' }}
              convoMembersUserObj={{
                '6ZnnKahooEU2wrtLGRBkqKOtBv63': {
                  profile_photo_url:
                    'https://firebasestorage.googleapis.com/v0/b/encoded-ensign-158201.appspot.com/o/images%2Fprofilephoto_6ZnnKahooEU2wrtLGRBkqKOtBv63.jpg?alt=media&token=6b317028-0021-4531-9608-a2a327b3fcb0',
                  username: 'user_name',
                },
              }}
              listOfBookmarkIds={[]}
            />
            <Title>ClassConversations</Title>
            <ClassConversations
              convos={[
                {
                  convo_id: 'aM1XOGI5Zx2s7m8ijdM8',
                  convoid: 'aM1XOGI5Zx2s7m8ijdM8',
                  date_created: 1611787796076,
                  date_last_read: 1611788774335,
                  date_latest_activity: 1611787796076,
                  last_message_id: 'baa6Sm2u9l1Va3AqIB6V',
                  last_message_sender_uid: '6ZnnKahooEU2wrtLGRBkqKOtBv63',
                  message_text: 'is it working',
                  other_uid: 'DSY3OlfI5YdLeSTjpy4SFI1aXEP2',
                  sender_uid: '6ZnnKahooEU2wrtLGRBkqKOtBv63',
                },
              ]}
              _u={{ uid: '6ZnnKahooEU2wrtLGRBkqKOtBv63' }}
              convoMembersUserObj={{
                '6ZnnKahooEU2wrtLGRBkqKOtBv63': {
                  profile_photo_url:
                    'https://firebasestorage.googleapis.com/v0/b/encoded-ensign-158201.appspot.com/o/images%2Fprofilephoto_6ZnnKahooEU2wrtLGRBkqKOtBv63.jpg?alt=media&token=6b317028-0021-4531-9608-a2a327b3fcb0',
                  username: 'user_name',
                },
              }}
              setRecipientId={(id) => { }}
              setConvoId={(convoid) => { }}
              setCurrentChatDetails={(details) => { }}
            />
            <Title>DmZeroState</Title>
            <DmZeroState
              dm
              headline1="Start a new conversation with a member"
              headline2="of FavYogis."
            />
            <Title>DmZeroState for class</Title>
            <DmZeroState
              headline1="Chat rooms of classes you own or have"
              headline2="registered for, will appear here."
            />
            <Title>DmZeroState for Teachers</Title>
            <DmZeroState
              headline1="Home to your personal chat room"
              headline2="and those of your favorite teachers."
            />
            <Title>MessageZeroState</Title>
            <MessageZeroState
              headline="Select a message or start a new chat to begin."
              dm
            />
            <Title>MessageZeroState for Classes or Teachers</Title>
            <MessageZeroState headline="Select a message to begin." />
            <Title>InputMessage</Title>
            <InputMessage
              replyMessageDetails={{ username: 'fake-username', message_text: 'fake-text' }}
              inputRef={{ current: { focus: () => { } } }}
            />
            <Title>InputMessage with reply</Title>
            <InputMessage
              reply
              replyInputStyle={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
              replyViewStyle={{
                border: '1px solid' + Colors.grey5,
                borderRadius: '26px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
              replyMessageDetails={{ username: 'fake-username', message_text: 'fake-text' }}
              inputRef={{ current: { focus: () => { } } }}
            />
            <Title>MessageHeader with group message popover</Title>
            <MessageHeader name="Clark Kent" listOfRecipientBlockedUsers={[]} listOfOwnerBlockedUsers={[]} />
          </Grid>
        </Grid>
      </div>
    );
  }
}
