import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { GlobalStyles, UtilFX } from '../../utils';
import AvatarDisplay from '../AvatarDisplay';

const PendingInvite = ({ uid, accepted, item }) => {
    const [user, setUser] = useState({});

    useEffect(() => {
        if (uid) {
            Promise.resolve(UtilFX.getFYuser(uid).then(res => {
                setUser({ ...res });
            }));
        }
    }, [uid]);
    return (

        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                border: '1px solid #61BEFF',
                paddingTop: '16px',
            }}
            >
                {
                !accepted ?
                (<React.Fragment>
                    <AvatarDisplay
                        imgSrc={user.profile_photo_url}
                        imgStyles={{
                            marginLeft: '20px',
                            width: '48px',
                            height: '48px',
                            borderRadius: '24px',
                        }}
                        imgAlt={'alert-img'}
                        userName={user.fullname}
                        textStyles={{
                            ...GlobalStyles.bodyMedium,
                            color: 'rgb(23, 155, 247)',
                            textAlign: 'center',
                            borderRadius: '50px',
                            border: '2px solid',
                            display: 'flex',
                            alignItems: 'center',
                            height: '30px',
                            width: '30px',
                            justifyContent: 'center'
                      }}
                    />
                    <div style={{ marginLeft: '16px', marginBottom: '13px', marginRight: '20px' }}>
                        <Typography style={{ ...GlobalStyles.bodyRegular, textAlign: 'left' }}>
                        <span style={{ fontWeight: 'bold' }}>{user.fullname || user.username} </span>
                        { item.status === 'invited' ? 'invited you to join a personal group' : 'requested to join your personal group.' }
                        </Typography>
                        <Typography style={{ ...GlobalStyles.bodyRegular, textAlign: 'left', marginTop: '5px' }}><span style={{ fontWeight: 'bold' }}>Group name: </span>{item.convo_name}</Typography>
                        <Typography style={{ ...GlobalStyles.bodyRegular, textAlign: 'left' }}><span style={{ fontWeight: 'bold' }}>Group type: </span>{item.class_id ? 'Class' : 'Teacher'}</Typography>
                    </div>
                </React.Fragment>)
                :
                <React.Fragment>
                    {
                        item.status === 'invited' ?
                        (<Typography style={{ ...GlobalStyles.bodyRegular, textAlign: 'left', marginLeft: '20px', marginRight: '20px', marginBottom: '13px', }}>You accepted to join {user.fullname || user.username} personal group.</Typography>)
                        :
                        (<Typography style={{ ...GlobalStyles.bodyRegular, textAlign: 'left', marginLeft: '20px', marginRight: '20px', marginBottom: '13px', }}>You added {user.fullname || user.username} to your personal group.</Typography>)
                    }
                </React.Fragment>
                }
        </div>
    );
};

export default PendingInvite;
