import React, { Component } from 'react';

import {
  Link,
  //     Redirect,
} from 'react-router-dom';
import Helmet from 'react-helmet';
import {
  InstantSearch,
  // SearchBox,
  // Hits,
  // Highlight,
  // Snippet,
  // Stats,
  // SortBy,
  // Panel,
  Configure,
  // Index,
  // Pagination,
} from 'react-instantsearch/dom';

import moment from 'moment-timezone';
import Grid from '@material-ui/core/Grid';
// import { withStyles, withTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
// import SaveIcon from '@material-ui/icons/Save';
import MdStar from '@material-ui/icons/Star';
import MdStarBorder from '@material-ui/icons/StarBorder';

// import MdEmail from '@material-ui/icons/Email';
// import MdLock from '@material-ui/icons/Lock';

// import { SocialIcon } from 'react-social-icons';
// import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { SingleCard, BottomCard } from './common/ClassesManageCards';
import Explore, { HitsPlaces } from './Explore';
import * as Algolia from '../utils/algolia';
import { PayConfirmation } from './PaymentStuff';

import {
  UtilFX,
  DataFX,
  Loading,
  // Colors,
  // GlobalContext,
  GlobalStyles,
  // AlertBox,
  Colors,
  ConvoMetadata
} from '../utils';

import {
  // auth,
  // database
  firebase,
  firestore,
} from '../fire';
import AvatarDisplay from './AvatarDisplay';

const AlgoliaYogisIndex = Algolia.getIndex({ indexName: 'yogis' });
const AlgoliaClassesIndex = Algolia.getIndex({ indexName: 'classes' });

export default class ProfileOutside extends Component {
  // constructor(props, context) {
  //     super(props, context);
  //     this.state = {};
  // }
  state = {
    isLoading: false,
    openPayModal: false,
  };

  componentDidUpdate(oldProps, oldState) {
    // this.determineYogiRelation();
    const { context = {} } = this.props || {};
    const newUserAuthenticated = (context && context.userAuthenticated) || {};
    const { context: oldContext = {} } = oldProps || {};
    const oldUserAuthenticated =
      (oldContext && oldContext.userAuthenticated) || {};

    // console.info("profile outisde cdu called. userAuthed? oldProps oldState, new Props & state", oldProps, oldState, this.props, this.state);

    const newTeacherCount = Object.keys(
      (newUserAuthenticated || {}).teachers || {}
    ).length;
    const oldTeacherCount = Object.keys(
      (oldUserAuthenticated || {}).teachers || {}
    ).length;
    if (
      !this.state.isLoading &&
      !this.state.error &&
      (this.state.username !== oldState.username ||
        this.state.yogiId !== oldState.yogiId ||
        oldUserAuthenticated.uid !== newUserAuthenticated.uid ||
        newTeacherCount !== oldTeacherCount ||
        (!this.state.emptyDataAfterApiCall &&
          !this.state.userData &&
          (this.state.userData || this.state.username || this.state.yogiId)))
    ) {
      // console.log("MADe it to update here!");
      this.getUserData();
    }
  }

  static getDerivedStateFromProps(newProps, oldState) {
    // console.info("profile outisde gdsfp called newProps, oldState", newProps, oldState);
    const { username } =
      (newProps.computedMatch || newProps.match || {}).params || {};
    const { userid: yogiId } =
      (newProps.computedMatch || newProps.match || {}).params || {};
    const oldUsername = oldState && oldState.username;
    const oldYogiId = oldState && oldState.yogiId;

    if (
      !oldUsername ||
      oldUsername !== username ||
      !yogiId ||
      oldYogiId !== yogiId
    ) {
      return {
        ...oldState,
        username,
        yogiId,
      };
    }
    return null;
  }

  componentDidMount() {
    this._mounted = true;
    this.getUserData();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  getUserData = () => {
    const { username } =
      (this.props &&
        (this.props.computedMatch || this.props.match || {}).params) ||
      {};
    // console.log("usernameareoor yogiID fo ruserid?", username, yogiId, this.props);
    AlgoliaYogisIndex.search({ query: username })
      .then((hitData) => {
        // console.log("hitdata from algolia", hitData)
        const { hits = [] } = hitData;
        let foundYogi;
        for (let i = 0; i < hits.length; i++) {
          const yogi = hits[i];
          if (yogi.username === username) {
            foundYogi = yogi;
            foundYogi.uid = yogi.objectID;
            break;
          }
        }
        this._mounted &&
          foundYogi &&
          this.setState({ userData: foundYogi }, () => {
            this.getClassData();
            Promise.resolve(
              UtilFX.getDefaultTimeZoneValue(foundYogi.uid)
            ).then((result) => this.setState({ defaultTimeZoneValue: result }));
          });
      })
      .catch((err) => {
        console.warn('Error loading data', err);
      });
  };

  groupChatRequestStatus = async () => {
    const { context } = this.props;
    const { userAuthenticated: student } = context || {};
    const { userData: teacher } = this.state;
    let query = await firestore.collection(ConvoMetadata);
    query = query.where('teacher_id', '==', teacher.uid);
    query.get().then((res) => {
      let convoId = null;
      if (!res.empty) {
        convoId = res.docs[0].data().convo_id;
      }
      this.setState({ convoId: convoId }, async () => {
        if (this.state.convoId && student) {
          let query = await firestore.collection('ConvoMembers');
          query = query.where('convo_id', '==', this.state.convoId);
          query = query.where('uid', '==', student.uid);
          const res = await query.get();
          let status = '';
          let documentId = '';
          if (!res.empty) {
            status = res.docs[0].data().status;
            documentId = res.docs[0].id;
            this.setState({
              groupChatRequestStatus: status,
              memberDocumentId: documentId,
            });
          }
        }
      });
    });
  };

  getClassData = () => {
    const { userData } = this.state;
    // console.log("userData", userData);
    if (!(userData && userData.uid)) {
      this._mounted && this.setState({ emptyDataAfterApiCall: true });
      console.warn('no appropritae userData found. no user id', userData);
    }
    this._mounted && this.setState({ isLoading: true });
    AlgoliaClassesIndex.search({
      query: userData.uid || '',
      hitsPerPage: 40,
      // tagFilters: [`teacherID:${userData.uid}`, "public"], //must change structure of tags to not use the ':' delimiter. interferes. use custom delimter [update process in index and when filter on app and web]
      // filters: `(classInfo.start_timestamp > ${timeDam} OR classFrequency:regular) AND _tags:public AND NOT classInfo.status:cancel`
      filters: '_tags:public AND NOT classInfo.status:cancel',
    })
      .then((hitData) => {
        // console.log("hit data from algolia", hitData);
        const { hits = [] } = hitData;
        const hitsToClasses = hits.map((v) => ({
          ...v.classInfo,
          algoliaData: v,
        }));
        // console.log("hitsToClasses", hitsToClasses);

        const ordered_classes = this.OrderClasses(hitsToClasses);
        // console.log("ordered_classes", ordered_classes);
        this._mounted &&
          this.setState(
            {
              classes_categorized: ordered_classes,
              emptyDataAfterApiCall: false,
            },
            () => {
              // if we have a user lets get info of their teachers to suggest
              this._mounted && this.setState({ isLoading: false });
              this.getSuggestedYogis();
              this.determineYogiRelation();
            }
          );
      })
      .catch((err) => {
        console.warn('error getting data', err);
        this._mounted &&
          this.state.isLoading &&
          this.setState({ error: err || true, isLoading: false });
      });
  };

  OrderClasses = (data = []) => {
    const c_arr = [];
    data.forEach((c) => {
      c.class_id = c.algoliaData.objectID; // .split(`${weekly ? "_REGULAR_" : "_ONETIME_"}`)[1];
      c.calculated_class_start_time = moment(
        UtilFX.getExpectedDateForClassOrEvent(c)
      ).valueOf();
      if (
        c.status !== 'cancel' &&
        !c.private &&
        c.calculated_class_start_time >= Date.now()
      ) {
        c_arr.push(c);
      }
    });

    // fetchAndSetData = async ({ is_from_event = false } = {}) => {
    const classCategoryMap = {}; // Create the blank map
    let classes_AND_events = c_arr;
    //map necessary values for section titles, keys, and start dates (relative to today)
    classes_AND_events = classes_AND_events.map((ci) => {
      try {
        ci.hour = moment(ci.start_timestamp).format('h');
        ci.min = moment(ci.start_timestamp).format('mm');
        ci.ap = moment(ci.start_timestamp).format('a');
      } catch (e) {
        console.warn('problem processing time - ', e);
      }
      if (ci.location) {
        ci.loc = ci.location.name;
        ci.address = ci.location.address;
      }

      ci.key = ci.cid;
      // ci.calculated_class_start_time = expected_date.getTime();

      const edm = moment(ci.calculated_class_start_time);
      const month_day = edm.format('D');
      const month_name = edm.format('MMM');
      let section_header = `${edm.format('dddd')} - ${month_name} ${month_day}`;

      if (edm.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        section_header = '(Today) ' + section_header;
      } else if (
        edm.format('YYYY-MM-DD') ===
        moment().add(1, 'days').format('YYYY-MM-DD')
      ) {
        section_header = '(Tomorrow) ' + section_header;
      }

      //   "MOVE section_header",
      //   expected_date.getDay(),
      //   section_header,
      //   UtilFX.getNameForWeekdayByJsGetday(expected_date.getDay())
      // );
      ci.section_header = section_header;

      return ci;
    });

    classes_AND_events = classes_AND_events.sort(
      (a, b) => a.calculated_class_start_time - b.calculated_class_start_time
    );

    const section_header_array = [];
    //sort
    classes_AND_events.forEach((classItem) => {
      const section_header = classItem.section_header;
      if (!classCategoryMap[section_header]) {
        //Create an entry in the map for the category if it hasn't yet been created
        classCategoryMap[section_header] = [];
        section_header_array.push(section_header);
      }
      classCategoryMap[section_header].push(classItem);
    });

    // store.save("CACHE_CLASSES_SECTION_HEADER_ARRAY", section_header_array).then(async ()=>{
    //   let v = await store.get("CACHE_CLASSES_SECTION_HEADER_ARRAY");
    //   console.log("CACHE section header being stored", v);
    // });

    const classes_array_categorized = section_header_array.map((v) => {
      const datlist = classCategoryMap[v];
      // store.save("CACHE_CLASSES_ARRAY_BY_SECTION_HEADER_"+v, datlist);
      return {
        data: datlist,
        title: v,
      };
    });

    // var state_update_obj = {
    //   classes_array_categorized: classes_array_categorized,
    //   refreshing: false
    // };
    return classes_array_categorized;
  };

  //get Suggested Yogi Data
  getSuggestedYogis = () => {
    const { userData } = this.state;

    if (!(userData && userData.uid)) {
      console.warn('no userData.uid found');
      this._mounted && this.setState({ isLoading: false });
      return;
    }

    this._mounted && this.setState({ isLoading: true });
    axios
      .post(UtilFX.getApiBaseUrl() + 'getApiData', {
        action: 'getTeachersOfYogi',
        uids: userData && userData.uid,
      })
      .then(({ data }) => {
        let { teachers } = data || [];
        teachers = teachers.sort((a, b) => Math.random() > 0.5);
        this._mounted &&
          this.setState(
            {
              suggestedYogis: teachers,
            },
            () => {
              this._mounted && this.setState({ isLoading: false });
              // if we have a user lets get info of their teachers to suggest and set that state
            }
          );
      })
      .catch((err) => {
        // this._mounted && this.setState({ isLoading: false });
        console.warn('error fetching data', err);
        this._mounted &&
          this.state.isLoading &&
          this.setState({ error: err || true, isLoading: false });
      });
  };

  determineYogiRelation = () => {
    const { userData, favToggle } = this.state;
    const { context } = this.props;
    let isFav = false;
    const favToggleBoolean = !!favToggle;
    // console.log("the context and the userData loaded into state", context, userData)
    if (context && context.userAuthenticated && userData && userData.uid) {
      isFav = !!(context.userAuthenticated.teachers || {})[userData.uid];
    } else {
      // console.warn("no authed user, or no teacher, no need to waste cycles here, if not already false, set to false");
      if (favToggle) {
        //cant be a fav if not student or teacher avail
        this._mounted &&
          this.setState({
            favToggle: false,
          });
      }
      return;
    }
    // console.warn("favToggle vs isFav", favToggleBoolean, isFav);

    if (favToggleBoolean !== isFav) {
      this._mounted &&
        this.setState({
          favToggle: isFav,
        }, () => {
          this.groupChatRequestStatus();
        });
    }
  };

  addTeacherToHistoryStorage = () => {
    const { userData: teacher } = this.state;
    if (teacher && teacher.uid) {
      const teacherId = teacher.uid;
      let yogiList =
        (window && window.localStorage.getItem('pendingAddYogis')) || '';
      yogiList = yogiList.replace(teacherId, '');
      yogiList += `${teacherId},`;
      window && window.localStorage.setItem('pendingAddYogis', yogiList);
      UtilFX.getIsDevEnv() &&
        console.info('adding yogi as pending for post auth', yogiList);
    } else {
      UtilFX.getIsDevEnv() && console.warn('no teacher uid to save. odd.');
    }
  };

  setYogiRelationship = (addFav) => {
    //db auth atomically complete the following
    if (!addFav) {
      const cont =
        window &&
        window.confirm(
          'Are you sure you want to remove this person from your favorites?'
        );
      if (!cont) {
        return;
      }
    }
    const { userData: teacher } = this.state;
    const { history, context } = this.props;
    const { userAuthenticated: student } = context || {};
    // const student = userAuthenticated;
    // const teacher = userData;
    const new_fav_val = addFav ? true : null;

    if (!student && teacher.uid) {
      //not loggged in
      const cont =
        window && window.confirm(`Add @${teacher.username} by signing in.`);
      if (cont) {
        //set local storage  or session storage so we can auto-add this teacher after signup (use last onea as the profile to navigage back to after signup)
        this.addTeacherToHistoryStorage({ teacherId: teacher.uid });

        history.push('/login');
      } else {
        return;
      }
    }

    if (teacher && student) {
      if (teacher.uid === student.uid) {
        console.warn('No need to add yourself');
        return;
      }
      //users/uid/teachers/tid:true
      //users/uid/students/uid:true
      DataFX.updateTeacherStudentRelationship({
        teacherId: teacher.uid,
        studentId: student.uid,
        connectValue: new_fav_val,
        subToNotifications: !student.push_disable_teacher_activity_alert,
      })
        .then(() => {
          // refreshUserAuthenticated(student.uid);
          const teacherCount = Object.keys(student.teachers || {}).length;
          console.warn('TeacherCount for user:', teacherCount);
          this.setState({ favToggle: new_fav_val });
          if (teacherCount < 1) {
            //     //this.refs.ModalBlank_PromptFirstFavYogisCongrats.showMe()
            //     this.refs.ModalBlank_PromptFirstFavYogisCongrats_StudentPushPermissions.showMe();
            //only if they are going from 0 to 1. could have added and we didnt rfresh the user, so add and remove will false trigger
            if (new_fav_val) {
              alert(
                'Congrats on adding your first teacher! Check your inbox for details on getting notifications.'
              );
            }
          }
        })
        .catch((err) => {
          console.warn('ERROR saving data', err);
          // loaderHandler.hideLoader();
        });
    }
  };

  handleOpenPayModal = (cb) => {
    this._mounted && this.setState({ openPayModal: true });
  };

  handleClosePayModal = (cb) => {
    this._mounted && this.setState({ openPayModal: false });
  };

  handleDonationSuccess = ({ result_msg }) => {
    this.handleClosePayModal();
    // odd behavior when alert and modal race conditioned closed both. unsavory. purposely pause to await
    setTimeout(() => {
      window && window.alert(`✅ Success: ${result_msg}`);
    }, 1000);
  };

  handleDonationError = ({ result_msg }) => {
    // this.handleClosePayModal();
    window && window.alert(`🚫 Error: ${result_msg}`);
  };

  render() {
    const { context: { userAuthenticated } = {} } = this.props || {};
    const {
      suggestedYogis,
      classes_categorized,
      favToggle,
      isLoading,
      defaultTimeZoneValue,
    } = this.state;
    const userData = this.state && this.state.userData;
    const class_category_keys = Object.keys(classes_categorized || []);
    const lastClassCategory =
      classes_categorized &&
      classes_categorized.length &&
      classes_categorized.slice(-1)[0].data;
    const lastClassLocation =
      lastClassCategory &&
      lastClassCategory.length &&
      lastClassCategory.slice(-1)[0].location;
    let lastClassAddress;
    let lastClassAddressSplit;
    let lastClassCityOrStreet;
    if (lastClassLocation) {
      lastClassAddress = lastClassLocation && lastClassLocation.address;
      lastClassAddressSplit = lastClassAddress && lastClassAddress.split(',');
      lastClassCityOrStreet =
        lastClassAddressSplit &&
        lastClassAddressSplit.length > 1 &&
        lastClassAddressSplit.slice(1).join(',');
    }
    if (!lastClassCityOrStreet) {
      lastClassCityOrStreet = 'around the world';
    }

    const pageTitle =
      userData &&
      `${userData.fullname} - Find Yoga Teachers from ${
        (lastClassCityOrStreet && lastClassCityOrStreet) || 'anywhere'
      } | Favyogis`;
    const pageDescription =
      userData &&
      `${userData.fullname}, @${userData.username} is a yoga teacher from ${
        (lastClassCityOrStreet && lastClassCityOrStreet) || 'anywhere'
      }. Check out my profile below and book a spot in any of my classes.`;
    // console.log({
    //     lastClassCategory,
    //     lastClassLocation,
    //     lastClassAddress,
    //     lastClassAddressSplit
    // })
    // console.log("Userdata nd isLoading", userData, isLoading, this.state);
  
    return (
      <Grid container justify="center">
        <Grid item xs={12} style={{ maxWidth: '90%' }}>
          {!userData || userData.date_deleted ? (
            isLoading ? (
              <Loading />
            ) : (
              <div data-hook="no-teacher-found-box">
                <p style={{ opacity: 0.25 }} data-hook="no-user-found">
                  No user found.
                </p>
                <hr />
                <Explore {...this.props} />
              </div>
            )
          ) : (
            <div
              style={{
                position: 'relative',
                backgroundColor: '#ffffff',
                margin: '50px auto',
                width: '100%',
                borderRadius: '20px',
              }}
            >
              <div
                style={{
                  top: 40,
                  position: 'relative',
                  // width: 300,
                  // textAlign: "center",
                  marginRight: '10px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                {userData && userData.website && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '-45px',
                      right: '0px',
                      display: 'inline-block',
                    }}
                    onClick={() => {
                      let url_ = userData.website;
                      if (url_) {
                        if (url_.indexOf('http') !== 0) {
                          url_ = 'http://' + url_;
                        }
                        window.open(url_);
                      }
                    }}
                  >
                    <IconButton color="primary" aria-label="Visit yogi website">
                      <Icon>link</Icon>
                    </IconButton>
                    <Typography
                      style={{ display: 'block', marginTop: '-15px' }}
                      color="primary"
                      variant="caption"
                    >
                      website
                    </Typography>
                  </div>
                )}
                <div
                  style={{
                    position: 'absolute',
                    top: '-45px',
                    left: '5px',
                    cursor: 'pointer',
                    display: 'inline-block',
                  }}
                  onClick={() => {
                    // console.log("open modal for donation");
                    if (userAuthenticated && userAuthenticated.uid) {
                      this.handleOpenPayModal();
                    } else {
                      this.addTeacherToHistoryStorage({
                        teacherId: userData.uid,
                      });
                      window &&
                        window.alert(
                          'You must be signed in to send money to teachers.'
                        );
                    }
                  }}
                >
                  <IconButton
                    color="primary"
                    aria-label="send money"
                    // style={{ fontSize: "1rem" }}
                  >
                    <Icon>monetization_on</Icon>
                  </IconButton>
                  <Typography
                    style={{ display: 'block', marginTop: '-15px' }}
                    color="primary"
                    variant="caption"
                  >
                    donate/tip
                  </Typography>
                </div>
              </div>
              <div>
               
               <AvatarDisplay
               imgSrc={userData.profile_photo_url}
               imgAlt={''}
               imgClassName={'yogi-photo'}
               imgStyles={{
                borderRadius: '50%',
                maxHeight: 180,
                marginTop: '20px',
                border: '4px white solid',
              }}
              onError={(ev) => {
                ev.target.src = 'https://placehold.it?text=NA';
              }}
               userName={userData.fullname}
               textStyles={{
                ...GlobalStyles.bodyMedium,
                color: 'rgb(23, 155, 247)',
                textAlign: 'center',
                borderRadius: '50px',
                border: '2px solid',
                display: 'flex',
                alignItems: 'center',
                height: '30px',
                width: '30px',
                justifyContent: 'center',
                margin: 'auto',
                marginTop: '8px'
              }}
               />
              </div>
              {favToggle && (
                <div
                  id="remove-fav-yogi-btn"
                  style={{
                    position: 'absolute',
                    top: 30,
                    right: 90,
                    boxShadow: '0 2px 1px 1px #00000077',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    padding: 5,
                    height: 24,
                    width: 24,
                    color: '#fff',
                    border: '2px #ffffffff solid',
                    backgroundColor: Colors.pri_color,
                  }}
                  className="add-to-favs"
                  onClick={() => {
                    // const x = UtilFX.getNextDayOfWeek(2, null);
                    // console.log("wanna add this teacher x", userData, x)
                    this.setYogiRelationship(false);
                  }}
                >
                  <MdStar style={{ fontSize: 20 }} />
                </div>
              )}
              <div
                style={{
                  // borderRadius: 20,
                  // padding: "40px 0",
                  // backgroundColor: "#fff",
                  // color: "#000"
                  // marginBottom: "40px",
                  paddingBottom: '20px',
                }}
              >
                <div>
                  <div
                    style={{
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      style={{
                        ...GlobalStyles.bodyRegular,
                        fontSize: '28px',
                        fontWeight: 300,
                        color: Colors.grey1,
                      }}
                    >
                      {userData.fullname}
                    </Typography>
                    <Typography
                      style={{
                        ...GlobalStyles.bodyRegular,
                        fontSize: '18px',
                        color: Colors.grey1,
                        fontWeight: 'normal',
                      }}
                    >
                      @{userData.username}
                    </Typography>
                    <Helmet>
                      <title>{pageTitle}</title>
                      <meta name="og:title" content={pageTitle} />
                      <meta name="twitter:title" content={pageTitle} />
                      <meta name="description" content={pageDescription} />
                      <meta name="og:description" content={pageDescription} />
                      <meta
                        name="twitter:description"
                        content={pageDescription}
                      />
                    </Helmet>
                    <div
                      style={{
                        // backgroundColor: Colors.pri_color,
                        // borderRadius: 18, alignSelf: "center",
                        // display: "inline-flex",
                        // justifyContent: "center"
                        marginTop: '20px',
                      }}
                    >
                      <Button
                        size="large"
                        variant="contained"
                        onClick={() => {
                          const { userData: teacher } = this.state;
                          const { history } = this.props;
                          // const { userAuthenticated: student } = context || {};

                          history.push('/chat', {
                            recipientId: teacher.uid,
                            recipientPhotoUrl: teacher.profile_photo_url,
                          });
                        }}
                        style={{
                          borderRadius: '8px',
                          width: '60%',
                          border: 'none',
                          boxShadow: 'none',
                          backgroundColor: Colors.pri_color,
                          color: '#ffffff',
                        }}
                      >
                        <Icon>mail</Icon>
                        &nbsp;Send a message
                      </Button>
                    </div>
                    <Typography
                      style={{
                        color: Colors.grey1,
                        margin: '20px',
                        textAlign: 'center',
                      }}
                    >
                      {userData.bio}
                    </Typography>
                  </div>
                  {Boolean(!favToggle) && Boolean(!isLoading) && (
                    <div style={{ marginTop: '16px', marginBottom: '32px' }}>
                      <Button
                        // id="add-fav-yogi-btn"
                        // className={`add-to-favs ${styleClasses.favbutton}`}
                        style={{
                          borderRadius: '8px',
                          border: 'none',
                          boxShadow: 'none',
                          backgroundColor: Colors.pri_color,
                          width: '60%',
                          color: '#ffffff',
                        }}
                        size="large"
                        variant="contained"
                        onClick={() => {
                          this.setYogiRelationship(true);
                        }}
                      >
                        <MdStarBorder style={{ fontSize: 30 }} />
                        Favorite
                      </Button>
                    </div>
                  )}
                </div>
                {this.state.groupChatRequestStatus !== 'accepted' &&
                  userData.classes_count &&
                  this.state.convoId && (userAuthenticated && userAuthenticated.uid) && (
                    <React.Fragment>
                      <hr style={{ border: '1px solid #F2F2F2' }} />
                      <div style={{ marginBottom: '32px' }}>
                        <Typography
                          style={{
                            ...GlobalStyles.titleBold,
                            color: Colors.grey1,
                            marginTop: '24px',
                            textAlign: 'center',
                          }}
                        >{`Join ${userData.username} group`}</Typography>
                        <Typography
                          style={{
                            ...GlobalStyles.bodyRegular,
                            color: Colors.grey1,
                            marginTop: '16px',
                            textAlign: 'center',
                          }}
                        >
                          {this.state.groupChatRequestStatus === 'pending'
                            ? 'Your request is currently in review. You will be notified of its status.'
                            : 'Connect with your teacher and their other students.'}
                        </Typography>
                        {this.state.groupChatRequestStatus === 'pending' && (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              marginTop: '16px',
                            }}
                          >
                            <Button
                              style={{
                                borderRadius: '12px',
                                border: 'none',
                                boxShadow: 'none',
                                backgroundColor: '#FFEEEE',
                                // width: '40%',
                                color: '#FF4646',
                                marginRight: '8px',
                              }}
                              size="small"
                              variant="contained"
                              onClick={async () => {
                                if (
                                  this.state.convoId &&
                                  this.state.memberDocumentId
                                ) {
                                  await firestore
                                    .collection('ConvoMembers')
                                    .doc(this.state.memberDocumentId).delete().then(() => {
                                      const updateData = {};
                                      updateData[`convobyusers/${userAuthenticated.uid}/${this.state.convoId}`] = null;
                                      firebase.database()
                                      .ref()
                                      .update(updateData);
                                    });
                                  return this.setState({
                                    groupChatRequestStatus: '',
                                  });
                                }
                              }}
                            >
                              <Icon
                                style={{
                                  fontSize: '1.0rem',
                                  marginRight: '5px',
                                }}
                              >
                                cancel
                              </Icon>
                              Cancel request
                            </Button>
                            <Button
                              style={{
                                borderRadius: '12px',
                                border: 'none',
                                boxShadow: 'none',
                                backgroundColor: '#ECF5FE',
                                // width: '40%',
                                color: '#1FA3FF',
                              }}
                              size="small"
                              variant="contained"
                              onClick={() => {
                                const { userData: teacher } = this.state;
                                this.props.history.push('chat', {
                                  teacherId: teacher.uid,
                                });
                              }}
                            >
                              <Icon
                                style={{
                                  fontSize: '1.0rem',
                                  marginRight: '5px',
                                }}
                              >
                                person
                              </Icon>
                              Visit group
                            </Button>
                          </div>
                        )}
                        {this.state.groupChatRequestStatus !== 'pending' && (
                          <Button
                            style={{
                              borderRadius: '12px',
                              border: 'none',
                              boxShadow: 'none',
                              backgroundColor: Colors.pri_color,
                              width: '50%',
                              color: '#ffffff',
                              marginTop: '16px',
                            }}
                            // size="large"
                            variant="contained"
                            onClick={async () => {
                              const { userData: teacher } = this.state;
                              const { context } = this.props;
                              const { userAuthenticated: student } =
                                context || {};
                              if (teacher.uid === student.uid) {
                                return alert('You can\'t join a group you created.');
                              }

                              if (
                                this.state.convoId &&
                                this.state.memberDocumentId
                              ) {
                                const docRef = await firestore
                                  .collection('ConvoMembers')
                                  .doc(this.state.memberDocumentId);
                                docRef.update({ status: 'pending' });
                                return this.setState({
                                  groupChatRequestStatus: 'pending',
                                });
                              } else if (
                                this.state.convoId &&
                                !this.state.memberDocumentId
                              ) {
                                const data_cbu = {};
                                data_cbu[
                                  `convobyusers/${student.uid}/${this.state.convoId}`
                                ] = true;
                                await firebase
                                  .database()
                                  .ref()
                                  .update(data_cbu);
                                const member_data_cm = {
                                  uid: student.uid,
                                  date_added: Date.now(),
                                  convo_id: this.state.convoId,
                                  status: 'pending',
                                };
                                const docRef = await firestore
                                  .collection('ConvoMembers')
                                  .add(member_data_cm);
                                this.setState({
                                  groupChatRequestStatus: 'pending',
                                  memberDocumentId: docRef.id,
                                });
                              }
                            }}
                          >
                            <Icon>person</Icon>
                            Join group
                          </Button>
                        )}
                      </div>
                    </React.Fragment>
                  )}
                <hr style={{ border: '1px solid #F2F2F2' }} />
                {Boolean(isLoading && !(class_category_keys || []).length) && (
                  <Loading />
                )}
                {class_category_keys && class_category_keys.length
                  ? class_category_keys.map((cck) => {
                      const c_arr = classes_categorized[cck];
                      return (
                        <div
                          key={cck}
                          style={{
                            margin: '40px auto',
                          }}
                        >
                          <Typography
                            style={{
                              ...GlobalStyles.titleBold,
                              color: Colors.grey1,
                            }}
                          >
                            {c_arr.title}
                          </Typography>
                          {c_arr.data.map((c) => {
                            const start_time_desc = moment(
                              c.calculated_class_start_time
                            ).format('h:mm A');
                            let daysByNickname = [];
                            let recurrence_desc = '';
                            if (c.recurrence_rule) {
                              const {
                                byday,
                                interval,
                              } = UtilFX.getRecurrenceRuleParts(
                                c.recurrence_rule
                              );
                              daysByNickname = byday
                                .split(',')
                                .map((n) => n[0] + n[1].toLowerCase());
                              recurrence_desc = `${
                                Number(interval) === 1
                                  ? '| every week'
                                  : '| every other week'
                              }`;
                            }
                            return (
                              <div
                                key={c.class_id}
                                style={{
                                  border: '1px solid #F2F2F2',
                                  borderRadius: 12,
                                  marginLeft: '20px',
                                  marginRight: '20px',
                                  marginBottom: '15px',
                                  textAlign: 'left',
                                }}
                              >
                                <Link
                                  to={{
                                    pathname: `/${userData.username}/${
                                      c.end ? 'event' : 'class'
                                    }/${c.class_id}`,
                                    state: {
                                      from: this.props.location.pathname,
                                    },
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'inline-block',
                                      width: '100%',
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        textAlign: 'center',
                                        color: Colors.pri_color,
                                        margin: '21px',
                                        ...GlobalStyles.titleBold,
                                      }}
                                    >
                                      {c.title}
                                    </Typography>
                                    <hr
                                      style={{ border: '1px solid #F2F2F2' }}
                                    />
                                    <SingleCard
                                      iconName="watch_later"
                                      title={`${start_time_desc} (${
                                        c.duration
                                      } min) ${UtilFX.checkTimeZoneValue({
                                        timeZone: c.time_zone,
                                        defaultTimeZoneValue,
                                      })}`}
                                    />
                                    {daysByNickname &&
                                      daysByNickname.length > 0 &&
                                      recurrence_desc && (
                                        <div
                                          style={{
                                            display: 'flex',
                                            flex: 1,
                                            flexDirection: 'row',
                                            marginLeft: '23px',
                                            marginTop: '8px',
                                            marginBottom: '8px',
                                          }}
                                        >
                                          <span
                                            className="material-icons"
                                            style={{
                                              color: Colors.grey4,
                                              marginRight: '17px',
                                              alignSelf: 'center',
                                              fontSize: 20,
                                            }}
                                          >
                                            work
                                          </span>
                                          <div
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                            }}
                                          >
                                            {daysByNickname.map((i) => (
                                              <Typography
                                                style={{
                                                  ...GlobalStyles.bodyRegular,
                                                  display: 'inline-block',
                                                  marginRight: '5px',
                                                  color: Colors.grey1,
                                                  textAlign: 'left',
                                                }}
                                                key={i}
                                              >
                                                {i}
                                              </Typography>
                                            ))}
                                            <Typography
                                              style={{
                                                ...GlobalStyles.bodyRegular,
                                                display: 'inline-block',
                                                marginRight: '5px',
                                                color: Colors.grey1,
                                                textAlign: 'left',
                                              }}
                                            >
                                              {recurrence_desc}
                                            </Typography>
                                          </div>
                                        </div>
                                      )}
                                    <SingleCard
                                      iconName="location_on"
                                      title={
                                        (c.location && c.location.name) ||
                                        'No physical class'
                                      }
                                    />
                                    {c.virtual_link && (
                                      <div>
                                        <SingleCard
                                          iconName="videocam"
                                          title="Online class available."
                                        />
                                      </div>
                                    )}
                                    {c.price && (
                                      <div>
                                        <SingleCard
                                          iconName="attach_money"
                                          title={`$ ${UtilFX.getBuyerTotal({
                                            price: c.price,
                                            fees_absorb: c.fees_absorb,
                                            teacher: this.state.userData,
                                          })}`}
                                        />
                                      </div>
                                    )}
                                    {c.status && (
                                      <div style={{ marginTop: '8px' }}>
                                        <BottomCard
                                          iconName={
                                            c.status === 'sub'
                                              ? 'error_outline'
                                              : 'block'
                                          }
                                          backgroundColor={
                                            c.status === 'sub'
                                              ? `${Colors.grey1}`
                                              : `${Colors.red}`
                                          }
                                          title={
                                            c.status === 'sub'
                                              ? 'This class is subbed out!'
                                              : 'This class is cancelled!'
                                          }
                                          iconColor={
                                            c.status === 'sub'
                                              ? '#FFDA6A'
                                              : '#fff'
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                </Link>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })
                  : null}
                {Boolean(class_category_keys && class_category_keys.length) && (
                  <div style={{ margin: '20px' }}>
                    <Typography
                      variant="subtitle2"
                      color="primary"
                      style={{ margin: '10px 0' }}
                    >
                      Places
                    </Typography>

                    <InstantSearch
                      appId={Algolia.ApplicationId}
                      apiKey={Algolia.SearchApiKey}
                      indexName="places"
                    >
                      <Configure filters={`teachers.${userData.uid}=1`} />
                      <HitsPlaces {...this.props} />
                    </InstantSearch>
                  </div>
                )}
                {suggestedYogis && suggestedYogis.length ? (
                  <div>
                    <Typography
                      style={{
                        ...GlobalStyles.titleBold,
                        color: Colors.grey1,
                        margin: '10px 0',
                      }}
                    >
                      Suggested Yogis
                    </Typography>
                    <Grid
                      container
                      style={{
                        // width: 287,
                        // borderColor: Colors.pri_color,
                        borderRadius: '12px',
                        border: '1px solid #F2F2F2',
                        // borderStyle: "solid",
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '10px',
                        marginBottom: '20px',
                        padding: '25px',
                      }}
                    >
                      {suggestedYogis.map((yogi) => (
                        // console.log("YOGI to DISLAY", yogi)
                        <Grid
                          style={{ marginBottom: 25 }}
                          item
                          xs={6}
                          key={yogi.uid}
                        >
                          <Link
                            to={{
                              pathname: `/${yogi.username}`,
                              state: {
                                from: this.props.location.pathname,
                              },
                            }}
                          >
                            <div style={{ display: 'inline-block' }}>
                              
                              <AvatarDisplay
                                imgSrc={yogi.profile_photo_url}
                                imgAlt={''}
                                imgClassName={'yogi-photo'}
                                imgStyles={{
                                  borderRadius: '50%',
                                  height: 70,
                                  width: 70,
                                }}
                                userName={yogi.fullname}
                                textStyles={{
                                  ...GlobalStyles.bodyMedium,
                                  color: 'rgb(23, 155, 247)',
                                  textAlign: 'center',
                                  borderRadius: '50px',
                                  border: '2px solid',
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: '30px',
                                  width: '30px',
                                  justifyContent: 'center'
                                }}
                              />

                              <Typography variant="body1" color="textSecondary">
                                {yogi.fullname}
                              </Typography>
                              <Typography variant="caption" color="primary">
                                @{yogi.username}
                              </Typography>
                            </div>
                          </Link>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                ) : null}
              </div>
              <Modal
                aria-labelledby="confirm payment"
                aria-describedby="Confirm payment to complete RSVP."
                className="payment-confirmation-modal center-modal"
                open={this.state.openPayModal}
                onClose={() => {
                  this.handleClosePayModal();
                }}
              >
                <div
                  style={{ position: 'absolute', ...UtilFX.getModalStyle() }}
                >
                  <PayConfirmation
                    {...this.props}
                    isDonation
                    // finishRegistrationPostPayment={this.finishRegistrationPostPayment}
                    // classInfo={}
                    handleClosePayModal={this.handleClosePayModal}
                    handleDonationSuccess={this.handleDonationSuccess}
                    handleDonationError={this.handleDonationError}
                    // isRegistered={isRegistered}
                    noteToRecipient=""
                    description="--donate / tip--"
                    recipientId={userData && userData.uid}
                    amount={0}
                  />
                </div>
              </Modal>
            </div>
          )}
        </Grid>
      </Grid>
    );
  }
}
// const ProfileBase = withTheme()(withStyles(stylesProfile)(ProfileBaseX))
