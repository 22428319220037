import React, { Component } from 'react';

// import {
//     Link,
//     //     Redirect,
// } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

// import { withStyles, withTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import {
    InstantSearch,
    // SearchBox,
    // Hits,
    // Highlight,
    // Snippet,
    // Stats,
    // SortBy,
    Configure,
    Index,
} from 'react-instantsearch/dom';
// import { connectHits, connectSearchBox, connectHighlight } from 'react-instantsearch/connectors';
import {
    // auth,
    // database
} from '../fire';
import {
    UtilFX,
    // Loading,
    // DataFX,
    Colors,
    // GlobalContext,
    // GlobalStyles,
    // AlertBox,
} from '../utils';

import * as Algolia from '../utils/algolia';
import { HitsYogis, HitsPlaces } from './Explore';
const AlgoliaIndex = Algolia.getIndex({ indexName: 'places' });

export default class PlaceDetail extends Component {
    state = {};

    componentDidMount() {
        this.getPlaceData();
    }

    componentDidUpdate(prevProps) {
        // console.log("componenet did update. here are props", this.props);
        // console.log("componenet did update. here are PREV vals props", prevProps, prevState, snapshot);
        // const { placeData: placeDataOld } = prevState || {};
        // const { placeData } = (this.props.location && this.props.location.state) || this.props || {};
        const { placeid: placeidOld } = (prevProps.match && prevProps.match.params) || {};
        const { placeid } = (this.props.match && this.props.match.params) || {};
        // console.log("old and new placeData", placeidOld, placeid);
        if ((placeid !== placeidOld)
            && Boolean(placeid)) { //it should have changed and must exist
            this.getPlaceData();
        }
    }

    getPlaceData = () => {
        const { placeData } = (this.props.location && this.props.location.state) || this.props || {};
        const { placeid } = (this.props.match && this.props.match.params) || {};
        // console.log("placeData and placeid", placeData, placeid);

        if (!(placeData && placeData.lat && placeData.lng) && placeid) {
            // console.warn("placedata nmot here, gotta look up by id", this.props);
            AlgoliaIndex.getObjects([placeid])
                .then(hitData => {
                    // console.log("hitdata from algolia", hitData)
                    const { results = [] } = hitData;
                    const placeData = results[0];
                    if (placeData) {
                        this.setPlaceData({ placeData });
                    }
                }).catch(err => {
                    console.warn('Error loading place', err);
                });
        } else if (placeData && placeData.lat && placeData.lng) {
            this.setPlaceData({ placeData });
        }
    }

    setPlaceData = ({ placeData }) => {
        const locMapUrl = UtilFX.getGoogleMapsImage({
            lat: placeData.lat,
            lng: placeData.lng,
            width: 300,
            height: 300,
            zoom: 14,
        });
        this.setState({ placeData, locMapUrl });
    }

    render() {
        // console.log("props --->", this.props);
        const { placeData, locMapUrl } = this.state || {};

        return (
            <Grid container justify="center">
                <Grid item xs={12} style={{ maxWidth: '90%' }}>
                    <div>
                        {
                            !placeData
                                ?
                                <p>No place data</p>
                                :
                                (
                                    <div>
                                        <h2 className="page-title">Place</h2>
                                        <div
                                            className="place-wrapper"
                                            style={{
                                                backgroundColor: '#fff',
                                                borderRadius: 18,
                                                padding: 15,
                                                color: Colors.pri_color,
                                                textAlign: 'center',
                                            }}
                                        >
                                            <Grid container>

                                                <Grid style={{ marginBottom: 25, }} item xs={12}>
                                                    <div style={{ marginTop: 10, }}>
                                                        <Typography color="textSecondary" variant="subtitle2">{placeData.name}</Typography>
                                                        <Typography color="textSecondary">{placeData.address}</Typography>
                                                    </div>
                                                    <img style={{
                                                        borderWidth: '1px',
                                                        borderColor: Colors.pri_color,
                                                        borderStyle: 'solid',
                                                        borderRadius: '10px',
                                                        width: '100%'
                                                    }}
                                                        src={locMapUrl}
                                                        alt="class location"
                                                    />

                                                </Grid>

                                                {
                                                    Boolean(placeData && placeData.teachers)
                                                    &&
                                                    (
                                                        <div style={{ margin: '0 20px', width: '100%' }}>
                                                            <Typography variant="subtitle2" color="primary" style={{ margin: '10px 0' }}>Teaching Yogis</Typography>
                                                            <InstantSearch
                                                                appId={Algolia.ApplicationId}
                                                                apiKey={Algolia.SearchApiKey}
                                                                indexName="yogis"
                                                            >
                                                                <Index indexName="yogis">
                                                                    <Configure
                                                                        filters={Object.keys((placeData || {}).teachers || {}).map(id => `objectID:${id}`).join(' OR ')}
                                                                    />
                                                                    <HitsYogis {...this.props} />
                                                                </Index>
                                                            </InstantSearch>
                                                        </div>
                                                    )
                                                }

                                                {
                                                    Boolean(true)
                                                    &&
                                                    (
                                                        <div style={{ margin: '0 20px', width: '100%' }}>
                                                            <Typography variant="subtitle2" color="primary" style={{ margin: '10px 0' }}>Places Nearby</Typography>
                                                            <InstantSearch
                                                                appId={Algolia.ApplicationId}
                                                                apiKey={Algolia.SearchApiKey}
                                                                indexName="places"
                                                            >
                                                                <Index indexName="places">
                                                                    <Configure
                                                                        filters={`NOT objectID:${placeData.objectID}`}
                                                                        aroundLatLng={`${placeData.lat}, ${placeData.lng}`}
                                                                        getRankingInfo
                                                                    />
                                                                    <HitsPlaces {...this.props} />
                                                                </Index>
                                                            </InstantSearch>
                                                        </div>
                                                    )
                                                }

                                            </Grid>
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                </Grid>
            </Grid>
        );
    }
}
