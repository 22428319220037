import React, { Component } from 'react';

import {
    Link,
    // Redirect,
} from 'react-router-dom';

// import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';

import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import {
    UtilFX,
    Loading,
    // DataFX,
    Colors,
    // GlobalContext,
    GlobalStyles,
    // AlertBox,
} from '../utils';
import GoogleCalendarConnect from './GoogleCalendarConnect';
import { firebase } from '../fire';
// // import { LoginSignupForgotAuthUI } from "../App";
// import { Colors } from "../utils/index";
import * as Algolia from '../utils/algolia';
import AvatarDisplay from './AvatarDisplay';
// const AlgoliaYogisIndex = Algolia.getIndex({ indexName: "yogis" });
const AlgoliaClassesIndex = Algolia.getIndex({ indexName: 'classes' });

export default class Schedule extends Component {
    state = {
        isLoading: true,
        openModal_GoogleCalendarConnect: false,
        showCalendarSyncPrompt: false,
    };

    componentDidMount() {
        this._mounted = true;
        this.gcalContentRef = null;
        this.getClassData();
    }

    componentWillUnmount() {
        this._mounted = false;
        if (this.gcalContentRef) {
            this.gcalContentRef.off();
        }
    }

    componentDidUpdate(oldProps, oldState) {
        // this.determineYogiRelation();
        const { context = {} } = this.props || {};
        const newUserAuthenticated = (context && context.userAuthenticated) || {};
        const { context: oldContext = {} } = oldProps || {};
        const oldUserAuthenticated = (oldContext && oldContext.userAuthenticated) || {};

        const newUID = Object.keys(newUserAuthenticated || {}).uid;
        const oldUID = Object.keys(oldUserAuthenticated || {}).uid;
        if (!this.state.isLoading &&
            !this.state.error &&
            (oldUID !== newUID)
        ) {
            this.getClassData();
        }
    }

    OrderClasses = (data = []) => {
        const c_arr = [];
        data.forEach((c) => {
            c.class_id = c.algoliaData.objectID;
            c.teacher_id = c.algoliaData.teacherID;
            c.calculated_class_start_time = moment(UtilFX.getExpectedDateForClassOrEvent(c)).valueOf();
            // if (c.status !== "cancel" && c.calculated_class_start_time >= Date.now()) { (this is a valid registration period, why put any restrictions on it coming up?) Already filtered in registration query
            c_arr.push(c);
            // }
        });

        // fetchAndSetData = async ({ is_from_event = false } = {}) => {
        const classCategoryMap = {}; // Create the blank map
        let classes_AND_events = c_arr;
        //map necessary values for section titles, keys, and start dates (relative to today)
        classes_AND_events = classes_AND_events.map(ci => {
            try {
                ci.hour = moment(ci.start_timestamp).format('h');
                ci.min = moment(ci.start_timestamp).format('mm');
                ci.ap = moment(ci.start_timestamp).format('a');
            } catch (e) {
                console.warn('problem processing time - ', e);
            }
            if(ci.location){
                ci.loc = ci.location.name;
                ci.address = ci.location.address;
            }

            ci.key = ci.cid;
            // ci.calculated_class_start_time = expected_date.getTime();

            const edm = moment(ci.calculated_class_start_time);
            const month_day = edm.format('D');
            const month_name = edm.format('MMM');
            let section_header = `${edm.format('dddd')} - ${month_name} ${month_day}`;

            if (edm.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
                section_header = '(Today) ' + section_header;
            } else if (edm.format('YYYY-MM-DD') === moment().add(1, 'days').format('YYYY-MM-DD')) {
                section_header = '(Tomorrow) ' + section_header;
            }

            //   "MOVE section_header",
            //   expected_date.getDay(),
            //   section_header,
            //   UtilFX.getNameForWeekdayByJsGetday(expected_date.getDay())
            // );
            ci.section_header = section_header;

            return ci;
        });

        classes_AND_events = classes_AND_events.sort((a, b) => a.calculated_class_start_time - b.calculated_class_start_time);

        const section_header_array = [];
        //sort
        classes_AND_events.forEach(classItem => {
            const section_header = classItem.section_header;
            if (!classCategoryMap[section_header]) {
                //Create an entry in the map for the category if it hasn't yet been created
                classCategoryMap[section_header] = [];
                section_header_array.push(section_header);
            }
            classCategoryMap[section_header].push(classItem);
        });

        const classes_array_categorized = section_header_array.map(v => {
            const datlist = classCategoryMap[v];
            // store.save("CACHE_CLASSES_ARRAY_BY_SECTION_HEADER_"+v, datlist);
            return {
                data: datlist,
                title: v
            };
        });

        // var state_update_obj = {
        //   classes_array_categorized: classes_array_categorized,
        //   refreshing: false
        // };
        return classes_array_categorized;
    }

    getClassData = async () => {
        const { context } = this.props || {};
        const { userAuthenticated: _u } = context || {};

        if (!(_u && _u.uid)) {
            return;
        }

        let registrations = await UtilFX.getRegistrationsBelongingToStudent({ studentId: _u.uid });
        // console.log({ registrations });

        this.gcalContentRef = firebase.database()
            .ref('showcontentbyuserid/' + _u.uid + '/gcal_v6');
        this.gcalContentRef.on('value', snap => {
            // console.log("got snapval", snap.val());
            if (registrations && registrations.length && !snap.val()) {
                this.setState({ showCalendarSyncPrompt: true });
            } else {
                this.setState({ showCalendarSyncPrompt: false });
            }
        });

        registrations = registrations.filter(item => {
            const { class_info = {} } = item || {};
            const isRecurring = Boolean(class_info.recurrence_rule); //item.key.indexOf("_dateofclass_") >= 0;
            const isPersonal = class_info.class_type === 'personal';
            return !item.date_deleted
                &&
                (
                    (item.dateofclass >= Date.now())
                    ||
                    ( isPersonal && isRecurring )
                ); //for personal classes keep if recurring even if dateofclass passed
        });

        const teacherIdObject = {};
        registrations.map(item => item.teacherid).forEach(id => {
            teacherIdObject[id] = true;
        });
        const teacherIds = Object.keys(teacherIdObject);

        // console.log({ teacherIds, registrations, classIdArr: registrations.map(item => item._classid) });
        Promise.all(teacherIds.map(tid => firebase.database().ref(`users/${tid}`)
            .once('value', snap => {
                const teacherObj = snap.val();
                // console.log({ teacherObj });
                teacherIdObject[tid] = teacherObj;
            }).catch(err => console.warn('error getting teacher usernames', { err })))).then(() => {
                //console.log({ usernames, teacherIdObject });
                // this.setState({ teacherUsernameById: teacherIdObject }, () => {
                this._mounted && this.setState({ isLoading: true });
                AlgoliaClassesIndex
                    .getObjects(registrations.map(item => item._classid))
                    .then(hitData => {
                        // console.log("hit data from algolia", hitData);
                        const { results: hits = [] } = hitData;
                        const hitsToClasses = hits.filter(item => Boolean(item && item.classInfo)).map((v) => ({
                            ...v.classInfo,
                            algoliaData: v,
                        }));
                        // console.log("hitsToClasses", hitsToClasses);

                        const ordered_classes = this.OrderClasses(hitsToClasses);
                        // console.log("ordered_classes", ordered_classes);
                        this._mounted && this.setState({
                            classes: hitsToClasses,
                            classes_categorized: ordered_classes,
                            teachersById: teacherIdObject,
                            // emptyDataAfterApiCall: false,
                        }, () => {
                            // if we have a user lets get info of their teachers to suggest
                            this._mounted && this.setState({ isLoading: false });
                        });
                    })
                    .catch(err => {
                        console.warn('error getting data', err);
                        this._mounted && this.state.isLoading && this.setState({ error: err || true, isLoading: false });
                    });
                // });
            });
        // if (!(_u && _u.uid)) {
        //     this._mounted && this.setState({ emptyDataAfterApiCall: true, });
        //     console.warn("no appropritae _u found. no user id", _u);
        // }
    }

    render() {
        const { isLoading, classes, classes_categorized, teachersById } = this.state;
        const class_category_keys = Object.keys(classes_categorized || {});

        if (isLoading) {
            return <Loading>Fetching registrations.</Loading>;
        }
        return (
            <div>
                <Typography variant="h1" style={{ fontSize: '1.5rem' }} className="page-title">Schedule</Typography>
                {
                    Boolean(this.state.showCalendarSyncPrompt)
                    && (
                        <div>
                            <Typography>Sync your schedule with Google calendar.</Typography>
                            <Button
                                // style={{ marginTop: 10 }}
                                onClick={() => {
                                    // console.log("do soemthing");
                                    // this.refs.ModalBlank_GoogleCalendarSettings.showMe();
                                    this.setState({ openModal_GoogleCalendarConnect: true });
                                }}
                            >
                                Continue
                        </Button>
                            <br />
                            <button
                                style={{ margin: '20px 0' }}
                                onClick={() => {
                                    // console.log("do soemthing --");
                                    const { context } = this.props || {};
                                    const user = context && context.userAuthenticated;
                                    this.setState({ openModal_GoogleCalendarConnect: false });
                                    firebase.database()
                                        .ref('showcontentbyuserid/' + user.uid + '/gcal_v6')
                                        .set(true);
                                }}
                            >
                                Skip
                        </button>
                            <hr />
                            <Modal
                                aria-labelledby="modal"
                                aria-describedby="modal dialog Google Calendar connect."
                                className="center-modal"
                                open={this.state.openModal_GoogleCalendarConnect}
                                onClose={() => {
                                    const { context } = this.props || {};
                                    const user = context && context.userAuthenticated;
                                    this._mounted && firebase.database()
                                        .ref('showcontentbyuserid/' + user.uid + '/gcal_v6')
                                        .set(true);
                                    this._mounted && this.setState({ openModal_GoogleCalendarConnect: false });
                                }}
                            >
                                <div
                                    style={{ margin: '20px auto', backgroundColor: Colors.pri_color, padding: 20, borderRadius: 10 }}
                                >
                                    <GoogleCalendarConnect {...this.props} />
                                </div>
                            </Modal>
                        </div>
                    )}
                {
                    (!classes || !classes.length)
                    && (
                        <div>
                            <Icon style={{ fontSize: 100, color: '#fff' }}>calendar_today</Icon>
                            <Typography>When you register for a class you'll see it here.</Typography>
                        </div>
                    )}
                {
                    Boolean(classes && classes.length)
                    && (
                        <div>
                            {

                            }
                            <Typography>Classes you are registered for:</Typography>
                            {class_category_keys && class_category_keys.length
                                ?
                                class_category_keys.map(cck => {
                                    const c_arr = classes_categorized[cck];
                                    return (
                                        <div key={cck}
                                            style={{
                                                margin: '40px auto',
                                                paddingTop: 20,
                                                paddingBottom: 20,
                                                borderRadius: 10,
                                                backgroundColor: '#fff'
                                            }}
                                        >
                                            <Typography variant="h1" color="textSecondary" style={{ letterSpacing: 0.18, fontSize: '17px', color: 'rgba(0,0,0,0.26)' }}>{c_arr.title}</Typography>
                                            {
                                                c_arr.data.map(c => {
                                                    const start_time_desc = moment(c.calculated_class_start_time).format('h:mm A');
                                                    let daysByNickname = [];
                                                    let recurrence_desc = '';
                                                    if (c.recurrence_rule) {
                                                        const { byday, interval } = UtilFX.getRecurrenceRuleParts(c.recurrence_rule);
                                                        daysByNickname = byday.split(',').map(n => n[0] + n[1].toLowerCase());
                                                        recurrence_desc = `${(Number(interval) === 1) ? '| every week' : '| every other week'}`;
                                                    }
                                                    const is_status_sub = c.status === 'sub';

                                                    return (
                                                        <div
                                                            key={c.class_id}
                                                            className={is_status_sub ? 'yogi-class-sub' : 'yogi-class'}
                                                            style={{
                                                                borderWidth: 1.1,
                                                                borderColor: Colors.pri_color,
                                                                borderStyle: 'solid',
                                                                borderRadius: 18,
                                                                paddingTop: 10,
                                                                paddingBottom: 10,
                                                                paddingLeft: 20,
                                                                paddingRight: 20,
                                                                marginLeft: 'auto',
                                                                marginRight: 'auto',
                                                                marginBottom: 15,
                                                                marginTop: 5,
                                                                textAlign: 'left',
                                                                width: '258px',
                                                            }}
                                                        >
                                                            <Link
                                                                to={{
                                                                    pathname: `/${teachersById[c.teacher_id].username}/class/${c.class_id}`,
                                                                    state: {
                                                                        from: this.props.location.pathname,
                                                                    }
                                                                }}

                                                                style={{textDecoration:'none'}}
                                                            >
                                                                <div style={{ display: 'inline-block', width: '100%' }}>
                                                                    <div
                                                                        style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                                    >
                                                                    
                                                                       <AvatarDisplay
                                                                       imgSrc={teachersById[c.teacher_id].profile_photo_url}
                                                                       imgTitle={`teacher ${teachersById[c.teacher_id].fullname}`}
                                                                       imgAlt={'teacher'}
                                                                       imgClassName={'yogi-photo'}
                                                                       imgStyles={{
                                                                        borderRadius: '50%',
                                                                        height: 46,
                                                                        width: 46,
                                                                        border: '4px white solid',
                                                                        margin: 15
                                                                        }}
                                                                       userName={teachersById[c.teacher_id].fullname} 
                                                                       textStyles={{
                                                                        ...GlobalStyles.bodyMedium,
                                                                        color: 'rgb(23, 155, 247)',
                                                                        textAlign: 'center',
                                                                        borderRadius: '50px',
                                                                        border: '2px solid',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        height: '30px',
                                                                        width: '30px',
                                                                        justifyContent: 'center'
                                                                      }}
                                                                       />

                                                                        <Typography variant="caption">
                                                                            @
{teachersById[c.teacher_id].username}
                                                                        </Typography>
                                                                        <hr />
                                                                    </div>

                                                                    <div style={{ display: 'inline-block', marginLeft: 10 }}>
                                                                        <div>
                                                                            <Typography variant="body1" color="primary" style={{ fontFamily: 'GillSans' }}>{`${is_status_sub ? '(Subbed out) ' : ''}${c.title}`}</Typography>
                                                                            <Typography variant="body1" color="textSecondary" style={{ fontStyle: 'italic', fontWeight: 100, }}>
                                                                                {start_time_desc}
                                                                                {' '}
                                                                                <span style={{ color: 'rgba(0,0,0,0.3)' }}>{`(${c.duration} min)`}</span>
                                                                            </Typography>
                                                                            <Typography variant="caption" color="textSecondary" style={{ fontStyle: 'italic', fontWeight: 100, }}>
                                                                                {
                                                                                    daysByNickname.map(i => (
                                                                                        <div
                                                                                            key={i}
                                                                                            style={{
                                                                                                backgroundColor: 'rgba(0,0,0,0.3)',
                                                                                                color: '#fff',
                                                                                                borderRadius: 10,
                                                                                                marginRight: 5,
                                                                                                height: 20,
                                                                                                width: 20,
                                                                                                lineHeight: '20px',
                                                                                                textAlign: 'center',
                                                                                                display: 'inline-block'
                                                                                            }}
                                                                                        >
                                                                                            <span>{i}</span>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                                {recurrence_desc}
                                                                            </Typography>
                                                                            <Typography variant="body1" color="textSecondary">{(c.location && c.location.name) || 'Virtual class'}</Typography>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    );
                                })
                                : null
                            }
                        </div>
                    )}
            </div>
        );
    }
}
