import React from 'react';

const Msg = ({ _handleAccept, _handleReject, toastMessage, toastAccept, toastReject }) => (
  <div>
    <p>{toastMessage}</p>
    <button
      type="button"
      style={{
        padding: '10px 15px', background: '#ffffff', borderRadius: 4, color: 'red', borderColor: 'red'
      }}
      onClick={() => _handleAccept && _handleAccept()}
    >
      {toastAccept}
    </button>
    <button
      type="button"
      style={{
        padding: '10px', marginLeft: '12px', background: '#ffffff', borderRadius: 4, color: 'green', borderColor: 'green'
      }}
      onClick={() => _handleReject && _handleReject()}
    >
      {toastReject}
    </button>
  </div>
);

export default Msg;
