import React, { useState, useEffect } from 'react';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import imgUndo from '../GroupChat/svg/undo.svg';
import { Colors, GlobalStyles } from '../../../utils';
import SendMessageButton from './svg/sendbutton.svg';
import ImgGIFActive from './svg/gif_active.svg';
import ImgGIFDisabled from './svg/gif_unactive.svg';
import GifPreview from './GifPreview';

const InputMessage = ({
  reply,
  replyViewStyle,
  replyInputStyle,
  cancelReplyMessage,
  handleSendMessage,
  onChange,
  onKeyPress,
  refFwd,
  inputRef,
  value,
  replyMessageDetails,
  setGifUrl,
}) => {
  const { fullname: username, message_text: messageText = "", gif_url: gifUrl } = replyMessageDetails || {};
  const [isGifActive, setGitActive] = useState(false);
  const [gifDefaultFilter, setGifDefaultFilter] = useState('excited');
  const [selectedGif, setSelectedGif] = useState({});
  const [gifSearchKey, setGifSearchKey] = useState('');

  useEffect(() => {
    if (messageText || gifUrl) {
      inputRef.current.focus()
    }
  }, [messageText, gifUrl, inputRef])

  return (
    <React.Fragment>
      {
        isGifActive && (
          <React.Fragment>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '7px 15px',
                backgroundColor: Colors.grey6,
              }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '11px' }}>
                {
                  !selectedGif?.url &&
                  <Input
                    disableUnderline
                    placeholder="Search gifs"
                    style={{
                      ...GlobalStyles.bodyRegular,
                      border: '1px solid' + Colors.grey5,
                      borderRadius: '26px',
                      color: Colors.grey1,
                      padding: '10px',
                    }}
                    onChange={(e) => {
                      setGifDefaultFilter(e.target.value);
                      setGifSearchKey(e.target.value);
                    }}
                    className='gif-input'
                    startAdornment={(<InputAdornment position="start" style={{ color: Colors.grey1, alignSelf: 'center' }}>
                      <SearchIcon />
                    </InputAdornment>)}
                    endAdornment={(<InputAdornment position="end" style={{ color: Colors.pri_color, alignSelf: 'center' }}>
                      {gifSearchKey && <CancelIcon />}
                    </InputAdornment>)}
                  />
                }
                {
                  selectedGif?.url && (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <img src={selectedGif.media[0].tinygif.url} style={{ width: '48px', height: '48px', borderRadius: '3px' }} alt='gif-small-preview' />
                      <div style={{ marginLeft: '16px', marginRight: '24px' }}>
                        <Typography style={{ ...GlobalStyles.bodyMedium, color: Colors.grey2, textAlign: 'left' }}>{gifDefaultFilter}</Typography>
                        <Typography style={{ ...GlobalStyles.bodyRegular, color: Colors.grey2, textAlign: 'left' }}>{selectedGif?.title}</Typography>
                      </div>
                    </div>
                  )}
                <div onClick={() => {
                  setGitActive(false);
                  setSelectedGif({});
                }} style={{ cursor: 'pointer', padding: '10px 16px 10px 14px', display: 'flex', flexDirection: 'row', backgroundColor: Colors.grey5, borderRadius: '26px', marginLeft: '8px' }}>
                  <span
                    className="material-icons"
                    style={{ fontSize: '25px', color: Colors.grey1, alignSelf: 'center' }}
                  >
                    clear
                  </span>
                  <Typography style={{ ...GlobalStyles.bodyRegular, color: Colors.grey1, fontSize: '14px', marginLeft: '10px', alignSelf: 'center' }}>Close</Typography>
                </div>
              </div>
              {
                !selectedGif?.url &&
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '14px', marginBottom: '12px', overflow: 'scroll' }}>
                  <GifPreview gifDefaultFilter={gifDefaultFilter} setSelectedGif={(data) => {
                    setSelectedGif(data);
                    setGifUrl(data.media[0].nanogif.url);
                    inputRef.current.focus();
                  }} />
                </div>
              }
            </div>
          </React.Fragment>
        )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: '7px 15px',
          backgroundColor: Colors.snow,
          alignItems: 'center',
          flex: 1,
        }}
      >
        <img src={isGifActive ? ImgGIFActive : ImgGIFDisabled}
          alt='diabled-gif-icon'
          style={{ marginRight: '6px', marginTop: reply && '50px' }}
          onClick={() => {
            setGitActive(!isGifActive);
            setSelectedGif({});
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {
            reply && (
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '10px',
                ...replyViewStyle,
                border: '1px solid' + Colors.grey5,
                borderRadius: '26px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <img src={imgUndo} style={{ marginRight: '14px', marginTop: '5px', marginLeft: '13px' }} alt='message-undo' />
                  {
                    gifUrl && (
                      <img src={gifUrl} style={{ width: '48px', height: '48px', borderRadius: '3px', marginRight: '10px' }} alt='gif-small-preview' />
                    )}
                  <div style={{ textAlign: 'left' }}>
                    <Typography style={{ color: Colors.primaryColor, ...GlobalStyles.bodyMedium }}>{username}</Typography>
                    <Typography style={{ color: Colors.grey2, ...GlobalStyles.bodyRegular }}>{messageText}</Typography>
                  </div>
                </div>
                <span onClick={() => cancelReplyMessage()} className="material-icons" style={{ color: Colors.grey3, fontSize: '18px', cursor: 'pointer' }}>
                  clear
                </span>
              </div>
            )}
          <Input
            onChange={onChange}
            onKeyPress={(e) => {
              onKeyPress(e, setGitActive, setSelectedGif);
            }}
            ref={refFwd}
            inputRef={inputRef}
            rowsMax={3}
            multiline={true}
            placeholder="Message"
            style={{
              ...replyInputStyle,
              ...GlobalStyles.bodyRegular,
              border: '1px solid' + Colors.grey5,
              borderRadius: '26px',
              color: Colors.grey1,
              padding: '10px',
              borderTopLeftRadius: (reply && '0') || '26px',
              borderTopRightRadius: (reply && '0') || '26px',
            }}
            disableUnderline
            value={value}
          />
        </div>
        <img
          style={{
            marginLeft: '6px',
            cursor: 'pointer',
            marginTop: (reply && '60px') || 0,
          }}
          src={SendMessageButton}
          onClick={() => {
            handleSendMessage();
            cancelReplyMessage();
            setGitActive(false);
            setSelectedGif({});
          }}
          alt='send-button'
          value={value}
        />
      </div>
    </React.Fragment>
  );
};

export default InputMessage;
