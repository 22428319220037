import algoliasearch from 'algoliasearch';
import { UtilFX } from './index';

const isDevEnv = UtilFX.getIsDevEnv();
// console.log("I got s DevEEnve", isDevEnv);

export const ApplicationId = !isDevEnv ? process.env.REACT_APP_PROD_ALGOLIA_applicationid : process.env.REACT_APP_DEV_ALGOLIA_applicationid;
export const SearchApiKey = !isDevEnv ? process.env.REACT_APP_PROD_ALGOLIA_searchapikey : process.env.REACT_APP_DEV_ALGOLIA_searchapikey;

export const Client = algoliasearch(ApplicationId, SearchApiKey);
export const getIndex = ({ indexName }) => Client.initIndex(indexName);
