import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import errorLocationImg from './error_boundary_img.svg';
import {
    Colors,
} from './utils';
import './App.css';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            errorInfo: null,
        };
    }

    componentDidCatch(error, errorInfo) {
        if (process.env.NODE_ENV === 'production') {
            window.Rollbar.error(error);
        }
        this.setState({ errorInfo: errorInfo });
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <div className="error-boundary">
                    <Grid container className="error-boundary-container">
                        <Grid container item md={12} xs={12} className="column-reverse-check">
                            <Grid item lg={6} sm={6} md={6} xs={12} style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                <img src={errorLocationImg} alt="error" className="error-boundary-image" />
                            </Grid>
                            <Grid item lg={4} xs={12} md={4} sm={4}>
                                <div className="error-boundary-text">
                                    <Typography style={{ fontFamily: 'Rubik', fontStyle: 'normal', fontWeight: '500', fontSize: '72px', color: '#4F4F4F', }}>Oops !</Typography>
                                    <Typography style={{ fontFamily: 'Rubik', fontStyle: 'normal', fontWeight: '500', fontSize: '16px', color: '#828282', width: '300px' }}>Something went wrong, and our engineers have been notified. Thanks for bearing with us until we get this fixed</Typography>
                                    <div style={{ marginTop: '40px', display: 'flex', flexDirection: 'row', width: '100%' }}>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            style={{ backgroundColor: Colors.primaryColor, color: Colors.white, borderRadius: '50px', width: '120px', border: '1px solid ' + Colors.primaryColor, boxShadow: 'none' }}
                                            onClick={() => this.props.history.push('/home')}
                                        >
                                            Go Home
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            style={{ backgroundColor: Colors.white, color: Colors.primaryColor, borderRadius: '50px', width: '120px', border: '1px solid ' + Colors.primaryColor, marginLeft: '16px', boxShadow: 'none' }}
                                            onClick={() => this.props.history.goBack()}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            );
        }
        return (
            this.props.children
        );
    }
}

export default ErrorBoundary;
