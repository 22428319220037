import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { GlobalStyles, UtilFX, Colors } from '../../../utils';
import { firestore, firebase } from '../../../fire';
import AvatarDisplay from '../../AvatarDisplay';

const PendingInviteCard = ({ recipientId, setHideGroupChatMembers, hideGroupChatMembers, data, setAcceptedGroupChatMembers, acceptedGroupChatMembers, pendingGroupChatMembers, setPendingGroupChatMembers }) => {
    const [user, setUser] = useState({});

    useEffect(() => {
        async function fetchData() {
            if (recipientId) {
                Promise.resolve(UtilFX.getFYuser(recipientId).then(res => {
                    setUser({ ...res });
                }));
            }
        }
        fetchData();
    }, [recipientId]);

  
    return (
        <div style={{ display: 'flex', flexDirection: 'row', padding: '16px', }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <AvatarDisplay
                    imgSrc={user.profile_photo_url}
                    imgAlt={'profile'}
                    imgStyles={{ width: '48px', height: '48px', borderRadius: '50%' }}
                    userName={user.fullname}
                    textStyles={{ ...GlobalStyles.bodyMedium, color: Colors.grey2, textAlign: 'center' }}

                />
                <div style={{ marginLeft: '20px' }}>
                    <Typography style={{ ...GlobalStyles.bodyMedium, color: Colors.grey2, textAlign: 'left' }}>{user.fullname || user.username}</Typography>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                        <div onClick={async() => {
                            if (data.documentId) {
                                const docRef = await firestore
                                .collection('ConvoMembers')
                                .doc(data.documentId);
                                docRef.update({ status: 'accepted' });
                                setHideGroupChatMembers([...hideGroupChatMembers, data.documentId]);
                                const pendingGCM = pendingGroupChatMembers.filter(result => result.documentId !== data.documentId);
                                setPendingGroupChatMembers(pendingGCM);
                                setAcceptedGroupChatMembers([...acceptedGroupChatMembers, data]);
                              }
                            }} style={{ backgroundColor: Colors.blueOnlineClass, width: '100px', borderRadius: '12px', padding: '4px', cursor: 'pointer' }}><Typography style={{ ...GlobalStyles.bodyMedium, color: Colors.pri_color }}>Accept</Typography></div>
                        <div onClick={async() => {
                            if (data.documentId) {
                                await firestore
                                    .collection('ConvoMembers')
                                    .doc(data.documentId).delete().then(() => {
                                        const updateData = {};
                                        updateData[`convobyusers/${recipientId}/${data.convo_id}`] = null;
                                        firebase.database()
                                        .ref()
                                        .update(updateData);
                                    });
                                const pendingGCM = pendingGroupChatMembers.filter(result => result.documentId !== data.documentId);
                                setPendingGroupChatMembers(pendingGCM);
                                setHideGroupChatMembers([...hideGroupChatMembers, data.documentId]);
                              }
                            }} style={{ width: '100px', backgroundColor: Colors.grey6, borderRadius: '12px', padding: '4px', margin: '0 9px', cursor: 'pointer' }}><Typography style={{ ...GlobalStyles.bodyMedium, color: Colors.grey3 }}>Ignore</Typography></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PendingInviteCard;
