import React from 'react';
import Typography from '@material-ui/core/Typography';
import HomeChat from './svg/home.svg';
import Button from '../ButtonComponent';
import { Colors, GlobalStyles } from '../../../utils';

const MessageZeroState = ({ headline, dm, onClick }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <img src={HomeChat} alt="home-svg-icon" />
      <div style={{ marginTop: '19px' }}>
        <Typography
          style={{
            ...GlobalStyles.bodyMedium,
            color: Colors.grey1,
            textAlign: 'center',
          }}
        >
          {headline}
        </Typography>
      </div>
      {dm && (
        <div style={{ marginTop: '16px' }}>
          <Button
            onClick={onClick}
            value="New message"
            buttonStyle={{
              backgroundColor: Colors.primaryColor,
              width: '136px',
              borderRadius: '5px',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default MessageZeroState;
