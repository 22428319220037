import React from 'react';

const ClearCard = ({ cancelEvent, back }) => {
  return (
    <div style={{ position: 'relative' }}>
      <span
        onClick={cancelEvent}
        style={{
          position: 'absolute',
          right: 10,
          top: 10,
          color: 'black',
          fontSize: '25px',
          cursor: 'pointer',
        }}
        className="material-icons"
      >
        {back ? 'arrow_back' : 'clear'}
      </span>
    </div>
  );
};

export default ClearCard;
