import React, { useState, useEffect } from 'react';
import { UtilFX } from '../../../utils';

const GifPreview = ({ gifDefaultFilter, setSelectedGif }) => {
    const [gifResults, setGifResults] = useState([]);

    useEffect(() => {
        UtilFX.getTenorGifData(gifDefaultFilter).then(res => setGifResults(res));
      }, [gifDefaultFilter]);
    return (
    <React.Fragment>
        {
            gifResults.map((item, key) => {
                return (
                    <img onClick={() => setSelectedGif(item)} key={key} style={{ width: '133px', height: '133px', borderRadius: '8px', marginLeft: '8px' }} src={item.media[0].tinygif.url} alt='gif-preview' />
                );
            })
        }
    </React.Fragment>
  );
};

export default GifPreview;
