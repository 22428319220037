import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Colors, GlobalStyles } from '../../../utils';
import ImgPin from './svg/pin.svg';

const PinMessageCard = ({ convoMessages, handlePinMessageModal, handlePinnedMessageId, teacherId, uid, hidePinnedMessageView }) => {
  return (
    convoMessages &&
    convoMessages.filter(item => !item.date_deleted && item.date_pinned)
      .map((item, key) => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: Colors.whiteSmoke,
                    justifyContent: 'space-between',
                    padding: '17px',
                    position: hidePinnedMessageView ? 'relative' : 'absolute',
                    boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.2)',
                    visibility: hidePinnedMessageView ? 'hidden' : null,
                    margin: hidePinnedMessageView ? '0px -18px' : null
                }}
                className='pin-message-view'
                key={key}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: '20px',
                    }}
                >
                    <div
                        style={{
                            cursor: 'pointer',
                        }}
                    >
                        <img src={ImgPin} alt='pin-holder' />
                    </div>
                    <Typography
                        style={{
                        ...GlobalStyles.bodyRegular,
                        color: Colors.grey1,
                        marginLeft: '12px',
                        }}
                    >
                        {item?.message_text}
                    </Typography>
                </div>
                {
                    teacherId === uid && (
                    <div
                        style={{
                            color: Colors.grey1,
                            cursor: 'pointer',
                            marginRight: '20px',
                        }}
                        onClick={() => {
                            handlePinMessageModal();
                            handlePinnedMessageId(item.message_id);
                        }}
                    >
                        <span className="material-icons">
                            clear
                        </span>
                    </div>
                )}
            </div>
        );
    })
  );
};

export default PinMessageCard;
