import React, { Component } from 'react';

import {
    Link,
    //     Redirect,
} from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

// import { withStyles, withTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import {
    InstantSearch,
    Configure,
    // Index,
    // RefinementList
} from 'react-instantsearch/dom';

// import axios from 'axios';

import {
    // UtilFX,
    Loading,
    // DataFX,
    // Colors,
    // GlobalContext,
    GlobalStyles,
    // AlertBox,
 Colors
} from '../utils';

import {
    // auth,
    // database,
    // firebase
} from '../fire';

import * as Algolia from '../utils/algolia';
import { HitsYogis } from './Explore';
import AvatarDisplay from './AvatarDisplay';

export default class Teachers extends Component {
    // constructor(props, context) {
    //     super(props, context);
    //     this.state = {};
    // }
    state = {};

    componentDidUpdate(oldProps, oldState) {
        const { context } = this.props;
        const { userAuthenticated } = context;
        const oldUA = oldProps && oldProps.context && oldProps.context.userAuthenticated;
        if (!oldUA && userAuthenticated) {
            this.refreshData();
            return;
        }
        if (oldUA && userAuthenticated) {
            const oldTeacherIds = Object.keys(oldUA.teachers || {});
            const newTeacherIds = Object.keys(userAuthenticated.teachers || {});
            if (oldTeacherIds.length !== newTeacherIds.length) {
                this.refreshData();
                return;
            }

            //const tidsCSV = newTeacherIds.join(",");
            const isSameTeachers = !newTeacherIds.filter(id => (oldTeacherIds.indexOf(id) < 0)).length;

            if (!isSameTeachers) {
                this.refreshData();
            }
        }
    }

    componentDidMount() {
        this._mounted = true;
        this.refreshData();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    refreshData = () => {
        const { context } = this.props || {};
        const { userAuthenticated: _u } = context || {};
        if (_u && _u.uid) {
            // axios.post(UtilFX.getApiBaseUrl() + "getApiData", {
            //     action: "getTeachersOfYogi",
            //     uids: userAuthenticated.uid,
            // })
            //     .then(({ data }) => {

            //         const { teachers } = data || [];
            //         this._mounted && this.setState({
            //             teacherListData: teachers,
            //         })
            //     }).catch((err) => {
            //         console.warn("error fetching data", err);
            //     });
            // Object.keys(_u.teachers || {}).join(",");

        } else {
            console.warn('no logged in user here');
        }
    }

    render() {
        const { teacherListData } = this.state;
        const { context } = this.props || {};
        const { userAuthenticated: _u } = context || {};
        // console.log("props and state", this.props, this.state)
        return (
            <Grid container justify="center">
                <Grid item xs={12} style={{ maxWidth: '90%' }}>
                    <div>
                        {
                            !_u
                                ?
                                <Loading />
                                :
                                (
<div
                                    style={{ position: 'relative' }}
>
                                    <Typography variant="h1" style={{ fontSize: '1.5rem' }} className="page-title">Instructors</Typography>
                                    <div style={{
                                        borderRadius: 10,
                                        marginTop: '30px',
                                        // paddingTop: "20px",
                                        // backgroundColor: "#fff",
                                        color: Colors.pri_color,
                                    }}
                                    >
                                        <div
                                            style={{
                                                marginBottom: 20,
                                            }}
                                        >
                                        </div>
                                        <InstantSearch
                                            appId={Algolia.ApplicationId}
                                            apiKey={Algolia.SearchApiKey}
                                            indexName="yogis"
                                        >
                                            <Configure
                                                filters={Object.keys((_u || {}).teachers || {}).map(id => `objectID:${id}`).join(' OR ')}
                                            // hitsPerPage={100}
                                            />
                                            <HitsYogis {...this.props} />
                                        </InstantSearch>
                                        {teacherListData && teacherListData.length
                                            ? (
                                            <Grid container>
                                                {teacherListData.map(yogi => (
                                                        <Grid style={{ marginBottom: 25, }}
item
xs={6}
                                                            key={yogi.uid}
                                                        >
                                                            <Link
                                                                to={{
                                                                    pathname: `/${yogi.username}`,
                                                                    state: {
                                                                        from: this.props.location.pathname,
                                                                    }
                                                                    
                                                                }}
                                                                style={{textDecoration:'none'}}
                                                            >
                                                                <div style={{ display: 'inline-block' }}>
                                                                  
                                                                    <AvatarDisplay
                                                                        imgSrc={yogi.profile_photo_url}
                                                                        imgClassName={'yogi-photo'}
                                                                        imgStyles={{ borderRadius: '50%', height: 80, }}
                                                                        userName={yogi.fullname}
                                                                        textStyles={{
                                                                            ...GlobalStyles.bodyMedium,
                                                                            color: 'rgb(23, 155, 247)',
                                                                            textAlign: 'center',
                                                                            borderRadius: '50px',
                                                                            border: '2px solid',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            height: '30px',
                                                                            width: '30px',
                                                                            justifyContent: 'center'
                                                                      }}/>
                                                                    <img style={{ borderRadius: '50%', height: 80, }} className="yogi-photo" src={yogi.profile_photo_url} alt="" />
                                                                    <Typography variant="body1" color="primary">{yogi.fullname}</Typography>
                                                                    <Typography variant="body1" color="primary">
@
{yogi.username}
                                                                    </Typography>
                                                                </div>
                                                            </Link>
                                                        </Grid>
                                                    ))

                                                }
                                            </Grid>
                                          )
                                            : null
                                        }
                                    </div>
</div>
)
                        }
                    </div>
                </Grid>
            </Grid>
        );
    }
}
