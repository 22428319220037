import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import ImgThumbsUpActive from './svg/thumbs_up_active.svg';
import ImgThumbsUpInActive from './svg/thumbs_up_inactive.svg';
import { Colors } from '../../../utils';
import { firestore } from '../../../fire';

const LikeEmoji = ({ user, item, left }) => {
    const [localLikeEmojiCount, setLocalLikeEmojiCount] = useState([]);
    const likes = (localLikeEmojiCount.length ? localLikeEmojiCount : item?.likes) || [];
    return (
        <div onClick={() => {
            let result = [];
            if (likes.includes(user.uid)) {
              result = likes.filter(id => user.uid !== id);
            } else {
              result = [...likes, user.uid];
            }
            setLocalLikeEmojiCount(result);
            const messageRef = firestore
            .collection('ConvoMessages')
            .doc(item.message_id);
            return messageRef
              .update({
                likes: result,
            });
        }} style={{ textAlign: left ? 'left' : 'right', display: 'flex', flexDirection: 'row', backgroundColor: Colors.grey6, borderRadius: '30px', marginTop: '8px', width: '40px', justifyContent: 'center', padding: '5px', cursor: 'pointer' }}>
            <img src={(likes.length ? ImgThumbsUpActive : ImgThumbsUpInActive)} style={{ alignSelf: 'end' }} alt='like-emoji-count' />
            <Typography style={{ color: Colors.black, marginLeft: '4px', alignSelf: 'center' }}>{likes.length}</Typography>
        </div>
    );
};

export default LikeEmoji;
