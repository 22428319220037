import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Modal from '@material-ui/core/Modal';
import ClearCard from './common/ClearCard';
import { firebase } from '../fire';
import ButtonComponent from './common/ButtonComponent';
import {
  UtilFX,
  Colors,
  Loading,
  GlobalStyles,

} from '../utils';

import '../App.css';
import AvatarDisplay from './AvatarDisplay';

export default class PaymentHistory extends Component {
  constructor(props) {
    super(props);
    this.auth_user = this.props.context.userAuthenticated;
    this.state = {
      payments_received: [],
      payments_received_obj: {},
      payments_sent: [],
      payments_received_array: [],
      payments_sent_array: [],
      user_global_object: {},
      show_sent: true,
      pageLoader: true,
      show_received: null,
      openPaymentDetailsModal: false,
      openRefundModal: false,
      showLoader: true,
    };
  }

  componentDidMount() {
    UtilFX.getFYuser().then((u) => {
      this.setState(
        {
          fyu: u,
        },
        () => {
          this._fetchAndSetData();
        }
      );
    });
  }

  fetchMorePaymentTransactionsFromUserId = () => {
    // const rcv = [];
    // const rcv_obj = {};
    const sent = [];
    const user_global_object = {};
    const itemsFromRef = firebase
      .database()
      .ref('paymenttransactionsbyfromuserid/' + this.auth_user.uid);
    // itemsFromRef.keepSynced(true);
    return itemsFromRef
      .orderByKey()
      .endAt(this.state.oldestPaymentSent || '')
      .limitToLast(20)
      .once('value')
      .then((snap) => {
        snap.forEach((data) => {
          const details = data.val();
          details.ptid = data.key;

          if (
            details.status === 'refunded' ||
            details.status === 'failed' ||
            details.status === 'paid' ||
            details.status === 'pending'
          ) {
            details.profit = UtilFX.getValueAsCurrency(Number(details.price_total));
            var type_calc = (details.class_state_snapshot || {}).when_onetime
              ? 'event'
              : 'class';
            type_calc += ' - ' + (details.class_state_snapshot || {}).title;
            details.type = details.type || type_calc;
            user_global_object[details.to] = true;
            //user_global_object[details.from]=true
            sent.push(details);
          } else {
            console.info('ODd, not an expeted status', details);
          }
        });
        const oldestPaymentSent = sent[0] || {};
        const index = sent.indexOf(oldestPaymentSent);
        if (index > -1) {
          sent.splice(index, 1);
        }
        this.setState(
          {
            payments_sent: sent,
            oldestPaymentSent: oldestPaymentSent.ptid
          },
          () => {
            //this.updateListings_sent()
          }
        );
      })
      .then(() => {
        const promise = Object.keys(user_global_object).map((uid) => {
          return UtilFX.getFYuser(uid);
          //console.log("the use we just got",user_global_object[uid])
        });
        Promise.all(promise).then((users) => {
          users.forEach((user) => {
            user_global_object[user.uid] = user;
          });
          const oldUserGlobalObject = this.state.user_global_object;
          this.setState(
            {
              user_global_object: { ...oldUserGlobalObject, ...user_global_object },
            },
            () => {
              this.updateListings_sent();
            }
          );
        });
      });
  }

  fetchMorePaymentTransactionstToUserId = () => {
    const receive = [];
    const rcv_obj = {};
    const user_global_object = {};
    const itemsToRef = firebase
      .database()
      .ref('paymenttransactionsbytouserid/' + this.auth_user.uid);
    // itemsToRef.keepSynced(true);

    return itemsToRef
      .orderByKey()
      .endAt(this.state.oldestPaymentReceived || '')
      .limitToLast(20)
      .once('value')
      .then((snap) => {
        snap.forEach((data) => {
          const details = data.val();
          details.ptid = data.key;
          if (
            details.status === 'refunded' ||
            details.status === 'paid' ||
            details.status === 'pending'
          ) {
            details.profit = UtilFX.getValueAsCurrency(
              Number(details.price_total) - Number(details.fee_amount)
            );
            let type_calc = (details.class_state_snapshot || {}).when_onetime
              ? 'event'
              : 'class';
            type_calc += ' - ' + (details.class_state_snapshot || {}).title;
            details.type = details.type || type_calc;
            //user_global_object[details.to]=true
            user_global_object[details.from] = true;
            receive.push(details);
            rcv_obj[details.ptid] = details;
          }
        });
        const oldestPaymentReceived = receive[0] || {};
        const index = receive.indexOf(oldestPaymentReceived);
        if (index > -1) {
          receive.splice(index, 1);
        }
        const previousPaymentReceived = this.state.payments_received_obj;
        this.setState(
          {
            payments_received: receive,
            payments_received_obj: { ...previousPaymentReceived, ...rcv_obj },
            oldestPaymentReceived: oldestPaymentReceived.ptid
          },
          () => {
            //this.updateListings_received()
            console.log({ receive, rcv_obj });
          }
        );
      })
      .then(() => {
        const promise = Object.keys(user_global_object).map((uid) => {
          return UtilFX.getFYuser(uid);
          //console.log("the use we just got",user_global_object[uid])
        });
        Promise.all(promise).then((users) => {
          users.forEach((user) => {
            user_global_object[user.uid] = user;
          });
          const oldUserGlobalObject = this.state.user_global_object;
          this.setState(
            {
              user_global_object: { ...oldUserGlobalObject, ...user_global_object },
            },
            () => {
              this.updateListings_received();
            }
          );
        });
      });
  }

  _fetchAndSetData = () => {
    const rcv = [];
    const rcv_obj = {};
    const snt = [];
    const user_global_object = {};
    const itemsToRef = firebase
      .database()
      .ref('paymenttransactionsbytouserid/' + this.auth_user.uid);
    // itemsToRef.keepSynced(true);

    return itemsToRef
      .orderByKey()
      .limitToLast(20)
      .once('value')
      .then((snap) => {
        snap.forEach((vs) => {
          const v = vs.val();
          v.ptid = vs.key;
          if (
            v.status === 'refunded' ||
            v.status === 'paid' ||
            v.status === 'pending'
          ) {
            v.profit = UtilFX.getValueAsCurrency(
              Number(v.price_total) - Number(v.fee_amount)
            );
            let type_calc = (v.class_state_snapshot || {}).when_onetime
              ? 'event'
              : 'class';
            type_calc += ' - ' + (v.class_state_snapshot || {}).title;
            v.type = v.type || type_calc;
            //user_global_object[v.to]=true
            user_global_object[v.from] = true;
            rcv.push(v);
            rcv_obj[v.ptid] = v;
          }
        });
        const oldestPaymentReceived = rcv[0] || {};
        const index = rcv.indexOf(oldestPaymentReceived);
        if (index > -1) {
          rcv.splice(index, 1);
        }
        this.setState(
          {
            payments_received: rcv,
            payments_received_obj: rcv_obj,
            oldestPaymentReceived: oldestPaymentReceived.ptid
          },
          () => {
            //this.updateListings_received()
            console.log(this.state.oldestPaymentReceived, '&&&');
          }
        );
      })
      .then(() => {
        const itemsFromRef = firebase
          .database()
          .ref('paymenttransactionsbyfromuserid/' + this.auth_user.uid);
        // itemsFromRef.keepSynced(true);
        return itemsFromRef
          .orderByKey()
          .limitToLast(20)
          .once('value')
          .then((snap) => {
            snap.forEach((vs) => {
              const v = vs.val();
              v.ptid = vs.key;

              if (
                v.status === 'refunded' ||
                v.status === 'failed' ||
                v.status === 'paid' ||
                v.status === 'pending'
              ) {
                v.profit = UtilFX.getValueAsCurrency(Number(v.price_total));
                var type_calc = (v.class_state_snapshot || {}).when_onetime
                  ? 'event'
                  : 'class';
                type_calc += ' - ' + (v.class_state_snapshot || {}).title;
                v.type = v.type || type_calc;
                user_global_object[v.to] = true;
                //user_global_object[v.from]=true
                snt.push(v);
              } else {
                console.info('ODd, not an expeted status', v);
              }
            });
            const oldestPaymentSent = snt[0] || {};
            const index = snt.indexOf(oldestPaymentSent);
            if (index > -1) {
              snt.splice(index, 1);
            }
            this.setState(
              {
                payments_sent: snt,
                oldestPaymentSent: oldestPaymentSent.ptid
              },
              () => {
                //this.updateListings_sent()
              }
            );
          });
      })
      .then(() => {
        const ps = Object.keys(user_global_object).map((uid) => {
          return UtilFX.getFYuser(uid);
          //console.log("the use we just got",user_global_object[uid])
        });
        Promise.all(ps).then((_us) => {
          _us.forEach((_u) => {
            user_global_object[_u.uid] = _u;
          });
          this.setState(
            {
              user_global_object: user_global_object,
            },
            () => {
              this.updateListings_received();
              this.updateListings_sent();
            }
          );
        });
      });
  };

  updateListings_received = () => {
    let _arr = this.state.payments_received;

    _arr = _arr.sort((a, b) => {
      return b.date_created - a.date_created;
    });

    //console.log("sorted announcements ", ann_arr)
    const oldPaymentReceivedArray = this.state.payments_received_array;
    this.setState({
      payments_received_array: [...oldPaymentReceivedArray, ..._arr],
    });
  };

  updateListings_sent = () => {
    let _arr = this.state.payments_sent;

    _arr = _arr.sort((a, b) => {
      return b.date_created - a.date_created;
    });

    const oldPaymentSentArray = this.state.payments_sent_array;
    //console.log("sorted announcements ", ann_arr)
    this.setState({
      payments_sent_array: [...oldPaymentSentArray, ..._arr],
      pageLoader: false
    });
  };

  loader = () => {
    return (
      <div style={{ flex: 1, backgroundColor: Colors.pri_color }}>
        <div style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Loading>...</Loading>
        </div>
      </div>
    );
  };

  renderRow_sent = ({ items }) => {
    return (
      items &&
      items.map((item, id) => (
        <div
          key={item.ptid}
          style={{
            flexDirection: 'row',
            borderBottom: '1px solid #fff',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'transparent',
            width: '100%',
            margin: '10px',
            padding: '5px',
            cursor: 'pointer',
            textDecoration: 'none',
          }}
        >
          <div style={{ margin: '0px 10px' }}>
            <Link
              to={{
                pathname: `/${
                  (this.state.user_global_object?.[item.to] || {})?.username
                }`,
              }}
            >
                <AvatarDisplay
                    imgSrc={(this.state.user_global_object?.[item?.to] || {})
                    ?.profile_photo_url}
                    imgAlt={'teachers_profile'}
                    imgStyles={{
                      width: 40,
                      height: 40,
                      borderRadius: 20,
                    }}
                    userName={this.state.user_global_object[item?.to]?.fullname}
                    textStyles={{
                      ...GlobalStyles.bodyMedium,
                      color: 'rgb(23, 155, 247)',
                      textAlign: 'center',
                      borderRadius: '50px',
                      border: '2px solid',
                      display: 'flex',
                      alignItems: 'center',
                      height: '30px',
                      width: '30px',
                      justifyContent: 'center',
                      margin: 'auto',
                    }}
              />  
              
            </Link>
            <Typography
              style={{ textAlign: 'center', fontSize: 12, color: '#fff' }}
            >
              {item.profit}
            </Typography>
          </div>
          <div>
            <Typography
              style={{ color: '#fff', fontWeight: 'bold', textAlign: 'left' }}
            >
              {`${
                (item &&
                  item.class_state_snapshot &&
                  item.class_state_snapshot.title) ||
                ''
              }`}
            </Typography>
            <Typography style={{ color: '#fff', textAlign: 'left' }}>
              To: {(this.state.user_global_object[item.to] || {}).fullname}
            </Typography>
            <Typography style={{ color: '#fff', textAlign: 'left' }}>
              For: {item.type}
            </Typography>
            <Typography style={{ color: '#fff', textAlign: 'left' }}>
              <em>{moment(item.date_created).format('YYYY-MM-DD hh:mm a')}</em>
            </Typography>
          </div>
          <div style={{ backgroundColor: '#fff', padding: 5, borderRadius: 5 }}>
            <Typography style={{ color: Colors.sec_color }}>
              {item.status}
            </Typography>
          </div>
        </div>
      ))
    );
  };

  renderRow_received = ({ items }) => {
    return items.map((item, id) => {
      return (
        <div
          key={item.ptid}
          style={{
            flexDirection: 'row',
            borderBottom: '1px solid #fff',
            padding: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: 'transparent',
            margin: '10px',
          }}
        >
          <div style={{ margin: '0px 10px' }}>
            <Link
              to={{
                pathname: `/${
                  (this.state.user_global_object[item.from] || {}).username
                }`,
              }}
            >
             
                  <AvatarDisplay
                    imgSrc={(this.state.user_global_object?.[item?.from] || {})
                    ?.profile_photo_url}
                    imgAlt={'teachers_profile'}
                    imgStyles={{
                      width: 40,
                      height: 40,
                      borderRadius: 20,
                    }}
                    userName={this.state.user_global_object?.[item?.from]?.fullname}
                    textStyles={{
                      ...GlobalStyles.bodyMedium,
                      color: 'rgb(23, 155, 247)',
                      textAlign: 'center',
                      borderRadius: '50px',
                      border: '2px solid',
                      display: 'flex',
                      alignItems: 'center',
                      height: '30px',
                      width: '30px',
                      justifyContent: 'center',
                      margin: 'auto',
                    }}
                />  
            </Link>
            <Typography style={{ textAlign: 'center', color: '#fff' }}>
              {item.profit}
            </Typography>
          </div>
          <div>
            <Typography
              style={{ color: '#fff', fontWeight: 'bold', textAlign: 'left' }}
            >
              {`${
                (item &&
                  item.class_state_snapshot &&
                  item.class_state_snapshot.title) ||
                ''
              }`}
            </Typography>
            <Typography style={{ color: '#fff', textAlign: 'left' }}>
              From: {(this.state.user_global_object[item.from] || {}).fullname}
            </Typography>
            <Typography style={{ color: '#fff', textAlign: 'left' }}>
              For: {item.type}
            </Typography>
            <Typography style={{ color: '#fff', textAlign: 'left' }}>
              {moment(item.date_created).format('YYYY-MM-DD hh:mm a')}
            </Typography>
          </div>
          <div
            style={{
              borderRadius: 10,
              border: '1px solid #fff',
              padding: '15px',
              margin: '10px',
              cursor: 'pointer',
            }}
            onClick={() => {
              this.setState({
                ptid_selected_to_view_detail: item.ptid,
                openPaymentDetailsModal: true,
              });
            }}
          >
            <Typography style={{ fontSize: 12 }}>details</Typography>
          </div>
          <div>
            <div
              style={{
                backgroundColor: '#fff',
                padding: 5,
                borderRadius: 5,
              }}
            >
              <Typography
                style={{
                  textAlign: 'center',
                  color: Colors.sec_color,
                }}
              >
                {item.status}
              </Typography>
            </div>
            {item.status === 'paid' && (
              <div
                onClick={() => {
                  this.setState({
                    ptid_selected_to_refund: item.ptid,
                    openRefundModal: true,
                  });
                }}
                style={{
                  border: '1px solid #fff',
                  padding: 15,
                  borderRadius: 5,
                  marginTop: '10px',
                  cursor: 'pointer',
                }}
              >
                <Typography style={{ color: '#fff' }}>refund</Typography>
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  contentMessage = () => {
    return (
      <div>
        <Typography style={{ textAlign: 'center' }}>
          {this.state.show_sent
            ? 'You have not sent any payments yet.'
            : 'You have not received any payments yet.'}
        </Typography>
      </div>
    );
  };

  render() {
    let ShowPaymentContent = this.loader;

    if (this.state.show_sent && !this.state.pageLoader) {
      if (this.state.payments_sent_array?.length > 0) {
        ShowPaymentContent = this.renderRow_sent;
      } else if (this.state.payments_sent_array?.length === 0) {
        ShowPaymentContent = this.contentMessage;
      }
    }

    if (this.state.show_received && !this.state.pageLoader) {
      if (this.state.payments_received_array?.length > 0) {
        ShowPaymentContent = this.renderRow_received;
      } else if (this.state.payments_received_array?.length === 0) {
        ShowPaymentContent = this.contentMessage;
      }
    }

    return (
      <Grid container>
        <Grid
          item
          md={12}
          xs={12}
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            display: 'flex',
            marginTop: '40px',
          }}
        >
          <div
            onClick={() => {
              this.setState({ show_sent: true, show_received: null });
            }}
            style={{
              backgroundColor: !this.state.show_received
                ? '#fff'
                : Colors.sec_color,
              padding: 15,
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              width: '250px',
              cursor: 'pointer',
              marginLeft: '10px',
            }}
          >
            <Typography
              style={{
                textAlign: 'center',
                color: Colors.pri_color,
              }}
            >
              Sent
            </Typography>
          </div>
          <div
            onClick={() => {
              this.setState({ show_sent: null, show_received: true });
            }}
            style={{
              backgroundColor: this.state.show_received
                ? '#fff'
                : Colors.sec_color,
              padding: 15,
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
              width: '250px',
              cursor: 'pointer',
              marginRight: '10px',
            }}
          >
            <Typography
              style={{
                textAlign: 'center',
                color: Colors.pri_color,
              }}
            >
              Received
            </Typography>
          </div>
        </Grid>
        <ShowPaymentContent
          items={
            this.state.show_sent
              ? this.state.payments_sent_array
              : this.state.payments_received_array
          }
        />
        {
          Boolean(this.state.payments_sent_array?.length > 0) && Boolean(this.state.payments_received_array?.length > 0)
          &&
          (<div style={{ margin: 'auto', width: '30%' }}>
            {
              this.state.show_sent && Boolean(this.state.payments_sent.length) && (
              <ButtonComponent
                buttonStyle={{ marginBottom: 24, marginTop: 20, padding: '10px', backgroundColor: Colors.sec_color }}
                type="submit"
                value={'More'}
                onClick={() => {
                  this.fetchMorePaymentTransactionsFromUserId();
                }}
              />
            )}
            {
              this.state.show_received && Boolean(this.state.payments_received.length) && (
              <ButtonComponent
                buttonStyle={{ marginBottom: 24, marginTop: 20, padding: '10px', backgroundColor: Colors.sec_color }}
                type="submit"
                value={'More'}
                onClick={() => {
                  this.fetchMorePaymentTransactionstToUserId();
                }}
              />
            )}
          </div>)
        }
        <Modal
          aria-labelledby="show payment"
          aria-describedby="show payment details"
          className="center-modal"
          open={this.state.openPaymentDetailsModal}
          onClose={() => this.setState({ openPaymentDetailsModal: false })}
          disableAutoFocus
          disableEnforceFocus
        >
          <div
            className="modal-content-wrapper"
            style={{
              backgroundColor: Colors.pri_color,
              width: '380px',
              borderRadius: '12px',
              padding: '10px 0px',
            }}
          >
            <ClearCard
              cancelEvent={() =>
                this.setState({ openPaymentDetailsModal: false })
              }
            />
            <Typography
              style={{ fontSize: 20, textAlign: 'center', padding: '15px' }}
            >
              Payment Details
            </Typography>
            <div
              style={{
                alignItems: 'center',
                borderTop: '1px solid #fff',
              }}
            >
            
                  <AvatarDisplay
                    imgSrc={(
                      this.state.user_global_object[
                        (
                          this.state.payments_received_obj?.[
                            this.state.ptid_selected_to_view_detail
                          ] || {}
                        )?.from
                      ] || {}
                    )?.profile_photo_url}
                    imgAlt={'show_extra_payment_details_profile'}
                    imgStyles={{
                      width: 50,
                      height: 50,
                      borderRadius: 25,
                      marginTop: '20px',
                    }}
                    userName={this.state.user_global_object[
                      this.state.payments_received_obj?.[
                        this.state.ptid_selected_to_view_detail
                      ]?.from
                    ]?.fullname}
                    textStyles={{
                      ...GlobalStyles.bodyMedium,
                      color: 'rgb(23, 155, 247)',
                      textAlign: 'center',
                      borderRadius: '50px',
                      border: '2px solid',
                      display: 'flex',
                      alignItems: 'center',
                      height: '30px',
                      width: '30px',
                      justifyContent: 'center'
                    }}
              />  
              
              <Typography
                style={{
                  textAlign: 'center',
                  fontSize: 32,
                  fontWeight: 'normal',
                }}
              >
                {
                  (
                    this.state.payments_received_obj[
                      this.state.ptid_selected_to_view_detail
                    ] || {}
                  ).profit
                }
              </Typography>
              <div style={{}}>
                <Typography
                  style={{
                    fontSize: 16,
                    color: '#fff',
                    fontWeight: 'bold',
                    textDecorationLine: 'none',
                    textAlign: 'center',
                  }}
                >
                  {`${
                    (
                      (
                        this.state.payments_received_obj[
                          this.state.ptid_selected_to_view_detail
                        ] || {}
                      ).class_state_snapshot || {}
                    ).title || ''
                  }`}
                </Typography>

                <Typography style={{ fontSize: 16, color: '#fff' }}>
                  From:{' '}
                  {
                    (
                      this.state.user_global_object[
                        (
                          this.state.payments_received_obj[
                            this.state.ptid_selected_to_view_detail
                          ] || {}
                        ).from
                      ] || {}
                    ).fullname
                  }
                </Typography>
                <Typography style={{ fontSize: 16, color: '#fff' }}>
                  For:&nbsp;
                  {
                    (
                      this.state.payments_received_obj[
                        this.state.ptid_selected_to_view_detail
                      ] || {}
                    ).type
                  }
                </Typography>

                <Typography>
                  Status:{' '}
                  {
                    (
                      this.state.payments_received_obj[
                        this.state.ptid_selected_to_view_detail
                      ] || {}
                    ).status
                  }
                </Typography>
                <Typography>
                  Service Fee: $
                  {
                    (
                      this.state.payments_received_obj[
                        this.state.ptid_selected_to_view_detail
                      ] || {}
                    ).fee_amount
                  }
                </Typography>
                <Typography>
                  Created:{' '}
                  {moment(
                    (
                      this.state.payments_received_obj[
                        this.state.ptid_selected_to_view_detail
                      ] || {}
                    ).date_created
                  ).format('YYYY-MM-DD')}
                  &nbsp;@{' '}
                  {moment(
                    (
                      this.state.payments_received_obj[
                        this.state.ptid_selected_to_view_detail
                      ] || {}
                    ).date_created
                  ).format('h:mm:ss a')}
                </Typography>
                {(
                  this.state.payments_received_obj[
                    this.state.ptid_selected_to_view_detail
                  ] || {}
                ).note_donation ? (
                  <Typography style={{ fontSize: 16, color: '#fff' }}>
                    Special Note:{' '}
                    {
                      (
                        this.state.payments_received_obj[
                          this.state.ptid_selected_to_view_detail
                        ] || {}
                      ).note_donation
                    }
                  </Typography>
                ) : null}
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          aria-labelledby="show refund"
          aria-describedby="show refund details"
          className="center-modal"
          open={this.state.openRefundModal}
          onClose={() => true}
          disableAutoFocus
          disableEnforceFocus
        >
          <div
            className="modal-content-wrapper"
            style={{
              backgroundColor: Colors.pri_color,
              width: '380px',
              borderRadius: '12px',
              padding: '20px',
            }}
          >
            <div>
              <Typography style={{ fontSize: 20, textAlign: 'center' }}>
                Refund Payment
              </Typography>

              <Typography>
                Confirm that you would like to fully refund this payment.
              </Typography>
              <span style={{ fontWeight: 'bold' }}>
                {this.state.showLoader
                  ? 'Processing....'
                  : 'This cannot be undone.'}
              </span>
              {this.state.showLoader ? (
                <this.loader />
              ) : (
                <div
                  style={{
                    alignItems: 'center',
                    border: '1px solid black',
                    borderColor: '#fff',
                    paddingVertical: 10,
                    marginTop: 5,
                  }}
                >
                  
                  <AvatarDisplay
                    imgSrc={(
                      this.state.user_global_object[
                        (
                          this.state.payments_received_obj?.[
                            this.state.ptid_selected_to_refund
                          ] || {}
                        )?.from
                      ] || {}
                    )?.profile_photo_url}
                    imgAlt={'user-profile-for-refund'}
                    imgStyles={{
                      width: 40,
                      height: 40,
                      borderRadius: 20,
                    }}
                    userName={this.state.user_global_object?.[
                      this.state.payments_received_obj?.[
                        this.state.ptid_selected_to_refund
                      ]?.from
                    ]?.fullname}
                    textStyles={{
                      ...GlobalStyles.bodyMedium,
                      color: 'rgb(23, 155, 247)',
                      textAlign: 'center',
                      borderRadius: '50px',
                      border: '2px solid',
                      display: 'flex',
                      alignItems: 'center',
                      height: '30px',
                      width: '30px',
                      justifyContent: 'center'
                    }}
              />  
                  <Typography
                    style={{ textAlign: 'center', fontWeight: 'bold' }}
                  >
                    {
                      (
                        this.state.payments_received_obj[
                          this.state.ptid_selected_to_refund
                        ] || {}
                      ).profit
                    }
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 14,
                      color: '#fff',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    {`${
                      (
                        (
                          this.state.payments_received_obj[
                            this.state.ptid_selected_to_view_detail
                          ] || {}
                        ).class_state_snapshot || {}
                      ).title || ''
                    }`}
                  </Typography>
                  <Typography style={{ fontSize: 14, color: '#fff' }}>
                    From:{' '}
                    {
                      (
                        this.state.user_global_object[
                          (
                            this.state.payments_received_obj[
                              this.state.ptid_selected_to_refund
                            ] || {}
                          ).from
                        ] || {}
                      ).fullname
                    }
                  </Typography>
                  <Typography style={{ fontSize: 14, color: '#fff' }}>
                    For:{' '}
                    {
                      (
                        this.state.payments_received_obj[
                          this.state.ptid_selected_to_refund
                        ] || {}
                      ).type
                    }
                  </Typography>
                </div>
              )}
            </div>
            <div
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 20,
                display: 'flex',
              }}
            >
              <ButtonComponent
                value="Cancel"
                buttonStyle={{
                  backgroundColor: Colors.sec_color,
                  border: '1px solid #fff',
                  padding: '15px',
                  borderRadius: '8px',
                  color: Colors.white,
                  marginRight: '10px',
                  cursor: this.state.showLoader ? 'not-allowed' : 'pointer',
                  opacity: this.state.showLoader ? '.3' : '1.0',
                }}
                onClick={() => {
                  if (!this.state.showLoader) {
                    this.setState({ openRefundModal: false });
                  }
                }}
              />
              <ButtonComponent
                value="Issue Refund"
                buttonStyle={{
                  border: '1px solid #fff',
                  padding: '15px',
                  borderRadius: '8px',
                  backgroundColor: Colors.white,
                  color: Colors.pri_color,
                }}
                onClick={() => {
                  this.setState({ showLoader: true }, async () => {
                    //kick off attempt
                    //save results and update the ptransaction if it was successfull
                    const ptid = this.state.ptid_selected_to_refund;
                    if (ptid) {
                      //we can proceed send ptid to server to make charge. await response. first determine url

                      let targetURL =
                        'https://us-central1-encoded-ensign-158201.cloudfunctions.net/refundtxn/';
                      const isProduction = !UtilFX.getIsDevEnv();
                      if (isProduction) {
                        targetURL =
                          'https://us-central1-favyogis-prod.cloudfunctions.net/refundtxn/';
                      }

                      try {
                        // var ptid_encoded = encodeURIComponent(ptid)
                        console.log('the ptid:', ptid);
                        //alertURL+=_msg

                        //targetURL+=ptid_encoded

                        const refund_resp = await fetch(targetURL, {
                          method: 'POST',
                          headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            ptid: ptid,
                          }),
                        });
                        //read the response, and see what it is. pending/paid/failed
                        const refund_resp_json = await refund_resp.json();

                        console.log('resp from ref attempt', refund_resp_json);

                        let result_msg = '';
                        let is_sux;
                        //just tweak the confirmation message
                        if (refund_resp_json.status === 'refunded') {
                          is_sux = true;
                          result_msg = 'Successfully completed refund.';
                          this._fetchAndSetData();
                        } else if (
                          refund_resp_json.status === 'unknown' ||
                          refund_resp_json.status === 'error'
                        ) {
                          result_msg =
                            refund_resp_json.status.message ||
                            'There was a problem issuing this refund.';
                        }

                        //hide loader
                        // loaderHandler.hideLoader();

                        if (is_sux) {
                          // alert('✅  Success', result_msg);
                          UtilFX.toastSuccess(`✅  Success, ${result_msg}`, {
                            toastId: 'refund payment successfull',
                          });
                        } else {
                          // alert('🚫 Error', result_msg);
                          UtilFX.toastError(`🚫  Error, ${result_msg}`, {
                            toastId: 'Error refunding',
                          });
                        }
                      } catch (err) {
                        // loaderHandler.hideLoader();
                        console.warn(
                          'error processing this txn',
                          err.toString()
                        );
                        // alert(
                        //   'Error',
                        //   'There was an error processing payments. Please try again or contact help@favyogis.com asap.',
                        // );
                        UtilFX.toastError(
                          'Error, There was an error processing payments. Please try again or contact help@favyogis.com asap.',
                          { toastId: 'Error refunding payment process' }
                        );
                      }
                    } else {
                      //there was a problem, we can show error or call failure and hide loader
                      // loaderHandler.hideLoader();
                      // alert(
                      //   'Alert',
                      //   'There was a problem starting this refund process. No transaction id was found. Please try again or contact help@favyogis.com asap.',
                      // );
                      UtilFX.toastError(
                        'Alert, There was a problem starting this refund process. No transaction id was found. Please try again or contact help@favyogis.com asap.',
                        { toastId: 'No transaction id was found' }
                      );
                    }
                    this.setState({
                      showLoader: false,
                      openRefundModal: false,
                    });
                  });
                }}
              />
            </div>
          </div>
        </Modal>
      </Grid>
    );
  }
}
