import React from 'react';
import pinIcon from './svg/pin_icon_grey.svg';

const PinIcon = ({ convo, pinnedConvoIds }) => {

    return (
        <div style={{ marginTop: '4px' }}>
            {(pinnedConvoIds.includes(convo.convo_id)) && (
                <img src={pinIcon} alt="pin-icon" />
            )}
        </div>
    );
};

export default PinIcon;
