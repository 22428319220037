import React, { useState } from 'react';
import FileUploader from 'react-firebase-file-uploader';
import { storage } from '../../fire';
import { DataFX } from '../../utils/index';

const FileUpload = ({ userAuthenticated: user, refreshUserAuthenticated, handleFileUploadProgress }) => {
    const [uploadDetails, setUploadDetails] = useState({ isUploading: false, uploadProgress: 0, progress: 0 });

    const handleUploadStart = () => {
        setUploadDetails({ ...uploadDetails, isUploading: true, uploadProgress: 0 });
        handleFileUploadProgress && handleFileUploadProgress(true);
    };
    const handleProgress = (uploadProgress) => {
        setUploadDetails({ ...uploadDetails, uploadProgress });
    };
    const handleUploadError = (error) => {
        setUploadDetails({ isUploading: false });
        console.error(error);
    };

    const handleUploadSuccess = (filename) => {
        // console.log("incoming filename pre url", filename);
        if (!(user && user.uid)) {
            console.warn('no user and uid found');
            return;
        }
        setUploadDetails({ ...uploadDetails, progress: 100, isUploading: false });
        storage.ref('images')
            .child(filename)
            .getDownloadURL().then(url => {
                // this.setState({ avatarURL: url })
                // console.log("incoming url", url);
                handleFileUploadProgress && handleFileUploadProgress(true);
                DataFX.updateYogiProfileInfo({ uid: user.uid, profile_photo_url: url }).then(() => refreshUserAuthenticated && refreshUserAuthenticated())
                .catch(err => {
                    alert('There was an error uploading your photo:', JSON.stringify(err));
                });
            });
    };

    return (
        <FileUploader
            hidden
            accept="image/*"
            maxWidth={300}
            maxHeight={300}
            filename={(file) =>
                // console.log("file object incoming", file);
                    `profilephoto_${user.uid}`
            } //kept unwanted extension when straight value
            storageRef={storage.ref('images')}
            onUploadStart={handleUploadStart}
            onUploadError={handleUploadError}
            onUploadSuccess={handleUploadSuccess}
            onProgress={handleProgress}
        />
    );
};

export default FileUpload;
