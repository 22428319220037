/**
 * @flow
 */
import React, { Component } from 'react';

import Icon from '@material-ui/core/Icon';

// import { withStyles, withTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import SaveIcon from '@material-ui/icons/Save';
// import MdStarBorder from '@material-ui/icons/StarBorder';
// import MdEmail from '@material-ui/icons/Email';
// import MdLock from '@material-ui/icons/Lock';


// import { SocialIcon } from 'react-social-icons';
// import TextField from '@material-ui/core/TextField';

// import axios from 'axios';
// import moment from 'moment';
import {
    UtilFX,
    Loading,
    // DataFX,
    // Colors,
    // GlobalContext,
    // GlobalStyles,
    // AlertBox,
} from '../utils';
import { firebase } from '../fire';

import * as google_calendar_icon from '../google_calendar_icon.png';

export default class GoogleCalendarConnect extends Component {
    constructor(props) {
        super(props);
        // console.log("props arg-", props);
        const auth_user = props.context && props.context.userAuthenticated; //only works because this is always called by ahigher order component
        this.user_id = auth_user && auth_user.uid;
    }
    state = {
        fyu: {},
        google_api_tokens: {},
        pristine: true,
    };
    componentDidMount() {
        this.mounted = true;
        firebase.database()
            .ref('users/' + this.user_id)
            .on('value', async snap_u => {
                const _u = snap_u.val();
                if (_u) {
                    this.mounted && this.setState({ pristine: false, fyu: _u });
                }
            });
    }
    componentWillUnmount = () => {
        this.mounted = false;
        firebase.database()
            .ref('users/' + this.user_id)
            .off();
    };
    _openLink = () => {
        const { fyu: user, fyu: { appweb_last_load_date, creation_date } } = this.state;
        const isValid = !(isNaN(appweb_last_load_date) || isNaN(creation_date));

        const token = isValid && (appweb_last_load_date - creation_date);
        UtilFX.getIsDevEnv()
        //  && console.log("IS valid token being sent?", token);
        if (isValid && window) {
            window.open(
                [
                    UtilFX.getApiBaseUrl(),
                    'google_calendar_oauth_init',
                    '?action=oauthinit',
                    '&client=web',
                    '&uid=' + this.user_id,
                    '&token=' + token,
                ].join('')
            );
        }

        let _msg = `Attmept to complete to Google Calendar oauth: @${
            (user || {}).username
            } email:${(user || {}).email} uid:${
            this.user_id
            }`;
        UtilFX.triggerAdminAlert(_msg,
            {
                action: 'ATTEMPT_GOOGLE-CALENDAR_CONNECT',
                uid: this.user_id
            }
        );
    }
    _disconnectCalendar = () => {
        const {
            fyu: user,
            // fyu: { uid, appweb_last_load_date, creation_date } 
        } = this.state;
        const isConf = window && window.confirm('Warning: All classes will be removed from your Google calendar.');
        if (isConf) {
            this.setState({ pristine: true });
            //update the DB to activate trigger. if successfully updated update UI here.
            firebase.database()
                .ref('users/' + this.user_id + '/google_api_tokens')
                .update({ delete_date: Date.now() });


            let _msg = `Attmept to DISCONNECT Google Calendar oauth: @${
                (user || {}).username
                } email:${(user || {}).email} uid:${
                this.user_id
                }`;
            UtilFX.triggerAdminAlert(_msg,
                {
                    action: 'ATTEMPT_GOOGLE-CALENDAR_DISCONNECT',
                    uid: this.user_id
                }
            );
        }
    }

    render() {
        const { pristine, fyu: { google_api_tokens = {} } } = this.state;
        // console.log("FY USER", user);
        // console.log("GCAL props", this.props);
        // console.log("GCAL states", this.state);

        // if (pristine || !user || (Object.keys(user || {}).length < 1)) {
        if (pristine || google_api_tokens.delete_date) { //if dleete date there is a trigger that should change this
            return (
                <div>
                    <Loading>
                        {
                            Boolean(google_api_tokens.delete_date)
                                ? <span>Removing...</span>
                                : <span>Loading...</span>
                        }
                    </Loading>
                </div>
            );
        }

        return (
            <div style={{ justifyContent: 'center', alignItems: 'center' }}>
                {
                    Boolean(Object.keys(google_api_tokens).length)
                        ?
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ marginBottom: 20 }}>
                                <Typography>Your schedule is currently connected and synced with Google Calendar.</Typography>
                                <Icon size={30}>sync</Icon>
                            </div>

                            <Button
                                style={{
                                    backgroundColor: '#f00'
                                }}
                                onClick={this._disconnectCalendar}
                            >
                                <Icon>sync_disabled</Icon>
                                Click to disconnect
                            </Button>
                        </div>
                        :
                        <div style={{ textAlign: 'center' }}>
                            <Typography>Your schedule is not synced with Google Calendar.</Typography>
                            {/* <div style={{ display: "flex", marginVertical: 20, flexDirection: "row", justifyContent: "center", }}>
                                <Icon size={30}>calendar_today</Icon>
                                <Icon size={30}>sync</Icon>

                            </div> */}
                            <div
                            // style={{ display: "flex", flex: 0, backgroundColor: "#fff", padding: 5, borderRadius: 10 }}
                            >
                                <img
                                    height={32}
                                    alt="Google calendar icon and brand logo"
                                    src={google_calendar_icon}
                                />
                            </div>
                            <Button
                                onClick={this._openLink}
                            >
                                <Icon>sync</Icon>
                                Click to connect & sync
                            </Button>
                        </div>
                }
            </div>
        );
    }

}
