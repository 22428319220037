import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import { GoogleApiWrapper } from 'google-maps-react';
import moment from 'moment';
// import moment from 'moment-timezone/builds/moment-timezone-with-data';
import ClassesEdit, { CLASS_MODE_VIEW, CLASS_MODE_CREATE } from '../ClassesEdit';
import {
    UtilFX,
 Loading
} from '../../utils';
import { firebase } from '../../fire';

const timeKey = (value) => {
    const timeKeyValueFormat = moment(value).format('HH:mm');
    const timeKeyValue = timeKeyValueFormat.replace(':', '.');
    return timeKeyValue;
};

const replacePublicWithCommunity = (classType) => {
    let newClassType = classType;
    if (newClassType === 'public') {
        newClassType = 'community';
    }
    return newClassType;
};

class ClassesEditContainer extends Component {
    constructor(props) {
        super(props);
        this.auth_user = this.props.context.userAuthenticated;
        this.fyUser = null;
    }

    componentDidMount() {
        this._mounted = true;
        let cRef;
        const { cid } = (this.props && (this.props.computedMatch || this.props.match || {}).params) || {};
        const { ctcid, goto_studentsregistered } = this.props.history.location.state || {};
        if (this.auth_user) {
            UtilFX.getFYuser(this.auth_user.uid).then(fyu => {
                this.fyUser = fyu;
                if (
                    cid || ctcid
                ) {
                    this.classDBRefString = `classesbyuserid/${this.props.context.user.uid}/${cid || ctcid}`;
                    cRef = firebase.database().ref(this.classDBRefString);

                    cRef.on('value', snap => {
                        const cc = snap.val();
                        const starting_state_obj = {
                            cid: cid || null, //ctcid should make it null, since its duplicate not edit
                            uid: this.auth_user.uid,
                            title: cc.title,
                            description: cc.description,
                            show_desc_ui: !!cc.description,
                            when_onetime_start: cc.start,
                            when_onetime_end: cc.end,
                            location: cc.location || null,
                            status: cc.status === 'sub' ? '2' : cc.status === 'cancel' ? '1' : '0',
                            when_onetime: true,
                            quantity: cc.quantity,
                            price: cc.price,
                            price_billing_cycle: cc.price_billing_cycle,
                            fees_absorb: cc.fees_absorb,
                            private: cc.private,
                            recurrence_rule: cc.recurrence_rule,
                            start_timestamp: cc.start_timestamp,
                            time_key: timeKey(cc.start_timestamp),
                            mode: cid ? CLASS_MODE_VIEW : CLASS_MODE_CREATE,
                            render_class_data: cid ? '' : 'description',
                            copy_class: !cid,
                            time: cc.start_timestamp,
                            date_created: cc.date_created,
                            date: cc.start_timestamp,
                            duration: cc.duration,
                            virtual_link: cc.virtual_link || null,
                            hideOrShowLink: !!cc.virtual_link,
                            timeZone: cc.time_zone || null,
                            class_type: replacePublicWithCommunity(cc.class_type),
                            paused: cc.paused,
                            personal_registration_uid: ctcid ? null : cc.personal_registration_uid, //only get existing reg uid if you are not copying a class. this otherwise prevents price from being updateable.
                            previewClass: false,
                            isAuthenticated: true
                        };
                        if (this._mounted) {
                            this.setState(starting_state_obj, () => {
                                this.original_class_state = this.state; //set original state after loading original class others it is what it was
                                if (this.state.cid) {
                                    UtilFX.getRegisteredStudentsForATeacherClassOrEvent({
                                        class_state: this.state,
                                        teacherid: this.auth_user.uid,
                                    }).then(reg_vals => {
                                        const rg_unique_student_list_obj = {};
                                        Object.values(reg_vals || {}).forEach(rv => {
                                            rg_unique_student_list_obj[rv.studentid] = rv;
                                        });
                                        const reg_vals_arr = Object.values(rg_unique_student_list_obj);
                                        this.setState({
                                            rsvp_arr: reg_vals_arr
                                        }, async () => {
                                            // if personal reg id exists and there are rsvps (should always be in sync) [then retrieve that single student to display info]
                                            if (this.state.personal_registration_uid && reg_vals_arr && reg_vals_arr.length) {
                                                const personal_student = await UtilFX.getFYuser(this.state.personal_registration_uid);
                                                this.setState({
                                                    personal_registration_user_object: personal_student
                                                });
                                            }
                                        });
                                    });
                                }

                                if (goto_studentsregistered) {
                                    this.props.history.push('studentsregistered', {
                                        class_state: this.state
                                    });
                                }
                            });
                        }
                    });
                }

                //check to see if this is a first time class-adder, if so setstate has_never_added_class_before. this determines whether or not to shwo congrats prop after save

                firebase.database()
                    .ref('classesbyuserid/' + this.auth_user.uid)
                    .orderByChild('date_deleted')
                    .endAt(true)
                    .once('value')
                    .then(snap => {
                        const evs = snap.val();
                        const activePersonalClassCount = Object.values(evs || {})
                            .filter(v => (!v.date_deleted && v.class_type === 'personal'))
                            .length;

                        let has_never_added_class_before = false;

                        if (!fyu.classes && !evs) {
                            has_never_added_class_before = true;
                        }
                        if (this._mounted) { this.setState({ activePersonalClassCount, has_never_added_class_before }); }
                    });
            });
        } else {
            this.setState({ isAuthenticated: false });
        }
    }

    render() {
        return this.state ? <ClassesEdit {...this.props} fyUser={this.fyUser} classData={this.state} /> : <Loading />;
    }
}
export default GoogleApiWrapper({
    apiKey: ('AIzaSyBon9Qo0FRSuJLHUD2IP2WVc2p2oq0rxb4')
})(withRouter(ClassesEditContainer));
