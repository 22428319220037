import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {
    Colors,
    GlobalStyles,
  } from '../../../utils';
import ClearCard from '../ClearCard';
import ImgCancelChat from '../../../cancel_chat.svg';
import ButtonComponent from '../ButtonComponent';

const FirstTimeChatVisitModal = ({ closeModal, OnClick }) => {
    return (
        <Grid container className='custom-modal'>
              <Grid item xs={12} md={12} className="custom-modal-wrapper" style={{
                margin: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}>
                <div style={{
                  width: '416px',
                  margin: 'auto',
                  borderRadius: '10px',
                  backgroundColor: Colors.white,
                }}>
                  <ClearCard cancelEvent={closeModal}/>
                  <img src={ImgCancelChat} style={{ marginTop: '63px' }}
                  alt='cta-share-a-class' />
                  <Typography style={{
                    ...GlobalStyles.titleBold,
                    color: Colors.grey1,
                    marginTop: '30px',
                    fontSize: '30px'
                  }}>
                    Cancel request
                  </Typography>
                  <Typography style={{
 ...GlobalStyles.bodyRegular,
color: Colors.grey1,
                    marginTop: '16px'
                  }}>
                    You can always try to join in the future.
                  </Typography>
                  <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', marginTop: '36px', marginBottom: '40px' }}>
                    <ButtonComponent value= 'Don’t cancel'
                        buttonStyle={{
                            backgroundColor: Colors.blueOnlineClass,
                            color: Colors.pri_color,
                            borderRadius: '12px',
                            marginRight: '15px',
                            border: 0,
                            width: '154px'
                        }}
                        onClick={closeModal}
                    />
                    <ButtonComponent value='Cancel request'
                        buttonStyle={{
                            backgroundColor: Colors.pri_color,
                            color: Colors.white,
                            borderRadius: '12px',
                            border: 0,
                            width: '154px'
                        }}
                        onClick={OnClick}
                    />
                  </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default FirstTimeChatVisitModal;
