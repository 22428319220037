import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    StripeProvider,
    CardElement,
    Elements,
    injectStripe,
} from 'react-stripe-elements';

import {
    UtilFX,
    Colors,
    // GlobalContext,
    GlobalStyles,
    AlertBox,
    Loading,
    // DataFX,
} from '../utils/index.js';
import {
    // auth,
    database,
    firebase,
    // analytics,
} from '../fire';

import {
    Typography,
    Modal,
    Icon,
    Button,
} from '@material-ui/core/';
import AvatarDisplay from './AvatarDisplay.jsx';


export class UpgradeConfirmation extends Component {
    // componentDidMount(){
    //     this._mounted = true;
    //     const { amount, description, isDonation } = this.props || {};
    //     const { payorUser } = this.props || {};
    // }

    setPaymentOption = (po) => {
        this.setState({ paymentOption: po });
    }


    handlePaymentRequest = async () => {
        const { payorUser, yearlyCycle, amount, showSuccessMessage, handleClosePayModal } = this.props;
        const { paymentOption: valid_card, } = this.state || {};
        if (!(payorUser && payorUser.uid)) {
            console.warn('no valid payorUser');
            return;
        }
        if (!valid_card || valid_card.is_expired) {
            window && window.alert(
                'Invalid Card: Please add a valid payment option to proceed with confirmation.'
            );
            return;
        }
        this.setState({ isProcessingPayment: true });
        const updateData = {
            user_id: payorUser.uid,
            amount: amount,
            billing_cycle: yearlyCycle ? 'year' : 'month',
            date_created: firebase.database.ServerValue.TIMESTAMP,
        };
        try {
            await firebase.database()
                .ref('receipts/' + payorUser.uid)
                .update({
                    type: 'stripe',
                    status: 'pending',
                    downgrade: null,
                    receipt_data: {
                        // data: sub_resp && sub_resp.receiptData,
                        // signature: sub_resp && sub_resp.receiptSignature,
                        ...updateData
                    },
                    product_id: 'com.favyogis.pro_teacher',
                    last_update_date: firebase.database.ServerValue.TIMESTAMP,
                    purchase_date: firebase.database.ServerValue.TIMESTAMP,
                });
            showSuccessMessage();
            handleClosePayModal();
        } catch (err) {
            this.setState({ isProcessingPayment: false });
            console.warn('error processing this txn', JSON.stringify(err));
            window && window.alert(
                'Error: There was an error processing payments. Please try again, possibly with a different payment option, or contact help@favyogis.com asap.'
            );
        }
    }

    render() {
        const { paymentOption, isProcessingPayment } = this.state || {};
        const { payorUser, amount, handleClosePayModal, yearlyCycle } = this.props;
        if (!payorUser) {
            return (<div><AlertBox>No paying user found.</AlertBox></div>);
        }
        return (
            <div style={{
                padding: 20,
                margin: 20,
                backgroundColor: Colors.pri_color,
                color: '#fff',
                borderRadius: 10,
                textAlign: 'center',
                overflow: 'scroll',
                maxHeight: '90vh',
                // backgroundColor: "#fff",
                // color: Colors.pri_color
            }}>

                <Typography color="inherit" variant="subtitle1">
                    Confirm Payment
            </Typography>
                {
                    isProcessingPayment ?
                        <Loading><Typography>Processing Payment</Typography></Loading>
                        :
                        <Typography>TeacherPro Upgrade Plan</Typography>
                }
                {
                    Boolean(amount !== 0)
                        ?
                        <Typography color="inherit">{UtilFX.getValueAsCurrency(amount || 0)}{` / ${yearlyCycle ? 'yearly' : 'monthly'}`}</Typography>
                        :
                        <Typography color="inherit">----</Typography>
                }
                <PayOptionWidget {...this.props} setPaymentOption={this.setPaymentOption} />
                {
                    paymentOption &&
                    <Button
                        fullWidth={true}
                        style={{
                            margin: '10px 5px',
                        }}
                        onClick={() => {
                            this.handlePaymentRequest();
                        }}>
                        Confirm
                    </Button>
                }
                <Typography
                    className="touchable-element"
                    variant="caption"
                    color="inherit"
                    style={{
                        margin: '30px 5px 5px 5px',
                        textDecorationLine: 'underline'
                    }}
                    onClick={() => {
                        handleClosePayModal && handleClosePayModal();
                    }}
                >
                    Cancel
                </Typography>
            </div>)
    }
}
export class PayConfirmation extends Component {
    state = {
        recipientUser: {},
        paymentOption: null,
        isProcessingPayment: false,
        showCustomAmountInput: false,
        selectedDonationItem: null,
    };
    componentDidMount() {
        this._mounted = true;
        const { recipientId = '', amount, description, isDonation } = this.props || {};
        const { context: { userAuthenticated: payorUser } = {} } = this.props || {};
        // console.log("props incoming", this.props);
        if (payorUser && payorUser.uid) {
            // console.log("payor incoming", payorUser);
            this.setState({
                payorUser,
                amount,
                description,
                isDonation,
            }, () => {
                if (recipientId.length) {
                    UtilFX.getFYuser(recipientId).then((recipientUser) => {
                        this.setState({
                            recipientUser,
                        });
                    });
                }
            })
        } else {
            console.warn('no recipient or payor ids', this.props);
        }

    }
    componentWillUnmount() {
        this._mounted = false;
    }
    setPaymentOption = (po) => {
        this.setState({ paymentOption: po });
    }
    handlePaymentRequest = async () => {
        const { paymentOption: valid_card, payorUser, amount, recipientUser } = this.state || {};
        const { classInfo, noteToRecipient, finishRegistrationPostPayment, isRegistered, handleDonationError, handleDonationSuccess, isDonation } = this.props || {};
        const isPersonal = !isDonation && classInfo.class_type === 'personal';
        const isRecurring = !isDonation && Boolean(classInfo.recurrence_rule);
        let price_billing_cycle_description = '--NA--';
        if (!isDonation) {
            if (classInfo.price_billing_cycle === 'weekly') {
                price_billing_cycle_description = 'every week';
            }
            if (classInfo.price_billing_cycle === 'biweekly') {
                price_billing_cycle_description = 'every 2 weeks';
            }
            if (classInfo.price_billing_cycle === 'monthly') {
                price_billing_cycle_description = 'every month';
            }
        }
        if (!(payorUser && payorUser.uid)) {
            console.warn('no valid payorUser');
            return;
        }

        if (classInfo && classInfo.cid && isRegistered) {
            window && window.alert('You are already successfully RSVP\'d for this class');
            return;
        }

        if (!valid_card || valid_card.is_expired) {
            window && window.alert(
                'Invalid Card: Please add a valid payment option to proceed with confirmation.'
            );
            return;
        }

        if (amount < 3) {
            window && window.alert('There is a $3 minimum requirement for all payments.');
            return;
        }

        if (isPersonal && isRecurring) {
            const isOkayToAutoPay = window && window.confirm(`This payment will repeat ${price_billing_cycle_description} starting from today. You may pause payment at any time.`);
            if (!isOkayToAutoPay) {
                return;
            }
        }

        // loaderHandler.showLoader("Processing confirmation...");
        this.setState({ isProcessingPayment: true })

        // console.info(
        //     "Start the process---first update db, then send url request and WAIT for a response. it starts off as pending"
        // );

        //class_state is at least optional in case there is more data to display and save, but not integral to display of confirmation (must be flexible enough for donations/tips)

        //first update db, then send url request and WAIT for a response. it starts off as pending
        //if the teacher isnt sestup then notify user in the success message that the charge may come later

        //STORE: fee_amount, price_total, fees_absorb?, to, from, type, reason, class_state_snapshot?, paymentoptionid?,date_created, status:"pending",
        let updateData = {
            to: recipientUser.uid,
            from: payorUser.uid,
            price_total: amount,
            status: 'untouched',
            date_created: firebase.database.ServerValue.TIMESTAMP
        };

        if (!isDonation && classInfo) {
            updateData.fees_absorb = !!classInfo.fees_absorb;
            updateData.fee_amount = UtilFX.getFeeCalculation({
                price: classInfo.price,
                fees_absorb: classInfo.fees_absorb,
                teacher: recipientUser
            });
            updateData.class_state_snapshot = classInfo;
            updateData.classid = classInfo.cid;
            updateData.type = 'class';
        } else {
            //donation
            updateData.fee_amount = UtilFX.getFeeCalculation({
                price: amount,
                fees_absorb: true, // true for donation,no choice, student picked max they would pay
                teacher: recipientUser
            });
            updateData.note_donation = noteToRecipient;
            updateData.type = 'donation';
        }
        let ptid = await database
            .ref('paymenttransactions/')
            .push(updateData).key;
        //add registration records

        if (ptid) {
            //we can proceed send ptid to server to make charge. await response. first determine url

            let targetURL =
                'https://us-central1-encoded-ensign-158201.cloudfunctions.net/processtxn/';
            if (!UtilFX.getIsDevEnv()) {
                targetURL =
                    'https://us-central1-favyogis-prod.cloudfunctions.net/processtxn/';
            }
            console.info("before ptID value", ptid);
            try {
                // var ptid_encoded = encodeURIComponent(ptid)
                UtilFX.getIsDevEnv() && console.log("the ptid:", ptid);
                //alertURL+=_msg

                //targetURL+=ptid_encoded

                var pt_resp = await fetch(targetURL, {
                    method: 'POST',
                    // mode: "no-cors",
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ptid: ptid
                    })
                });
                //read the response, and see what it is. pending/paid/failed
                console.info("after ptID value",pt_resp);
                UtilFX.getIsDevEnv() && console.info('The response raw', pt_resp);
                let pt_resp_json = await pt_resp.json();

                let result_msg = '';
                let result_json;
                let is_sux;
                //just tweak the confirmation message
                if (pt_resp_json.status === 'paid') {
                    result_msg = 'Successfully completed payment.';
                } else if (pt_resp_json.status === 'pending') {
                    result_msg =
                        'Payment completion is pending teacher action.';
                } else if (pt_resp_json.status === 'failed') {
                    //if failed, let them know payment failed and confirmation cannot be completed as such
                    result_msg = 'Payment failed.';
                } else {
                    result_msg = 'There was a problem processing your payment.';
                }

                //if pending or paid, go ahead and set the registration and navigate them back, just tweak the confirmation message
                if (
                    pt_resp_json.status === 'paid' ||
                    pt_resp_json.status === 'pending'
                ) {
                    is_sux = true;
                    result_msg += 'Your confirmation has been successful!';

                }

                //hide loader
                // loaderHandler.hideLoader();
                this.setState({ isProcessingPayment: false });

                result_json = pt_resp_json;
                this.setState(
                    {
                        result_msg,
                        result_json,
                    },
                    () => {
                        //depending on state show success, and alert. Navigate them back, or show a modal that forces them to Okay and navigate back
                        if (is_sux) {
                            // window && window.alert(`✅ Success: ${result_msg}`);
                            // Analytics.logEvent("ecommerce_purchase", {
                            //     value: UtilFX.getFeeCalculation(
                            //         this.state.price_total,
                            //         true
                            //     )
                            // });
                            // Analytics.logEvent("paymentconfirmation_success", {});
                            if (classInfo) {
                                //if this came from class reg, then make sure it gets set
                                finishRegistrationPostPayment({ success: true, paymentTransactionId: ptid, priceTotal: amount, result_msg, result_json });
                            } else if (isDonation) {
                                // window && window.alert(`✅ Success: ${result_msg}`);
                                handleDonationSuccess && handleDonationSuccess({ result_msg });
                            }

                            // this.props.navigation.goBack(null);
                        } else {
                            // window && window.alert(`🚫 Error: ${result_msg}`);
                            // Analytics.logEvent("paymentconfirmation_failure", {});
                            if (classInfo) {
                                //if this came from class reg, then make sure it gets set
                                finishRegistrationPostPayment({ success: false, paymentTransactionId: ptid, priceTotal: amount, result_msg, result_json });
                            } else if (isDonation) {
                                handleDonationError && handleDonationError({ result_msg });
                            }
                        }
                    }
                );
            } catch (err) {
                // loaderHandler.hideLoader();
                this.setState({ isProcessingPayment: false });
                console.warn('error processing this txn', err.toString());
                window && window.alert(
                    'Error: There was an error processing payments. Please try again, possibly with a different payment option, or contact help@favyogis.com asap.'
                );
                // Analytics.logEvent("paymentconfirmation_error", {});
            }
        } else {
            //there was a problem, we can show error or call failure and hide loader
            // loaderHandler.hideLoader();
            this.setState({ isProcessingPayment: false });
            window && window.alert(
                'Alert: There was a problem starting this transaction process. Please try again or contact help@favyogis.com.'
            );
            // MessageBarManager.showAlert({
            //           title: '😮 Oops!',
            //           message: 'Confirmation failed.',//err_msg,
            //           alertType: 'error',
            //           position:'top',
            //           duration:5000,
            //           // See Properties section for full customization
            // });
        }
        //make fetch request to proper url about this transaction to make a charge attempt and get a response

        //AFTER DONE DISPLAY MESSAGE AND GO BACK...OR JUST GO BACK AND THE MESSAGE DISPLAYED IS UPDATED
        // MessageBarManager.showAlert({
        //            title: 'SUCCESS!',
        //            message: 'Confirmation complete.',//err_msg,
        //            alertType: 'warning',
        //            position:'top',
        //            duration:5000,
        //            // See Properties section for full customization
        //    });

    }
    render() {
        const { payorUser, amount, recipientUser, description, paymentOption, isProcessingPayment, selectedDonationItem, showCustomAmountInput } = this.state || {};
        const { handleClosePayModal, isDonation, classInfo } = this.props || {};
        const { class_type, recurrence_rule, price_billing_cycle } = classInfo || {};
        const isPersonal = class_type === 'personal';
        const isRecurring = Boolean(recurrence_rule);
        if (!payorUser) {
            return (<div><AlertBox>No paying user found.</AlertBox></div>);
        }

        return (
            <div style={{
                padding: 20,
                margin: 20,
                backgroundColor: Colors.pri_color,
                color: '#fff',
                borderRadius: 10,
                textAlign: 'center',
                overflow: 'scroll',
                maxHeight: '90vh',
                // backgroundColor: "#fff",
                // color: Colors.pri_color
            }}>

                <Typography color="inherit" variant="headline">
                    Confirm Payment
                </Typography>
                {
                    isProcessingPayment ?
                        <Loading><Typography>Processing Payment</Typography></Loading>
                        :
                        <div style={{ padding: 20, }}>
                            <Typography color="inherit">To: {recipientUser.fullname}</Typography>
                            
                            <AvatarDisplay
                              imgSrc={recipientUser?.profile_photo_url}
                              imgAlt={''}
                              imgClassName={'yogi-photo'}
                              imgStyles={{
                                borderRadius: '50%',
                                height: 50,
                                width: 50,
                                border: '2px white solid',
                                position: 'relative',
                            }}
                              userName={recipientUser?.fullname}
                              textStyles={{
                                ...GlobalStyles.bodyMedium,
                                color: 'rgb(23, 155, 247)',
                                textAlign: 'center',
                                borderRadius: '50px',
                                border: '2px solid',
                                display: 'flex',
                                alignItems: 'center',
                                height: '30px',
                                width: '30px',
                                justifyContent: 'center',
                                margin: 'auto',
                              }}
                            />
                            
                            <Typography color="inherit">For: {description}</Typography>
                            {
                                Boolean(isDonation)
                                && Boolean(showCustomAmountInput)
                                &&
                                <div>
                                    <div style={GlobalStyles.textInputContainer}>
                                        <input
                                            id="formCustomInputAmt"
                                            placeholder="$0"
                                            style={{ ...GlobalStyles.textInputDefault, textAlign: 'center' }}
                                            // type="number"
                                            name="amt_custom"
                                            value={
                                                (amount ? '$' : '') +
                                                (amount || '')
                                            }
                                            onChange={({ target: { value } }) => {
                                                // console.log("incomint text", value);
                                                let newVal = Number(value.replace(/[^0-9]/g, ''));
                                                if (isNaN(newVal)) {
                                                    newVal = 0;
                                                }
                                                this.setState({ amount: newVal });
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                Boolean(isDonation) && !Boolean(showCustomAmountInput)
                                &&
                                <div
                                    style={{
                                        // borderTop: "1px white solid",
                                        // borderBottom: "1px white solid",
                                        margin: 5,
                                        padding: 5,
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            //margin: 5,
                                            justifyContent: 'center',
                                            paddingBottom: 5,
                                        }}
                                    >
                                        <div
                                            className="donate-preset-amount"
                                            style={{
                                                backgroundColor: (selectedDonationItem === 1) ? Colors.sec_color : 'transparent',
                                            }}
                                            onClick={() => {
                                                this.setState({
                                                    selectedDonationItem: 1,
                                                    amount: 3.00,
                                                })
                                            }}
                                        >
                                            <Typography variant="headline"><small className="donate-preset-amount__currency-marker">$</small>3</Typography>
                                        </div>
                                        <div
                                            className="donate-preset-amount"
                                            style={{
                                                backgroundColor: (selectedDonationItem === 2) ? Colors.sec_color : 'transparent',
                                            }}
                                            onClick={() => {
                                                this.setState({
                                                    selectedDonationItem: 2,
                                                    amount: 6.00,
                                                })
                                            }}
                                        >
                                            <Typography variant="headline"><small className="donate-preset-amount__currency-marker">$</small>6</Typography>
                                        </div>
                                        <div
                                            className="donate-preset-amount"
                                            style={{
                                                backgroundColor: (selectedDonationItem === 3) ? Colors.sec_color : 'transparent',
                                            }}
                                            onClick={() => {
                                                this.setState({
                                                    selectedDonationItem: 3,
                                                    amount: 12.00,
                                                })
                                            }}
                                        >
                                            <Typography variant="headline"><small className="donate-preset-amount__currency-marker">$</small>12</Typography>
                                        </div>
                                    </div>
                                    <Typography
                                        color="inherit"
                                        onClick={() => {
                                            this.setState({
                                                showCustomAmountInput: true,
                                            });
                                        }}
                                        variant="caption"
                                        style={{
                                            fontSize: 'small',
                                            textDecorationLine: 'underline',
                                            cursor: 'pointer',
                                        }}>custom</Typography>
                                </div>

                            }
                            {
                                Boolean(amount !== 0)
                                    ?
                                    <Typography color="inherit">{UtilFX.getValueAsCurrency(amount || 0)}{isPersonal && isRecurring && ` / ${price_billing_cycle}`}</Typography>
                                    :
                                    <Typography color="inherit">----</Typography>
                            }
                            {/*
                                <Typography color="inherit" paragraph gutterBottom={true} variant="caption">
                                    This is a recurring subscription. You can pause or resume billing for this subscription at any time.
                                </Typography>
                            */}
                            <PayOptionWidget {...this.props} setPaymentOption={this.setPaymentOption} />
                            {
                                paymentOption &&
                                <Button
                                    fullWidth={true}
                                    style={{
                                        margin: '10px 5px',
                                    }}
                                    onClick={() => {
                                        this.handlePaymentRequest();
                                    }}>
                                    Confirm
                        </Button>
                            }
                            <Typography
                                className="touchable-element"
                                variant="caption"
                                color="inherit"
                                style={{
                                    margin: '30px 5px 5px 5px',
                                    textDecorationLine: 'underline'
                                }}
                                onClick={() => {
                                    handleClosePayModal && handleClosePayModal();
                                }}>
                                Cancel
                            </Typography>
                        </div>
                }
            </div>
        );
    }
}

PayConfirmation.propTypes = {
    recipientId: PropTypes.string.isRequired,
    // payorId: PropTypes.string.isRequired,
    isDonation: PropTypes.bool,
    isRegistered: PropTypes.bool.isRequired,
    amount: PropTypes.number.isRequired,
    classInfo: PropTypes.shape({
        cid: PropTypes.string,
    }),
    noteToRecipient: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
}

PayConfirmation.defaultProps = {
    isRegistered: false,
    recipientId: '',
    amount: 0,
    noteToRecipient: '',
    description: '',
}

class _CardForm extends React.Component {
    state = {
        errorMessage: '',
    }

    _savePaymentTokenInfo = async ({ stripeToken }) => {
        const { context: { userAuthenticated } = {} } = this.props || {};
        const { payorUser } = this.props;
        const { card: { last4, exp_month, exp_year, brand } = {} } = stripeToken || {};
        try {
            // Client specific code
            const newpoid = await database
                .ref(`paymentoptions/${(userAuthenticated && userAuthenticated.uid) || (payorUser && payorUser.uid)}/`)
                .push({
                    cardnum_lastfour: last4,
                    expMonth: exp_month,
                    expYear: (exp_year > 2000) ? Number(String(exp_year).slice(-2)) : exp_year,
                    cardbrand: brand,
                    date_created: firebase.database.ServerValue.TIMESTAMP,
                    stripe_token: stripeToken
                }).key;

            console.info('NEW POID yabish', newpoid);
            if (this.props.updateDefaultCard) {
                this.props.updateDefaultCard(newpoid);
            }
            if (this.props.onComplete) {
                this.props.onComplete(newpoid);
            }
            // MessageBarManager.showAlert({
            //     title: "SUCCESS!",
            //     message: "Card info saved.", //err_msg,
            //     alertType: "warning",
            //     position: "top",
            //     duration: 5000
            //     // See Properties section for full customization
            // });
        } catch (err) {
            //window && window.alert("","There was an error saving your card information. Please try again.")
            window && window.alert('There was an error saving your card information. Please try again.');
            console.warn(err);
            if (this.props.onComplete) {
                this.props.onComplete();
            }
            // MessageBarManager.showAlert({
            //     title: "Error",
            //     message: "Card info could not be saved.", //err_msg,
            //     alertType: "error",
            //     position: "top",
            //     duration: 5000
            //     // See Properties section for full customization
            // });
        }
    }
    handleSubmit = (ev) => {
        ev.preventDefault();
        if (this.props.stripe) {
            this.props.stripe
                .createToken()
                .then((payload) => {
                    // console.log('[token]', payload);
                    const { token } = payload;
                    const tokenFavYogisCustomized = Object.assign({}, token);
                    if (token && token.id) {
                        tokenFavYogisCustomized.tokenId = token.id;
                    }
                    if (token && token.card && token.card.id) {
                        tokenFavYogisCustomized.card.cardId = token.card.id;
                    }
                    //superfluous but just for symmetry in the stored objects
                    if (token && token.tokenId) {
                        tokenFavYogisCustomized.id = token.tokenId;
                    }
                    if (token && token.card && token.card.cardId) {
                        tokenFavYogisCustomized.card.id = token.card.cardId;
                    }
                    if (!token) {
                        console.warn('No token for this payload');
                    }
                    this._savePaymentTokenInfo({ stripeToken: tokenFavYogisCustomized });

                });
        } else {
            // console.log("Stripe.js hasn't loaded yet.");
        }
    };
    handleBlur = () => {
        // console.log('[blur]');
    };
    handleChange = (change) => {
        // console.log('[change]', change);
        const { error } = change;
        if (error) {
            this.setState({ errorMessage: error.message || 'Unknown error processing card.' });
        } else {
            this.setState({ errorMessage: '' })
        }
    };
    handleClick = () => {
        // console.log('[click]');
    };
    handleFocus = () => {
        // console.log('[focus]');
    };
    handleReady = () => {
        // console.log('[ready]');
    };

    createOptions = (fontSize, padding) => {
        return {
            style: {
                base: {
                    fontSize,
                    color: '#424770',
                    letterSpacing: '0.025em',
                    fontFamily: 'Source Code Pro, monospace',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                    padding,
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        };
    };

    render() {
        const { errorMessage } = this.state;
        return (
            <form className="stripe-po" onSubmit={this.handleSubmit}>
                <label className="stripe-po">
                    Card Details
                    <CardElement
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onReady={this.handleReady}
                        {...this.createOptions(this.props.fontSize)}
                    />
                </label>
                {
                    Boolean(errorMessage.length > 0)
                    && <AlertBox>{errorMessage}</AlertBox>
                }
                {/* <button className="stripe-po">Add Card</button> */}
                <Button
                    fullWidth={true}
                    onClick={this.handleSubmit}>
                    Add Card
                </Button>
            </form>
        );
    }
}
const CardForm = injectStripe(_CardForm);
export class PayOptionWidget extends Component {
    state = {
        payorUser: {},
        open: false,
        cardsAll: [],
        stripe: null,
        elementFontSize: (window && window.innerWidth < 450) ? '12px' : '14px',
    };
    constructor() {
        super();
        window && window.addEventListener('resize', () => {
            if (window.innerWidth < 450 && this.state.elementFontSize !== '12px') {
                this.setState({ elementFontSize: '12px' });
            } else if (
                window.innerWidth >= 450 &&
                this.state.elementFontSize !== '14px'
            ) {
                this.setState({ elementFontSize: '14px' });
            }
        });
    }

    componentDidMount() {
        this._mounted = true;
        const stripeApiKey = UtilFX.getIsDevEnv() ? process.env.REACT_APP_DEV_STRIPE_publishablekey : process.env.REACT_APP_PROD_STRIPE_publishablekey;
        if (window.Stripe) {
            this.setState({ stripe: window.Stripe(stripeApiKey) });
        } else {
            const stripeDOM = document.querySelector('#stripe-js');
            stripeDOM && stripeDOM.addEventListener('load', () => {
                // Create Stripe instance once Stripe.js loads
                this.setState({ stripe: window.Stripe(stripeApiKey) });
            });
        }
        const { context: { userAuthenticated } = {}, payorUser } = this.props || {};
        // console.log("props incoming", this.props);
        if ((userAuthenticated && userAuthenticated.uid) || (payorUser && payorUser.uid)) {
            // console.log("payor incoming", payorUser);
            this.setState({ payorUser: payorUser || userAuthenticated }, () => {
                this._updatePaymentOptionData();
            });

        } else {
            console.warn('no payor', this.props);
        }
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    handleOpen = () => {
        // console.log("calling OPEN");
        this.setState({ open: true });
    }

    handleClose = () => {
        // console.log("calling CLOSE");
        this.setState({ open: false });
    }

    _updatePaymentOptionData = () => {
        const { payorUser } = this.state;
        const { setPaymentOption } = this.props || {};
        payorUser.uid && database
            .ref(`paymentoptions/${payorUser.uid}`)
            .once('value', snap => {
                //for each, update
                let card;
                let cardsAll = [];
                snap.forEach(v => {
                    //console.log("po key and pod",v.key + "*"+ poid)
                    const card_val = v.val() || {};
                    let d_now = new Date();
                    let curr_century = Math.trunc(d_now.getFullYear() / 100) * 100;
                    let d_cardexp = new Date();
                    d_cardexp.setYear(curr_century + card_val.expYear); //assuming exp m
                    d_cardexp.setMonth(card_val.expMonth - 1);
                    d_cardexp.setDate(1);
                    d_cardexp.setHours(0, 0, 0);
                    card_val.is_expired = d_cardexp <= d_now;
                    card_val.poid = v.key;
                    !card_val.date_deleted && cardsAll.push(card_val);
                    // console.log("goin thru cards", card_val)
                    if (card_val.is_default && !card_val.date_deleted) {
                        card = card_val;
                    }
                });

                cardsAll = cardsAll.sort((a, b) => {
                    return b.is_default && (b.date_created - a.date_created);
                });
                // console.info("about ot set card as state", card);
                this._mounted && this.setState(
                    {
                        card,
                        cardsAll,
                    },
                    () => {
                        //console.log("the STATE", this.state)
                        setPaymentOption && setPaymentOption(card);
                    }
                );
            });
    };
    _updateDefaultCard = poid => {
        //take this poid and make it the default payment option
        //get all the pos and update each one as is_default:null(true if it matches this poid)
        const { payorUser } = this.state;
        // console.log("the staet", this.state, poid);
        poid && payorUser.uid && database
            .ref(`paymentoptions/${payorUser.uid}`)
            .once('value', snap => {
                //for each, update
                snap.forEach(v => {
                    //console.log("po key and pod",v.key + "*"+ poid)
                    database
                        .ref(`paymentoptions/${payorUser.uid}/${v.key}`)
                        .update({
                            is_default: v.key === poid ? true : null
                        });
                });
            }).then(() => {
                this._updatePaymentOptionData();
                this.handleClose();
            });
    };
    _removeCard = poid => {
        //take this poid and remove it by setting date_deleted and removing default payment option
        //get all the pos and update each one as is_default:null(true if it matches this poid)
        const { payorUser } = this.state;
        poid && payorUser.uid
            && database
                .ref(`paymentoptions/${payorUser.uid}/${poid}`)
                .update({
                    is_default: null,
                    date_deleted: firebase.database.ServerValue.TIMESTAMP
                }).then(() => {
                    this._updatePaymentOptionData();
                });
    };

    renderAPaymentOption = (po) => {
        const v = po;
        return (
            <div
                key={v.poid}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    // borderColor: "#fff",
                    // borderTopWidth: 0,
                    borderBottom: '1px white solid',
                    padding: '15px  10px',
                    backgroundColor: Colors.sec_color,
                }}
            >
                <div>
                    <Typography style={{ fontSize: 14 }}>{`💳 ${(v &&
                        v.stripe_token &&
                        v.stripe_token.card &&
                        v.stripe_token.card.brand) ||
                        v.cardbrand} **** ${v.cardnum_lastfour} `}</Typography>
                    <Typography style={{ fontSize: 12 }}>{`expires: ${v.expMonth}/${
                        v.expYear
                        }`}</Typography>
                    <Typography style={{ fontSize: 12 }}>{`created: ${new Date(
                        v.date_created
                    ).toLocaleDateString()}`}</Typography>
                </div>
                <div style={{ justifyContent: 'center' }}>
                    {v.is_expired ?
                        (
                            <Typography><span className="icon-and-text-wrapper"><Icon style={{ color: 'red', marginRight: 5 }}>cancel</Icon>Expired</span></Typography>
                        ) :
                        v.is_default ? (
                            <div
                                style={{
                                    backgroundColor: '#fff',
                                    borderRadius: 5,
                                    padding: 5
                                }}
                            >
                                <Typography
                                    style={{
                                        color: Colors.sec_color,
                                        fontSize: 12,
                                        textDecorationLine: 'none',
                                        borderColor: '#fff'
                                    }}
                                >
                                    default
                          </Typography>
                            </div>
                        )
                            : (
                                <Button
                                    //raised
                                    // fontSize={12}
                                    // title="set default"
                                    style={{ padding: '10px 5px', margin: '0 5px', }}
                                    onClick={() => {
                                        this._updateDefaultCard(v.poid);
                                    }}
                                ><span style={{ fontSize: 12, fontWeight: 'normal', textTransform: 'none' }}>set default</span></Button>
                            )
                    }
                </div>
                <div style={{ justifyContent: 'center', paddingLeft: 10, }}>
                    <Icon
                        // name="delete-forever"
                        // color="#fff"
                        onClick={() => {
                            //send an alert to confirm deleting of the card and make sure it is not set as default
                            const doDelete = window && window.confirm('Would you like to remove this payment option? You cannot undo this action.')
                            if (doDelete) {
                                // console.warn("about to remove card");
                                this._removeCard(v.poid);
                            }
                        }}
                    >delete</Icon>
                </div>
            </div>
        );
    }

    render() {
        const { payorUser, card: valid_card, cardsAll, elementFontSize } = this.state || {};
        // console.log("the state", this.state);

        if (!payorUser) {
            return (<div><p>No paying user found.</p></div>);
        }

        return (
            <StripeProvider stripe={this.state.stripe}>
                <div>
                    <div
                        style={{
                            color: '#fff',
                            backgroundColor: Colors.pri_color,
                            border: `1px ${'#fff'} solid`,
                            padding: '10px',
                            margin: '10px',
                            borderRadius: 10,
                            cursor: 'pointer',
                        }}
                        onClick={this.handleOpen}
                    >
                        <Typography color="inherit" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Icon>credit_card</Icon><span style={{ padding: 5 }}>{
                                valid_card
                                    ?
                                    `${(valid_card.stripe_token &&
                                        valid_card.stripe_token.card &&
                                        valid_card.stripe_token.card.brand) ||
                                    valid_card.cardbrand} **** ${
                                    valid_card.cardnum_lastfour
                                    }`
                                    :
                                    'Add payment option'
                            }</span>
                        </Typography>
                    </div>
                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        className="center-modal payment-option-modal"
                        open={this.state.open}
                        onClose={() => {
                            this.handleClose();
                        }}
                    >
                        <div style={{ position: 'absolute', ...UtilFX.getModalStyle() }}>
                            <div style={{
                                maxWidth: 400,
                                minWidth: 250,
                                padding: 20,
                                margin: 20,
                                backgroundColor: Colors.pri_color,
                                color: '#fff',
                                borderRadius: 10,
                                textAlign: 'center',
                            }}>
                                <Typography color="inherit" variant="subtitle1" id="modal-title" paragraph={true}>
                                    <span className="icon-and-text-wrapper">
                                        <Icon>lock</Icon>Payment Options
                                    </span>
                                </Typography>
                                {/* <Typography color="inherit" variant="subheading" id="simple-modal-description">
                                    We keep your financial details secure so no one ever sees your sensitive info.
                            </Typography> */}
                                {
                                    Boolean(this.state.isAddingNewCard) &&
                                    <Loading>Adding card as payment option...</Loading>
                                }
                                <Elements>
                                    <CardForm
                                        onComplete={(poid) => {
                                            this.setState({ isAddingNewCard: true }, () => {
                                                setTimeout(() => {
                                                    this.setState({ isAddingNewCard: false })
                                                    this._updateDefaultCard(poid);
                                                    // this.handleClose(); the previous functions handles the closing after setting default
                                                }, 3500);
                                            });
                                        }}
                                        // updateDefaultCard={this._updateDefaultCard} //this was closing ui before mnecessary so new customer lag time for creation was not allowed. handle it in handle close
                                        {...this.props}
                                        fontSize={elementFontSize}
                                    />
                                </Elements>

                                {
                                    Boolean(cardsAll.length > 0)
                                    && (
                                        <div>
                                            <hr />
                                            <div style={{
                                                maxHeight: 150,
                                                overflow: 'scroll'
                                            }}>
                                                {
                                                    cardsAll.map(this.renderAPaymentOption)
                                                }
                                            </div>
                                        </div>)
                                }
                                <hr />
                                <Button
                                    fullWidth={true}
                                    style={{ margin: '10px 5px' }}
                                    onClick={() => {
                                        this.handleClose();
                                    }}>
                                    Close
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </StripeProvider>
        );
    }
}

PayOptionWidget.propTypes = {
    context: PropTypes.shape({
        userAuthenticated: PropTypes.shape({
            uid: PropTypes.string.isRequired,
        })
    }),
}
