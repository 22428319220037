import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { Colors, GlobalStyles, ConvoMessages } from '../../../utils';
import { firestore } from '../../../fire';

const ReplyMessage = ({ repliedToMessageId, left, convoMembersUserObj }) => {
  const [messageDetails, setMessageDetails] = useState({});
  useEffect(() => {
    const docRef = firestore.collection(ConvoMessages).doc(repliedToMessageId);
    docRef
      .get()
      .then(function (doc) {
        setMessageDetails({ ...doc?.data() });
      })
      .catch(function (error) {
        console.log('Error getting document:', error);
      });
  }, [repliedToMessageId]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: '10px',
        background: Colors.shade,
        borderRadius: '0px 5px 0px 0px',
        borderLeft: `5px solid ${Colors.primaryColor}`,
        textAlign: 'left',
      }}
    >
      {messageDetails?.gif_url && (
        <div style={{ marginRight: '10px' }}>
          <img
            src={messageDetails?.gif_url}
            style={{ width: '48px', height: '48px', borderRadius: '3px' }}
            alt="gif-small-preview"
          />
        </div>
      )}
      <div>
        <Typography
          style={{
            ...GlobalStyles.bodyMedium,
            color: Colors.fauxGCBlue,
            textAlign: 'left',
            marginLeft: left ? '10px' : 0,
            marginRight: left ? 0 : '10px',
            marginTop: '0px',
            marginBottom: '4px',
            display: 'block',
          }}
        >
          {(convoMembersUserObj[messageDetails?.sender_uid] || {})?.fullname ||
            ''}
        </Typography>
        <Typography
          style={{
            ...GlobalStyles.bodyRegular,
            color: Colors.grey2,
            fontSize: '10px',
            textAlign: 'left',
          }}
        >
          {messageDetails?.message_text}
        </Typography>
      </div>
    </div>
  );
};

export default ReplyMessage;
