import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { Colors, GlobalStyles } from '../../../utils';
import TeacherPopoverOptions from '../TitleCardOptions';
import MuteIcon from './MuteIcon';
import PinIcon from './PinIcon';
import AvatarDisplay from '../../AvatarDisplay';

const TeacherConversations = ({
  setTeacherId,
  convos,
  setConvoId,
  setCurrentChatDetails,
  _u,
  convoMembersUserObj,
  archiveConvoIds,
  pinnedConvoIds,
  mutedConvoIds,
  handleArchiveConvoIds,
  handlePinnedConvoIds,
  handleMutedConvoIds,
  defaultConvoId
}) => {
  const [showIcon, setShowIcon] = useState({ mute: false, pin: false });

  return (
    convos &&
    convos
      .filter((convo) => convo.teacher_id && !convo.class_id && !(archiveConvoIds.includes(convo.convo_id)))
      .sort((a, b) => b.date_pinned - a.date_pinned || b.date_created - a.date_created)
      .map((convo, key) => {
        const fromMe = convo.last_message_sender_uid === _u.uid;
        const isUnread = (!fromMe && (convo.date_created - convo.date_last_read > 0)) || !convo.date_last_read;
  
        return (
          <div
            key={key}
            onClick={() => {
              setTeacherId(
                convo.teacher_id,
                (convoMembersUserObj[convo.teacher_id] || {}).profile_photo_url,
                convo.convo_name
              );
              setConvoId(convo.convo_id);
              setCurrentChatDetails(convoMembersUserObj[convo.teacher_id] || {});
            }}
            style={{
              display: 'flex',
              flexDirection: 'row',
              cursor: 'pointer',
              flex: 1,
              padding: '13px 13px 13px 0',
              borderBottom: `1px solid ${Colors.brightGrey}`,
              backgroundColor: convo.convo_id === defaultConvoId ? Colors.whisper : null,
            }}
          >
            <div style={{ marginLeft: '16px', marginRight: 0 }}>
          
              <AvatarDisplay
                imgSrc={convo.convo_profile_photo_url || 'https://placehold.it/100'}
                imgStyles={{ width: '48px', height: '48px', borderRadius: '24px' }}
                imgAlt={'class-avatar'}
                userName={convo.convo_name}
                textStyles={{color: 'rgb(23, 155, 247)', fontSize: '16px'}}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: '16px',
                width: '100%',
                marginTop: '4px',
              }}
            >
              <div style={{}}>
                <Typography
                  style={{
                    ...GlobalStyles.bodyMedium,
                    color: Colors.grey1,
                    textAlign: 'left',
                  }}
                >
                  {convo.convo_name}
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {convo.message_text &&
                    <Typography
                      style={{
                        ...GlobalStyles.bodyRegular,
                        color: Colors.grey1,
                        textAlign: 'left',
                        fontWeight: '500',
                      }}
                    >
                      {!convo.last_message_date_deleted 
                        ? !fromMe
                        ? `${(convoMembersUserObj[convo.other_uid] || {}).fullname ||
                        ''
                        }:  `
                        : 'You:  '
                        : ''}
                    </Typography>
                  }
                  <div style={{ width: '80px' }}>
                    <Typography
                      style={{
                        ...GlobalStyles.bodyRegular,
                        color: Colors.grey3,
                        textAlign: 'left',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        marginLeft: '5px',
                      }}
                    >
                      {convo.last_message_date_deleted
                        ? ''
                        : convo.message_text}
                    </Typography>
                  </div>
                </div>
              </div>
              <div style={{}}>
                <Typography
                  style={{
                    ...GlobalStyles.bodyRegular,
                    color: Colors.grey3,
                    fontSize: '10px',
                  }}
                >
                  {moment(convo.date_created).format('YYYY-MM-DD h:mm a')}
                </Typography>
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {(mutedConvoIds.includes(convo.convo_id)) && (
                    <MuteIcon mutedConvoIds={mutedConvoIds} convo={convo} />
                  )}
                  {isUnread && (
                    <div
                      style={{
                        width: '20px',
                        height: '20px',
                        backgroundColor: Colors.primaryColor,
                        borderRadius: '50%',
                        margin: '3px 2px 0 2px',
                      }}
                    >
                    </div>
                  )}
                  <PinIcon handlePinnedConvoIds={handlePinnedConvoIds} convo={convo} pinnedConvoIds={pinnedConvoIds} />
                </div>
              </div>
            </div>
            <div style={{ marginTop: '5px' }}>
              <TeacherPopoverOptions
                titlePopover
                setShowIcon={setShowIcon}
                showIcon={showIcon}
                convo={convo}
                uid={_u.uid}
                handleArchiveConvoIds={handleArchiveConvoIds}
                archiveConvoIds={archiveConvoIds}
                handlePinnedConvoIds={handlePinnedConvoIds}
                pinnedConvoIds={pinnedConvoIds}
                handleMutedConvoIds={handleMutedConvoIds}
                mutedConvoIds={mutedConvoIds}
              />
            </div>
          </div>
        );
      })
  );
};

export default TeacherConversations;
