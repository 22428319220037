import React, { Component } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import ClassesManage from '../ClassesManage';
import {
    UtilFX,
} from '../../utils';
import { firebase } from '../../fire';

class ClassesManageContainer extends Component {
    constructor(props) {
        super(props);
        // this.FBA = this.props.screenProps.FBA;
        this.FBA = firebase;
        this.auth_user = this.props.context.userAuthenticated;
        this.user = null;
        this.max_length_bio = 300;
        this.state={
            pristine: true,
        }
    }

    componentDidMount() {
        this._mounted = true;
        //this.refresh_profile_data()
        firebase.database()
            .ref('users/' + this.auth_user.uid)
            .on('value', async snap => {
                this.user = snap.val();
                const _u = snap.val();
                const user_paid_plan = _u && _u.config && _u.config.paid_plan;

                this.setState(
                    {
                        fyUser: _u,
                        profile_photo_url: _u.profile_photo_url,
                        fullname: _u.fullname,
                        username: _u.username,
                        website: _u.website,
                        bio: _u.bio,
                        students_count: Object.values(_u.students || {}).length,
                        teachers_count: Object.values(_u.teachers || {}).length,
                        notify_students_switch: user_paid_plan === 'teacherpro'
                    }
                );
            });

        firebase.database()
            .ref('announcements/' + this.auth_user.uid)
            .on('value', snap => {
                const anns = snap.val();
                const ann_count = Object.keys(anns || {}).length;
                this.setState(
                    {
                        announcements_count: ann_count,
                        announcements: anns
                    },
                    () => {
                        this.updateListings_announcements();
                    }
                );
            });

        firebase.database()
            .ref('classesbyuserid/' + this.auth_user.uid)
            .on('value', snap => {
                const evs = snap.val();
                const classes_count = Object.keys(evs || {}).length;
                this.setState(
                    {
                        classes: evs,
                        classes_count
                    },
                    () => {
                        this.updateListings_classes();
                    }
                );
            });

        this.FBA.database()
            .ref('paymenttransactions/')
            .orderByChild('to')
            .equalTo(this.auth_user.uid)
            .once('value', snap => {
                //var ptxns = snap.val()
                const ptxns_count = snap.numChildren(); //Object.keys(ptxns||{}).length
                //console.log("payments received", ptxns)
                //no need to check if pending, this is for coupling other check
                this.setState({
                    incoming_payments_count: ptxns_count
                });
            });
    }

    componentWillUnmount() {
        this._mounted = false;
        this.FBA.database()
            .ref('users/' + this.auth_user.uid)
            .off();
        this.FBA.database()
            .ref('announcements/' + this.auth_user.uid)
            .off();
        this.FBA.database()
            .ref('eventsbyuserid/' + this.auth_user.uid)
            .off();
    }

    updateListings_announcements = () => {
        //console.log("update notification listings")
        let ann_arr = [];

        //let ann_final_obj ={}
        const ann_arr_raw = Object.keys(this.state.announcements || {}).map(
            (v, i) => {
                const aa = this.state.announcements[v];
                aa.aid = v;
                aa.key = v;
                return aa;
            }
        );
        ann_arr_raw.forEach((v, i) => {
            if (!v.date_deleted)
                //remove deleted ones so if none exist it shows proper messaging
                { ann_arr.push(v); }
        });
        ann_arr = ann_arr.sort((a, b) => b.date_created - a.date_created);
        //ann_arr = ann_arr.slice(1)

        //console.log("sorted announcements ", ann_arr)
        this.setState({
            announcements_count: ann_arr.length,
            announcements_array: ann_arr,
            refreshing_announcements: false
        });
    };

    updateListings_classes = () => {
        // console.log("updateing list view");
        this.setState({
            classes_array_categorized: this.getClassArrayCategorized(),
            refreshing: false, //when it stops refreshing too fast it sticks instead of sliding up
            show_classes: true,
            pristine: false
        }, () => {
            this.updateShareScheduleText();
        });
    };

    getClassArrayCategorized = () => {
        //console.log("first print out what we think", this.user.classes)
        //quick map of weekday to value for key sorting
        // const day_key_map = UtilFX.getScheduleWeekdayValueMap();
        // {
        //   "Monday":1,
        //   "Tuesday":2,
        //   "Wednesday":3,
        //   "Thursday":4,
        //   "Friday":5,
        //   "Saturday":6,
        //   "Sunday":7
        // }
        let listings = [];
        if (this.state.classes) {
            listings = Object.keys(this.state.classes).map((k, i) => {
                //console.log("the key we think ", k)
                const listing = this.state.classes[k];
                listing.cid = k;
                listing.key = k;
                //console.log("the listing ", listing)
                try {
                    // var time_temp = listing.time.split(":");
                    const time_temp = moment(listing.start_timestamp).format('h:mm a').split(':');
                    //listing.hour = time_temp[0];
                    listing.time_temp = time_temp.join('');
                    listing.hour = time_temp[0];
                    listing.min = time_temp[1].split(' ')[0];
                    listing.ap = time_temp[1].split(' ')[1];
                } catch (e) {
                    console.warn('problem processing time - ', e);
                }
               
                // console.log('the individual class time', listing.time_temp)
                // listing.day_key_order = day_key_map[listing.day];
                if (listing.location) {
                    listing.loc = listing.location.name;
                    listing.address = listing.location.address;
                }
                listing.name = '(PENDING)';
                listing.category = 'PLACEHOLD-listing.day';
                const expected_date = UtilFX.getNextDayOfWeek(null, null, this.state.classes[k]);
                //expected_date.setHours(Math.floor(listing.time_key), listing.min);
                //console.log({ expected_date, });

                listing.calculated_class_start_time = expected_date.getTime();

                // var month_day = expected_date.getDate();
                // var month_name = expected_date.getMonthName().substring(0, 3);
                // var section_header = listing.day + " - " + month_name + " " + month_day;

                listing.section_header = '-';//section_header;

                return listing;
            });
        }

        listings = listings.filter(i => !i.date_deleted).sort((a, b) => (b.date_edited || b.date_created || b.calculated_class_start_time) - (a.date_edited || a.date_created || a.calculated_class_start_time));

        //store this calculation of class listings for access
        this.setState({ classes_array: listings });

        const classCategoryMap = {}; // Create the blank map
        let paid_class_count = 0;
        listings.forEach((ci) => {
            if (ci.price) { paid_class_count++; }

            if (!classCategoryMap[ci.section_header]) classCategoryMap[ci.section_header] = [];

            classCategoryMap[ci.section_header].push(ci);
        });
        if (paid_class_count > 0) this.setState({ has_paid_classes_or_events: true });
        // console.log({ classCategoryMap });
        const classes_array_categorized = Object.keys(classCategoryMap).map(v => ({
                data: classCategoryMap[v],
                title: v
            }));
        return classes_array_categorized;
    };

    updateShareScheduleText = () => {
        //based on state set state
        const {
            classes_array = [],
            share_schedule_options_location,
            share_schedule_options_title,
        } = this.state;
        let sharetxt = '';
        //get classes and events and set key and expectedstart
        const all_array = classes_array.sort((a, b) => a.calculated_class_start_time - b.calculated_class_start_time).map(v => {
            const start = v.calculated_class_start_time;
            v.universal_start_time = start;
            return v;
        }).filter(v => {
            const cutoff = Date.now() - (1000 * 60 * 60 * 24);
            const isCancel = v.status === 'cancel';
            const isPrivate = !!v.private;
            return (v.universal_start_time > cutoff) && !isCancel && !isPrivate;
        });
        all_array.forEach(c => {
            // console.log("share text manip", c)
            let formatted_time = moment(c.universal_start_time).format('ddd, MMM Do @ h:mm a');

            if (c.recurrence_rule) {
                formatted_time = `${UtilFX.getRecurrenceRuleParts(c.recurrence_rule).byday} @ ${moment(c.universal_start_time).format('h:mm a')}`;
            }

            sharetxt += `${formatted_time}`;

            if (share_schedule_options_location) {
                if (c.loc) {
                    sharetxt += `|${c.loc}`;
                }
            }
            if (share_schedule_options_title) { sharetxt += `|${c.title}`; }

            sharetxt += '\n';
        });
        sharetxt += '\n-get my schedule @favyogis';

        //merge and order by startdate

        this.setState({ share_schedule_text: sharetxt });
    }

    render() {
        return (
            <ClassesManage initialData={this.state} {...this.props} />
        );
    }
}

export default withRouter(ClassesManageContainer);
