import React, { useState } from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { Colors, GlobalStyles } from '../../../utils';
import DmPopoverOptions from '../TitleCardOptions';
import MuteIcon from './MuteIcon';
import PinIcon from './PinIcon';
import AvatarDisplay from '../../AvatarDisplay';

const DmConversations = ({
  setRecipientId,
  convos,
  setConvoId,
  setCurrentChatDetails,
  convoMembersUserObj,
  _u,
  archiveConvoIds,
  pinnedConvoIds,
  mutedConvoIds,
  handleArchiveConvoIds,
  handlePinnedConvoIds,
  handleMutedConvoIds,
  deleteDMConvo,
  deletedConvoIds,
  defaultConvoId,
}) => {
  const [showIcon, setShowIcon] = useState({ mute: false, pin: false });
  
  return (
    convos &&
    convos
      .filter((convo) => convo.dm_members && !(archiveConvoIds.includes(convo.convo_id)) && !deletedConvoIds.includes(convo.convo_id))
      .sort((a, b) => b.date_pinned - a.date_pinned || b.date_created - a.date_created)
      .map((convo, key) => {
        const fromMe = convo.last_message_sender_uid === _u.uid;
        const isUnread = (!fromMe && (convo.date_created - convo.date_last_read > 0)) || !convo.date_last_read;

        return (
          <div
            key={key}
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              cursor: 'pointer',
              padding: '13px 13px 13px 0',
              borderBottom: `1px solid ${Colors.brightGrey}`,
              backgroundColor:
                convo.convo_id === defaultConvoId ? Colors.whisper : null,
            }}
          >
            <div style={{ marginLeft: '16px', marginRight: 0 }}>
        
             <AvatarDisplay
                imgSrc={convoMembersUserObj && (convoMembersUserObj[convo.other_uid] || {})?.profile_photo_url }
                imgStyles={{ width: '48px', height: '48px', borderRadius: '24px' }}
                imgAlt={'title-avatar'}
                userName={convoMembersUserObj && (convoMembersUserObj[convo.other_uid])?.fullname}
                textStyles={{
                ...GlobalStyles.bodyMedium,
                color: 'rgb(23, 155, 247)',
                textAlign: 'center',
                paddingTop: '5px'
            }}
             />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: '16px',
                width: '100%',
                marginTop: '4px',
              }}
              onClick={() => {
                setRecipientId(convo.other_uid);
                setConvoId(convo.convo_id);
                setCurrentChatDetails(convoMembersUserObj[convo.other_uid] || {});
              }}
            >
              <div>
                <Typography
                  style={{
                    ...GlobalStyles.bodyMedium,
                    color: Colors.grey1,
                    textAlign: 'left',
                  }}
                >
                  {(convoMembersUserObj[convo.other_uid] || {}).fullname || ''}
                </Typography>
                <Typography
                  style={{
                    ...GlobalStyles.bodyRegular,
                    color: Colors.grey3,
                    textAlign: 'left',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '195px',
                  }}
                >
                  {convo.last_message_date_deleted
                    ? ''
                    : convo.message_text}
                </Typography>
              </div>
              <div>
                <Typography
                  style={{
                    ...GlobalStyles.bodyRegular,
                    color: Colors.grey3,
                    fontSize: '10px',
                  }}
                >
                  {moment(convo.date_created).format('YYYY-MM-DD h:mm a')}
                </Typography>
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {(mutedConvoIds.includes(convo.convo_id)) && (
                    <MuteIcon mutedConvoIds={mutedConvoIds} convo={convo} />
                  )}
                  {isUnread && (
                    <div
                      style={{
                        width: '20px',
                        height: '20px',
                        backgroundColor: Colors.primaryColor,
                        borderRadius: '50%',
                        margin: '3px 2px 0 2px',
                      }}
                    >
                    </div>
                  )}
                  <PinIcon convo={convo} pinnedConvoIds={pinnedConvoIds} />
                </div>
              </div>
            </div>
            <div style={{ marginTop: '5px' }}>
              <DmPopoverOptions
                titlePopover
                classTeacherPopover
                setShowIcon={setShowIcon}
                showIcon={showIcon}
                handleArchiveConvoIds={handleArchiveConvoIds}
                archiveConvoIds={archiveConvoIds}
                convo={convo}
                uid={_u.uid}
                handlePinnedConvoIds={handlePinnedConvoIds}
                pinnedConvoIds={pinnedConvoIds}
                handleMutedConvoIds={handleMutedConvoIds}
                mutedConvoIds={mutedConvoIds}
                deleteDMConvo={deleteDMConvo}
                deletedConvoIds={deletedConvoIds}
              />
            </div>
          </div>
        );
      })
  );
};

export default DmConversations;
