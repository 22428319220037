import React, { Component } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from '@material-ui/pickers';
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import PublicIcon from '@material-ui/icons/Public';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Modal from '@material-ui/core/Modal';
import isEqual from 'lodash.isequal';
import EventIcon from '@material-ui/icons/Event';
import MenuItem from '@material-ui/core/MenuItem';
// import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Group from '@material-ui/icons/Group';
import AttachMoney from '@material-ui/icons/AttachMoney';
import PauseCircleOutlineOutlinedIcon from '@material-ui/icons/PauseCircleOutlineOutlined';
import PlayCircleFilledOutlinedIcon from '@material-ui/icons/PlayCircleFilledOutlined';
import TextFieldComponent from './common/TextFieldComponent';
// import Icon from "@material-ui/core/Icon";
// import EditIcon from "@material-ui/icons/Edit";
import VideocamIcon from '@material-ui/icons/Videocam';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
// import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import imgRocket from '../rocket.svg';
import ButtonComponent from './common/ButtonComponent';
import Map from './common/Map';
import CheckBoxComponent from './common/CheckBoxComponent';
import ToastComponent from './common/ToastMessage';
import { ViewCard } from './common/ClassesEditCards';


import {
    UtilFX,
    classContext,
    Colors,
    GlobalStyles,
    NumberOfLimitedCommunityClasses
} from '../utils';
import '../App.css';
import { firebase } from '../fire';
import AvatarDisplay from './AvatarDisplay';
// const Analytics = firebase.analytics();
const style_features = {
    featureInfo: {
        // textAlign: "left",
        marginTop: 5,
        marginBottom: 5,
        paddingHorizontal: 20,
        color: '#fff',
        fontSize: 15
    },
    description: {
        // marginTop: 5,
        marginBottom: 5,
        paddingHorizontal: 5,
        color: '#fff',
        fontSize: 20
    },
    header: {
        textAlign: 'center',
        fontWeight: 'normal',
        fontSize: 24,
    },

};

export const CLASS_MODE_EDIT = 'edit';
export const CLASS_MODE_VIEW = 'view';
export const CLASS_MODE_CREATE = 'create';
const styled = createTheme({
    overrides: {
        MuiButton: {
            root: {
                backgroundColor: 'transparent'
            }
        },
        MuiInputBase: {
            root: {
                color: '#FFFFFF',
            },
        },
        MuiTextField: {
            root: {
                backgroundColor: 'transparent'
            }
        },
        MuiOutlinedInput: {
            root: {

            }
        },
        MuiSelect: {
            root: {
                color: '#fff'
            }
        },
        MuiChecked: {
            color: 'blue'
        },
        MuiSlider: {
            markLabelActive: {
                color: '#FFFFFF'
            }
        },
        MuiToggleButton: {
            root: {
                width: '100px'
            }
        },
    }
});

const BorderLinearProgress = withStyles({
    root: {
        height: 10,
        backgroundColor: '#FFFFFF35',
    },
    bar: {
        borderRadius: 3,
        backgroundColor: '#FFFFFF',
    },
})(LinearProgress);

const GetValuesFromGoogleBasedRecurrenceRule = (recurrenceRuleText) => UtilFX.getRecurrenceRuleParts(recurrenceRuleText);
const DaysOfWeekItems = [
    { id: '0', value: 'Sunday', nickname: 'Su' },
    { id: '1', value: 'Monday', nickname: 'Mo' },
    { id: '2', value: 'Tueday', nickname: 'Tu' },
    { id: '3', value: 'Wednesday', nickname: 'We' },
    { id: '4', value: 'Thursday', nickname: 'Th' },
    { id: '5', value: 'Friday', nickname: 'Fr' },
    { id: '6', value: 'Saturday', nickname: 'Sa' }
];

// const getTimekeyOnTime
const _getClassRepeat = (rrParts) => {
    let prettyText = 'Does not repeat';
    if (rrParts.byday && rrParts.interval) {
        if (Number(rrParts.interval) === 1) {
            prettyText = 'Every week:';
        } else if (Number(rrParts.interval) === 2) {
            prettyText = 'Every other weel:';
        }
        prettyText += rrParts.byday.split(',').map(nick => (nick[0].toUpperCase() + nick[1].toLowerCase())).join(',');
        return prettyText;
    }
    return prettyText;
};

const expression = /[-a-zA-Z0-9@:%_\\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\*)?/i;
const regexp = new RegExp(expression);
const _handleURLValidation = (value) => regexp.test(value);

class ClassesEdit extends Component {
    constructor(props) {
        super(props);
        this.auth_user = props.context.userAuthenticated;

        this.state = {
            cid: null,
            uid: null,
            class_type: null,
            title: '',
            day: '',
            time: false,
            date: false,
            day_key: null,
            time_key: null,
            time_formatted: null,
            duration: '',
            recurringDaysOfWeek: [],
            days_key: {
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
                6: false,
                0: false
            },
            defaultLocation: {},
            status: '0',
            progress: 0,
            error: false,
            isModalVisible_day: false,
            isModalVisible_time: false,
            isModalVisible_time_onetime: false,
            isModalVisible_duration: false,
            isModalVisible_location: false,
            isModalVisible_validation: false,
            isModalVisible_permission_location: false,
            has_shown_location_permission_prompt: false,
            has_never_added_class_before: false,
            activePersonalClassCount: false,
            picker_time_interval: null, // used in setting date time picker correctly.
            description_text_remaining_char: 500,
            description_text_max_char: 500,
            description: '',
            when_onetime: false,
            when_onetime_datepicker_startorend: '',
            when_onetime_start: null,
            when_onetime_end: null,
            isRecurring: '',
            private: false,
            recurrenceContext: '',
            quantity: '',
            price: null,
            price_billing_cycle: 'weekly',
            fees_absorb: null,
            personal_registration_uid: null,
            personal_registration_user_object: {},
            recurrence_rule: null,
            rsvp_arr: [],
            show_desc_ui: false,
            openPayModal: false,
            classtype_description: false,
            mode: CLASS_MODE_CREATE,
            render_class_data: null,
            location: {},
            location_error_handler: false,
            location_check: false,
            disable: true,
            start_timestamp: null,
            community_prompt: true,
            personal_prompt: true,
            copy_class: false,
            virtual_link: null,
            hideOrShowLink: false,
            openTimeZoneModal: false,
            openDefaultTimeZoneModal: false,
            timeZone: '',
            timeZoneOffset: '',
            teacherProUpgrade: false,
            isTeacherPro: false,
            freeClass: false,
            previewClass: true,
            isAuthenticatedClassModal: false
        };
        this.original_class_state = this.state;
    }

    componentDidMount() {
        if (this.auth_user) {
            UtilFX.getFullCommunityClassesCount(this.auth_user.uid).then(response => this.setState({ communityClassCount: response }));
            Promise.resolve(UtilFX.getDefaultTimeZoneValue()).then(result => this.setState({ defaultTimeZoneValue: result }));
            UtilFX.getFYuser(this.auth_user.uid).then((fyu = {}) => {
                const { config = {} } = fyu;
                this.setState({
                    isTeacherPro: !!(config.paid_plan || '').includes('teacherpro')
                });
            });
        }
        this._mounted = true;
        this.auth_user = this.props.context.userAuthenticated;
        const { classData } = this.props;
        this.setState(classData, () => {
            // console.warn('new state CDU update', this.state);
            this._setupRecurrenceState();
            this.original_class_state = this.state;
        });

    }

    componentDidUpdate(prevProps) {
        const { classData } = this.props;
        if ((prevProps.classData || {}).cid !== (classData || {}).cid
        ) {
            this.setState(classData, () => {
                // console.warn('new state CDU update', this.state);
                this.original_class_state = this.state;
            });
        }
        if ((prevProps.classData || {}).paused !== (classData || {}).paused
        ) {
            this.setState(classData, () => {
                // console.warn('new state CDU update', this.state);
                this.original_class_state = this.state;
            });
        }
        // had to do this here because i want the containers to do all the api calls: so i added another if check to update the state at its specific value
        if ((prevProps.classData || {}).activePersonalClassCount !== (classData || {}).activePersonalClassCount) {
            this.setState({ activePersonalClassCount: classData.activePersonalClassCount });
        }
        if (!isEqual((prevProps.classData || {}).personal_registration_user_object, (classData || {}).personal_registration_user_object)) {
            this.setState({ personal_registration_user_object: classData.personal_registration_user_object });
        }
    }

    classTypePrompt = () => {
        const { fyUser } = this.props;
        const { isTeacherPro, communityClassCount } = this.state;
        const { activePersonalClassCount } = this.state;

        return (

            <classContext.Consumer>
                {(context) => (
                    <Grid
                        container
                        spacing={5}
                    >
                        <Grid item md={12} xs={12}>

                            <h2 style={{ textAlign: 'center', marginTop: '20px' }}>
                                What type of class would you like to add ?
                            </h2>
                        </Grid>
                        <Grid
                            md={6}
                            item
                            xs={12}
                        >
                            <ButtonComponent value="Community"
                                onClick={() => {
                                    if (communityClassCount < NumberOfLimitedCommunityClasses || isTeacherPro || !this.auth_user) {
                                        this.setState({ class_type: 'community', classtype_description: true, render_class_data: 'description' })
                                    }
                                    else {
                                        this.setState({ teacherProUpgrade: true });
                                    }
                                }}
                                buttonStyle={{ backgroundColor: Colors.temp_color }}
                            />
                        </Grid>
                        <Grid
                            md={6}
                            xs={12}
                            item
                        >
                            <ButtonComponent value="Personal"
                                onClick={() => {
                                    if (isTeacherPro || activePersonalClassCount === 0 || !this.auth_user) {
                                        this.setState({ class_type: 'personal', private: true, render_class_data: 'description' });
                                    } else {
                                        UtilFX.triggerAdminAlert('WEB: triggered paywall limit on adding personal class in classesedit prompt', { user: fyUser });
                                        this.setState({ teacherProUpgrade: true });
                                    }
                                }}
                                buttonStyle={{ backgroundColor: Colors.temp_color }}
                            />
                        </Grid>
                        <Grid
                            style={{
                                paddingTop: 10,
                                borderColor: '#fff',
                                borderBottomWidth: 1,
                                flexBasis: '100%',
                                maxWidth: '100%'
                            }}
                            item
                            md={12}
                            sm={12}
                        >
                            <div style={{ borderBottomWidth: 1, borderColor: '#fff', paddingBottom: 5 }}>

                                <Typography style={style_features.header}>
                                    Community
                                </Typography>
                            </div>
                            <div style={{
                                flex: 1,
                                borderColor: '#fff',
                                borderTopWidth: 0,
                                paddingTop: 0,
                                marginTop: 5,
                                textAlign: 'center'
                            }}
                            >
                                <Typography style={style_features.description}>Group class instruction.</Typography>
                                <Accordion style={{ backgroundColor: Colors.temp_color, color: '#FFFFFF' }}
                                    onClick={() => this.setState({ community_prompt: !this.state.community_prompt })}

                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>{this.state.community_prompt ? 'see more....' : 'see less...'}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style={{ flexDirection: 'column', flex: 1, textAlign: 'left' }}>
                                            <Typography variant="subtitle2" style={{ lineHeight: 1, marginBottom: '1rem' }}>
                                                <DoneOutlineIcon style={{ fontSize: '0.7rem' }} />
                                                {' '}
Multiple attendees
                                            </Typography>
                                            <Typography variant="subtitle2" style={{ lineHeight: 1, marginBottom: '1rem' }}>
                                                <DoneOutlineIcon style={{ fontSize: '0.7rem' }} />
                                                {' '}
One-time registration
                                            </Typography>
                                            <Typography variant="subtitle2" style={{ lineHeight: 1 }}>
                                                <DoneOutlineIcon style={{ fontSize: '0.7rem' }} />
                                                {' '}
One-time payment
                                            </Typography>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div style={{ borderBottomWidth: 1, borderColor: '#fff', paddingBottom: 5, paddingTop: 10 }}>
                                <Typography style={style_features.header}>
                                    Personal
                                </Typography>
                            </div>
                            <div style={{
                                flex: 1,
                                borderColor: '#fff',
                                borderTopWidth: 0,
                                paddingTop: 0,
                                marginTop: 5,
                                textAlign: 'center',
                            }}
                            >

                                <Typography style={style_features.description}>1-on-1 class instruction.</Typography>
                                <Accordion style={{ backgroundColor: Colors.temp_color, color: '#FFFFFF' }}
                                    onClick={() => this.setState({ personal_prompt: !this.state.personal_prompt })}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>{this.state.personal_prompt ? 'see more....' : 'see less...'}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style={{ flexDirection: 'column', flex: 1, textAlign: 'left' }}>
                                            <Typography variant="subtitle2" style={{ lineHeight: 1, alignSelf: 'self', marginBottom: '1rem' }}>
                                                <DoneOutlineIcon style={{ fontSize: '0.7rem' }} />
                                                {' '}
Private only
                                            </Typography>
                                            <Typography variant="subtitle2" style={{ lineHeight: 1, alignSelf: 'self', marginBottom: '1rem' }}>
                                                <DoneOutlineIcon style={{ fontSize: '0.7rem' }} />
                                                {' '}
Recurring registrations
                                            </Typography>
                                            <Typography variant="subtitle2" style={{ lineHeight: 1, alignSelf: 'self' }}>
                                                <DoneOutlineIcon style={{ fontSize: '0.7rem' }} />
                                                {' '}
Recurring payments
{' '}
                                            </Typography>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div style={{ marginTop: '4rem' }}>
                                <Grid item md={12} xs={12}>
                                    <Button
                                        style={{
                                            display: 'block',
                                            padding: '8px',
                                            margin: 'auto 5px',
                                            width: '100%',
                                            color: '#fff',
                                            // border: '1px solid ' + Colors.sec_color,
                                            backgroundColor: Colors.grey1,
                                            textDecoration: 'none',
                                            borderRadius: 4,
                                        }}
                                        onClick={() => this.props.history.goBack()}
                                    >
                                        Back
                                    </Button>
                                </Grid>
                            </div>
                        </Grid>
                        <Modal
                            disableAutoFocus
                            disableEnforceFocus
                            aria-labelledby="premium upgrade"
                            aria-describedby="upgrade to teacherPro."
                            className="center-modal"
                            open={this.state.teacherProUpgrade}
                            onClose={() => {
                                this.setState({ teacherProUpgrade: false });
                            }}
                        >
                            <div className="modal-upgrade-wrapper" style={{ backgroundColor: Colors.white }}>
                                <span
                                    className="material-icons"
                                    style={{ color: Colors.grey1, fontSize: 20, display: 'flex', justifyContent: 'flex-end', margin: '20px', cursor: 'pointer' }}
                                    onClick={() => this.setState({ teacherProUpgrade: false })}
                                >
                                    clear
                                </span>
                                <div style={{ marginLeft: '40px', marginRight: '40px', justifyContent: 'center', marginTop: '50px', marginBottom: '40px' }}>
                                    <img src={imgRocket} alt="premium upgrade path" />
                                    <Typography style={{ fontFamily: 'Rubik', fontStyle: 'normal', fontWeight: '500', fontSize: '30px', color: '#333333', marginTop: '32px' }}>Time to upgrade ?</Typography>
                                    <Typography style={{ ...GlobalStyles.bodyRegular, color: '#333333', marginTop: '16px' }}>
                                        {' '}
Creating more than one personal class is a
<br />
                                        {' '}
premium feature.
{' '}
                                    </Typography>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        style={{ ...GlobalStyles.bodyMedium, backgroundColor: Colors.primaryColor, color: Colors.white, borderRadius: '8px', marginTop: '36px', boxShadow: '0 0 0 0', padding: '18px', fontWeight: '600', textTransform: 'none' }}
                                        onClick={() => this._handlePremiumUpgradeModal()}
                                    >
                                        Learn more about the premium
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    </Grid>
                )}
            </classContext.Consumer>
        );
    }

    _handlePremiumUpgradeModal = () => {
        this.props.history.push('/limitsandplans');
    }

    fetchLocation = (value) => {
        this.setState({
            location: {
                address: value.address,
                lat: value.mapPosition.lat,
                lng: value.mapPosition.lng,
                name: value.area
            },
            location_error_handler: false,
            location_check: true
        });
    }

    classTypeDescription = () => (
        <Grid container style={{ marginTop: 20, }}>
            <Grid item md={12} xs={12}>
                <h2>How would you describe the class ?</h2>
            </Grid>
            <Grid item md={12} xs={12}>
                <TextField
                    placeholder="Title"
                    variant="outlined"
                    style={{ display: 'flex', flex: 1, }}
                    name='title'
                    value={this.state.title || ''}
                    type='text'
                    onChange={this.handleChange}
                    error={this.state.title ? false : this.state.error}
                />
            </Grid>
            <Grid item md={12} xs={12} style={{ marginTop: 20, }}>
                <TextField
                    placeholder="Description (optional)"
                    style={{ display: 'flex', flex: 1 }}
                    name="description"
                    autoComplete="on"
                    value={this.state.description || ''}
                    onChange={this.handleChange}
                    id="outlined-multiline-static"
                    multiline
                    rows="5"
                    variant="outlined"
                />
            </Grid>

        </Grid>
    )

    classTypeDate = () => {
        const { openDefaultTimeZoneModal, date, timeZone, timeZoneOffset, error, time, defaultTimeZoneValue } = this.state;
        return (
            <Grid container style={{ marginTop: 20 }}>
                <Grid item md={12} xs={12}>
                    <h2>
                        When is the class ?
                    </h2>
                </Grid>
                <Grid item md={12} xs={12} style={{ marginTop: '50px', marginLeft: '70px', marginRight: '70px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginTop: '15px', marginRight: '10px' }}>
                            <EventIcon style={{ color: 'rgba(0,0,0,0.4)' }} />
                        </div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                // minDate={new Date()}
                                clearable
                                inputVariant="outlined"
                                format="MM/dd/yyyy"
                                value={date || null}
                                placeholder="Date"
                                style={{ display: 'flex', flex: 1, color: '#ffffff' }}
                                onChange={text => this.handleDate(text)}
                                error={date ? false : error}
                                animateYearScrolling
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </Grid>
                <Grid item md={12} xs={12} style={{ marginTop: '10px', marginLeft: '70px', marginRight: '70px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginTop: '15px', marginRight: '10px' }}>
                            <AccessAlarmIcon style={{ color: 'rgba(0,0,0,0.4)' }} />
                        </div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                                inputVariant="outlined"
                                value={time || null}
                                style={{ display: 'flex', flex: 1, color: '#ffffff', }}
                                onChange={text => this.handleTime(text)}
                                error={time ? false : error}
                                clearable
                                placeholder="Time"
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </Grid>
                <Grid item md={12} xs={12} style={{ marginTop: '10px', }}>
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '70px' }}>
                        <div style={{ marginTop: '15px', marginRight: '10px' }}>
                            <PublicIcon style={{ color: 'rgba(0,0,0,0.4)' }} />
                        </div>
                        <FormControl variant="outlined" style={{ width: '63%', }}>
                            <Select name="timezone_offset"
                                onChange={(e) => {
                                    if (e.target.value === '') return;
                                    this.setState({ timeZone: UtilFX.getTimezoneNameFromOffset(e.target.value), timeZoneOffset: e.target.value });
                                    if (!defaultTimeZoneValue && this.auth_user) {
                                        this.setState({ openDefaultTimeZoneModal: true });
                                    }
                                }}
                                error={timeZone ? false : error}
                                placeholder="Select a timezone"
                                defaultValue={(timeZoneOffset) || (timeZone && moment().tz(timeZone).format('Z')) || (defaultTimeZoneValue && moment().tz(defaultTimeZoneValue).format('Z')) || ''}
                            >
                                <MenuItem value=''>Select timezone</MenuItem>
                                {
                                    UtilFX.getFullTimeZoneList().map((tz, key) => {
                                        return (<MenuItem key={key} value={tz.value}>{tz.name}</MenuItem>)
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
                <Modal
                    disableAutoFocus
                    disableEnforceFocus
                    aria-labelledby="select timezone"
                    aria-describedby="select your timezone."
                    className="center-modal"
                    open={openDefaultTimeZoneModal}
                    onClose={() => {
                        this._handleDefaultTimeZoneModal();
                    }}
                >
                    <div className="modal-content-wrapper" style={{ borderRadius: '20px' }}>
                        <Typography style={{ fontFamily: 'Rubik', fontStyle: 'normal', fontWeight: 'normal', fontSize: '32px', marginTop: '56px' }}>{`(${timeZoneOffset}) ${timeZone}`}</Typography>
                        <Typography style={{ marginTop: '16px', ...GlobalStyles.bodyMedium }}> Keep using this as your default timezone? </Typography>
                        <Button size="large"
                            variant="contained"
                            style={{ backgroundColor: Colors.primaryColor, color: Colors.white, borderRadius: '5px', width: '60%', marginTop: '32px', boxShadow: '0 0 0 0', }}
                            onClick={() => {
                                if (timeZone) {
                                    firebase.database()
                                        .ref('users/' + this.auth_user.uid)
                                        .update({
                                            default_time_zone: timeZone
                                        }).then(() => this._handleDefaultTimeZoneModal());
                                }
                            }}
                        >
                            Default
                        </Button>
                        <Button size="large"
                            variant="contained"
                            style={{ backgroundColor: 'transparent', color: Colors.white, borderRadius: '5px', width: '60%', border: '1px solid #ffffff', marginTop: '15px', marginBottom: '56px', boxShadow: '0 0 0 0', }}
                            onClick={this._handleDefaultTimeZoneModal}
                        >
                            Just for this class
                        </Button>
                    </div>
                </Modal>
            </Grid>
        );
    }

    _handleTimeZoneModal = () => {
        this.setState({ openTimeZoneModal: !this.state.openTimeZoneModal });
    }

    _handleDefaultTimeZoneModal = () => {
        this.setState({ openDefaultTimeZoneModal: !this.state.openDefaultTimeZoneModal });
    }

    classTypeStatus = () => (
        <Grid container style={{ marginTop: '20px' }}>
            <Grid item md={12} xs={12}>
                <h2>
                    How should students see this class ?
                </h2>
            </Grid>
            <Grid item md={12} xs={12} style={{ marginTop: '20px' }}>
                {
                    this.state.class_type !== 'personal' && (
                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                            <div style={{ backgroundColor: this.state.status === '0' ? Colors.white : Colors.pri_color, border: '1px solid white', width: '115px', cursor: 'pointer', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }} onClick={() => this.setState({ status: '0' })}>
                                <Typography style={{ ...GlobalStyles.bodyMedium, color: this.state.status === '0' ? Colors.pri_color : Colors.white, padding: '12px 0' }}>Active</Typography>
                            </div>
                            <div key={2} style={{ backgroundColor: this.state.status === '1' ? Colors.white : Colors.pri_color, border: '1px solid white', width: '115px', cursor: 'pointer' }} onClick={() => this.setState({ status: '1' })}>
                                <Typography style={{ ...GlobalStyles.bodyMedium, color: this.state.status === '1' ? Colors.pri_color : Colors.white, padding: '12px 0' }}>Canceled</Typography>
                            </div>
                            <div key={3} style={{ backgroundColor: this.state.status === '2' ? Colors.white : Colors.pri_color, border: '1px solid white', width: '115px', cursor: 'pointer', borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }} onClick={() => this.setState({ status: '2' })}>
                                <Typography style={{ ...GlobalStyles.bodyMedium, color: this.state.status === '2' ? Colors.pri_color : Colors.white, padding: '12px 0' }}>SUB</Typography>
                            </div>
                        </div>
                    )}
                {
                    Boolean(this.state.class_type !== 'personal')
                    && (
                        <Grid container item md={12} xs={12} style={style_features.description}>
                            <Grid item md={8} xs={8}>
                                <Typography style={style_features.featureInfo}>
                                    {' '}
                                    <strong>Hide this class </strong>
                                </Typography>
                            </Grid>
                            <Grid item md={4} xs={4}>
                                <FormControlLabel
                                    control={
                                        <Switch checked={this.state.private} onChange={this.handleToggler} name="private" color="primary" />
                                    }
                                    style={{ textAlign: 'right' }}
                                />
                            </Grid>
                            <Grid item>
                                <Typography style={style_features.featureInfo}>Hidden classes are only visible to invitees </Typography>
                            </Grid>
                        </Grid>
                    )}
            </Grid>
        </Grid>
    )

    classPrice = (props) => {
        const { class_type, recurrenceContext, freeClass } = this.state;
        const isPersonalAndRecurring = (class_type === 'personal') && recurrenceContext;
        return (
            <Grid container style={{ marginTop: 20 }}>
                <Grid item md={12} xs={12}>
                    <Typography style={{ ...GlobalStyles.titleBold, textAlign: 'center' }}>
                        Setup payment
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                        <div onClick={() => this.setState({ freeClass: false })} style={{ padding: '13px', border: '1px solid white', backgroundColor: freeClass ? Colors.pri_color : Colors.white, width: '173px', borderRadius: '6px 0px 0px 6px', cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div></div>
                            <Typography style={{ ...GlobalStyles.bodyMedium, color: freeClass ? Colors.white : Colors.pri_color, marginLeft: '15px' }}>Paid class
                            </Typography>
                            {
                                (!freeClass && (
                                    <span style={{ color: '#27EF7C' }} className="material-icons">
                                        check_circle
                                    </span>
                                )) || (<div></div>)}
                        </div>
                        <div onClick={() => this.setState({ freeClass: true })} style={{ padding: '13px', borderRadius: '0px 6px 6px 0px', border: '1px solid white', backgroundColor: freeClass ? Colors.white : Colors.pri_color, width: '173px', cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div></div>
                            <Typography style={{ ...GlobalStyles.bodyMedium, color: freeClass ? Colors.pri_color : Colors.white, marginLeft: '15px' }}>Free class</Typography>
                            {
                                (freeClass && (
                                    <span style={{ color: '#27EF7C' }} className="material-icons">
                                        check_circle
                                    </span>
                                )) || (<div></div>)}
                        </div>
                    </div>
                    {
                        !freeClass && (
                            <div>
                                <div style={{ marginTop: '18px', display: 'flex', flexDirection: 'row' }}>
                                    <span style={{ color: Colors.white }} className="material-icons">
                                        info
                                </span>
                                    <Typography style={{ fontSize: '12px', marginLeft: '3px', fontFamily: 'Rubik', fontWeight: '400', textAlign: 'left', fontStyle: 'italic' }}>Community classes do not allow for recurring subscription payment. </Typography>
                                </div>
                                <div style={{ marginTop: '16px' }}>
                                    <Typography style={{ ...GlobalStyles.bodyRegular, textAlign: 'left', color: '#9BDBFF' }}>Price</Typography>
                                    <TextFieldComponent
                                        onChange={this.handlePricing}
                                        value={this._getPriceFormatString() || ''}
                                        name="price"
                                        startAdornment={
                                            (<InputAdornment position="start" style={{ color: '#FFFFFF' }}>
                                                <AttachMoney />
                                            </InputAdornment>)
                                        }
                                        placeholder='0' inputStyle={{ ...GlobalStyles.bodyRegular, border: '2px solid #187DC4', borderRadius: '10px', color: '#ffffff', marginTop: '8px' }} />
                                </div>
                            </div>
                        )}
                    {Boolean(class_type !== 'personal') && (
                        <div>
                            <div style={{ marginTop: '12px' }}>
                                <Typography style={{ ...GlobalStyles.bodyRegular, textAlign: 'left', color: '#9BDBFF' }}>Tickets</Typography>
                                <TextFieldComponent
                                    onChange={this.handlePricing}
                                    name='quantity'
                                    value={this.state.quantity}
                                    startAdornment={(
                                        <InputAdornment position="start" style={{ color: '#FFFFFF' }}>
                                            <Group />
                                        </InputAdornment>
                                    )}
                                    type='number' placeholder='Unlimited' inputStyle={{ ...GlobalStyles.bodyRegular, border: '2px solid #187DC4', borderRadius: '10px', color: '#ffffff', marginTop: '8px' }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                                <span style={{ color: Colors.white, fontSize: '15px', marginTop: '1px' }} className="material-icons">
                                    info
                            </span>
                                <Typography style={{ fontSize: '12px', marginLeft: '3px', fontFamily: 'Rubik', fontWeight: '400', textAlign: 'left' }}>Number of students allowed to register. For example: 15 </Typography>
                            </div>
                        </div>
                    )}
                    {
                        !freeClass && (
                            <div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '30px' }}>
                                    <Typography style={{ ...GlobalStyles.bodyRegular, textAlign: 'left', marginTop: '10px' }}>{(this.state.fees_absorb && 'Student(s) will absorb the transaction fee') || 'I will absorb the transaction fee'}</Typography>
                                    <div style={{}}>
                                        <FormControlLabel
                                            control={
                                                <Switch checked={this.state.fees_absorb} onChange={this.handleToggler} name="fees_absorb" color="primary" />
                                            }
                                            style={{ textAlign: 'right', marginRight: '-15px' }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div style={{ display: 'flex', flexDirection: 'row', }}>
                                        <Typography style={{ ...GlobalStyles.bodyMedium, textAlign: 'left', marginTop: '35px', }}>Student pays : {`$${this.state.price ? this._getPriceFormatString() : '0.00'}`}</Typography>
                                        {Boolean(isPersonalAndRecurring) && (
                                            <Typography style={{ ...GlobalStyles.bodyMedium, backgroundColor: '#56CCF2', color: Colors.white, padding: '6px 12px', borderRadius: '10px', marginLeft: '9px', marginTop: '30px', }}>{`Auto-renews ${this.state.price_billing_cycle}`}</Typography>)}
                                    </div>
                                    <Typography style={{ ...GlobalStyles.bodyMedium, marginTop: '15px', textAlign: 'left' }}>Transaction fee : {`$${this._getFeeCalculation() || '0.00'}`}</Typography>
                                    <Typography style={{ ...GlobalStyles.bodyMedium, marginTop: '20px', textAlign: 'left' }}>You receive : {`$${this._getReceiversTotal() || '0.00'}`}</Typography>
                                </div>
                            </div>
                        )}
                    {Boolean(isPersonalAndRecurring) && !(freeClass) && (
                        <div style={{ marginTop: '24px' }}>
                            <Typography style={{ ...GlobalStyles.bodyRegular, textAlign: 'left', color: '#9BDBFF', marginBottom: '8px' }}>Auto-renewal plan</Typography>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div onClick={() => this.setState({ price_billing_cycle: 'weekly' })} style={{ backgroundColor: this.state.price_billing_cycle === 'weekly' ? Colors.white : Colors.pri_color, border: '1px solid white', width: '115px', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', cursor: 'pointer' }}><Typography style={{ ...GlobalStyles.bodyMedium, color: this.state.price_billing_cycle === 'weekly' ? Colors.pri_color : Colors.white, padding: '8px 0' }}>Weekly</Typography></div>
                                <div onClick={() => this.setState({ price_billing_cycle: 'biweekly' })} style={{ backgroundColor: this.state.price_billing_cycle === 'biweekly' ? Colors.white : Colors.pri_color, border: '1px solid white', width: '115px', cursor: 'pointer' }}><Typography style={{ ...GlobalStyles.bodyMedium, color: this.state.price_billing_cycle === 'biweekly' ? Colors.pri_color : Colors.white, padding: '8px 0' }}>Bi-weekly</Typography></div>
                                <div onClick={() => this.setState({ price_billing_cycle: 'monthly' })} style={{ backgroundColor: this.state.price_billing_cycle === 'monthly' ? Colors.white : Colors.pri_color, border: '1px solid white', width: '115px', borderTopRightRadius: '10px', borderBottomRightRadius: '10px', cursor: 'pointer' }}><Typography style={{ ...GlobalStyles.bodyMedium, color: this.state.price_billing_cycle === 'monthly' ? Colors.pri_color : Colors.white, padding: '8px 0' }}>Monthly</Typography></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                                <span style={{ color: Colors.white, fontSize: '15px', marginTop: '1px' }} className="material-icons">
                                    info
                            </span>
                                <Typography style={{ fontSize: '12px', marginLeft: '3px', fontFamily: 'Rubik', fontWeight: '400', textAlign: 'left' }}>Interval for automatically charging your student.</Typography>
                            </div>
                        </div>
                    )}
                    {
                        (freeClass && isPersonalAndRecurring) && (
                            <Typography style={{ ...GlobalStyles.bodyRegular, textAlign: 'center', margin: '60px 0px 30px 0px' }}>No payment will be required for registration.</Typography>
                        )}
                </Grid>
            </Grid>
        )
    }

    classTypeDuration = () => (
        <Grid container style={{ justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
            <Grid item md={12} xs={12}>
                <h2>
                    How long is the class ?
                </h2>
            </Grid>
            <Grid item md={4} xs={4} style={{ marginTop: '50px', justifyContent: 'center', alignItems: 'center', marginLeft: '60px' }}>
                <TextField
                    placeholder="(60)"
                    variant="outlined"
                    style={{ display: 'flex', flex: 1, width: '65%' }}
                    name='duration'
                    value={this.state.duration}
                    type='number'
                    onChange={this.handleChange}
                    inputProps={{ min: '0', max: '300', step: '5' }}
                    error={this.state.duration ? false : this.state.error}

                />
                <Grid item md={12} xs={12}>
                    <Typography style={{ marginRight: '70px', marginTop: 5, marginBottom: 5, paddingHorizontal: 20, color: '#fff', fontSize: 15 }}>minutes</Typography>
                </Grid>
            </Grid>
        </Grid>
    )

    classTypeRepeat = () => {
        return (
            <Grid container style={{ marginTop: 20 }}>
                <Grid item md={12} xs={12}>
                    <h2>
                        Does the class repeat ?
                </h2>
                </Grid>
                <Grid item md={12} xs={12} style={{ marginTop: '20px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div onClick={() => this.setState({ recurrenceContext: '', disable: true })} style={{ backgroundColor: !this.state.recurrenceContext ? Colors.white : Colors.pri_color, border: '1px solid white', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', cursor: 'pointer', width: '150px' }}>
                            <Typography style={{ ...GlobalStyles.bodyMedium, color: !this.state.recurrenceContext ? Colors.pri_color : Colors.white, padding: '8px 0' }}>Does not repeat</Typography>
                        </div>
                        <div onClick={() => this.setState({ recurrenceContext: 'weekly_1', disable: false })} style={{ backgroundColor: this.state.recurrenceContext === 'weekly_1' ? Colors.white : Colors.pri_color, border: '1px solid white', cursor: 'pointer', width: '150px' }}>
                            <Typography style={{ ...GlobalStyles.bodyMedium, color: this.state.recurrenceContext === 'weekly_1' ? Colors.pri_color : Colors.white, padding: '8px 0' }}>Repeats every week</Typography>
                        </div>
                        <div onClick={() => this.setState({ recurrenceContext: 'weekly_2', disable: false })} style={{ backgroundColor: this.state.recurrenceContext === 'weekly_2' ? Colors.white : Colors.pri_color, border: '1px solid white', borderTopRightRadius: '10px', borderBottomRightRadius: '10px', cursor: 'pointer', width: '150px' }}>
                            <Typography style={{ ...GlobalStyles.bodyMedium, color: this.state.recurrenceContext === 'weekly_2' ? Colors.pri_color : Colors.white, padding: '8px 0' }}>Repeats every other week</Typography>
                        </div>
                    </div>
                </Grid>
                {
                    this.repeatCheck()
                }
            </Grid>
        )
    }

    classTypeLocation = () => {
        const { latitude, longitude, lat, lng } = this.state.location || {};
        const center = lat ? { lat, lng } : { lat: latitude, lng: longitude };
        return (
            <Grid container style={{ marginTop: '42px' }}>
                <Grid item md={12} xs={12}>
                    <Typography style={GlobalStyles.titleBold}>
                        Where is the class located ?
                    </Typography>
                    <Typography style={{ ...GlobalStyles.bodyMedium, textAlign: 'center', marginTop: '8px' }}>Provide either an online class link, a physical address or both.</Typography>
                </Grid>
                <Grid item md={12} xs={12} style={{ marginTop: '24px' }}>
                    <Typography style={{ ...GlobalStyles.bodyRegular, textAlign: 'left' }}>Online class</Typography>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'row', backgroundColor: '#117DC9', padding: '18px', justifyContent: 'center', cursor: 'pointer' }} onClick={this._handleVirtualLink}>
                        {
                            this.state.hideOrShowLink ?
                                <VideocamOffIcon style={{ color: '#FFFFFF', fontSize: 15, marginRight: '15px', alignSelf: 'center' }} />
                                :
                                <VideocamIcon style={{ color: '#FFFFFF', fontSize: 15, marginRight: '15px', alignSelf: 'center' }} />
                        }
                        <Typography style={{ ...GlobalStyles.bodyMedium, alignSelf: 'center' }}>
                            {this.state.hideOrShowLink ? 'Cancel' : 'Add your link'}
                        </Typography>
                    </div>
                    {this.state.hideOrShowLink ? (
                        <TextField
                            placeholder="Example - https://bit.ly/my-zoom-lecture"
                            variant="outlined"
                            style={{ display: 'flex', flex: 1, border: 0 }}
                            name='virtual_link'
                            value={this.state.virtual_link || ''}
                            type='text'
                            onChange={this.handleChange}
                            error={this.state.virtual_link ? false : this.state.error}
                        />
                    )
                        : null
                    }
                </Grid>
                <Grid item md={12} xs={12} style={{ marginTop: '30px' }}>
                    <Typography style={{ ...GlobalStyles.bodyRegular, textAlign: 'left' }}>Physical address</Typography>
                    <Map
                        google={this.props.google}
                        center={center}
                        height='300px'
                        zoom={15}
                        onChange={this.fetchLocation}
                        error={this.state.location_error_handler}
                    />

                </Grid>
            </Grid>
        );
    }

    _handleVirtualLink = () => {
        const { hideOrShowLink } = this.state;
        if (hideOrShowLink) {
            return this.setState({ hideOrShowLink: !hideOrShowLink, virtual_link: '' });
        }
        this.setState({ hideOrShowLink: !hideOrShowLink });
    }

    repeatCheck = () => (
        <Grid item md={12} xs={12} style={{ justifyContent: 'center' }}>

            <CheckBoxComponent
                onChange={this.handleCheckChange}
                name="1"
                color="primary"
                disabled={this.state.disable}
                checked={this.state.days_key['1']}
                label="Mon"
            />

            <CheckBoxComponent
                onChange={this.handleCheckChange}
                name="2"
                color="primary"
                disabled={this.state.disable}
                checked={this.state.days_key['2']}
                label="Tue"
            />

            <CheckBoxComponent
                onChange={this.handleCheckChange}
                name="3"
                color="primary"
                disabled={this.state.disable}
                checked={this.state.days_key['3']}
                label="Wed"
            />

            <CheckBoxComponent
                onChange={this.handleCheckChange}
                name="4"
                color="primary"
                disabled={this.state.disable}
                checked={this.state.days_key['4']}
                label="Thur"
            />

            <CheckBoxComponent
                onChange={this.handleCheckChange}
                name="5"
                color="primary"
                disabled={this.state.disable}
                checked={this.state.days_key['5']}
                label="Fri"
            />
            <CheckBoxComponent
                onChange={this.handleCheckChange}
                name="6"
                color="primary"
                disabled={this.state.disable}
                checked={this.state.days_key['6']}
                label="Sat"
            />

            <CheckBoxComponent
                onChange={this.handleCheckChange}
                name="0"
                color="primary"
                disabled={this.state.disable}
                checked={this.state.days_key['0']}
                label="Sun"
            />
        </Grid>
    )

    _togglePause = () => {
        const { cid, paused } = this.state || {};
        if (paused) {
            return UtilFX.toastSend(<Typography>
                Subscription already paused, Only the registered student can resume the subscription billing for this class.
                                    </Typography>, { toastId: 'toggle class' });
        }

        return UtilFX.toastSend(<ToastComponent
            _handleAccept={async () => {
                await firebase.database()
                    .ref(`classesbyuserid/${this.auth_user.uid}/${cid}`)
                    .update({
                        paused: { by_teacher: Date.now() },
                    });
            }}
            toastMessage='Pause active subscription, You are about to pause billing. Only the student will be able to resume billing after this.'
            toastAccept='Continue'
            toastReject='Cancel'
        />, { toastId: 'toggle class', autoClose: false });
    }

    newClassTypeInViewMode = () => {
        const {
            recurrence_rule, class_type, cid, paused, price,
            //   rsvp_arr,
            personal_registration_user_object
        } = this.state;
        const rrParts = GetValuesFromGoogleBasedRecurrenceRule(recurrence_rule);
        const recurrenceValue = _getClassRepeat(rrParts);
        const isPersonalAndRecurring = (class_type === 'personal') && recurrence_rule;
        const isPersonal = class_type === 'personal';
        // const hasRegistration = rsvp_arr.length;
        const personalStudent = personal_registration_user_object || {};
      
        return (
            <Grid container
                style={{
                    flex: 1,
                }}
            >
                <Grid item md={12} xs={12} style={{}}>
                    {
                        this.state.previewClass && <Typography style={{ ...GlobalStyles.titleBold, textAlign: 'center' }}>Review class details</Typography>
                    }
                    {
                        class_type === 'personal' && Boolean(cid) && (
                            <div style={{ margin: '10px 0' }}>
                                <div>
                                    {
                                        paused
                                            ?
                                            <PauseCircleOutlineOutlinedIcon />
                                            :
                                            <PlayCircleFilledOutlinedIcon />
                                    }
                                    <Typography style={{ textAlign: 'center' }}>
                                        {!this.state.personal_registration_uid // (this.state.rsvp_arr || []).length
                                            ? 'Pending student registration'
                                            : `${isPersonalAndRecurring && price
                                                ? 'Subscription is'
                                                : 'Registration is'
                                            } ${this.state.paused ? 'paused' : 'active'}`}
                                    </Typography>
                                </div>
                                {isPersonalAndRecurring &&
                                    Boolean(price) &&
                                    !!this.state.personal_registration_uid &&
                                    !this.state.paused &&
                                    (
                                        <Button
                                            size="medium"
                                            variant="contained"
                                            style={{ backgroundColor: Colors.white, color: Colors.primaryColor, borderRadius: '5px', width: '50%', boxShadow: 'none' }}
                                            onClick={() => this._togglePause()}
                                        >
                                            Pause
                                        </Button>
                                    )}
                            </div>
                        )}
                    {Boolean(this.state.cid) && (
                        <div style={{ margin: '10px 0' }}>
                            {/* {Boolean(!isPersonal || (isPersonal && !hasRegistration)) && (
                      //community or pending privates are the only valid share button states &&
                    <Button
                    size="medium"
                      variant="contained"
                    style={{ backgroundColor: Colors.white, color: Colors.primaryColor, borderRadius: '5px', boxShadow: 'none' }}
                    onClick={() => {
                        this._showShareModal();
                    }}
                    startIcon={<ArrowBackIosIcon />}
                    >
                        Share/Invite
                    </Button>
                  )} */}
                            {/* {Boolean(hasRegistration && !isPersonal) && (
                    <Button
                      buttonStyle={{
                        marginTop: 20,
                        backgroundColor: 'transparent',
                        borderWidth: 1,
                      }}
                      onPress={() => {
                        this.props.navigation.navigate(
                          ScreenArkByName.StudentsRegistered.name,
                          {
                            class_state: this.state,
                          },
                        );
                      }}
                      icon={{
                        name: 'group',
                        type: 'font-awesome',
                        color: '#fff',
                      }}
                      title={`See registered students (${
                        hasRegistration || 0
                        })`}
                    />
                  )} */}
                            {Boolean(
                                // hasRegistration &&
                                isPersonal &&
                                this.state.personal_registration_uid,
                            ) && (
                                    <div>
                                        <div style={{ justifyContent: 'center' }}>
                                           
                                          <AvatarDisplay
                                            imgStyles={{
                                                marginTop: 10,
                                                marginBottom: 10,
                                                width: 100,
                                                height: 100,
                                                borderRadius: 50,
                                            }}
                                            imgAlt={'student'}
                                            imgSrc={personalStudent.profile_photo_url}
                                            userName={personalStudent.fullname}
                                            textStyles={{fontSize:'14px'}}
                                          />
                                        </div>
                                        <Button
                                            size="medium"
                                            variant="contained"
                                            style={{ backgroundColor: Colors.white, color: Colors.primaryColor, borderRadius: '5px', width: '50%', boxShadow: 'none' }}
                                            onClick={() => {
                                                const { history } = this.props;
                                                history.push('/inbox',
                                                    {
                                                        recipientId: this.state.personal_registration_uid,
                                                        // username: personalStudent.username,
                                                        recipientPhotoUrl: personalStudent.profile_photo_url
                                                    });
                                            }}
                                        >
                                            {`Message @${personalStudent.username}`}
                                        </Button>
                                    </div>
                                )}
                        </div>
                    )}
                </Grid>
                <Grid item
                    md={12}
                    xs={12}
                    style={{
                        padding: '30px 5px 21px 5px',
                        backgroundColor: Colors.white,
                        borderRadius: '12px',
                        minWidth: '290px',
                        margin: 'auto',
                    }}
                >
                    <ViewCard iconName="title" title={this.state.title} eventHandler={() => this.setState({ render_class_data: 'description', mode: CLASS_MODE_EDIT })} />
                    <ViewCard iconName="watch_later"
                        title={this._getWhenIsClassDescription()}
                        eventHandler={() => this.setState({ render_class_data: 'date', mode: CLASS_MODE_EDIT })}
                    />
                    <ViewCard iconName="timelapse" title={`${this.state.duration} minutes`} eventHandler={() => this.setState({ render_class_data: 'duration', mode: CLASS_MODE_EDIT })} />
                    <ViewCard iconName="loop"
                        eventHandler={() => {
                            if (recurrence_rule) {
                                this._setCheckedValue(rrParts);
                            }
                            this.setState({
                                render_class_data: 'repeat',
                                mode: CLASS_MODE_EDIT,
                                recurrenceContext: Number(rrParts.interval) === 1 ? 'weekly_1' : Number(rrParts.interval) === 2 ? 'weekly_2' : '',
                                disable: !(Number(rrParts.interval) > 0)
                            });
                        }
                        }
                        title={recurrenceValue}
                    />
                    <ViewCard iconName="location_on" title={`${(this.state.location && this.state.location.address) ? this.state.location.address : 'No physical location'}`} eventHandler={() => this.setState({ render_class_data: 'location', mode: CLASS_MODE_EDIT })} />
                    <ViewCard iconName="videocam"
                        title={this.state.virtual_link || 'No link'}
                        eventHandler={() => this.setState({ render_class_data: 'location', mode: CLASS_MODE_EDIT })}
                    />
                    <ViewCard iconName="attach_money"
                        title={UtilFX.getTicketDescriptionString({ class_type: this.state.class_type, recurrence_rule: this.state.recurrence_rule, price_billing_cycle: this.state.price_billing_cycle, quantity: this.state.quantity, price: this.state.price, fees_absorb: this.state.fees_absorb, teacher: this.props.fyUser }) || 'No Tickets'}
                        eventHandler={() => {
                            if (recurrence_rule) {
                                this._setCheckedValue(rrParts);
                            }
                            this.setState({ render_class_data: 'price', mode: CLASS_MODE_EDIT, recurrenceContext: Number(rrParts.interval) === 1 ? 'weekly_1' : Number(rrParts.interval) === 2 ? 'weekly_2' : '' });
                        }}
                    />
                    <ViewCard iconName="remove_red_eye"
                        title={`${this.state.private ? 'Private' : 'Public'} and ${this.state.status === '0' ? 'active' : this.state.status === '1' ? 'cancelled' : 'subbed'}`}
                        eventHandler={() => this.setState({ render_class_data: 'status', mode: CLASS_MODE_EDIT })}
                    />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '20px', }}>
                        {
                            this.state.previewClass && this.auth_user &&
                            (<ButtonComponent onClick={() => {
                                this._handleClassDelete();
                            }} value="Delete" buttonStyle={{ ...GlobalStyles.bodyMedium, backgroundColor: 'rgba(255, 70, 70, 0.1)', borderRadius: '5px', color: Colors.red, padding: '14px', border: 'none', marginRight: '10px' }} />
                            )}

                        {
                            this.state.previewClass && !this.auth_user &&
                            (<ButtonComponent onClick={() => {
                                this.setState({ render_class_data: 'price', mode: CLASS_MODE_CREATE })
                            }} value="Back" buttonStyle={{ ...GlobalStyles.bodyMedium, backgroundColor: 'rgba(255, 70, 70, 0.1)', borderRadius: '5px', color: Colors.red, padding: '14px', border: 'none', marginRight: '10px' }} />
                            )}

                        {
                            this.state.previewClass &&
                            (<ButtonComponent onClick={() => {
                                if (!this.auth_user) {
                                    return this.setState({ isAuthenticatedClassModal: true });
                                }
                                UtilFX.toastSuccess('New class listing saved', { toastId: 'New class listing saved' });
                                this.props.history.push('/classesmanage')
                            }} value="Done" buttonStyle={{ ...GlobalStyles.bodyMedium, backgroundColor: Colors.pri_color, borderRadius: '5px', color: Colors.white, padding: '14px', border: 'none' }} />
                            )}

                        {
                            !this.state.previewClass && (
                                <ButtonComponent startIcon={<ArrowBackIosIcon />} onClick={() => this.props.history.push('/classesmanage')} value="Back" buttonStyle={{ ...GlobalStyles.bodyMedium, backgroundColor: Colors.pri_color, borderRadius: '5px', color: Colors.white, padding: '14px', marginRight: '10px', border: 'none' }}
                                />
                            )}
                        {
                            !this.state.previewClass && (
                                <ButtonComponent startIcon={<DeleteIcon />} onClick={() => this._handleClassDelete()} value="Delete" buttonStyle={{ ...GlobalStyles.bodyMedium, backgroundColor: 'rgba(255, 70, 70, 0.1)', borderRadius: '5px', color: Colors.red, padding: '14px', border: 'none' }}
                                />
                            )}
                    </div>
                </Grid>
            </Grid>
        );
    }

    ButtonNavigation = () => {
        const buttonNavStyle = { ...GlobalStyles.bodyMedium, backgroundColor: Colors.white, borderRadius: '10px', color: Colors.pri_color, padding: '18px', width: '130px' };
        return (
            <Grid item container justify="space-between" style={{ margin: '35px 0px' }}>
                {
                    Boolean(this.state.mode === CLASS_MODE_EDIT) && (
                        <Grid item>
                            <ButtonComponent
                                onClick={() => {
                                    const old_state = this.original_class_state;
                                    this.setState(old_state);
                                    this.setState({ mode: CLASS_MODE_VIEW });
                                }}
                                value="Cancel" buttonStyle={{ ...buttonNavStyle }}
                            />
                        </Grid>
                    )}

                {
                    Boolean(this.state.mode === CLASS_MODE_EDIT) && (
                        <Grid item>
                            <ButtonComponent onClick={() => {
                                if (this.auth_user) {
                                    this.handleSave();
                                }
                                else {
                                    this.handleSubmit();
                                }
                            }} value="Save" buttonStyle={{ ...buttonNavStyle }} />
                        </Grid>
                    )}

                {
                    Boolean(this.state.mode === CLASS_MODE_CREATE) && Boolean(!this.state.copy_class || this.state.render_class_data !== 'description') && (
                        <Grid item>
                            <ButtonComponent onClick={this.previousClasstype} value="Back" buttonStyle={{ ...buttonNavStyle }} />
                        </Grid>
                    )}

                {
                    Boolean(this.state.copy_class && this.state.render_class_data === 'description') && Boolean(this.state.mode === CLASS_MODE_CREATE) && (
                        <Grid item>
                            <ButtonComponent onClick={() => this.props.history.goBack()} value="Cancel" buttonStyle={{ ...buttonNavStyle }} />
                        </Grid>
                    )}

                {Boolean(this.state.render_class_data === 'price' && this.state.mode === CLASS_MODE_CREATE) && (
                    <Grid item>
                        <ButtonComponent onClick={() => this.nextClasstype()} value={this.auth_user ? "Save & Review" : "Review"} buttonStyle={{ ...buttonNavStyle, width: !this.auth_user ? '130px' : 'auto' }} />
                    </Grid>
                )}
                {
                    Boolean(this.state.render_class_data !== 'price') && Boolean(this.state.mode === CLASS_MODE_CREATE) && (
                        <Grid item>
                            <ButtonComponent onClick={this.nextClasstype} value="Next" buttonStyle={{ ...buttonNavStyle }} />
                        </Grid>
                    )}

            </Grid>
        )
    }

    handleSave = () => {
        if ((this.state.title || '').trim().length < 1) {
            this.setState({ error: true });
            UtilFX.toastError('Title is required', { toastId: 'Title is required' });
            return;
        }
        if (!this.state.time || !this.state.date || (!this.state.timeZone && !this.state.defaultTimeZoneValue)) {
            this.setState({ error: true });
            UtilFX.toastError('Date, Time and Timezone is required', { toastId: 'Date and Time is required' });
            return;
        }
        if (!this.state.duration) {
            this.setState({ error: true });
            UtilFX.toastError('Duration is required', { toastId: 'Duration is required' });
            return;
        }
        if (this.state.recurrenceContext) {
            const values = Object.values(this.state.days_key);
            if (values.indexOf(true) === -1) {
                UtilFX.toastError('You need to pick a day', { toastId: 'You need to pick a day' });
                return;
            }
        }
        if (!this.state.virtual_link && !this.state.location.lat) {
            this.setState({ error: true, hideOrShowLink: true, location_error_handler: true });
            UtilFX.toastError('A valid URL or location input is required', { toastId: 'Valid Video URL is required' });
            return;
        }
        if (this.state.virtual_link) {
            const checkUrl = _handleURLValidation(this.state.virtual_link);
            if (!checkUrl) {
                this.setState({ error: true, hideOrShowLink: true });
                UtilFX.toastError('A valid URL is required', { toastId: 'Valid Video URL is required' });
                return;
            }
        }
        if (
            (parseFloat(this._getFeeCalculation()) >= parseFloat(this._getBuyerTotal()))
            && this.state.fees_absorb
        ) {
            const err_msg = `Please adjust pricing. Your absorbed fee (${this._getFeeCalculation()}) must be less than the price (${this._getBuyerTotal()}). \n`;
            this.setState({ error: true });
            UtilFX.toastError(err_msg, { toastId: err_msg });
            return;
        }
        if (this.state.freeClass) {
            this.setState({ price: null, fees_absorb: null });
        }
        const uid = this.auth_user.uid;

        const classData = {
            uid: uid,
            location: this.state.location || null,
            duration: this.state.duration,
            start_timestamp: this.state.start_timestamp,
            time_key: this.state.time_key,
            recurrence_rule: this._setRecurrenceRule() || null,
            title: this.state.title,
            description: this.state.description || null,
            status: this._updateStatusIndex() || null,
            price: this.state.price || null,
            price_billing_cycle: this.state.price_billing_cycle || null,
            quantity: this.state.quantity || null,
            time_zone: this.state.timeZone || null,
            fees_absorb: this.state.fees_absorb || null,
            private: this.state.private || null,
            class_type: this.state.class_type || 'community',
            virtual_link: this.state.virtual_link || null,
        };
        classData.date_edited = firebase.database.ServerValue.TIMESTAMP;
        const classdbref = 'classesbyuserid/' + uid + '/' + this.state.cid;
        const classtodeletedbref = false;
        firebase.database()
            .ref(classdbref)
            .update(classData)
            .then(() => {
                if (classtodeletedbref) {
                    firebase.database()
                        .ref(classtodeletedbref)
                        .remove();
                }
                this.original_class_state = this.state; //update the original to the new save to prevent a fals "exit without saving warning"
                // Analytics.logEvent("classedit_update_success");
                UtilFX.toastSuccess('Class updated successfully', { toastId: 'Class updated successfully' });
                this.setState({ mode: CLASS_MODE_VIEW });
            })
            .catch(() => {
                // Analytics.logEvent("classedit_update_error");
                UtilFX.toastError('Error updating class', { toastId: 'Error updating class', });
            });
    }

    geoSuccess = (position) => {
        const { coords: { latitude, longitude, accuracy } = {}, timestamp } = position;
        const successCustomized = { latitude, longitude, accuracy, timestamp };
        this.setState({ geoError: null, geoSuccess: successCustomized });
    }

    geoError = (error) => {
        // console.warn("log Error", error);

        switch (error.code) {
            case error.PERMISSION_DENIED: {
                // console.log("User denied the request for Geolocation.");
                break;
            }
            case error.POSITION_UNAVAILABLE: {
                // console.log("Location information is unavailable.");
                break;
            }
            case error.TIMEOUT: {
                // console.log("The request to get user location timed out.");
                break;
            }
            case error.UNKNOWN_ERROR: {
                // console.log("An unknown error occurred.");
                break;
            }
            default:
                break;
        }

        const { message: errorMsg, code: errorCode } = error;
        const errCustomized = { errorMsg, errorCode };
        this.setState({ geoError: errCustomized, geoSuccess: null });
    }

    handleChange = (e) => {
        if (e.target.name === 'duration' && e.target.value > 999) return true;
        this.setState({ [e.target.name]: e.target.value });
    }

    handleCheckChange = (e) => {
        let tmpArr = this.state.recurringDaysOfWeek;
        const index = e.target.name;
        const data = { [e.target.name]: e.target.checked };
        this.setState({ ...this.state, days_key: { ...this.state.days_key, ...data } });
        const currentCheck = tmpArr.indexOf(index);
        if (currentCheck > -1) {
            tmpArr = tmpArr.filter(id => id !== index);
        } else {
            tmpArr.push(index);
        }
        this.setState({ recurringDaysOfWeek: tmpArr });
    }

    _getFeeCalculation = () => {
        const fee = UtilFX.getFeeCalculation({
            price: this.state && this.state.price,
            fees_absorb: this.state.fees_absorb,
            teacher: this.props.fyUser
        });
        return fee;
    };

    _getReceiversTotal = () => {
        return (Number(this._getPriceFormatString()) - Number(this._getFeeCalculation())).toFixed(2);
    }

    _handleClassDelete = () => {
        UtilFX.toastSend(<ToastComponent _handleAccept={this._handleRemove} toastMessage='Are you sure you want to delete the class?' toastAccept='Remove' toastReject='Cancel' />, { toastId: 'remove class' });
    }

    _handleRemove = () => {
        const classtoremovedbref = 'classesbyuserid/' + this.auth_user.uid + '/' + this.state.cid;
        firebase.database()
            .ref(classtoremovedbref)
            .update({ date_deleted: moment().valueOf() })
            .then(() => {
                UtilFX.toastSuccess('successfully deleted class', { toastId: 'successfully deleted class' });
                this.props.history.push('/classesmanage');
            })
            .catch(() => {
                UtilFX.toastError('Error deleting class', { toastId: 'Error deleting class' });
            });
    }

    handleToggler = (e) => {
        this.setState({ [e.target.name]: e.target.checked });
    }

    _setRecurrenceRule = () => {
        const { recurringDaysOfWeek, recurrenceContext } = this.state || {};

        // console.warn({ recurringDaysOfWeek, recurrenceContext });
        //Does not repeat
        //Weekly: Mo,Tu,We,Th,Fr,Sa,Su
        //Every other week:
        let recurrenceRule = null;
        const txtToTest = (recurrenceContext && recurrenceContext.toLowerCase()) || '';
        if (txtToTest.indexOf('weekly_') > -1 && recurringDaysOfWeek.length) {
            recurrenceRule = 'FREQ=WEEKLY';
            const joinedWeekdayNicknames = DaysOfWeekItems.filter(item => (recurringDaysOfWeek.indexOf(item.id) > -1)).map(item => item.nickname).join(',');
            if (txtToTest === 'weekly_1') {
                recurrenceRule += `;BYDAY=${joinedWeekdayNicknames.toUpperCase()};INTERVAL=1`;
            } else if (txtToTest === 'weekly_2') {
                recurrenceRule += `;BYDAY=${joinedWeekdayNicknames.toUpperCase()};INTERVAL=2`;
            }
        }
        this.setState({ recurrence_rule: recurrenceRule });
        return recurrenceRule;
    }

    _setupRecurrenceState = () => {
        const { recurrence_rule: recurrenceRule = '' } = this.props.classData || {};
        let recurringDaysOfWeek = [];
        let recurrenceContext;
        //Does not repeat
        //Weekly: Mo,Tu,We,Th,Fr,Sa,Su
        //Every other week:
        const { byday, interval } = GetValuesFromGoogleBasedRecurrenceRule(recurrenceRule);
        if (Number(interval) === 1) {
            recurrenceContext = 'weekly_1';
        }
        if (Number(interval) === 2) {
            recurrenceContext = 'weekly_2';
        }
        if (recurrenceRule.includes('FREQ=WEEKLY') && byday) {
            recurringDaysOfWeek = DaysOfWeekItems.filter(item => byday.toLowerCase().includes(item.nickname.toLowerCase())).map(item => item.id);
        }
        const daysKey = { ...recurringDaysOfWeek.map(v => true) };
        // console.warn({ daysKey, props: this.props, recurrenceRule, recurrenceContext, recurringDaysOfWeek });
        this.setState({ recurrenceContext, recurringDaysOfWeek, days_key: daysKey });
    }

    handlePricing = (e) => {
        let value = e.target.value;
        if (e.target.name === 'quantity') {
            if (value === 0) {
                value = '';
            }
            value = value.replace(/\D/g, '');
        } else if (e.target.name === 'price') {
            if (value.indexOf('.') === value.length - 1) value += '00';
            value = value.replace(/[^0-9]/g, '');
            value = parseFloat(value).toFixed(2) / 100;
        }
        this.setState({ [e.target.name]: value });
    }

    _getBuyerTotal = () => {
        const btotal = UtilFX.getBuyerTotal(
            {
                price: this.state && this.state.price,
                fees_absorb: this.state.fees_absorb,
                teacher: this.props.fyUser
            }
        );
        return btotal;
        // return parseFloat(this.state.price||0) + parseFloat(this.state.fees_absorb?0:this._getFeeCalculation())
    };

    _getPriceFormatString = () => {
        if (this.state && this.state.price) {
            if (isNaN(this.state.price)) return false;
            // else
            return parseFloat(this.state.price)
                .toFixed(2)
                .toString();
        }
        return false;
    };

    handleSubmit = () => {
        const uid = (this.auth_user && this.auth_user.uid) || null;
        const price = this.state.freeClass ? null : this.state.price;
        const feesAbsorb = this.state.freeClass ? null : this.state.fees_absorb;
        const classData = {
            uid: uid,
            location: this.state.location || null,
            duration: this.state.duration,
            start_timestamp: this.state.start_timestamp,
            //end: this.state.when_onetime_end,
            time_key: this.state.time_key,
            recurrence_rule: this._setRecurrenceRule() || null,
            //day_nicknames: this.state.day_nicknames,
            //day_fullnames: this.state.day_fullnames,
            //day_ints: this.state.day_ints,
            //day_key: this.state.day_key || this.day_key_map[this.state.day],
            title: this.state.title,
            description: this.state.description || null,
            status: this._updateStatusIndex() || null,
            price: price || null,
            price_billing_cycle: this.state.price_billing_cycle || null,
            quantity: this.state.quantity || null,
            fees_absorb: feesAbsorb || null,
            private: this.state.private || null,
            class_type: this.state.class_type || 'community',
            virtual_link: this.state.virtual_link || null,
            time_zone: this.state.timeZone || null
        };
        if (!this.auth_user) {
            return this.setState({ mode: CLASS_MODE_VIEW, pendingClassData: { ...classData } }, () => this.original_class_state = this.state);
        }
        let newcid;
        if (!this.state.cid) {
            classData.date_created = firebase.database.ServerValue.TIMESTAMP;
            const newclassRef = firebase.database()
                .ref()
                .child('classesbyuserid/' + uid)
                .push(classData);

            newcid = newclassRef.key;
            //classes dont exist by themselves at all and currently have no relevancy, no need to data fan-out
            //currently events will exist alone
            newclassRef
                .update(classData)
                .then(async () => {
                    this.setState({ cid: newcid, mode: CLASS_MODE_VIEW }, () => {
                        this.original_class_state = this.state; //update the original to the new save to prevent a fals "exit without saving warning"
                    }); //consequent save calls will update not duplicate

                    UtilFX.logAnalytics('class created', { ...classData });
                    UtilFX.setupTeacherGroupChat({ uid: this.auth_user.uid, username: this.auth_user.username, profilePhotoUrl: this.auth_user.profile_photo_url });
                    UtilFX.setupClassGroupChat({ uid: this.auth_user.uid, classTitle: this.state.title, profilePhotoUrl: this.auth_user.profile_photo_url, classId: newcid });
                })
                .catch(async (err) => {
                    console.warn('issue creating class', err);
                    await UtilFX.toastError('There was a problem saving your class. Please try again', { toastId: 'There was a problem saving your class. Please try again' });
                });
        }
    }

    _calculate_start_time = () => {
        if (!this.state.time_key) return new Date();

        const d = new Date();
        const time_key_str = (this.state.time_key && this.state.time_key.toFixed(2)) + ''; //toFixed adds trailing 0 for single digit numbers
        const tmp_hours = time_key_str.split('.')[0];
        const tmp_minutes = time_key_str.split('.')[1] || '00';
        d.setHours(tmp_hours);
        d.setMinutes(tmp_minutes);
        return d;
    };

    handleDate = (e) => {
        if (e) {
            const startTimestampCheck = this.state.start_timestamp || moment().hours(12).minutes(0).valueOf();
            const day = new Date(e).getDate();
            const month = new Date(e).getMonth();
            const year = new Date(e).getFullYear();
            this.setState({
                date: moment(startTimestampCheck)
                    .year(year)
                    .month(month)
                    .date(day),
                start_timestamp: moment(startTimestampCheck)
                    .year(year)
                    .month(month)
                    .date(day).valueOf(),
            });
        } else {
            this.setState({ date: null });
        }
    }

    handleTime = (date) => {
        // if (date === null || date === '') return date = null
        if (date) {
            const startTimestampCheck = this.state.start_timestamp || moment().hours(12).minutes(0).valueOf();
            const the_hours = date.getHours(); //(remember this is 24 hour clock default, only used for date key)
            const the_minutes = ('00' + (Math.floor(date.getMinutes() / 5) * 5)).slice(-2); //slice to make sure it is 2 digits
            const date_fmt_key = the_hours + (0.01 * the_minutes); //24 hour clock to make sorting by time easier
            //added Math.floor to round down to nearest multiple of 5 for time since clock will take current time
            //if not which wont fall into multipel of 5.
            //careful not to round up so you dont accidentlaly end up at 60..which isnt real. so not suing ceiling..floor for sure
            const date_fmt = (the_hours % 12 || 12)
                + ':'
                + the_minutes
                + ' '
                + (the_hours < 12 ? 'AM' : 'PM');
            // console.log("A time has been picked: ", date_fmt);
            //date_fmt = date_fmt.split(":")[0]+":"+the_minutes+" "+date_fmt.split(' ')[1]
            // console.log("A time has been FORMATTED after picked: ", date_fmt);
            // console.log("a raw key value time created", date_fmt_key);
            this.setState({
                time_formatted: date_fmt,
                time_key: date_fmt_key,
                time: moment(startTimestampCheck).hour(the_hours).minute(Number(the_minutes)).valueOf(),
                start_timestamp: moment(startTimestampCheck).hour(the_hours).minute(Number(the_minutes)).valueOf()
            });
        } else {
            this.setState({ time: null, time_key: null });
        }
    }

    _getWhenIsClassDescription = () => {
        const { start_timestamp, timeZone, defaultTimeZoneValue } = this.state;
        if (start_timestamp) {
            const prettyText = `${moment(start_timestamp).format('ddd M/DD @ h:mm a')} ${UtilFX.checkTimeZoneValue({ timeZone, defaultTimeZoneValue })}`;

            return prettyText;
        }
        return false;
    };

    _setCheckedValue = (rrParts) => {
        const recurringDaysOfWeek = rrParts.byday.split(',').map(nick => nick && DaysOfWeekItems.filter(e => e.nickname === (nick[0].toUpperCase() + nick[1].toLowerCase()))[0].id);
        // debugger;
        this.setState({
            recurringDaysOfWeek,
            days_key: {
                0: recurringDaysOfWeek.includes(String(0)),
                1: recurringDaysOfWeek.includes(String(1)),
                2: recurringDaysOfWeek.includes(String(2)),
                3: recurringDaysOfWeek.includes(String(3)),
                4: recurringDaysOfWeek.includes(String(4)),
                5: recurringDaysOfWeek.includes(String(5)),
                6: recurringDaysOfWeek.includes(String(6)),
            }
        });
    }

    handleCheckChange = (e) => {
        let tmpArr = this.state.recurringDaysOfWeek;
        const index = e.target.name;
        const data = { [e.target.name]: e.target.checked };
        this.setState({ ...this.state, days_key: { ...this.state.days_key, ...data } });
        const currentCheck = tmpArr.indexOf(index);
        if (currentCheck > -1) {
            tmpArr = tmpArr.filter(id => id !== index);
        } else {
            tmpArr.push(index);
        }
        this.setState({ recurringDaysOfWeek: tmpArr });
    }

    _updateStatusIndex = () => {
        let _status = null;
        if (this.state.status === '1') { _status = 'cancel'; } else if (this.state.status === '2') { _status = 'sub'; }

        // Analytics.logEvent("classedit_status_change");
        // Analytics.logEvent("classedit_status_change_" + (_status || ""));
        return _status;
        // this.setState({ status: _status });
        // console.log("index and status", selectedIndex, _status);
    };

    _getTicketDescriptionString = () => {
        const { class_type, recurrence_rule, price_billing_cycle } = this.state;
        const isPersonalAndRecurring = (class_type === 'personal') && recurrence_rule;
        const p = this._getBuyerTotal()
            // .toFixed(2)
            .toString();
        const q = this.state && this.state.quantity;
        let f = '';
        if (q) { f += q + ' tickets '; }
        if (p && parseFloat(p)) { f += (q ? '@' : '') + ' $' + p + (isPersonalAndRecurring ? ` / auto-pay: ${price_billing_cycle || 'choose frequency'}` : ' each'); }

        f = f.trim();
        return f;
    };

    nextClasstype = () => {
        const { render_class_data, title, class_type, date, time, duration, recurrenceContext, days_key, fees_absorb, location_check, timeZone, virtual_link, defaultTimeZoneValue } = this.state;
        switch (render_class_data) {
            case 'description':
                if ((title || '').trim().length < 1) {
                    this.setState({ error: true });
                    UtilFX.toastError('Title is required', { toastId: 'Title is required' });
                    break;
                }
                if (class_type === 'community') {
                    this.setState({ render_class_data: 'status', progress: 10, error: false });
                    break;
                } else {
                    this.setState({ render_class_data: 'date', progress: 20, error: false });
                    break;
                }
            case 'status':
                this.setState({ render_class_data: 'date', progress: 20, error: false });
                break;
            case 'date':
                if (!date || !time || (!timeZone && !defaultTimeZoneValue)) {
                    this.setState({ error: true });
                    UtilFX.toastError('Date, Time and Timezone is required', { toastId: 'Date and Time is required' });
                    break;
                }
                this.setState({ render_class_data: 'duration', progress: 40, error: false });
                break;
            case 'duration':
                if (!duration) {
                    this.setState({ error: true });
                    UtilFX.toastError('Duration is required', { toastId: 'Duration is required' });
                    break;
                }
                this.setState({ render_class_data: 'repeat', progress: 60, error: false });
                break;
            case 'repeat':
                if (recurrenceContext) {
                    const values = Object.values(days_key);
                    if (values.indexOf(true) > -1) {
                        this.setState({ render_class_data: 'location', progress: 80, error: false });
                    } else {
                        UtilFX.toastError('You need to pick a day ', { toastId: 'You need to pick a day ' });
                    }
                    break;
                }
                this.setState({ render_class_data: 'location', progress: 80, error: false });
                break;
            case 'location':
                if (virtual_link) {
                    const checkUrl = _handleURLValidation(virtual_link);
                    if (!checkUrl) {
                        this.setState({ error: true, hideOrShowLink: true });
                        UtilFX.toastError('A valid URL is required', { toastId: 'Valid Video URL is required' });
                        break;
                    }
                }
                if (!virtual_link && !location_check) {
                    this.setState({ error: true, hideOrShowLink: true, location_error_handler: true });
                    UtilFX.toastError('A valid URL or location input is required', { toastId: 'Valid Video URL is required' });

                    break;
                }
                this.setState({ render_class_data: 'price', progress: 100, error: false });
                break;
            case 'price':
                if (
                    (parseFloat(this._getFeeCalculation()) >= parseFloat(this._getBuyerTotal()))
                    && fees_absorb
                ) {
                    const err_msg = `Please adjust pricing. Your absorbed fee (${this._getFeeCalculation()}) must be less than the price (${this._getBuyerTotal()}). \n`;
                    this.setState({ error: true });
                    UtilFX.toastError(err_msg, { toastId: err_msg });
                    break;
                }
                this.handleSubmit();
                break;
            default:
                break;
        }
    }

    previousClasstype = () => {
        const { render_class_data, class_type } = this.state;
        switch (render_class_data) {
            case 'price':
                return this.setState({ render_class_data: 'location', progress: 80 });
            case 'location':
                return this.setState({ render_class_data: 'repeat', progress: 60 });
            case 'repeat':
                return this.setState({ render_class_data: 'duration', progress: 40 });
            case 'duration':
                return this.setState({ render_class_data: 'date', progress: 20 });
            case 'date':
                if (class_type === 'community') {
                    this.setState({ render_class_data: 'status', progress: 10 });
                } else {
                    this.setState({ render_class_data: 'description', progress: 0 });
                }
                break;
            case 'status':
                this.setState({ render_class_data: 'description', progress: 0 });
                break;
            case 'description':
                this.setState({ class_type: '', render_class_data: '' });
                break;
            default:
                break;
        }
    }

    render() {
        const { class_type, recurrence_rule } = this.state;
        const isPersonalAndRecurring = (class_type === 'personal') && recurrence_rule;

        return (

            <classContext.Provider value={{ ...this.state }}>
                <MuiThemeProvider theme={styled}>
                    {
                        Boolean(this.state.mode === CLASS_MODE_VIEW) &&
                        <div style={{ margin: '20px 16px' }}>
                            <this.newClassTypeInViewMode />
                        </div>
                    }

                    {
                        Boolean(this.state.mode === CLASS_MODE_CREATE && !(class_type)) && (
                            <div style={{ flex: 1, flexBasis: '100%', display: 'flex', margin: '10px 16px', }}>
                                <this.classTypePrompt />
                            </div>
                        )}

                    {
                        Boolean(this.state.mode === CLASS_MODE_CREATE && class_type) && (
                            <div style={{ flex: 1, flexBasis: '100%', margin: '10px 16px' }}>
                                <div style={{ marginTop: '20px' }}>
                                    <BorderLinearProgress
                                        variant="determinate"
                                        color="primary"
                                        value={this.state.progress}
                                    />
                                </div>
                            </div>
                        )}

                    {
                        Boolean(this.state.mode === CLASS_MODE_CREATE || this.state.mode === CLASS_MODE_EDIT) && (
                            <div
                                className='class-item'
                                style={{
                                    margin: '0px 16px',
                                }}
                            >

                                {
                                    Boolean(this.state.render_class_data === 'description') &&
                                    <this.classTypeDescription />
                                }

                                {
                                    Boolean(this.state.render_class_data === 'status') &&
                                    <this.classTypeStatus />
                                }

                                {
                                    Boolean(this.state.render_class_data === 'date') &&
                                    <this.classTypeDate />
                                }

                                {
                                    Boolean(this.state.render_class_data === 'duration') &&
                                    <this.classTypeDuration />
                                }

                                {
                                    Boolean(this.state.render_class_data === 'repeat') &&
                                    <this.classTypeRepeat />
                                }
                                {
                                    Boolean(this.state.render_class_data === 'location') &&
                                    <this.classTypeLocation />
                                }
                                {
                                    Boolean(this.state.render_class_data === 'price') &&
                                    <this.classPrice isPersonalAndRecurring={isPersonalAndRecurring} />
                                }
                                {
                                    Boolean(class_type || this.render_class_data) &&

                                    <this.ButtonNavigation mode={this.state.mode} />
                                }
                            </div>
                        )}
                    <Modal
                        disableAutoFocus
                        disableEnforceFocus
                        aria-labelledby="isAuthenticated class modal"
                        aria-describedby="adding a class from outside"
                        className="center-modal"
                        open={this.state.isAuthenticatedClassModal}
                        onClose={() => {
                            this.setState({ isAuthenticatedClassModal: false })
                        }}
                    >
                        <div className="modal-content-wrapper" style={{ borderRadius: '30px', padding: '5px 10px' }}>
                            <Typography style={{ fontFamily: 'Rubik', fontStyle: 'normal', fontWeight: '500', fontSize: '32px', marginTop: '42px' }}>Sign up to save your class!</Typography>
                            <Typography style={{ marginTop: '24px', ...GlobalStyles.bodyMedium, fontWeight: 'normal' }}>After signing up you can manage and share your class. </Typography>
                            <Button size="large"
                                variant="contained"
                                style={{ backgroundColor: Colors.primaryColor, color: Colors.white, borderRadius: '12px', width: '80%', marginTop: '24px', boxShadow: '0 0 0 0', marginBottom: '42px' }}
                                onClick={() => {
                                    if (!this.auth_user) {
                                        window.localStorage.setItem('pendingAddClass', JSON.stringify(this.state.pendingClassData));
                                        this.props.history.push('/signup');
                                    }
                                }}
                            >
                                SIGN UP
                        </Button>
                        </div>
                    </Modal>
                </MuiThemeProvider>
            </classContext.Provider>
        );
    }
}

ClassesEdit.propTypes = {
    context: PropTypes.shape({
        userAuthenticated: PropTypes.shape({
            uid: PropTypes.string.isRequired
        })
    }),
    fyUser: PropTypes.shape({
        uid: PropTypes.string.isRequired
    }),
    classData: PropTypes.object
};
export default ClassesEdit;
