import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { Colors, GlobalStyles, DefaultProfilePhotoUrl } from '../../../utils';
import TitleCardOptions from '../TitleCardOptions';
import { Link } from 'react-router-dom';
import {
  firestore,
} from '../../../fire';
import AvatarDisplay from '../../AvatarDisplay';

const MessageHeader = ({
  name,
  username,
  imgUrl,
  groupMessagePopover,
  messageHeaderPopover,
  eventHandler,
  handleGroupInfoModal,
  convoId,
  dmEntryPoint,
  uid,
  mutedConvoIds,
  handleMutedConvoIds,
  classId,
  openNewMemberSnackBar,
  classChat,
  chatBookmarkNavigator,
  currentChatToggleHeader,
  props,
  listOfAllMessages,
  updateChatMembershipStatus,
  updateClearAllCurrentConversationChat,
  recipientId,
  listOfRecipientBlockedUsers,
  listOfOwnerBlockedUsers,
  handleBlockedUsersModal
}) => {
  const [showIcon, setShowIcon] = useState({
    mute: false,
    pin: false,
    block: false,
  });
  const [pendingGroupChatMembers, setPendingGroupChatMembers] = useState([]);
  const [acceptedGroupChatMembers, setAcceptedGroupChatMembers] = useState([]);
  const [hideGroupChatMembers, setHideGroupChatMembers] = useState([]);
  const [mutedMembers, setMutedMembers] = useState([]);
  const [chatOwnerId, setChatOwnerId] = useState('');
  const [groupMembers, setGroupMembers] = useState([]);
 
  useEffect(() => {
    async function fetchData() {
        if (convoId) {
          let query = await firestore.collection('ConvoMembers');
          query = query.where('convo_id', '==', convoId);
          const res = await query.get();
          const pendingMembers = [];
          const acceptedMembers = [];
          const allMembers = [];
          const muted = [];
          res.forEach((doc) => {
            allMembers.push({documentId: doc.id, ...doc.data()})
              if (doc.data().status === 'pending') {
                  pendingMembers.push({ documentId: doc.id, ...doc.data() });
              } else if (doc.data().status === 'accepted') {
                  acceptedMembers.push({ documentId: doc.id, ...doc.data() });
                  if (doc.data().muted) {
                    muted.push(doc.id);
                  }
              }
          });
          setMutedMembers(muted);
          setPendingGroupChatMembers(pendingMembers);
          setAcceptedGroupChatMembers(acceptedMembers);
          setGroupMembers(allMembers)
          if (acceptedMembers.length) {
            let query2 = await firestore.collection('ConvoMetadata');
            query2 = query2.where('convo_id', '==', acceptedMembers[0].convo_id);
            query2.get().then((res) => {
              let teacherId = '';
              if (!res.empty) {
                teacherId = res.docs[0].data().teacher_id;
              }
              setChatOwnerId(teacherId);
            });
          }
        }
    }
    fetchData();
  }, [convoId]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: Colors.primaryColor2,
        padding: '6px',
        flex: 1,
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="hide-back-arrow">
          <span
            className="material-icons"
            style={{
              marginRight: '20px',
              cursor: 'pointer',
              marginTop: '10px',
            }}
            onClick={eventHandler}
          >
            arrow_back
          </span>
        </div>
        {<Link
          to={
            {
              pathname: `/${username}`,
            }
          }
          target="_blank" 
          style={{textDecoration: 'none'}}
        >
                
          <AvatarDisplay
            imgSrc={imgUrl || DefaultProfilePhotoUrl}
            imgStyles={{ width: '48px', height: '48px', borderRadius: '24px' }}
            imgAlt={'chat-avatar'}
            userName={name}
            textStyles={{
              ...GlobalStyles.bodyMedium,
              color: '#fff',
              textAlign: 'center',
              borderRadius: '50px',
              border: '2px solid',
              display: 'flex',
              alignItems: 'center',
              height: '30px',
              width: '30px',
              justifyContent: 'center',
              margin: 'auto',
              marginTop: '5px'
          }}
          />

        </Link>}
        <Typography
          style={{
            ...GlobalStyles.bodyMedium,
            color: Colors.white,
            textAlign: 'left',
            marginLeft: '8px',
            marginTop: '13px',
          }}
        >
          {name}
        </Typography>
        <Typography style={{ backgroundColor: Colors.delineatedBlue, color: Colors.white, padding: '4px 12px', borderRadius: '20px', alignSelf: 'center', fontSize: '10px', marginLeft: '10px', fontWeight: '500', fontFamily: 'Rubik', fontStyle: 'normal' }}>
          {currentChatToggleHeader === 'dm' ? 'DM' : currentChatToggleHeader === 'class' ? 'CLASS GROUP' : 'TEACHER\'S GROUP'}
        </Typography>
        {showIcon.mute && (
          <span
            className="material-icons outlined"
            style={{
              color: Colors.white,
              marginLeft: '13px',
              marginTop: '10px',
            }}
          >
            notifications_off
          </span>
        )}
        { (listOfRecipientBlockedUsers.includes(uid) || listOfOwnerBlockedUsers.includes(recipientId)) &&
          <span
            className="material-icons outlined"
            style={{
              color: Colors.white,
              marginLeft: '13px',
              marginTop: '10px',
            }}
          >
            block
          </span>
        }
      </div>
      <div style={{ marginTop: '10px' }}>
        <TitleCardOptions
          groupMessagePopover={groupMessagePopover}
          messageHeaderPopover={messageHeaderPopover}
          username={username}
          setShowIcon={setShowIcon}
          showIcon={showIcon}
          handleGroupInfoModal={handleGroupInfoModal}
          convoId={convoId}
          dmEntryPoint={dmEntryPoint}
          uid={uid}
          imgUrl={imgUrl}
          name={name}
          mutedConvoIds={mutedConvoIds}
          handleMutedConvoIds={handleMutedConvoIds}
          props={props}
          classId={classId}
          pendingGroupChatMembers={pendingGroupChatMembers}
          acceptedGroupChatMembers={acceptedGroupChatMembers}
          mutedMembers={mutedMembers}
          hideGroupChatMembers={hideGroupChatMembers}
          setPendingGroupChatMembers={setPendingGroupChatMembers}
          setAcceptedGroupChatMembers={setAcceptedGroupChatMembers}
          setHideGroupChatMembers={setHideGroupChatMembers}
          setMutedMembers={setMutedMembers}
          chatOwnerId={chatOwnerId}
          chatBookmarkNavigator={chatBookmarkNavigator}
          openNewMemberSnackBar={openNewMemberSnackBar}
          classChat={classChat}
          listOfAllMessages={listOfAllMessages}
          currentChatToggleHeader={currentChatToggleHeader}
          updateChatMembershipStatus={updateChatMembershipStatus}
          updateClearAllCurrentConversationChat={updateClearAllCurrentConversationChat}
          recipientId={recipientId}
          listOfOwnerBlockedUsers={listOfOwnerBlockedUsers}
          handleBlockedUsersModal={handleBlockedUsersModal}
          groupMembers={groupMembers}
        />
      </div>
    </div>
  );
};

export default MessageHeader;
