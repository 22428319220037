import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ImgSnackSuccess from '../../snack_success.svg';
import ImgSnackSuccessGray from './GroupChat/svg/copy_link_gray.svg';
import { Colors, GlobalStyles } from '../../utils';

const SnackBar = ({ closeSnackBar, text, showCancelIcon }) => {
    return (
        <Grid
            container
            className="custom-modal"
            style={{ backgroundColor: 'transparent' }}
            onClick={closeSnackBar}
        >
            <Grid
                item
                xs={12}
                md={12}
                style={{
                margin: '50px',
                }}
            >
                <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: 'auto',
                    width: '300px',
                }}
                >
                <div
                    style={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: !showCancelIcon ? Colors.pinkClosest : Colors.grey1,
                    padding: '15px',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px',
                    borderTopRightRadius: !showCancelIcon ? '8px' : null,
                    borderBottomRightRadius: !showCancelIcon ? '8px' : null,
                    }}
                >
                    <img
                    src={ !showCancelIcon ? ImgSnackSuccessGray : ImgSnackSuccess}
                    alt="snack-success"
                    style={{ marginLeft: '18px' }}
                    />
                    <Typography
                    style={{
                        ...GlobalStyles.bodyRegular,
                        color: Colors.grey5,
                        alignSelf: 'center',
                        marginLeft: '25px',
                        marginRight: '29px',
                    }}
                    >
                    {text}
                    </Typography>
                </div>
                {showCancelIcon && (
                    <snap
                        className="material-icons"
                        style={{
                        color: Colors.white,
                        cursor: 'pointer',
                        alignSelf: 'center',
                        padding: '15px 17px',
                        backgroundColor: Colors.grey1,
                        borderTopRightRadius: '8px',
                        borderBottomRightRadius: '8px',
                        borderRight: `1px solid ${Colors.white}`,
                        }}
                    onClick={closeSnackBar}
                    >
                        close
                    </snap>
                )}
                </div>
            </Grid>
        </Grid>
    );
};

export default SnackBar;
