import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonComponent from './common/ButtonComponent';
import {
    Colors,
    GlobalStyles
} from '../utils';


class Settings extends Component {


    render() {
        return (
            <Grid container>
                <Grid item md={12} xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin:'10px' }}>
                    <Typography style={{ ...GlobalStyles.titleBold, color: Colors.white, marginTop: '21px' }}>Settings</Typography>
                    <ButtonComponent onClick={() => this.props.history.push('limitsandplans')} value="Manage TeacherPro" buttonStyle={{ ...GlobalStyles.bodyMedium, backgroundColor: Colors.pri_color, borderRadius: '10px', color: Colors.white, padding: '18px', border: '1px solid #fff', marginTop: '40px' }} />
                    
                    <ButtonComponent onClick={() => this.props.history.push('earnings')} value="Earnings" buttonStyle={{ ...GlobalStyles.bodyMedium, backgroundColor: Colors.pri_color, borderRadius: '10px', color: Colors.white, padding: '18px', border: '1px solid #fff', marginTop: '20px' }} />
                    <ButtonComponent onClick={() => this.props.history.push('deleteaccount')} value="Delete Account" buttonStyle={{ ...GlobalStyles.bodyMedium, backgroundColor: Colors.pri_color, borderRadius: '10px', color: Colors.white, padding: '18px', border: '1px solid #fff', marginTop: '20px' }} />
                </Grid>
            </Grid>
        );
    }
}

export default Settings;
