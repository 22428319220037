import React from 'react';
import Typography from '@material-ui/core/Typography';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { Colors, GlobalStyles } from '../../../utils';
import '../../../App.css';

const ToggleHeader = ({
 title, mobileFirstView, navigation, unreadDmMessageIndicator,
  unreadTeacherMessageIndicator, unreadClassMessageIndicator
}) => {
  return (
    <div
      className={(mobileFirstView && 'chat-mobile-view') || null}
      style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            padding: unreadDmMessageIndicator ? '10px 20px' : '20px',
            backgroundColor: title === 'dm' ? Colors.skyBlue : Colors.white,
            cursor: 'pointer',
            width: '33.3%',
          }}
          onClick={() => navigation('/chat/dm')}
        >
          { unreadDmMessageIndicator && <ChatBubbleIcon style={{ marginLeft: '63px', color: '#1FA3FF', fontSize: '15px', display: 'inline' }} />}
          <Typography
            style={{
              ...GlobalStyles.bodyMedium,
              color: title === 'dm' ? Colors.primaryColor : Colors.grey3,
              whiteSpace: 'nowrap',
            }}
          >
            Direct messages
          </Typography>
        </div>
        <div
          style={{
            padding: unreadClassMessageIndicator ? '10px 20px' : '20px',
            backgroundColor: title === 'class' ? Colors.skyBlue : Colors.white,
            cursor: 'pointer',
            width: '33.3%',
          }}
          onClick={() => navigation('/chat/class')}
        >
          { unreadClassMessageIndicator && <ChatBubbleIcon style={{ marginLeft: '63px', color: '#1FA3FF', fontSize: '15px', display: 'inline' }} />}
          <Typography
            style={{
              ...GlobalStyles.bodyMedium,
              color: title === 'class' ? Colors.primaryColor : Colors.grey3,
            }}
          >
            Classes
          </Typography>
        </div>
        <div
          style={{
            padding: unreadTeacherMessageIndicator ? '10px 20px' : '20px',
            backgroundColor:
            title === 'teacher' ? Colors.skyBlue : Colors.white,
            cursor: 'pointer',
            width: '33.3%',
          }}
          onClick={() => navigation('/chat/teacher')}
        >
          { unreadTeacherMessageIndicator && <ChatBubbleIcon style={{ marginLeft: '63px', color: '#1FA3FF', fontSize: '15px', display: 'inline' }} />}
          <Typography
            style={{
              ...GlobalStyles.bodyMedium,
              color: title === 'teacher' ? Colors.primaryColor : Colors.grey3,
            }}
          >
            Teachers
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ToggleHeader;
