/**
 * @flow
 */
import React, { Component } from 'react';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { auth, database, analytics, firestore, messaging } from '../fire';
import {version as AppVersion} from '../../package.json';
import 'react-toastify/dist/ReactToastify.css';
import Typography from '@material-ui/core/Typography';
import AvatarDisplay from '../components/AvatarDisplay';

toast.configure({
  position: toast.POSITION.TOP_CENTER,
});
const loadingImage = require('../loading.gif');
const maxLengthUsername = 20;
const maxLengthFullname = 60;
const maxLengthBio = 300;
export const GlobalContext = React.createContext();
export const classContext = React.createContext();
export const NumberOfLimitedCommunityClasses = 4;
export const ConvoMessages = 'ConvoMessages';
export const ConvoMetadata = 'ConvoMetadata';
// Date.prototype.getMonthName = function (i) {
//     var monthNames = [
//         "January",
//         "February",
//         "March",
//         "April",
//         "May",
//         "June",
//         "July",
//         "August",
//         "September",
//         "October",
//         "November",
//         "December"
//     ];
//     if (i) return monthNames[i - 1];
//     else return monthNames[this.getMonth()];
// };

export const Colors = {
  //pri_color:"#888",
  //pri_color:"#0e81ee",
  //sec_color:"#1fa3ff",
  pri_color: '#1fa3ff',
  sec_color: '#0e81ee',
  temp_color: 'rgb(14,129,238)',
  white: '#FFFFFF',
  grey1: '#333333',
  grey5: '#E0E0E0',
  grey2: '#4F4F4F',
  grey3: '#828282',
  grey4: '#BDBDBD',
  grey6: '#F2F2F2',
  grey7: '#C4C4C4',
  primaryColor: '#1FA3FF',
  brightGrey: '#EEEEEE',
  whiteSmoke: '#F5F5F5',
  fauxGCBlue: '#3A86F8',
  whisper: '#EAEAEA',
  shade: '#DCF9F9',
  snow: '#FBFBFB',
  red: '#FF4646',
  yellow: '#FBCD42',
  skyBlue: 'rgba(31,163,255,0.1)',
  // grey5: "#6E6E6E",
  primaryColor2: '#179BF7',
  black: '#000000',
  darkBlue: '#187DC4',
  pinkClosest: '#01132C',
  //sec_color:"green"
  green1: '#22A95C',
  blueOnlineClass: '#ECF5FE',
  lightRed: '#FFEEEE',
  lightGreen: '#DCF9F9',
  ash: '#CCCCCC',
  blue: '#2F80ED',
  lightBlue: '#EFF6FF',
  lightYellow: '#FFF8E2',
  delineatedBlue: '#2879E8',
  blueText: '#258DF0',
};

export const GlobalStyles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5FCFF',
    fontFamily: 'Avenir Next',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  textLink: {
    textDecorationLine: 'underline',
  },
  navbarHeader: {
    backgroundColor: Colors.pri_color,
    shadowOpacity: 0,
    elevation: 0,
  },
  instructions: {
    textAlign: 'center',
    color: '#333333',
    marginBottom: 5,
  },
  textInputDefault: {
    height: 40,
    borderWidth: 0,
    borderColor: '#ffffff77',
    backgroundColor: 'rgba(255,255,255,0.25)',
    display: 'flex',
    flex: 1,
    fontSize: 18,
    padding: 5,
    color: '#fff',
  },
  textInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    marginBottom: 15,
    paddingHorizontal: 4,
    borderColor: '#fff',
    borderWidth: 0,
  },
  remainder: {
    textAlign: 'right',
    width: 24,
  },
  textInputValidationErrorView: {
    backgroundColor: '#f00f',
    padding: 3,
    marginTop: -15,
  },
  textInputValidationErrorText: {
    fontSize: 12,
  },
  disabledFormButton: {
    backgroundColor: '#0004',
  },
  titleBold: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '22px',
    // lineHeight: 30
  },
  bodyMedium: {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    // lineHeight: 20
  },
  bodyRegular: {
    // fontFamily: "Rubik",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    // lineHeight: 20
  },
  upgradeHeader: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '44px',
    // lineHeight: "54px"
  },
};

export class SlimWrapper extends Component {
  render() {
    return (
      <div style={{ maxWidth: 380, margin: 'auto' }}>{this.props.children}</div>
    );
  }
}

export class Wrapper extends Component {
  render() {
    return <div style={{ margin: 0, padding: 0 }}>{this.props.children}</div>;
  }
}

export const AlertBox = (props) => {
  const { bgColor, children } = props;
  return (
    <div
      style={{
        padding: 20,
        borderRadius: 10,
        margin: 20,
        backgroundColor: bgColor || '#ffd3d3',
        color: '#828282',
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'left',
        alignItems: 'flex-start',
      }}
    >
      {children}
    </div>
  );
};
AlertBox.displayName = 'AlertBox';

export const Loading = (props) => (
  <div style={{ display: 'block' }}>
    <div style={{ padding: 5, margin: 5 }}>
      <img
        alt="loading"
        src={loadingImage}
        width={50}
        style={{ borderRadius: '50%' }}
      />
      <br />
      {props.children || <small>Loading...</small>}
    </div>
  </div>
);
Loading.displayName = 'Loading';

export const GifLoader = (props) => (
  <div
    className="parentContainer"
    style={{ backgroundColor: props.transparentBg ? 'transparent' : '#e5e5e5' }}
  >
    <div className="image" id="fadeIn">
      {props.children}
    </div>
  </div>
);
GifLoader.displayName = 'PreLoading';

const logAnalytics = (message, payload) => {
  analytics.logEvent(message, payload);
};
// const getNextDayOfWeek = (dayOfWeek, date) => {
//     // Code to check that date and dayOfWeek are valid left as an exercise ;)
//     //note that in FY system the dayofWeek is a 1-7/Mon-Sun system, but Javascript (and specifically this function) natively has a 0-6/Sun-Sat system
//     //the above means, we match up except on sunday we dont..this breaks things...so check if day of week ===7...if so, set it to 0..then proceeed
//     //at least switch arg order so the date can be left off...
//     console.log("the day of the week function", dayOfWeek, date)
//     if (dayOfWeek === 7) dayOfWeek = 0; //to turn 7th day to 0 (sunday either way)
//     var date_ms = Date.now(); //we need a date (even if they dont pass it in) so we have an hour/min/sec of the day to use
//     if (date) date_ms = date.getTime();
//     var resultDate = new Date(date_ms);
//     resultDate.setDate(
//         resultDate.getDate() + (7 + dayOfWeek - resultDate.getDay()) % 7
//     );
//     return resultDate;
// };

const saveWebToken = async (tok) => {
  const auth_user = await getFYuser();
  console.log({ auth_user });
  if (auth_user && tok) {
    var uRef = await database.ref('users/' + auth_user.uid);
    console.log('===> auth_user.uid, tok', auth_user.uid, tok);
    uRef
      .update({
        web_token: tok,
      })
      .then(() => {
        console.log('web token updated successfully');
      })
      .catch((err) => {
        //something went wrong,
        console.warn(
          'error saving push token and possibly subscribing to teacher topics',
          err
        );
      });
  } else {
    console.log('no auth_user or no token');
  }
};

export const requestFirebaseNotificationPermission = async () => {
  try {
    await messaging.requestPermission();
    const firebaseWebToken = await messaging.getToken({
      vapidKey:
        'BDaFKiZir5ikmHfFjLCU7qtfiFCY4ZeWW3Lw2XR08XOppWZqt4fdnNmgQkGpmqPtjPsfVtf0gyZ6DdrMdcXCIgk',
    });

    console.log(firebaseWebToken, 'my web token');
    saveWebToken(firebaseWebToken);
  } catch (err) {
    console.log('error getting token', err);
  }
};

const toastSend = (msg, options) => {
  toast(msg, options);
};

const toastSuccess = (msg, options) => {
  toastSend(msg, { ...options, type: toast.TYPE.SUCCESS });
};
const toastError = (msg, options) => {
  toastSend(msg, { ...options, type: toast.TYPE.ERROR });
};

export const onMessageListener = async (props) => {
  try {
    messaging.onMessage(async (payload) => {
      console.log(payload);
      const senderInfo = await getFYuser(payload.data.uid);
      const onSignUpProfileUrl = DefaultProfilePhotoUrl === senderInfo?.profile_photo_url;
      toastSend(
      <div style={{ flexDirection: 'row', display: !onSignUpProfileUrl ?? 'flex' }}>
        <div>       
          <AvatarDisplay
            imgSrc={senderInfo.profile_photo_url}
            imgStyles={{
              borderRadius: '50%',
              height: 50,
            }}
            imgAlt={'yogi-profile'}
            userName={senderInfo.fullname}
            textStyles={{fontSize: '16px'}}
          />
        </div>
        <div style={{ marginLeft: '5px' }}>
          <Typography color="textSecondary" variant="subtitle1">{senderInfo.fullname}</Typography>
          <Typography color="textSecondary" variant="caption">
            { payload.data.body }
          </Typography>
        </div>
      </div>
        , { toastId: payload.fcmMessageId, autoClose: true, onClick: props.history.push(`/chat/convo/${payload.data.convoId}`), position: 'top-right' });
    });
  } catch (e) {
    console.log(e);
  }
};

const getListOfBlockedUsers = async (uid) => {
  let listOfBlockedUids = [];
  await database.ref(`users/${uid}/blocks`).once('value', async (s) => {
    const uids = (await s.val()) || {};
    listOfBlockedUids = Object.keys(uids) || [];
  });
  return listOfBlockedUids;
};

const getNextDayOfWeek = (dayOfWeek, date, classData) => {
  // Code to check that date and dayOfWeek are valid left as an exercise ;)
  //note that in FY system the dayofWeek is a 1-7/Mon-Sun system, but Javascript (and specifically this function) natively has a 0-6/Sun-Sat system
  //the above means, we match up except on sunday we dont..this breaks things...so check if day of week ==7...if so, set it to 0..then proceeed
  //at least switch arg order so the date can be left off...

  let resultDate = new Date();
  // console.log("in styles global", { classData });
  if (classData && classData.start_timestamp) {
    // based on new classesbyuserid so mind recurrence_rule and start_timestamp
    const { recurrence_rule, start_timestamp } = classData;
    // resultDate = new Date(start_timestamp);
    // console.log({ recurrence_rule });
    let resultMoment = moment(start_timestamp);

    // if weekly
    if (recurrence_rule) {
      const { interval, byday = '' } = getRecurrenceRuleParts(recurrence_rule);
      const bydayAsItems = getDaysOfWeekItems().filter(
        (item) =>
          byday.toLowerCase().indexOf(item.nickname.toLocaleLowerCase()) > -1
      );

      const nextViableDay = bydayAsItems
        .map((i) => {
          // from startDate isoWeekday add 1 or 2 weeks
          const currentDate = moment(classData.start_timestamp).isoWeekday(
            i.value
          );
          if (currentDate.valueOf() < moment().valueOf()) {
            do {
              currentDate.add(interval, 'weeks');
            } while (currentDate.valueOf() < moment().valueOf());
          }
          return currentDate;
        })
        .sort((a, b) => a.valueOf() - b.valueOf())[0];
      // dayINeed = nextDayOfWeekItem.id || 7; //change from 0-6(Sun-Sat)based to iso 1-7(Mon-Wed)
      // dayImAt = moment().isoWeekday(); //today
      // console.log({ nextViableDay: nextViableDay.format() });
      resultMoment = nextViableDay;
    }
    resultDate = new Date(resultMoment.valueOf());
  }
  // console.log("made it out of res setting", { resultDate });
  return resultDate;
};

const checkForExistingUsername = async (incomingUsername) => {
  let result = false;
  await database.ref(`usernames/${incomingUsername}`).once('value', (snap) => {
    const check = snap.val() || false;
    if (check) {
      result = true;
    }
  });
  return result;
};

const getTenorGifData = async (gifFilter = '') => {
  // set the apikey and limit
  const apikey = 'HKDUPS91VPCW';
  const lmt = 8;
  // test search term
  const filter = gifFilter;
  // using default locale of en_US
  var searchUrl = `https://g.tenor.com/v1/search?q=${filter}&key=${apikey}&limit=${lmt}`;

  const response = await fetch(searchUrl, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  const responseJson = await response.json();
  return responseJson.results || [];
};

const getUserContentShownHistory = async (uid) => {
  let usercontent;
  await database.ref(`showcontentbyuserid/${uid}`).once('value', (s) => {
    //return s.val()
    usercontent = s.val() || {};
  });
  return usercontent;
};

const getFYuser = async (uid) => {
  //var auser_promise;
  let auser;

  if (!uid) {
    //assume default current user id, if id passed in we can get any user
    await auth.onAuthStateChanged((auth_user) => {
      if (auth_user) {
        uid = auth_user.uid;
      }
    });
  }

  const user_ref = database.ref('users/' + uid);
  // user_ref.keepSynced(true); memory is an issue
  await user_ref.once('value', (snap) => {
    auser = snap.val();
  });

  auser.uid = uid;
  //console.log("UTIL FX USER pull", auser)
  return auser;
};
const getClassLimit = async (uid, auser) => {
  if (!auser) auser = await getFYuser(uid);

  let class_limit = (auser && auser.config && auser.config.limit_classes) || 0;
  const referral_credit_classes =
    (auser && auser.config && auser.config.referral_credit_classes) || 0;
  const global_standard_class_limit = await getClassLimit_Global();
  //console.log("class_limit, referral_credit_classes, global_standard_class_limit", class_limit, referral_credit_classes, global_standard_class_limit)
  // await database.ref("config/limit_classes").once("value", (snap_global)=>{
  //               global_standard_class_limit =  snap_global.val()
  //               //console.log("in the global limit getter - limite = ",  global_standard_class_limit)
  // })//.then(v=>v).catch(err=>0)
  //if(!class_limit){
  //only need to query global if not having an overriding limit
  if ((auser.config || {}).paid_plan === 'teacherpro') {
    class_limit = await getClassLimit_Max();
  } else {
    class_limit =
      (class_limit || global_standard_class_limit) + referral_credit_classes;
  }
  // console.log("2ND- class_limit, referral_credit_classes, global_standard_class_limit", class_limit, referral_credit_classes, global_standard_class_limit)
  //}

  return class_limit;
};
const getFavYogiLimit = async (uid, auser) => {
  if (!auser) auser = await getFYuser(uid);

  let favyogi_limit =
    (auser && auser.config && auser.config.limit_teachers) || 0;
  const referral_credit_teachers =
    (auser && auser.config && auser.config.referral_credit_teachers) || 0;
  const global_standard_teacher_limit = await getFavYogiLimit_Global();

  //if(!favyogi_limit){
  const pp = (auser.config || {}).paid_plan;
  if (pp === 'teacherpro' || pp === 'studentpro') {
    favyogi_limit = await getFavYogiLimit_Max();
  } else {
    //only need to query global if not having an overriding limit
    favyogi_limit =
      (favyogi_limit || global_standard_teacher_limit) +
      referral_credit_teachers;
  }
  //}

  return favyogi_limit;
};
const getBlastLimit = async (uid, auser) => {
  if (!auser) auser = await getFYuser(uid);

  let blast_limit = (auser && auser.config && auser.config.limit_blasts) || 0;
  const global_standard_blast_limit = await getBlastLimit_Global();

  //if(!blast_limit){
  const pp = (auser.config || {}).paid_plan;
  if (pp === 'teacherpro') {
    blast_limit = 99999;
  } else {
    //only need to query global if not having an overriding limit
    blast_limit = blast_limit || global_standard_blast_limit;
  }
  //}

  return blast_limit;
};

const getFavYogiLimit_Max = async () => {
  let limit_teachers_max;
  await database.ref('config/limit_teachers_max').once('value', (s) => {
    //return s.val()
    limit_teachers_max = s.val();
  });
  return limit_teachers_max;
};
const getClassLimit_Max = async () => {
  let limit_classes_max;
  await database.ref('config/limit_classes_max').once('value', (s) => {
    limit_classes_max = s.val();
  });
  return limit_classes_max;
};
const getBlastLimit_Global = async () => {
  let limit_blasts_global;
  await database.ref('config/limit_blasts').once('value', (s) => {
    //return s.val()
    limit_blasts_global = s.val();
  });
  return limit_blasts_global;
};
const getFavYogiLimit_Global = async () => {
  let limit_teachers_global;
  await database.ref('config/limit_teachers').once('value', (s) => {
    //return s.val()
    limit_teachers_global = s.val();
  });
  return limit_teachers_global;
};
const getClassLimit_Global = async () => {
  let limit_classes_global;
  await database.ref('config/limit_classes').once('value', (s) => {
    limit_classes_global = s.val();
  });
  return limit_classes_global;
};
const isOverClassLimit = async (uid, auser) => {
  if (!auser) auser = await getFYuser(uid);

  const class_count = Object.keys(auser.classes || {}).length;
  const class_limit = await getClassLimit(auser.uid || uid);
  return (
    class_count > class_limit && (auser.config || {}).paid_plan !== 'teacherpro'
  );
};

export const DefaultProfilePhotoUrl =
  'https: //firebasestorage.googleapis.com/v0/b/encoded-ensign-158201.appspot.com/o/assets%2Fdefault_profile_photo.svg?alt=media&token=f908db89-5728-4a17-9f0e-aaf55f0b17b0';

const getDefaultTimeZoneValue = async (uid) => {
  let defaultTimeZone;
  if (!uid) {
    uid = await getFYuser();
    defaultTimeZone = uid.default_time_zone;
    return defaultTimeZone;
  }
  await database.ref('users/' + uid).once('value', (s) => {
    const sVal = s.val() || {};
    defaultTimeZone = sVal.default_time_zone || null;
  });
  return defaultTimeZone;
};

const timeZoneList = moment.tz
  .names()
  .map((tzn) => ({
    name: tzn,
    offset: moment().tz(tzn).format('Z'),
  }))
  .sort((v1, v2) => v1.offset.localeCompare(v2.offset));

const getTimeZoneList = (search = '', limit = 5) => {
  if (search) {
    return timeZoneList
      .filter(
        (v) =>
          v.name.toLowerCase().includes(search.toLowerCase()) ||
          v.offset.includes(search)
      )
      .slice(0, limit);
  }
  return timeZoneList.slice(0, limit);
};

const getTimezoneNameFromOffset = (search = '') => {
  let timeZoneValue;
  for (let i = 0; i < timeZoneList.length; i++) {
    if (timeZoneList[i].offset === search) {
      timeZoneValue = timeZoneList[i].name;
      break;
    }
  }
  return timeZoneValue;
};

const getFullTimeZoneList = () => [
  { value: '-12:00', name: '(GMT -12:00) Eniwetok, Kwajalein' },
  { value: '-11:00', name: '(GMT -11:00) Midway Island, Samoa' },
  { value: '-10:00', name: '(GMT -10:00) Hawaii' },
  { value: '-09:30', name: '(GMT -9:30) Taiohae' },
  { value: '-09:00', name: '(GMT -9:00) Alaska' },
  { value: '-08:00', name: '(GMT -8:00) Pacific Time (US & Canada)' },
  { value: '-07:00', name: '(GMT -7:00) Mountain Time (US & Canada)' },
  {
    value: '-06:00',
    name: '(GMT -6:00) Central Time (US & Canada), Mexico City',
  },
  {
    value: '-05:00',
    name: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
  },
  // {value: "-04:30", name: "(GMT -4:30) Caracas"},
  {
    value: '-04:00',
    name: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
  },
  { value: '-03:30', name: '(GMT -3:30) Newfoundland' },
  { value: '-03:00', name: '(GMT -3:00) Brazil, Buenos Aires, Georgetown' },
  { value: '-02:00', name: '(GMT -2:00) Mid-Atlantic' },
  { value: '-01:00', name: '(GMT -1:00) Azores, Cape Verde Islands' },
  {
    value: '+00:00',
    name: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
  },
  { value: '+01:00', name: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris' },
  { value: '+02:00', name: '(GMT +2:00) Kaliningrad, South Africa' },
  {
    value: '+03:00',
    name: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
  },
  { value: '+03:30', name: '(GMT +3:30) Tehran' },
  { value: '+04:00', name: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi' },
  { value: '+04:30', name: '(GMT +4:30) Kabul' },
  {
    value: '+05:00',
    name: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
  },
  { value: '+05:30', name: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi' },
  { value: '+05:45', name: '(GMT +5:45) Kathmandu, Pokhara' },
  { value: '+06:00', name: '(GMT +6:00) Almaty, Dhaka, Colombo' },
  { value: '+06:30', name: '(GMT +6:30) Yangon, Mandalay' },
  { value: '+07:00', name: '(GMT +7:00) Bangkok, Hanoi, Jakarta' },
  { value: '+08:00', name: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong' },
  { value: '+08:45', name: '(GMT +8:45) Eucla' },
  {
    value: '+09:00',
    name: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
  },
  { value: '+09:30', name: '(GMT +9:30) Adelaide, Darwin' },
  {
    value: '+10:00',
    name: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
  },
  { value: '+10:30', name: '(GMT +10:30) Lord Howe Island' },
  {
    value: '+11:00',
    name: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
  },
  // {value: "+11:30", name: "(GMT +11:30) Norfolk Island"},
  {
    value: '+12:00',
    name: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
  },
  // {value: "+12:45", name: "(GMT +12:45) Chatham Islands"},
  { value: '+13:00', name: '(GMT +13:00) Apia, Nukualofa' },
  { value: '+14:00', name: '(GMT +14:00) Line Islands, Tokelau' },
];

const checkTimeZoneValue = ({ timeZone = '', defaultTimeZoneValue = '' }) => {
  if (timeZone) {
    return `${moment().tz(timeZone).format('Z')} ${timeZone}`;
  }
  if (defaultTimeZoneValue) {
    return `${moment()
      .tz(defaultTimeZoneValue)
      .format('Z')} ${defaultTimeZoneValue}`;
  }
  return '';
};

const getClassesAndEventsCount = async (uid) => {
  let total = 0;
  await database
    .ref('classesbyuserid/' + uid)
    .once('value')
    .then((s) => {
      const sVal = s.val() || {};
      total = Object.keys(sVal).filter((key) => {
        const aClass = sVal[key];
        return !aClass.date_deleted;
      }).length;
    });
  return total;
};

const getFullCommunityClassesCount = async (uid) => {
  let total = 0;
  await database
    .ref('classesbyuserid/' + uid)
    .once('value')
    .then((s) => {
      const sVal = s.val() || {};
      total = Object.keys(sVal).filter((k, i) => {
        const listing = sVal[k];
        return (
          !listing.date_deleted &&
          (listing.class_type === 'community' ||
            listing.class_type === 'public')
        );
      }).length;
    });
  return total;
};

const getFullPersonalClassesCount = async (uid) => {
  let total = 0;
  await database
    .ref('classesbyuserid/' + uid)
    .once('value')
    .then((s) => {
      const sVal = s.val() || {};
      total = Object.keys(sVal).filter((k, i) => {
        const listing = sVal[k];
        return !listing.date_deleted && listing.class_type === 'personal';
      }).length;
    });
  return total;
};

const isOverFavYogiLimit = async (uid, auser) => {
  if (!auser) auser = await getFYuser(uid);

  const teacher_count = Object.keys(auser.teachers || {}).length;
  const teacher_limit = await getFavYogiLimit(auser.uid || uid);
  return teacher_count > teacher_limit && !(auser.config || {}).paid_plan;
};

//get name for day of the week
const getNameForWeekdayByJsGetday = (dow) => {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const dayName = days[dow];
  return dayName;
};

//this sorts the values of the week days according to the current day
const getScheduleWeekdayValueMap = () => {
  const day_key_map = {
    Sunday: 7,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };
  const day_names = Object.keys(day_key_map);
  const t = new Date().getDay();
  const start = day_names[t];
  let _index = day_names.indexOf(start);
  const done = {};
  for (let i = 0; i < day_names.length; i++) {
    const ad = day_names[_index];
    done[ad] = i + 1;
    _index = (_index + 1) % 7;
  }
  //console.log(done)
  return done;
};
const getUsername = async (uid) => {
  let username;
  // console.log("for user id looking for a name", uid);
  await database.ref('users/' + uid + '/username').once('value', (snap_un) => {
    username = snap_un.val();
    // console.log("for user id FOUND this", username);
  });
  return username;
};

const getFullname = async (uid) => {
  let fullname;
  // console.log("for user id looking for a name", uid);
  await database.ref('users/' + uid + '/fullname').once('value', (snap_un) => {
    fullname = snap_un.val();
    // console.log("for user id FOUND this", username);
  });
  return fullname;
};
const getProfilePhotoUrl = async (uid) => {
  let profile_photo_url;
  await database
    .ref('users/' + uid + '/profile_photo_url')
    .once('value', (snap_ppu) => {
      profile_photo_url = snap_ppu.val();
    });
  return profile_photo_url;
};
const time_formats = [
  [60, 'seconds', 1], // 60
  [120, '1 minute ago', '1 minute from now'], // 60*2
  [3600, 'minutes', 60], // 60*60, 60
  [7200, '1 hour ago', '1 hour from now'], // 60*60*2
  [86400, 'hours', 3600], // 60*60*24, 60*60
  [172800, 'yesterday', 'tomorrow'], // 60*60*24*2
  [604800, 'days', 86400], // 60*60*24*7, 60*60*24
  [1209600, 'last week', 'next week'], // 60*60*24*7*4*2
  [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
  [4838400, 'last month', 'next month'], // 60*60*24*7*4*2
  [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
  [58060800, 'last year', 'next year'], // 60*60*24*7*4*12*2
  [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
  [5806080000, 'last century', 'next century'], // 60*60*24*7*4*12*100*2
  [58060800000, 'centuries', 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
];
const getLoquaciousDate = (date_val) => {
  //stuff for pretty dates
  //function pretty_date(date_str){
  const time = date_val;
  const current_time = new Date().toUTCString();
  let seconds = (new Date(current_time) - new Date(time)) / 1000;
  let token = 'ago';
  let list_choice = 1;
  if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = 'from now';
    list_choice = 2;
  }
  let i = 0;
  let format;
  while ((format = time_formats[i++])) {
    if (seconds < format[0]) {
      if (typeof format[2] === 'string') {
        return format[list_choice];
      }
      return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
    }
  }
  return time;
};
//send a msg and any postable data
const triggerAdminAlert = async (msg, data = {}) => {
  //try to send alert sms to admin
  const dataLength = Object.keys(data).length;
  // console.log("should send admint alert trigger", msg, data, process.env.NODE_ENV);
  const isDevEnv = getIsDevEnv();
  let alertURL = `https: //us-central1-${
    isDevEnv ? 'encoded-ensign-158201' : 'favyogis-prod'
  }.cloudfunctions.net/sendAlertToAdmin`;
  try {
    if (!dataLength) {
      const _msg = encodeURIComponent(msg);
      alertURL += '?msg=' + _msg;
    }
  } catch (err) {
    // console.warn("error parsing for alert:", err.toString());
    return Promise.resolve('error parsing');
  }
  if (true || !isDevEnv) {
    // return fetch(alertURL);
    if (dataLength) {
      return fetch(alertURL, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          msg: msg,
          data: data,
        }),
      });
    }
    return fetch(alertURL);
  }
  // console.warn("not sending alert since its process.env.NODE_ENV ==== development", msg, data);
  return Promise.resolve('not sendin since its dev');
};

const getTicketDescriptionString = ({
  class_type,
  recurrence_rule,
  price_billing_cycle,
  quantity,
  price,
  fees_absorb,
  teacher,
}) => {
  const isPersonalAndRecurring = class_type === 'personal' && recurrence_rule;
  const p = getBuyerTotal({ price, fees_absorb, teacher }).toString();
  const q = quantity;
  let f = '';
  if (q) {
    f += q + ' tickets ';
  }
  if (p && parseFloat(p)) {
    f +=
      (q ? '@' : '') +
      ' $' +
      p +
      (isPersonalAndRecurring
        ? ` / auto-pay: ${price_billing_cycle || 'choose frequency'}`
        : ' each');
  }

  f = f.trim();
  return f;
};

const getConvoByUserIdInfo = async ({ uid, convoId }) => {
  let data;
  await database.ref(`convobyusers/${uid}/${convoId}`).once('value', (snap) => {
    data = snap.val();
  });
  return data;
};

const getChatMembershipDetails = async ({ convoId, uid }) => {
  let query = await firestore.collection('ConvoMembers');
  query = query.where('convo_id', '==', convoId);
  query = query.where('uid', '==', uid);
  const res = await query.get();
  if (!res.empty) {
    return {
      ...res.docs[0].data(),
      documentId: res.docs[0].id,
    };
  } else {
    return {};
  }
};

const handleProfileValidation = ({
  isUsername,
  isFullname,
  isBio,
  incomingVal,
}) => {
  let finalVal = incomingVal;
  if (isUsername) {
    finalVal = finalVal.replace(/ /g, '_'); //spaces get turned to underscore
    finalVal = finalVal.replace(/\W/g, ''); //non-alpha numeric get removed (exempts underscore)
    finalVal = finalVal.toLowerCase(); //make it lowercase for easier search
    finalVal = finalVal.substring(0, maxLengthUsername);
  }

  if (isFullname) {
    finalVal = finalVal.substring(0, maxLengthFullname);
  }
  if (isBio) {
    finalVal = finalVal.substring(0, maxLengthBio);
  }
  return finalVal;
};

const getSubFeeCalculation = ({ price, fees_absorb, teacher }) => {
  const { config } = teacher || {};
  const isTeacherPro = config && config.paid_plan === 'teacherpro';

  if (price) {
    if (isNaN(price)) {
      return 0;
    }
    // else
    //(0.29) rounded to 3% + .30 + ($2 if not teacher pro)
    const fee_flat = 0.3 + (isTeacherPro ? 0 : 2.0);
    const fee_rate = 0.03;
    const sub_fee_raw = price * fee_rate + fee_flat;
    let sub_fee = sub_fee_raw; //Math.ceil(sub_fee_raw);
    // console.warn("WTF2----------->", { fee_flat, fee_rate, price, fees_absorb, teacher });
    if (!fees_absorb) {
      //if not absorbing fee then need to calculate fee with formula because the sub_bee will be higher based on the higher ticket price.
      sub_fee = (price + fee_flat) / (1 - fee_rate) - price; // Math.ceil((price + fee_flat) / (1 - fee_rate) - price);
    }
    const fee = sub_fee;
    const finalFee = parseFloat(fee).toFixed(2);
    // console.warn("WTF3----------->", { isTeacherPro, finalFee, fee, fee_flat, fee_rate, price, fees_absorb, teacher });
    return finalFee;
  }
  return 0;
};
const getFeeCalculation = ({ price, fees_absorb, teacher }) => {
  const fee = getSubFeeCalculation({ price, fees_absorb, teacher }); //Math.ceil(getSubFeeCalculation(price, fees_absorb));
  // console.log({ deepFee: fee });

  return fee; //parseFloat(fee).toFixed(2);
};
const getBuyerTotal = ({ price, fees_absorb, teacher }) => {
  // console.warn("WTF----------->", { price, fees_absorb, teacher });
  const parsePrice = parseFloat(price || 0);
  const parseFee = parseFloat(
    fees_absorb ? 0 : getSubFeeCalculation({ price, fees_absorb, teacher })
  );
  const total = (parsePrice + parseFee).toFixed(2);
  // console.log({ total, parsePrice, parseFee });
  return total;
};

const getValueAsCurrency = (val) => {
  // var formatter = new global.Intl.NumberFormat("en-US", {
  //     style: "currency",
  //     currency: "USD",
  //     minimumFractionDigits: 2,
  //     // the default value for minimumFractionDigits depends on the currency
  //     // and is usually already 2
  // });
  // return formatter.format(val)
  let fin = val.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  //console.log(fin)
  fin = '$' + fin;
  //console.log(fin)
  //fin = fin.replace(".00","")
  //console.log(fin)
  return fin;
};
const getInboxUnreadCount = async (uid) => {
  const convo_data = {};
  let unread_count = 0;
  await database.ref(`convobyusers/${uid}`).once('value', (snap_cbu) => {
    // console.warn("total Inbox Messages!", snap_cbu.numChildren())
    snap_cbu.forEach((_cbu) => {
      convo_data[_cbu.key] = _cbu.val();
    });
  });

  //console.warn("CBUS found", _c.length)
  const convo_proms = Object.keys(convo_data).map(async (cid) =>
    database.ref(`convometadata/${cid}`).once('value')
  );
  // console.warn("just setup proms")
  await Promise.all(convo_proms).then((snap_cmd) => {
    //console.warn("cmetadata", cbu.length, this.auth_user.uid)
    //console.warn("in proms")
    snap_cmd.forEach((v) => {
      const _cmd = v.val() || {};
      _cmd.convo_id = v && v.key;

      if (
        _cmd.uid !== uid && //criteria to count it as unread. cant be self
        _cmd.date_created >
          ((typeof convo_data[_cmd.convo_id] === 'object' &&
            convo_data[_cmd.convo_id].date_last_read) ||
            1) //if convo is just true and never read it still will count as unread
      ) {
        // console.warn("found un unread!", { convo_data, convo_id: _cmd.convo_id, item: convo_data[_cmd.convo_id] });
        // convo_data[_cmd.convo_id].is_unread = true;
        //console.warn("found un unread!", _cmd.message)
        unread_count++;
      }
    });
    //return unread_count;
  });
  // .then(x=>{
  //   console.warn("about to return",x)
  //   return x
  // })
  return unread_count;
};
const getRegisteredStudentsForATeacherClassOrEvent = (params) => {
  // const nav_params = params;
  const { class_state, classInfo, classId, teacher, teacherid } = params;

  let metaclassid;
  let classregref;
  const finalTeacherId = teacherid || (teacher && teacher.uid);

  if (class_state && class_state.cid) {
    metaclassid = getMetaIdForClassOrEvent({
      classInfo: class_state,
      classId: class_state.cid,
    });
    classregref = `classregistrationsbyteacher/_teacherid_${finalTeacherId}/${metaclassid}`;
  } else {
    metaclassid = getMetaIdForClassOrEvent({ classInfo, classId });
    classregref = `classregistrationsbyteacher/_teacherid_${finalTeacherId}/${metaclassid}`;
  }
  // console.log("incoming params", params)
  // console.log("class reg ref", classregref);

  return database
    .ref(classregref)
    .once('value')
    .then((snap_reg) => {
      const rs = snap_reg.val();
      // console.log("reg value received", rs);
      return rs;
    });
};

const getApiBaseUrl = () => {
  // if (process.env.NODE_ENV == "development") {
  const host = window.location.host && window.location.host.toLowerCase();
  let url = host + '/';
  if (host.indexOf('favyogis.com') > -1 || host.indexOf('favyogis.me') > -1) {
    url = 'https: //us-central1-favyogis-prod.cloudfunctions.net/';
  } else if (host.indexOf('orun.favyogis.com') > -1) {
    url = 'https: //us-central1-encoded-ensign-158201.cloudfunctions.net/';
  } else {
    url = 'https: //us-central1-encoded-ensign-158201.cloudfunctions.net/';
  }
  return url;
};

const getWebsiteBaseUrl = () => {
  const host = window.location.host && window.location.host.toLowerCase();
  const url = `${host}/`;
  return url;
};

const copyToClipboard = (textToCopy) => {
  let textArea;
  if (navigator.clipboard) {
    navigator.clipboard.writeText(textToCopy);
  } else if (window.clipboardData) {
    // IE
    window.clipboardData.setData('text', textToCopy);
  } else if (document.queryCommandSupported('copy')) {
    // other browsers, iOS, Mac OS
    createTextArea(textToCopy);
    selectText();
    copyTo();
  }
  function isOS() {
    //can use a better detection log ic here
    return navigator.userAgent.match(/ipad|iphone/i);
  }

  function createTextArea(text) {
    textArea = document.createElement('textArea');
    textArea.readOnly = true;
    textArea.contentEditable = true;
    textArea.value = text;
    document.body.appendChild(textArea);
  }

  function selectText() {
    let range;
    let selection;

    if (isOS()) {
      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
  }

  function copyTo() {
    document.execCommand('copy');
    document.body.removeChild(textArea);
  }
};

const sendClassInvitationToStudent = ({ studentid, cid, uid }) => {
  return database.ref(`invitesbystudentid/${studentid}`).push({
    teacherid: uid,
    studentid: studentid,
    date_created: Date.now(),
    classid: cid,
  });
};

const setupDirectMessageConvo = async ({ recipientId, uid, navigator }) => {
  const data_cbu = {};
  const convoId = await database.ref('convobyusers/' + recipientId).push().key;
  data_cbu[`convobyusers/${uid}/${convoId}`] = true;
  data_cbu[`convobyusers/${recipientId}/${convoId}`] = true;
  await database.ref().update(data_cbu);
  const recipient_data_cm = {
    uid: recipientId,
    date_added: Date.now(),
    convo_id: convoId,
    status: 'accepted',
  };
  const uid_data_cm = {
    uid: uid,
    date_added: Date.now(),
    convo_id: convoId,
    status: 'accepted',
  };
  await firestore.collection('ConvoMembers').add(recipient_data_cm);
  await firestore.collection('ConvoMembers').add(uid_data_cm);
  const meta_msg = {
    date_latest_activity: Date.now(),
    date_created: Date.now(),
    convo_id: convoId,
    dm_members: {
      [uid]: true,
      [recipientId]: true,
    },
  };
  const metadataRef = await firestore.collection(ConvoMetadata).doc(convoId);
  metadataRef.set(meta_msg);
  navigator.push(`/chat/convo/${convoId}`);
};

const sendDirectMessage = async ({
  convoId,
  uid,
  convoText,
  repliedToMessageId,
  recipientId,
  gifUrl,
}) => {
  const data_msg = {
    message_text: convoText,
    date_created: Date.now(),
    sender_uid: uid,
    convo_id: convoId,
    replied_to_message_id: repliedToMessageId,
    gif_url: gifUrl,
  };

  const messageRef = await firestore.collection(ConvoMessages).add(data_msg);
  const meta_msg = {
    ...data_msg,
    date_latest_activity: Date.now(),
    date_created: Date.now(),
    last_message_sender_uid: uid,
    last_message_id: messageRef.id,
    convo_id: convoId,
    gif_url: gifUrl,
    dm_members: {
      [uid]: true,
      [recipientId]: true,
    },
  };
  const metadataRef = await firestore.collection(ConvoMetadata).doc(convoId);
  return metadataRef.set(meta_msg);
};

const sendGroupMessage = async ({
  convoId,
  uid,
  convoText,
  repliedToMessageId,
  teacherId,
  convoProfilePhotoUrl,
  convoName,
  classId,
  gifUrl,
}) => {
  const data_msg = {
    message_text: convoText,
    date_created: Date.now(),
    sender_uid: uid,
    convo_id: convoId,
    replied_to_message_id: repliedToMessageId,
    gif_url: gifUrl,
  };

  const messageRef = await firestore.collection(ConvoMessages).add(data_msg);
  const meta_msg = {
    ...data_msg,
    date_latest_activity: Date.now(),
    date_created: Date.now(),
    last_message_sender_uid: uid,
    last_message_id: messageRef.id,
    convo_id: convoId,
    teacher_id: teacherId,
    convo_profile_photo_url: convoProfilePhotoUrl,
    convo_name: convoName,
    gif_url: gifUrl,
  };
  if (classId) {
    meta_msg.class_id = classId;
  }
  const metadataRef = await firestore.collection(ConvoMetadata).doc(convoId);
  return metadataRef.set(meta_msg);
};

const setupTeacherGroupChat = async ({ uid, username, profilePhotoUrl }) => {
  let query = await firestore.collection(ConvoMetadata);
  query = query.where('teacher_id', '==', uid);
  query.get().then(async (res) => {
    let convoId = '';
    if (!res.empty) {
      convoId = res.docs[0].data().convo_id;
    }
    if (!convoId) {
      const data_cbu = {};
      const newConvoId = database.ref('convobyusers/' + uid).push().key;
      data_cbu[`convobyusers/${uid}/${newConvoId}`] = true;
      await database.ref().update(data_cbu);
      const owner_data_cm = {
        uid: uid,
        date_added: Date.now(),
        convo_id: newConvoId,
        status: 'accepted',
      };
      await firestore.collection('ConvoMembers').add(owner_data_cm);
      const meta_msg = {
        date_latest_activity: Date.now(),
        date_created: Date.now(),
        convo_id: newConvoId,
        teacher_id: uid,
        convo_name: username,
        convo_profile_photo_url: profilePhotoUrl,
      };
      const metadataRef = await firestore
        .collection(ConvoMetadata)
        .doc(newConvoId);
      await metadataRef.set(meta_msg);
    }
  });
};

const setupClassGroupChat = async ({
  uid,
  classTitle,
  profilePhotoUrl,
  classId,
  studentId = null,
  navigation = null,
}) => {
  let convoId = '';
  let query = await firestore.collection(ConvoMetadata);
  query = query.where('teacher_id', '==', uid);
  query = query.where('class_id', '==', classId);
  query.get().then(async (res) => {
    if (!res.empty) {
      convoId = res.docs[0].data().convo_id;
    }
    const data_cbu = {};

    if (!convoId) {
      convoId = database.ref('convobyusers/' + uid).push().key;
      const owner_data_cm = {
        uid: uid,
        date_added: Date.now(),
        convo_id: convoId,
        status: 'accepted',
      };
      await firestore.collection('ConvoMembers').add(owner_data_cm);
      const meta_msg = {
        date_latest_activity: Date.now(),
        date_created: Date.now(),
        convo_id: convoId,
        teacher_id: uid,
        class_id: classId,
        convo_name: classTitle,
        convo_profile_photo_url: profilePhotoUrl,
      };
      const metadataRef = await firestore
        .collection(ConvoMetadata)
        .doc(convoId);
      await metadataRef.set(meta_msg);
    }
    if (studentId) {
      const member = await getChatMembershipDetails({
        convoId,
        uid: studentId,
      });
      if (member.documentId) {
        const docRef = await firestore
          .collection('ConvoMembers')
          .doc(member.documentId);
        return docRef.update({ status: 'accepted' });
      }
      data_cbu[`convobyusers/${studentId}/${convoId}`] = true;
      await database.ref().update(data_cbu);
      const member2_data_cm = {
        uid: studentId,
        date_added: Date.now(),
        convo_id: convoId,
        status: 'accepted',
      };
      await firestore.collection('ConvoMembers').add(member2_data_cm);
    }
    navigation && navigation(convoId);
  });
};

const getIsDevEnv = () => {
  const host = window.location.host && window.location.host.toLowerCase();
  let isDevEnv = true;

  if (!host) {
    return isDevEnv;
  }
  if (process.env.REACT_APP_ENV_FILE === 'production') {
    isDevEnv = false;
  }
  // if (
  //     host.startsWith("web.favyogis.com")
  //     || host.startsWith("favyogis.me")
  //     || host.startsWith("www.favyogis.me")
  // ) {
  //     isDevEnv = false;
  // }
  // if (host.startsWith("orun.favyogis.com")) {
  //     isDevEnv = true;
  // }
  return isDevEnv;
};

const getDaysOfWeekItems = () => [
  { id: 0, value: 'Sunday', nickname: 'Su' },
  { id: 1, value: 'Monday', nickname: 'Mo' },
  { id: 2, value: 'Tueday', nickname: 'Tu' },
  { id: 3, value: 'Wednesday', nickname: 'We' },
  { id: 4, value: 'Thursday', nickname: 'Th' },
  { id: 5, value: 'Friday', nickname: 'Fr' },
  { id: 6, value: 'Saturday', nickname: 'Sa' },
];

const getRecurrenceRuleParts = (recurrenceRuleText) => {
  // FREQ=WEEKLY;BYDAY=SU,MO,TU,WE,TH,FR,SA;INTERVAL=1
  // parse recurrence rule text to determine the correct array
  let rrFreq;
  let rrByday;
  let rrInterval;
  if (recurrenceRuleText) {
    const rrParts = recurrenceRuleText.split(';');
    [, rrFreq] = rrParts
      .filter((v) => v.toUpperCase().indexOf('FREQ=') > -1)[0]
      .split('=');
    [, rrByday] = rrParts
      .filter((v) => v.toUpperCase().indexOf('BYDAY=') > -1)[0]
      .split('=');
    [, rrInterval] = rrParts
      .filter((v) => v.toUpperCase().indexOf('INTERVAL=') > -1)[0]
      .split('=');
  }
  // console.log({ rrFreq, rrByday, rrInterval });

  return { freq: rrFreq, byday: rrByday, interval: rrInterval };
};

const getExpectedDateForClassOrEvent = (ci = {}) => {
  // let expected_date;
  // let min = 0;
  // if (ci.start) {
  //     expected_date = new Date(ci.start);
  // } else {
  //     let time_temp = ci.time.split(":");
  //     // hour = time_temp[0];
  //     time_temp = time_temp[1].split(" ");
  //     min = time_temp[0];
  //     expected_date = UtilFX.getNextDayOfWeek(ci.day_key);
  //     expected_date.setHours(Math.floor(ci.time_key), min);
  // }
  // return expected_date;

  const { start_timestamp, recurrence_rule } = ci;
  //const { lat, lng } = location;
  // let classTimezone;
  // if (lat && lng) {
  //     classTimezone = geoTz(lat, lng);
  // }
  let expected_date_moment;
  if (start_timestamp) {
    // resultDate = new Date(start_timestamp);
    // console.log({ recurrence_rule });
    expected_date_moment = moment(start_timestamp); // moment.tz(start_timestamp, classTimezone);
    // if weekly
    if (recurrence_rule) {
      const { interval, byday = '' } = getRecurrenceRuleParts(recurrence_rule);
      const bydayAsItems = getDaysOfWeekItems().filter(
        (item) =>
          byday.toLowerCase().indexOf(item.nickname.toLocaleLowerCase()) > -1
      );

      expected_date_moment = bydayAsItems
        .map((i) => {
          // from startDate isoWeekday add 1 or 2 weeks
          const currentDate = moment(start_timestamp).isoWeekday(i.value); //moment.tz(start_timestamp, classTimezone).isoWeekday(i.value);
          if (currentDate.valueOf() < moment().valueOf()) {
            do {
              currentDate.add(interval, 'weeks');
            } while (currentDate.valueOf() < moment().valueOf());
          }
          return currentDate;
        })
        .sort((a, b) => a.valueOf() - b.valueOf())[0];
    }
  } else {
    // console.warn("no start_timestamp, so returning null", ci);
  }
  return expected_date_moment;
};

const getGoogleMapsImage = ({ lat, lng, width, height, zoom }) =>
  `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&markers=size:small%7Ccolor:0x1fa3ff%7C${lat},${lng}&zoom=${
    zoom || 20
  }&size=${width || height || 150}x${height || width || 150}&key=${
    process.env.REACT_APP_DEV_GFB_apiKey
  }`;

const getMetaIdForClassOrEvent = ({
  classInfo: ci,
  classId: cid,
  eventId: eid,
  existingClassRegistration: ecr,
}) => {
  let expected_date = getExpectedDateForClassOrEvent(ci);
  if (ecr) {
    expected_date = moment(ecr.dateofclass);
  }
  // console.info("cc and expected date", cc, expected_date);
  const theID = cid || eid;
  let metaClassOrEventId = `_classid_${theID}`;
  if (theID) {
    const ed = moment(expected_date);
    if (ci.recurrence_rule) {
      const formated_ed = ed.format('YYYY_MM_DD');
      metaClassOrEventId = `_classid_${theID}_dateofclass_${formated_ed}`;
    }
  } else {
    throw new Error(
      'No class or event id picked up. cannot generate the metaclass or eventid'
    );
  }

  return metaClassOrEventId;
};

const getRegistrationByStudentDatabaseRef = ({
  classInfo: ci,
  student,
  //teacher,
  existingClassRegistration,
  classId,
}) => {
  let metaclassid;
  let cr_data_ref;
  if (classId && classId.length) {
    // let cc = ci;
    // const expected_date = getExpectedDateForClassOrEvent(cc);
    //console.info("cc and expected date", cc, expected_date);
    metaclassid = getMetaIdForClassOrEvent({
      classInfo: ci,
      classId,
      existingClassRegistration,
    });
    cr_data_ref = `classregistrationsbystudent/_studentid_${student.uid}/${metaclassid}`;
  } else {
    throw new Error(
      'No class or event id picked up. cannot generate the metaclass or eventid'
    );
  }

  return cr_data_ref;
};

const getValidStudentRegistrationForClassOrEvent = async ({
  classInfo,
  student,
  // teacher,
  classId,
}) => {
  let classRegistrationObject;
  const dbRef = getRegistrationByStudentDatabaseRef({
    classInfo,
    student,
    classId,
  });
  // console.log({ dbRef });
  if (classInfo.class_type === 'personal') {
    await database
      .ref(`classregistrationsbystudent/_studentid_${student.uid}`)
      .orderByKey()
      .startAt(`_classid_${classId}`)
      .endAt(`_classid_${classId}\uf8ff`) // The \uf8ff character used in the query above is a very high code point in the Unicode range. Because it is after most regular characters in Unicode, the query matches all values that start with a b.
      .once('value', (snap) => {
        const confirmed_registration = Object.values(snap.val() || {})
          .filter((v) => !v.date_deleted) //not deleted and date of class is > today or
          .sort((a, b) => b.created - a.created)[0]; //paymenttransactions usees date_created but classregistratiosn are using created...and users uses creation_date ...be mindful

        classRegistrationObject = confirmed_registration;
      })
      .catch((err) => {
        throw err;
      });
  } else {
    await database
      .ref(dbRef)
      .once('value', (snap) => {
        classRegistrationObject = snap.val();
      })
      .catch((err) => {
        throw err;
      });
  }
  return classRegistrationObject;
};
const getRegistrationsBelongingToStudent = async ({ studentId }) => {
  const dbRef = `classregistrationsbystudent/_studentid_${studentId}`;
  const regInfoArr = [];
  await database
    .ref(dbRef)
    .once('value', (snap) => {
      snap.forEach((item) => {
        const x = item.val() || {};
        x.key = item.key;
        x._classid = item.key.split('_dateofclass_')[0].split('_classid_')[1];
        regInfoArr.push(x);
      });
    })
    .catch((err) => {
      throw err;
    });

  return regInfoArr;
};

const getAlgoliaBasedObjectIdFromLocation = ({ lat, lng }) => {
  const ll_id = `${String(lat).replace('.', '--point--')}_FY_${String(
    lng
  ).replace('.', '--point--')}`;
  return ll_id;
};
//PRE-Existing function called from app previously refactor that... const getRegisteredStudentsForTeacherClassOrEvent = (
//     {
//         teacher,
//         classInfo,
//         classId,
//         eventId }) => {
//     throw new Error("not implemented yet");
// };
const getModalStyle = () => {
  const rand = function () {
    return Math.round(Math.random() * 20) - 10;
  };
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};
export const UtilFX = {
  toastSuccess,
  toastError,
  toastSend,
  getApiBaseUrl: getApiBaseUrl,
  getWebsiteBaseUrl: getWebsiteBaseUrl,
  getVersion: () => `web_${AppVersion}`,
  doLogout: () => {
    auth.signOut();
  },
  getIsDevEnv,
  getModalStyle,
  getAlgoliaBasedObjectIdFromLocation,
  getNextDayOfWeek,
  getGoogleMapsImage,
  getExpectedDateForClassOrEvent,
  getRecurrenceRuleParts,
  getRegisteredStudentsForATeacherClassOrEvent,
  getValidStudentRegistrationForClassOrEvent,
  getRegistrationsBelongingToStudent,
  getInboxUnreadCount,
  getValueAsCurrency,
  getSubFeeCalculation,
  getFeeCalculation,
  getBuyerTotal,
  triggerAdminAlert,
  getLoquaciousDate,
  getUsername,
  getFullname,
  getProfilePhotoUrl,
  getFullTimeZoneList,
  getFYuser,
  getClassLimit,
  getClassLimit_Max,
  getClassLimit_Global,
  getFavYogiLimit,
  getFavYogiLimit_Max,
  getFavYogiLimit_Global,
  getBlastLimit,
  getBlastLimit_Global,
  isOverClassLimit,
  isOverFavYogiLimit,
  getClassesAndEventsCount,
  getFullCommunityClassesCount,
  getFullPersonalClassesCount,
  getScheduleWeekdayValueMap,
  getNameForWeekdayByJsGetday,
  sendClassInvitationToStudent,
  logAnalytics,
  getDefaultTimeZoneValue,
  checkTimeZoneValue,
  getTimeZoneList,
  getTimezoneNameFromOffset,
  getTicketDescriptionString,
  handleProfileValidation,
  checkForExistingUsername,
  getUserContentShownHistory,
  sendDirectMessage,
  copyToClipboard,
  setupTeacherGroupChat,
  sendGroupMessage,
  setupDirectMessageConvo,
  setupClassGroupChat,
  getConvoByUserIdInfo,
  getChatMembershipDetails,
  getTenorGifData,
  getListOfBlockedUsers,
};

const updateTeacherStudentRelationship = ({
  teacherId,
  studentId,
  connectValue,
  subToNotifications,
}) => {
  const new_fav_val = connectValue ? true : null;

  if (teacherId && studentId) {
    if (teacherId === studentId) {
      // console.warn("No updating of self-self. shoudlnt even happen");
      return Promise.reject('Cannot add yourself as a user.');
    }
    //users/uid/teachers/tid:true
    //users/uid/students/uid:true
    const dataToUpdate = {};
    dataToUpdate[`users/${studentId}/teachers/${teacherId}`] = new_fav_val;
    dataToUpdate[`users/${teacherId}/students/${studentId}`] = new_fav_val;

    if (
      // student.push_token &&
      subToNotifications
    ) {
      //make sure they have already approved and they are open tot eacher activity alerts
      dataToUpdate[
        `users/${studentId}/push_subscriptions/my_fav_yogi_${teacherId}`
      ] = new_fav_val;
    }
    // console.info("datsto update", dataToUpdate);
    //show loader
    return database.ref().update(dataToUpdate);
  }
  return Promise.reject('Missing a student or teacher id.');
};

const updateYogiProfileInfo = ({
  uid,
  fullname,
  username,
  usernameOriginal,
  bio,
  website,
  email,
  profile_photo_url,
}) => {
  if (!(uid && uid.length)) {
    return Promise.reject('Cannot find user to update.');
  }
  if (fullname && fullname.length) {
    const dataToUpdate = {};
    dataToUpdate[`users/${uid}/fullname`] = fullname;
    return database.ref().update(dataToUpdate);
  }

  if (bio && bio.length) {
    const dataToUpdate = {};
    dataToUpdate[`users/${uid}/bio`] = bio;
    return database.ref().update(dataToUpdate);
  }

  if (website && website.length) {
    const dataToUpdate = {};
    dataToUpdate[`users/${uid}/website`] = website;
    return database.ref().update(dataToUpdate);
  }

  if (profile_photo_url && profile_photo_url.length) {
    const dataToUpdate = {};
    dataToUpdate[`users/${uid}/profile_photo_url`] = profile_photo_url;
    return database.ref().update(dataToUpdate);
  }

  if (username && username.length) {
    const dataToUpdate = {};
    dataToUpdate[`users/${uid}/username`] = username;
    dataToUpdate[`usernames/${username}`] = uid;
    if ((usernameOriginal || '').length) {
      //if it exists,remove the old usename->uid connection to free up for others
      dataToUpdate[`usernames/${usernameOriginal}`] = null;
    }
    return database.ref().update(dataToUpdate);
  }

  return Promise.reject('Cannot find data to update.');
};

const removeStudentInClassConvo = async ({ uid, classId, studentId }) => {
  let query = await firestore.collection(ConvoMetadata);
  query = query.where('teacher_id', '==', uid);
  query = query.where('class_id', '==', classId);
  query.get().then(async (res) => {
    let convoId = '';
    if (!res.empty) {
      convoId = res.docs[0].data().convo_id;
      const updateData = {};
      updateData[`convobyusers/${studentId}/${convoId}`] = null;
      const member = await getChatMembershipDetails({
        convoId,
        uid: studentId,
      });
      if (member.documentId) {
        firestore.collection('ConvoMembers').doc(member.documentId).delete();
      }
      return database.ref().update(updateData);
    }
  });
};

const updateStudentRegistrationForClassOrEvent = ({
  classInfo,
  student,
  teacher,
  classId,
  eventId,
  priceTotal,
  paymentTransactionId,
}) => {
  //double check only one id exist
  if (eventId && classId) {
    // console.warn("Do not send both an eventId and classId. Event if empty string at the moment.");
    //return;
  }
  return Promise.resolve(
    getValidStudentRegistrationForClassOrEvent({ classInfo, student, classId })
  ).then((existingClassRegistration) => {
    //this.refs.ModalBlank_RegisterFreeClass.hideMe();
    const expected_date = getExpectedDateForClassOrEvent(classInfo); // only intended to be used when not pre-existing in logic below. so its projecting to create a registration

    const metaclassid = getMetaIdForClassOrEvent({
      classInfo,
      classId,
      eventId,
      existingClassRegistration,
    });

    const cr_data_ref = getRegistrationByStudentDatabaseRef({
      classInfo,
      student,
      classId,
      eventId,
      existingClassRegistration,
    });
    const cr_data_teacher_ref = `classregistrationsbyteacher/_teacherid_${teacher.uid}/${metaclassid}/_studentid_${student.uid}`;
    const cbu_data_personal_registration_uid_ref = `classesbyuserid/${teacher.uid}/${classId}/personal_registration_uid`;

    const updateData = {};

    if (existingClassRegistration) {
      updateData[cr_data_ref] = null;
      updateData[cr_data_teacher_ref] = null;
      updateData[cbu_data_personal_registration_uid_ref] = null;
      removeStudentInClassConvo({
        uid: teacher.uid,
        classId,
        studentId: student.uid,
      });
    } else {
      //default for onetime
      const cr_data = {
        created: Date.now(),
        title: classInfo.title || null,
        description: classInfo.description || null,
        teacherid: teacher.uid,
        studentid: student.uid,
        dateofclass: moment(expected_date).valueOf() || null,
        location: classInfo.location || null,
        buyertotalprice: priceTotal || null,
        paymenttransactionid: paymentTransactionId || null,
        class_info: classInfo,
      };
      setupClassGroupChat({
        uid: teacher.uid,
        classTitle: classInfo.title,
        profilePhotoUrl: teacher.profile_photo_url,
        classId,
        studentId: student.uid,
      });
      updateData[cr_data_ref] = cr_data;
      updateData[cr_data_teacher_ref] = cr_data;
      if (classInfo.class_type === 'personal') {
        updateData[cbu_data_personal_registration_uid_ref] = student.uid;
      }
    }

    return database
      .ref()
      .update(updateData)
      .catch((err) => {
        throw err;
      });
  });
};

const doSetUsernameAttempt = ({ uid, desiredUsername, attemptCount }) => {
  let uname = desiredUsername;
  uname = uname.replace(/ /g, '_'); //spaces get turned to underscore
  uname = uname.replace(/\W/g, ''); //non-alpha numeric get removed (exempts underscore)
  uname = uname.toLowerCase(); //make it lowercase for easier search
  //remove alphanumerics
  // Write the new post"s data simultaneously in the posts list and the user"s post list.
  const updates = {};
  updates['users/' + uid + '/username'] = uname;
  updates['usernames/' + uname] = uid;

  database
    .ref()
    .update(updates)
    .catch((err) => {
      // console.warn("Perhaps This username is already taken. Please try a different name.", err);
      // console.warn("Username set attempt count and name", attemptCount, uname);
      if (!attemptCount || attemptCount < 4) {
        const newUsername = uname + '_' + Math.round(Math.random() * 1000);
        const newAttemptCount = (attemptCount || 1) + 1;
        doSetUsernameAttempt({
          uid,
          desiredUsername: newUsername,
          attemptCount: newAttemptCount,
        });
      }
    });
};

export const DataFX = {
  doSetUsernameAttempt,
  updateTeacherStudentRelationship,
  updateYogiProfileInfo,
  updateStudentRegistrationForClassOrEvent,
  removeStudentInClassConvo,
};

export const getNameIntials = (fullname) => {
  if (!fullname) {
    return "";
  }
  let names = fullname.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
};
