import React from 'react';
import ReactDOM from 'react-dom';

import {
  // BrowserRouter,
  Router,
} from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import ErrorBoundary from './ErrorBoundary';

const history = createBrowserHistory();
let previousPathname;

history.listen((location, action) => {
  const path =
    window &&
    window.location &&
    window.location.pathname + location.pathname.slice(1);
  if (previousPathname !== path) {
    window.scrollTo(0, 0);
  }
  previousPathname = path;
  // console.log("location and action to be saved in local session storage for memory? maybe an array or string", location, action);
  const currentUrl = `${location.pathname}${location.search}${location.hash}`;

  // console.log(`The current URL is ${currentUrl}`);
  // console.log(`The last navigation action was ${action}`);

  let currentHistory = JSON.parse(
    window.sessionStorage.getItem('myCustomHistory') || '[]'
  );
  // console.log("current history pulled from session storage", currentHistory);
  if (currentHistory.constructor !== Array) {
    currentHistory = [];
  }
  if (action !== 'POP') {
    currentHistory = [currentUrl]; //.push(currentUrl);
    const newHistory = JSON.stringify(currentHistory);
    // console.log("new history bieng sent to session storage", newHistory);
    window.sessionStorage.setItem('myCustomHistory', newHistory);
  }
});
// console.log("history after init app root", history);
// Put any other imports below so that CSS from your
// components takes precedence over default styles.
// const youAreHere = document.getElementById('youAreHere')

// ReactDOM.render(<App />, document.getElementById('root'));

ReactDOM.render(
  <Router history={history}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Router>,
  document.getElementById('root')
);
registerServiceWorker();
