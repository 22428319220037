import React, { Component } from 'react';
import {
    InstantSearch,
    SearchBox,
    // Highlight,
    // Snippet,
    // Stats,
    // SortBy,
    // Panel,
    Configure,
    Index,
} from 'react-instantsearch/dom';

import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import { isEqual } from 'lodash';
import {
    // UtilFX,
    // DataFX,
    Colors,
    // GlobalContext,
    // GlobalStyles,
    // AlertBox,
} from '../utils';
import * as Algolia from '../utils/algolia';
import { HitsYogis } from './Explore';
import ButtonComponent from './common/ButtonComponent';
// import { Colors } from '../utils/index';
// import { index } from '../utils/algolia';


export const InviteActionComponent = (props) => {
    const { invitedStudents, item, onMark } = props;

    const isStudentAlreadyInvited = invitedStudents.filter(value => isEqual(value, item)).length > 0;

    return (
        <div
            style={{
                borderWidth: 1,
                borderColor: '#fff',
                borderRadius: 5,
                padding: 5,
                justifyContent: 'center',
                alignItems: 'center'
            }}
            onClick={() => onMark(item)}
        >
            {
                isStudentAlreadyInvited ? <Icon name="check" type="material" style={{ color: Colors.pri_color, width: 30, height: 30 }}> check </Icon> : <Typography className="cursor-pointer"> invite </Typography>
            }
        </div>
    );
};

export default class YogiPicker extends Component {
    state = {
        currentSearchText: '',
    };

    componentDidMount() {
        this._mounted = true;
    }

    componentWillUnmount() {
        this._mounted = false;
    }


    render() {
        const { context } = this.props;
        const _u = context && context.userAuthenticated;
        const {
            onSelected, initialUserIdList = [], maxResults, actionComponent: ActionComponent, persistedUserIdList = [],
            showInviteAllStudentsButton, inviteAllStudentsAction
        } = this.props;
        const { currentSearchText } = this.state;
        let filterString = `NOT objectID:${_u && _u.uid}`;

        if (currentSearchText.length < 1 && initialUserIdList.length) {
            filterString = initialUserIdList.map(uid => `objectID:${uid}`).join(' OR ');
        }

        if (persistedUserIdList.length) {
            filterString = persistedUserIdList.map(uid => `objectID:${uid}`).join(' OR ');
        }
        return (
            <div>
                <div
                    // className="explore-wrapper"
                    style={{
                        backgroundColor: '#fff',
                        borderRadius: 18,
                        padding: 15,
                        color: Colors.pri_color,
                        textAlign: 'center',
                    }}
                >
                    {
                        Boolean(Algolia && Algolia.ApplicationId && Algolia.SearchApiKey)
                        && (
                            <InstantSearch
                                appId={Algolia.ApplicationId}
                                apiKey={Algolia.SearchApiKey}
                                indexName="yogis"
                                onSearchStateChange={(ossc_props) => {
                                    // console.log("osearcgstatechanged", ossc_props);
                                }}
                            >
                                {/* Use widgets here */}
                                <SearchBox
                                    translations={{
                                        placeholder: 'Search by name...',
                                    }}
                                />

                                {
                                    showInviteAllStudentsButton && (
                                        <div style={{ margin: '20px 0px' }}>
                                            <ButtonComponent 
                                                value="Invite all students"
                                                buttonStyle={{ backgroundColor: '#ECF5FE', padding: '10px', border: '1px solid ' + Colors.pri_color, color: Colors.pri_color }}
                                                onClick={()=> inviteAllStudentsAction()}
                                            />
                                        </div>
                                    )
                                }

                                <Index indexName="yogis">
                                    <Configure
                                        // page={1}
                                        hitsPerPage={maxResults || 6}
                                        filters={filterString}
                                    />
                                    <div style={{ marginTop: 15 }}>
                                        <HitsYogis
                                            {...this.props}
                                            onMark={(item) => {
                                                // console.log({ item });
                                                if (typeof onSelected === 'function') {
                                                   onSelected(item);
                                                }
                                            }}
                                            actionComponent={ActionComponent}
                                        />
                                    </div>
                                </Index>

                                {/* <Pagination
                                        totalPages={2}
                                        showNext={false}
                                        showPrevious={false}
                                        showFirst={false}
                                        showLast={false}
                                        padding={10}
                                    /> */}
                            </InstantSearch>
                        )
                    }

                </div>

            </div>
        );
    }
}
