import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ButtonComponent from "./common/ButtonComponent";
import { firebase } from "../fire";
import Button from "@material-ui/core/Button";
import { UtilFX, Colors, GlobalStyles } from "../utils";

export default class PaymentEarnings extends Component {
  constructor(props) {
    super(props);
    this.auth_user = this.props.context.userAuthenticated;

    this.state = {
      total_pending: 0,
      total_confirmed: 0,
    };
  }

  componentDidMount() {
    // const total = {}
    let total_pending = 0;
    let total_confirmed = 0;
    firebase
      .database()
      .ref("paymenttransactions")
      .orderByChild("to")
      .equalTo(this.auth_user.uid)
      .on("value", (snap) => {
        //reset the count, lest we just keep adding
        total_pending = 0;
        total_confirmed = 0;
        snap.forEach((vs) => {
          const v = vs.val();
          v.ptid = vs.key;
          //count up
          //console.log("all t me status", v.status, v.price_total)
          if (v.status === "pending") {
            const x = Number(v.price_total) - Number(v.fee_amount);
            total_pending += x;
            //console.log("got apending", v.status, v.price_total, v.fee_amount, total_pending)
          } else if (v.status === "paid") {
            const x = Number(v.price_total) - Number(v.fee_amount);
            total_confirmed += x;
            //console.log("got apaid", v.status, v.price_total, v.fee_amount, total_confirmed)
          } else {
            console.log("uncountedstatus", v.status, v.price_total);
          }
        });

        this.setState({
          total_pending,
          total_confirmed,
          total_everything: total_confirmed + total_pending,
        });
      });

    firebase
      .database()
      .ref("users/" + this.auth_user.uid)
      .on("value", (usnap) => {
        const _u = usnap.val();

        this.setState({ fyu: _u });
      });
  }

  componentWillUnmount() {
      firebase.database()
        .ref('users/' + this.auth_user.uid)
        .off();
      firebase.database()
        .ref('paymenttransactions')
        .orderByChild('to')
        .equalTo(this.auth_user.uid)
        .off();
  }

  openStripeAccountUrl = () => {
    const client_id = UtilFX.getIsDevEnv()
      ? process.env.REACT_APP_DEV_STRIPE_clientid
      : process.env.REACT_APP_PROD_STRIPE_clientid;
    const _u = this.state.fyu || {};
    let url_website = _u.website || "";
    if (url_website.length) {
      if (url_website.indexOf("http") !== 0) {
        url_website = "http://" + url_website;
      }
    }
    const fname = (_u.fullname || "").split(" ")[0].trim();
    const lname = (_u.fullname || "").replace(fname, "").trim();

    let _msg = `Attmept to complete to Stripe oauth: @${
      (this.state.fyu || {}).username
    } email:${(this.state.fyu || {}).email} uid:${this.auth_user.uid}`;
    UtilFX.triggerAdminAlert(_msg, {
      action: "ATTEMPT_STRIPE_CONNECT",
      uid: this.auth_user.uid,
    });
    UtilFX.logAnalytics("earnings_oauth_init", {});
    return window.open(`https://connect.stripe.com/oauth/authorize?response_type=code&scope=read_write&client_id=${client_id}&stripe_user[email]=${_u.email}&stripe_user[url]=${url_website}&stripe_user[first_name]=${fname}&stripe_user[last_name]=${lname}&state=${this.auth_user.uid}__WEB`);
  };

  openStripeUrl = () => {
    return window.open("https://stripe.com/");
  };

  render() {
    return (
      <Grid container>
        <Grid
          item
          md={12}
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <Typography
              style={{
                marginTop: "20px",
                fontSize: "40px",
              }}
            >
              <span role="img" aria-label="money icon">
                💰
              </span>
            </Typography>
            <Typography
              style={{
                textAlign: "center",
                marginTop: "5px",
                fontSize: "20px",
              }}
            >
              {UtilFX.getValueAsCurrency(
                (this.state || {}).total_everything || 0
              )} USD
            </Typography>
          </div>
          {this.state.total_pending ? (
            <Typography style={{ textAlign: "center" }}>
              ({UtilFX.getValueAsCurrency(this.state.total_pending)} of your
              earnings are still pending)
            </Typography>
          ) : null}
        </Grid>
        <Grid item md={12} xs={12} style={{ margin: "20px" }}>
          <ButtonComponent
            onClick={() => this.props.history.push("/history")}
            value="See payment history"
            buttonStyle={{
              ...GlobalStyles.bodyMedium,
              backgroundColor: Colors.white,
              borderRadius: "10px",
              color: Colors.pri_color,
              padding: "18px",
            }}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ marginTop: "20px" }}>
          {!(this.state && (this.state.fyu || {}).stripe_account_id) ? (
            <div style={{ justifyContent: "center", alignItems: "center" }}>
              <Typography
                style={{ textAlign: "center", paddingHorizontal: 15 }}
              >
                To get paid you must connect to Stripe. Please have the
                following bank information ready:
              </Typography>
              <div>
                <Typography style={{ textAlign: "center", fontWeight: "bold" }}>
                  Routing number
                </Typography>
                <img
                  alt="9-digits-check"
                  style={{
                    height: 50,
                  }}
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/favyogis-prod.appspot.com/o/marketing_assets%2Ffavyogis_linkbank_routing.png?alt=media&token=08c660be-e50e-497e-96c7-6998be8ebd0f"
                  }
                />
                <Typography style={{ textAlign: "center", fontSize: 12 }}>
                  It's the 9 digit number at the lower left corner of your
                  checks. No checks? Log into your online banking and you can
                  find it there.
                </Typography>
              </div>

              <div>
                <Typography style={{ textAlign: "center", fontWeight: "bold" }}>
                  Account number
                </Typography>
                <img
                  style={{
                    height: 50,
                  }}
                  alt="account-number-link"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/favyogis-prod.appspot.com/o/marketing_assets%2Ffavyogis_linkbank_account_number.png?alt=media&token=1f4826e6-b9c6-47e4-863e-fb23f04bface"
                  }
                />
              </div>
              <Button
                style={{ marginTop: 20, border: 0, boxShadow: "none" }}
                onClick={this.openStripeAccountUrl}
              >
                <img
                  style={{
                    width: 300,
                    height: 52,
                  }}
                  alt="stripe-connect-account"
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/favyogis-prod.appspot.com/o/marketing_assets%2Fstripe_connect.png?alt=media&token=967d67f4-b6a6-4fb9-9993-877e8c487bcf"
                  }
                />
              </Button>
              <Typography style={{ textAlign: "center", padding: 15 }}>
                Connect to Stripe and get paid.
              </Typography>
            </div>
          ) : (
            <Button
              onClick={this.openStripeUrl}
              style={{ border: 0, boxShadow: "none" }}
            >
              <img
                style={{
                  width: 238,
                  height: 52,
                }}
                alt="stripe"
                src={
                  "https://firebasestorage.googleapis.com/v0/b/favyogis-prod.appspot.com/o/marketing_assets%2Fstripe_poweredby.png?alt=media&token=c2823c6e-9204-46f6-a4b5-027355001799"
                }
              />
            </Button>
          )}
        </Grid>
      </Grid>
    );
  }
}
