import React, { useState } from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import TitleCardOptions from '../TitleCardOptions';
import ReplyMessage from './ReplyMessage';
import { Colors, GlobalStyles } from '../../../utils';
import LikeEmoji from './LikeEmoji';
import AvatarDisplay from '../../AvatarDisplay';

const MessageCard = ({
  user,
  replyHandler,
  convoMessages,
  convoMembersUserObj,
  getAllBookmarkMessages,
  bookmarkMessageHandler,
  listOfBookmarkIds,
  showConvoBookmark,
  teacherId,
  pinnedMessages,
  dmChatType,
  convos
}) => {
  const [performAction, setPerformAction] = useState({ reply: false });

  let filteredConvoMessages = (convoMessages && convoMessages.filter((item) => !item.date_deleted)) || [];
  if (showConvoBookmark) {
    filteredConvoMessages = (filteredConvoMessages && filteredConvoMessages.filter((item) => listOfBookmarkIds.includes(item.message_id))) || [];
  }
  return (
    filteredConvoMessages
      .sort((a, b) => a.date_created - b.date_created)
      .map((item, index) => {
        const left = user.uid !== item.sender_uid;
        const reply = !!item.replied_to_message_id;
        const isBookmarked = listOfBookmarkIds.includes(item.message_id);
        
        return (
          <div key={item.message_id} style={{
            marginTop: '26px',
            paddingBottom: (index === filteredConvoMessages.length - 1) ? 15 : 0 // the last item needs more padding for proper scroll
          }}>
            <div
              style={{
                display: 'flex',
                flexDirection: left ? 'row' : 'row-reverse',
              }}
            >
              {convoMembersUserObj && (
                <Link
                  to={
                    {
                      pathname: `/${convoMembersUserObj[item.sender_uid]?.username}`,
                    }
                  }
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                >
                 
                 <AvatarDisplay
                 imgSrc={(convoMembersUserObj[item.sender_uid] || {})
                 .profile_photo_url || 'https://placehold.it/100'}
                 imgAlt={'message-avatar'}
                 imgStyles={{
                  width: '48px',
                  height: '48px',
                  borderRadius: '24px',
                }}
                 userName={convoMembersUserObj[item.sender_uid]?.fullname}
                 textStyles={{color: 'rgb(23, 155, 247)', marginTop: '.8rem'}}
                 />
                </Link>
              )}
              {convoMembersUserObj && (
                <Typography
                  style={{
                    ...GlobalStyles.bodyMedium,
                    color: Colors.grey1,
                    textAlign: 'left',
                    marginLeft: left ? '10px' : 0,
                    marginRight: left ? 0 : '10px',
                    marginTop: '13px',
                  }}
                >
                  {(convoMembersUserObj[item.sender_uid] || {}).fullname || ''}
                </Typography>
              )}
              <Typography
                style={{
                  ...GlobalStyles.bodyMedium,
                  color: Colors.grey7,
                  textAlign: 'left',
                  marginLeft: left ? '6px' : 0,
                  marginRight: left ? 0 : '6px',
                  marginTop: '7px',
                  fontSize: '15px',
                }}
              >
                .
              </Typography>
              <Typography
                style={{
                  ...GlobalStyles.bodyRegular,
                  color: Colors.grey3,
                  textAlign: 'left',
                  marginLeft: left ? '6px' : 0,
                  marginRight: left ? 0 : '6px',
                  marginTop: '15px',
                  fontSize: '10px',
                }}
              >
                {moment(item.date_created).format('YYYY-MM-DD h:mm a')}
              </Typography>
              {isBookmarked && (
                <span
                  className="material-icons"
                  style={{
                    color: Colors.grey4,
                    marginLeft: left ? '10px' : 0,
                    marginRight: left ? 0 : '10px',
                    fontSize: '15px',
                    marginTop: '14px'
                  }}
                >
                  star
                </span>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: left ? 'row' : 'row-reverse',
                marginLeft: left ? '60px' : 0,
                marginRight: left ? 0 : '60px',
              }}
            >
              <div>
                <div style={{
                  boxShadow:
                    '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 3px 5px rgba(0, 0, 0, 0.2)',
                  borderRadius: '0px 10px 10px 10px',
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '352px',
                }}>
                  {reply && (
                    <div style={{ padding: '10px' }}>
                      <ReplyMessage
                        repliedToMessageId={item.replied_to_message_id}
                        left={left}
                        convoMembersUserObj={convoMembersUserObj}
                      />
                    </div>
                  )}
                  {
                    item.gif_url &&
                    <img src={item.gif_url} className='tenor-gif' style={{ borderRadius: !item.message_text && !reply && '10px', boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 3px 5px rgba(0, 0, 0, 0.2)' }} alt='gif-preview' />
                  }
                  {
                    item.message_text &&
                    <Typography
                      style={{
                        ...GlobalStyles.bodyRegular,
                        padding: '10px',
                        color: Colors.grey2,
                        whiteSpace: 'pre-wrap',
                        textAlign: 'left',
                      }}
                    >
                      {item.message_text}
                    </Typography>
                  }
                </div>
                <LikeEmoji left={left} item={item} user={user} />
              </div>
              <div
                style={{
                  color: Colors.grey2,
                  marginLeft: left ? '27px' : 0,
                  marginRight: left ? 0 : '27px',
                }}
              >
                {convoMembersUserObj && (
                  <TitleCardOptions
                    messageDetails={{
                      fullname: (convoMembersUserObj[item.sender_uid] || {})
                        .fullname,
                      uid: user.uid,
                      ...item,
                    }}
                    replyHandler={replyHandler}
                    setPerformAction={setPerformAction}
                    performAction={performAction}
                    arrowDown
                    messagePopover
                    getAllBookmarkMessages={getAllBookmarkMessages}
                    bookmarkMessageHandler={bookmarkMessageHandler}
                    teacherId={teacherId}
                    uid={user.uid}
                    pinnedMessages={pinnedMessages}
                    dmChatType={dmChatType}
                    convos={convos}
                  />
                )}
              </div>
            </div>
          </div>
        );
      })
  );
};

export default MessageCard;
