import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import ButtonComponent from '../ButtonComponent';
import { Colors, GlobalStyles } from '../../../utils';
import ImgDelete from './svg/delete_image.svg';
import '../../../App.css';

const BookmarkHeader = ({ title, goBack, bookmarks }) => {
  const [openDelete, setOpenDelete] = useState(false);

  const handleDeleteModal = () => {
    setOpenDelete(!openDelete);
    bookmarks();
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: Colors.primaryColor2,
        padding: '16px',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="hide-back-arrow">
          <span
            className="material-icons"
            style={{ marginRight: '20px', cursor: 'pointer' }}
            onClick={goBack}
          >
            arrow_back
          </span>
        </div>
        <Typography style={{ ...GlobalStyles.titleBold, fontSize: '18px' }}>
          {title}
        </Typography>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', }}>
        {/* <span className="material-icons" style={{ marginRight: '33px' }}>
          search
        </span> */}
        <span
          className="material-icons"
          onClick={() => setOpenDelete(!openDelete)}
          style={{ cursor: 'pointer' }}
        >
          delete
        </span>
      </div>
      <Modal
        disableAutoFocus
        disableEnforceFocus
        aria-labelledby="clear bookmarks"
        aria-describedby="clear bookmarks or cancel"
        className="center-modal"
        open={Boolean(openDelete)}
      >
        <div
          style={{
            width: '356px',
            backgroundColor: Colors.white,
            margin: 'auto',
            marginTop: '70px',
            borderRadius: '8px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <img
              src={ImgDelete}
              alt="delete-svg"
              style={{ borderRadius: '8px' }}
            />
            <div style={{ margin: '20px 24px 24px 24px', textAlign: 'center' }}>
              <Typography
                style={{
                  ...GlobalStyles.titleBold,
                  fontSize: '18px',
                  color: Colors.grey1,
                }}
              >
                Clear all bookmarks
              </Typography>
              <Typography
                style={{
                  ...GlobalStyles.bodyRegular,
                  color: Colors.grey1,
                  marginTop: '6px',
                }}
              >
                {'This action can’t be undone! Proceed\n\nanyway?'}
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '24px',
                }}
              >
                <ButtonComponent
                  value="CANCEL"
                  buttonStyle={{
                    backgroundColor: Colors.grey6,
                    color: Colors.grey1,
                    borderRadius: '5px',
                    marginRight: '10px',
                    borderColor: Colors.grey6
                  }}
                  onClick={() => setOpenDelete(!openDelete)}
                />
                <ButtonComponent
                  value="Delete"
                  buttonStyle={{
                    backgroundColor: Colors.red,
                    color: Colors.white,
                    borderRadius: '5px',
                    borderColor: Colors.red
                  }}
                  onClick={handleDeleteModal}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BookmarkHeader;
