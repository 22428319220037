import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import Helmet from 'react-helmet';

import Grid from '@material-ui/core/Grid';

import moment from 'moment-timezone';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { UtilFX, Loading, DataFX, Colors, GlobalStyles } from '../utils';

import { firebase } from '../fire';

import SnackBarComponent from './common/SnackBarComponent';
import { LoginSignupForgotAuthUI } from '../App';
import { PayConfirmation } from './PaymentStuff';

import * as Algolia from '../utils/algolia';
import CheckBoxComponent from './common/CheckBoxComponent';
import ButtonComponent from './common/ButtonComponent';
import ImgMessage from '../new_message_icon.svg';
import ImgTickSucccess from '../subway_tick.svg';
import AvatarDisplay from './AvatarDisplay';

const AlgoliaYogisIndex = Algolia.getIndex({ indexName: 'yogis' });
const AlgoliaClassesIndex = Algolia.getIndex({ indexName: 'classes' });

const google_calendar_icon = require('../google_calendar_icon.png');
const ical_calendar_icon = require('../ical_calendar_icon.png');
const calender_grey_icon = require('../calender_grey_icon.png');

const google_grey_icon = require('../google_grey_icon.png');
const yahoo_calendar_icon = require('../yahoo_calendar_icon.png');
const yahoo_grey_icon = require('../yahoo_grey_icon.png');

export default class ClassesDetailX extends Component {
  state = {
    isLoading: false,
    isRegistered: null,
    feesAbsorb: null,
    isSoldOut: null,
    price: null,
    ticketsDispensed: null,
    ticketsAvailable: null,
    classInfo: null,
    locMapUrl: null,
    openPayModal: false,
    openDonationModal: false,
    favorite: true,
    openSnackBar: null,
    showModalToFavoriteTeacher: true,
    openClassRegistrationModal: false,
  };

  componentDidUpdate(oldProps, oldState) {
    const { context: { userAuthenticated } = {} } = this.props;
    const {
      context: { userAuthenticated: oldUserAuthenticated } = {},
    } = oldProps;
    const { isRegistered, error, classInfo = {} } = this.state;
    const {
      isRegistered: oldIsRegistered,
      classInfo: oldClassInfo = {},
    } = oldState;

    if (
      !error &&
      (isRegistered !== oldIsRegistered || //reg changed
        (userAuthenticated && !oldUserAuthenticated) || // user/student data chagned. update registration
        Object.keys((classInfo && classInfo.paused) || {}).length !==
          Object.keys((oldClassInfo && oldClassInfo.paused) || {}).length) // if pauseing of a class subscription changed, we gotta re-render
    ) {
      this.getUserData();
    }
  }

  static getDerivedStateFromProps(oldProps, oldState) {
    const { userData } =
      (oldProps.location && oldProps.location.state) || oldProps || {};
    const { username } =
      (oldProps.match && oldProps.match.params) || userData || {};
    const oldUsername = oldState && oldState.username;
    if (!oldUsername || oldUsername !== username) {
      return {
        ...oldState,
        userData,
        username,
      };
    }

    return null;
  }

  componentDidMount() {
    this._mounted = true;
    const { userData } =
      (this.props.location && this.props.location.state) || this.props || {};
    const { username, classid, eventid } =
      (this.props &&
        (this.props.computedMatch || this.props.match || {}).params) ||
      userData ||
      {};

    this._mounted = true;
    this.setState(
      {
        userData,
        username,
        classid: classid || eventid,
      },
      () => {
        this.getUserData();
      }
    );
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  checkToFavoriteTeacher = () => {
    const { context: { userAuthenticated } = {} } = this.props;
    const { userData: { uid: teacherId = '' } = {} } = this.state;
    const favorite = !Object.keys(
      (userAuthenticated && userAuthenticated.teachers) || {}
    ).includes(teacherId);
    this.setState({ favorite, showModalToFavoriteTeacher: favorite });
  };

  addTeacherToFavorite = () => {
    const { context: { userAuthenticated: student } = {} } = this.props;
    const { userData: teacher } = this.state;
    DataFX.updateTeacherStudentRelationship({
      teacherId: teacher.uid,
      studentId: student.uid,
      connectValue: true,
      subToNotifications: !student.push_disable_teacher_activity_alert,
    })
      .then(() => {
        this.setState({
          openClassRegistrationModal: false,
          openSnackBar: true,
          showModalToFavoriteTeacher: false,
        });
      })
      .catch((err) => {
        console.warn('ERROR saving data', err);
      });
  };

  getUserData = () => {
    const { username } = this.state || {};
    this._mounted && this.setState({ isLoading: true });
    AlgoliaYogisIndex.search({ query: username })
      .then((hitData) => {
        const { hits = [] } = hitData;
        let foundYogi;
        for (let i = 0; i < hits.length; i++) {
          const yogi = hits[i];
          if (yogi.username === username) {
            foundYogi = yogi;
            foundYogi.uid = yogi.objectID;
            break;
          }
        }

        if (foundYogi) {
          this._mounted &&
            foundYogi &&
            this.setState({ userData: foundYogi }, () => {
              this._mounted && this.setState({ isLoading: false });
              this._mounted && this.getClassEventData();
              this._mounted &&
                Promise.resolve(
                  UtilFX.getDefaultTimeZoneValue(foundYogi.uid)
                ).then((result) =>
                  this.setState({ defaultTimeZoneValue: result })
                );
              this._mounted && this.checkToFavoriteTeacher();
            });
        } else {
          this._mounted &&
            this.state.isLoading &&
            this.setState({ userData: null, isLoading: false });
        }
      })
      .catch((err) => {
        console.warn('Error loading data', err);
        this._mounted &&
          this.state.isLoading &&
          this.setState({ error: err || true, isLoading: false });
      });
  };

  getClassEventData = () => {
    const { userData: teacher, classid } = this.state || {};
    const { context: { userAuthenticated: student } = {} } = this.props || {};
    const classAlgoliaObjectID = classid;
    if (!(teacher && teacher.uid)) {
      this._mounted && this.setState({ error: 'No user found.' });
      console.warn('no appropritae teacher found. no user id', teacher);
    }
    this.setState({ isLoading: true });
    let classInfoFromFirebaseRTDB; //RTDB updats are faster than othe so when pause stuff changes, we need to get it from there
    const classInfoPromise = firebase
      .database()
      .ref(`classesbyuserid/${teacher.uid}/${classid}`)
      .once('value');
    return classInfoPromise
      .then(async (snap_classInfoFromFirebaseRTDB) => {
        classInfoFromFirebaseRTDB =
          snap_classInfoFromFirebaseRTDB &&
          snap_classInfoFromFirebaseRTDB.val();
        let hitData;
        try {
          hitData = await AlgoliaClassesIndex.getObject(classAlgoliaObjectID);
        } catch (err) {
          console.info('err retrieving', err);
        }
        const { classInfo: classInfoFromAlgolia, placeID } = hitData || {};
        const classInfo = classInfoFromFirebaseRTDB || classInfoFromAlgolia;
        this._mounted &&
          this.setState(
            {
              classInfo,
            },
            async () => {
              // if we have a user lets get info of their teachers to suggest
              let isRegistered;
              let existingClassRegistration;
              if (classInfo && student && student.uid) {
                existingClassRegistration = await UtilFX.getValidStudentRegistrationForClassOrEvent(
                  { classInfo, student, classId: classid }
                );
                isRegistered = Boolean(existingClassRegistration);
              }
              const coords = {
                lat: classInfo && classInfo.location && classInfo.location.lat,
                lng: classInfo && classInfo.location && classInfo.location.lng,
              };
              let locMapUrl;
              let placeId;
              if (coords.lat) {
                locMapUrl = UtilFX.getGoogleMapsImage({
                  ...coords,
                  width: 300,
                  height: 150,
                });
                placeId =
                  placeID || UtilFX.getAlgoliaBasedObjectIdFromLocation(coords);
              }
              this._mounted &&
                this.setState(
                  {
                    isLoading: false,
                    isRegistered,
                    locMapUrl,
                    placeId,
                  },
                  () => {
                    this.updateInviteStatus();
                  }
                );
            }
          );
      })
      .catch((err) => {
        UtilFX.getIsDevEnv() && console.warn('error getting data', err);
        this._mounted &&
          this.state.isLoading &&
          this.setState({ error: err || true, isLoading: false });
      });
  };

  updateInviteStatus = () => {
    const { classid } = this.state || {};
    const { context: { userAuthenticated: student } = {} } = this.props || {};
    if (student && student.uid) {
      firebase
        .database()
        .ref(`invitesbystudentid/${student.uid}`)
        .orderByChild('classid')
        .equalTo(classid)
        .on('value', (snap) => {
          const invitesRetrieved = snap.val() || {};
          const latestInviteId = Object.keys(invitesRetrieved)
            .sort((a, b) => a.localeCompare(b))
            .reverse()[0];

          const latestInvite = invitesRetrieved[latestInviteId];
          if (latestInvite) {
            latestInvite._inviteid = latestInviteId;
          }
          this.setState(
            {
              invite: latestInvite,
              // pristine: false,
            },
            () => {
              this.updateTicketStatus();
            }
          );
        });
    } else {
      this.updateTicketStatus();
    }
  };

  updateTicketStatus = () => {
    //get # of registrants for class and check it against quanity
    const { classInfo: { quantity: ticketQuantityMax } = {} } =
      this.state || {};
    const { userData: teacher, classInfo, classid: classId } = this.state || {};
    const { context } = this.props;
    const { userAuthenticated: student } = context || {};

    student &&
      student.uid &&
      UtilFX.getRegisteredStudentsForATeacherClassOrEvent({
        classInfo,
        teacher,
        classId,
      })
        .then((registrationList) => {
          const ticketsDispensed = Object.keys(registrationList || {}).length;
          const isSoldOut = !!(
            ticketQuantityMax && ticketsDispensed >= ticketQuantityMax
          );
          this._mounted &&
            this.setState({ ticketsDispensed, isSoldOut }, async () => {
              let isRegistered;
              let existingClassRegistration;
              if (classInfo && student && student.uid) {
                existingClassRegistration = await UtilFX.getValidStudentRegistrationForClassOrEvent(
                  { classInfo, student, classId }
                );
                isRegistered = Boolean(existingClassRegistration);
              }

              setTimeout(() => {
                const { action } =
                  (this.props.location && this.props.location.state) || {};
                const { invite } = this.state;

                if (
                  isRegistered === false &&
                  action === 'register' &&
                  student
                ) {
                  const stateCopy = { ...this.state };
                  delete stateCopy.action;
                  this.props.history &&
                    this.props.history.replace({ state: stateCopy });

                  //  possibly open invite curtain
                  if (
                    classInfo.private &&
                    (!invite || (invite && !invite.date_opened))
                  ) {
                    this.openInvitation();
                  } else if (!isSoldOut) {
                    // not sold out? register the class // else do nothing
                    this.registerForClassOrEvent_Begin();
                  }
                }
              }, 200);
            });
        })
        .catch((err) => {
          console.warn('error - ', err);
        });
    // if (this.ticket_quantity) {
    //     var qty = Number(this.ticket_quantity);
    //     this.FBA.database()
    //         .ref(
    //             `classregistrationsbyteacher/_teacherid_${this.state.teacherid}/${
    //             this.state.metaclassid
    //             }`
    //         )
    //         .once('value', snap => {
    //             var reg_count = snap.numChildren();
    //             var is_sold_out = reg_count >= qty;
    //             console.log(
    //                 'reg/qty and is ticket sold out',
    //                 reg_count,
    //                 qty,
    //                 is_sold_out
    //             );
    //             this.setState({ is_sold_out: is_sold_out });
    //         });
    // }
  };

  _setClassHistoryInLocalStorage = () => {
    const { userData: teacher, classid } = this.state || {};
    // console.log('I woudl dir you away with a history.push', history.push);
    // set local storage  or session storage so we can auto-add this teacher after signup (use last onea as the profile to navigage back to after signup)
    let classOrEventList =
      window.localStorage.getItem('pendingAddClassOrEvent') || '';
    let localStorageId = `_CLASS-ID_=${classid}_TEACHER-ID_=${teacher.uid}`;
    localStorageId += ','; // separator
    //console.log('local storeange Id======>', localStorageId, classInfo);

    if (classOrEventList.indexOf(localStorageId) > -1) {
      UtilFX.getIsDevEnv() &&
        console.warn(
          'adding same class. nothing to do: localStorageId, classOrEventList',
          localStorageId,
          classOrEventList
        );
      return;
    }

    classOrEventList = classOrEventList.replace(localStorageId, '');
    classOrEventList += `${localStorageId},`;
    window.localStorage.setItem('pendingAddClassOrEvent', classOrEventList);

    // console.info('adding yogi as pending interesat for post auth', classOrEventList);
  };

  handleOpenPayModal = () => {
    // console.log('calling OPEN');
    this._mounted && this.setState({ openPayModal: true });
  };

  handleOpenDonationModal = () => {
    // console.log('calling OPEN');
    this._mounted && this.setState({ openDonationModal: true });
  };

  handleClosePayModal = () => {
    // console.log('calling CLOSE');
    this._mounted && this.setState({ openPayModal: false });
  };

  handleCloseDonationModal = () => {
    // console.log('calling CLOSE');
    this._mounted && this.setState({ openDonationModal: false });
  };

  handleDonationSuccess = ({ result_msg }) => {
    this.handleCloseDonationModal();
    // odd behavior when alert and modal race conditioned closed both. unsavory. purposely pause to await
    setTimeout(() => {
      window && window.alert(`✅ Success: ${result_msg}`);
    }, 1000);
  };

  handleDonationError = ({ result_msg }) => {
    // this.handleClosePayModal();
    window && window.alert(`🚫 Error: ${result_msg}`);
  };

  openInvitation = () => {
    const { invite, userData: teacherData, classid } = this.state;
    const { history, context = {} } = this.props;
    const { userAuthenticated: student = {} } = context;

    if (!(student && student.uid) && teacherData.uid) {
      const cont = window.confirm(
        'Before you can open an invite you need to sign in.'
      );
      if (cont) {
        this._setClassHistoryInLocalStorage();
        history.push('/login');
        return;
      }
      return;
    }

    let invitePromise = Promise.resolve(null);
    if (invite && invite._inviteid) {
      invitePromise = firebase
        .database()
        .ref(`invitesbystudentid/${student.uid}/${invite._inviteid}`)
        .update({ date_opened: Date.now() });
    } else {
      invitePromise = firebase
        .database()
        .ref(`invitesbystudentid/${student.uid}/`)
        .push({
          teacherid: teacherData.uid,
          studentid: student.uid,
          classid: classid,
          date_opened: Date.now(),
          date_created: Date.now(),
        });
    }
    // this.refs.ModalBlank_ShowInviteCurtain.hideMe();
    Promise.resolve(invitePromise)
      .then(() => {
        this.setState({
          invite: { ...invite, date_opened: Date.now() },
        });
      })
      .catch((err) => {
        window && window.alert('There was an error opening this invite');
      });
  };

  registerForClassOrEvent_Begin = () => {
    //db auth atomically complete the following
    const { userData: teacher, classInfo, isRegistered } = this.state || {};
    const { history, context = {} } = this.props;
    const { userAuthenticated: student = {} } = context;
    //const student = userAuthenticated || {};
    // const teacher = userData;
    // console.log('student', student);

    if ((!student || !student.uid) && teacher.uid) {
      //not loggged in
      const cont = window.confirm(
        `Before you can register for @${teacher.username}'s class you need to sign in.`
      );
      if (cont) {
        this._setClassHistoryInLocalStorage();
        return history.push('/login');
      }
    } else if (student && student.uid && teacher && teacher.uid) {
      // set registration (or unset? maybe allwo this to be a toggle just in case)
      // if (classInfo.price) {
      //     UtilFX.triggerAdminAlert('User tried to sign up for a paid class', { teacher, studentId: student.uid, studentUsername: student.username, studentEmail: student.email, url: window.document.URL });
      //     alert('For security reasons, you currently need to use the mobile app to register for paid classes. Your attempt has been noted and will help us bring this ability to the web soon.');
      //     return;
      // }
      if (classInfo.status === 'cancel') {
        alert(
          'This class is currently canceled. Registration will be prohibited until it becomes active again.'
        );
        this.setState({
          is_processing_registration: false,
        });
        return;
      }
      if (teacher.uid === student.uid) {
        console.warn('Cannot register for your own class...? why not?');
        alert(
          'Currently we do not permit reflective-registrations of a single user in the system. We do encourage self-practice.'
        );
        this.setState({
          is_processing_registration: false,
        });
        return;
      }
      if (classInfo.price && isRegistered) {
        window &&
          window.alert(
            `Since you have paid for this class, the teacher must refund you. Please contact ${
              teacher && teacher.fullname
            } to initiate refund or cancellation.`
          );
        this.setState({
          is_processing_registration: false,
        });
        return;
      }
      if (classInfo.price && !isRegistered) {
        this.handleOpenPayModal();
        return;
      }
      // no catches or detours, continue
      this.registerForClassOrEvent_Continue();
    }
  };

  finishRegistrationPostPayment = ({
    paymentTransactionId,
    priceTotal,
    success,
    result_msg,
    result_json,
  }) => {
    this.setState(
      {
        paymentTransactionId,
        priceTotal,
        successfulPayment: success,
        paymentResultMsg: result_msg,
      },
      () => {
        if (success) {
          this.registerForClassOrEvent_Continue({ successMessage: result_msg });
          this.handleClosePayModal();
        } else {
          window && window.alert(`🚫 Error: ${result_msg}`);
        }
      }
    );
  };

  registerForClassOrEvent_Continue = () => {
    //db auth atomically complete the following
    const {
      invite,
      userData: teacher,
      classInfo,
      classid: classId,
      isRegistered,
      priceTotal,
      paymentTransactionId,
    } = this.state || {};
    const { context = {} } = this.props;
    const { userAuthenticated: student = {} } = context;
    // const student = userAuthenticated || {};
    // const teacher = userData;
    // console.log('student', student);

    if (student && student.uid && teacher && teacher.uid) {
      //its a double check, this is already checked, but for safety in case dev calls this method direct.
      if (classInfo.status === 'cancel') {
        console.warn('This class is cancleed. WRONG METHOD call');
        // alert('This class is currently canceled. Registration will be prohibited until it becomes active again.');
        this.setState({
          is_processing_registration: false,
        });
        return;
      }
      if (teacher.uid === student.uid) {
        console.warn(
          'Cannot register for your own class. WRONG METHOD call. use _Begin not _Continue.'
        );
        // alert('Currently we do not permit reflective-registrations of a single user in the system. We do encourage self-practice.');
        this.setState({
          is_processing_registration: false,
        });
        return;
      }

      const opts = {
        student,
        teacher,
        isRegistered,
        classInfo,
        classId,
        priceTotal,
        paymentTransactionId,
      };

      //make call and such. catch error if neeed to stll set procesing_reistration to false
      // console.log('Making the call');
      this.setState({
        is_processing_registration: true,
      });
      // FIX wha is being sent in here. Need to have the registration boject to properly do the updating because psersonal recurring classes will not always have the right 'next available date since htey are recurring subscriptions'
      DataFX.updateStudentRegistrationForClassOrEvent(opts)
        .then(() => {
          // console.log('Back from the call');
          this._mounted &&
            this.setState({
              is_processing_registration: false,
            });
          this.getUserData();
          if (invite && invite._inviteid) {
            if (!isRegistered) {
              // was previously unregistered, after update they are now, so update invite.otherwise dont count unregistrations on invite necessarily
              // this is used to determine whether or not to show in invite list (if registtered already showign up in 'classes taking' so we dont show invites for registered classes)
              // even if unregistered. invite is not 'restored' by not registering. if you registered you got the messgae.  otherwise invite sits indefinitely in invite list. could allow for delete/reject of invites in future but for now let them sit
              firebase
                .database()
                .ref(`invitesbystudentid/${student.uid}/${invite._inviteid}`)
                .update({ date_registered: Date.now() });
            }
          }
          // window && window.alert(isRegistered ? 'You\'ve successfully removed your RSVP.' : 'You\'ve successfully registered. Open the app to manage your classes.');
          return (
            !isRegistered && this.setState({ openClassRegistrationModal: true })
          );
        })
        .catch((err) => {
          this._mounted &&
            this.setState({
              is_processing_registration: false,
              error: err,
            });
          this.getUserData();
          window && window.alert('There was an error procesing our request');
          console.warn('Error - ', err);
        });

      //     // MessageBarManager.showAlert({
      //     title: 'SUCCESS!',
      //         message: 'De-confirmation complete.', //err_msg,
      //             alertType: 'warning',
      //                 position: 'top',
      //                     duration: 5000
      //     // See Properties section for full customization
      // });
      /* try {
                var lpid_goto = 'REMINDER_GOTOCLASS_' + this.state.metaclassid;
                var lpid_donate = 'REMINDER_DONATECLASS_' + this.state.metaclassid;
                FCM.cancelLocalNotification(lpid_goto);
                FCM.cancelLocalNotification(lpid_donate);
            } catch (err) {
                console.warn(
                    'hey there was an error getting rid of notifications',
                    err
                );
            }
            Analytics.logEvent('classregistration_free_unconfirm_success', {}); */

      /* this._finishProcessingRegistration();
            Analytics.logEvent('classregistration_free_unconfirm_error', {});
            MessageBarManager.showAlert({
                title: '😮 Oops!',
                message: 'De-confirmation failed.', //err_msg,
                alertType: 'error',
                position: 'top',
                duration: 5000
                // See Properties section for full customization
            }); */

      //////////

      /*
            () => {
        this.setState({ confirmed_registration: cr_data });
        this._finishProcessingRegistration();
        MessageBarManager.showAlert({
            title: 'SUCCESS!',
            message: 'Confirmation complete.', //err_msg,
            alertType: 'warning',
            position: 'top',
            duration: 5000
            // See Properties section for full customization
        });

        //1. if date of class is >=  2 hours away then
        //set a reminder to happen 2 hours before class
        var lpid_goto = 'REMINDER_GOTOCLASS_' + this.state.metaclassid;
        var lpid_donate = 'REMINDER_DONATECLASS_' + this.state.metaclassid;
        //reminders to go to class in 30min/1hr/2hr
        var time_till_class = this.state.startofclass - Date.now();
        if (time_till_class > 1000 * 60 * 60 * 0.25) {
            var reminder_timefactor = 0.25;
            if (time_till_class > 1000 * 60 * 60 * 0.5)
                reminder_timefactor = 0.5;
            if (time_till_class > 1000 * 60 * 60 * 1) reminder_timefactor = 1;
            if (time_till_class > 1000 * 60 * 60 * 2) reminder_timefactor = 2;

            var lp_goto_fire_date =
                this.state.startofclass - 1000 * 60 * 60 * reminder_timefactor;

            var lp_goto_body = `@${
                this.state.teacherusername
                } is teaching a class you registered for soon. It starts ${
                this.state.time_description
                }`;
            FCM.scheduleLocalNotification({
                id: lpid_goto, // (optional for instant notification)
                fire_date: lp_goto_fire_date,
                // title: 'Teacher Class Update Reminder',               // as FCM payload
                body: lp_goto_body, // as FCM payload (required)
                // //sound: 'default',                                   // as FCM payload
                priority: 'high', // as FCM payload
                // badge: 0,                                          // as FCM payload IOS only, set 0 to clear badges
                // number: 10,                                         // Android only
                mycustomdata_pusheventid: 'local_gotoreminder',
                mycustomdata_classid: this.state.classid,
                mycustomdata_teacherid: this.state.teacherid,
                mycustomdata_is_onetime: this.state.is_onetime,
                ticker: lp_goto_body, // Android only
                // lights: true,                                       // Android only, LED blinking (default false)
                show_in_foreground: true // notification when app is in foreground (local & remote)
            });
        }
        //2. set a reminder to donate or tip if they enjoyed class X hours AFTER class
        var lp_donate_fire_date = this.state.endofclass + 1000 * 60 * 15;
        var lp_donate_body = `Enjoy class with @${
            this.state.teacherusername
            } earlier today? Send a thank you message, tip or donation to show some love!`;
        FCM.scheduleLocalNotification({
            id: lpid_donate, // (optional for instant notification)
            fire_date: lp_donate_fire_date,
            // title: 'Teacher Class Update Reminder',               // as FCM payload
            body: lp_donate_body, // as FCM payload (required)
            // //sound: 'default',                                   // as FCM payload
            priority: 'high', // as FCM payload
            // badge: 0,                                          // as FCM payload IOS only, set 0 to clear badges
            // number: 10,                                         // Android only
            mycustomdata_pusheventid: 'local_donatereminder',
            //mycustomdata_classid:this.state.classid,
            mycustomdata_teacherid: this.state.teacherid,
            //mycustomdata_is_onetime:this.state.is_onetime,
            ticker: lp_donate_body, // Android only
            // lights: true,                                       // Android only, LED blinking (default false)
            show_in_foreground: true // notification when app is in foreground (local & remote)
        });

        Analytics.logEvent('classregistration_free_confirm_success', {});
    } */
    } else {
      console.warn(
        'bogus reg attempt, missing required data',
        student,
        teacher
      );
    }
  };

  getFormattedTimeForCalendars = (adate) =>
    adate.toISOString().replace(/-|:|\.\d\d\d/g, '');

  getCalendarHref = ({
    classInfo = {},
    expected_date,
    expected_date_end,
    calendarType,
  }) => {
    const expected_date_fmt = this.getFormattedTimeForCalendars(expected_date);
    const expected_date_end_fmt = this.getFormattedTimeForCalendars(
      expected_date_end
    );
    const classEncodedUrl = `Details can be found here: ${
      window.document.URL
    } \n ${classInfo.description || ''}`; //it all gets encoded later

    // console.log('exp date vs. formatted for fcal', expected_date, expected_date_fmt);
    // console.log('exp date end vs. formatted for fcal', expected_date_end, expected_date_end_fmt);
    // console.log('class desc url', classEncodedUrl);

    let href = window.encodeURI(
      'data:text/calendar;charset=utf8,' +
        [
          'BEGIN:VCALENDAR',
          'VERSION:2.0',
          'BEGIN:VEVENT',
          'URL:' + window.document.URL,
          'DTSTART:' + (expected_date_fmt || ''),
          'DTEND:' + (expected_date_end_fmt || ''),
          'SUMMARY:' + (classInfo.title || ''),
          'DESCRIPTION:' + (classInfo.description || ''),
          'LOCATION:' + ((classInfo.location || {}).address || ''),
          'END:VEVENT',
          'END:VCALENDAR',
        ].join('\n')
    );

    if (calendarType === 'google') {
      href = window.encodeURI(
        `https://calendar.google.com/calendar/r/eventedit?text=${
          classInfo.title
        }&dates=${expected_date_fmt}/${expected_date_end_fmt}&details=${classEncodedUrl}&location=${
          (classInfo.location || {}).address
        }`
      );
    } else if (calendarType === 'yahoo') {
      // <a href='https://calendar.yahoo.com/?v=60&view=d&type=20&title=Summary+of+the+event&st=20151119T090000&et=20151119T110000&desc=example+text.%0A%0AThis+is+the+text+entered+in+the+event+description+field.&in_loc=Location+of+the+event&uid='>add to Yahoo calendar</a>
      href = window.encodeURI(
        [
          'http://calendar.yahoo.com/?v=60&view=d&type=20',
          '&title=' + (classInfo.title || ''),
          '&st=' + expected_date_fmt,
          '&et=' + expected_date_end_fmt,
          // '&dur=' + (yahooEventDuration || ''),
          '&desc=' + (classEncodedUrl || ''),
          '&in_loc=' + ((classInfo.location || {}).address || ''),
        ].join('')
      );
    }

    return href;
  };

  togglePause = () => {
    // const { classData = {}, teacherid, classid } = this.state || {};
    const { userData: teacher, classInfo, classid: classId } = this.state || {};
    // const { context = {} } = this.props;
    // const { userAuthenticated: student = {} } = context;
    // const isPersonal = classData && (classData.class_type === 'personal');
    // const isRecurring = classData && Boolean(classData.recurrence_rule);
    const confMsg = classInfo.paused
      ? 'You are about to re-activate this subscription.'
      : 'You are about to pause this subscription.';

    const cont = window && window.confirm(confMsg);
    if (cont) {
      firebase
        .database()
        .ref(`classesbyuserid/${teacher.uid}/${classId}`)
        .update({
          paused: classInfo.paused ? null : { by_student: Date.now() },
        })
        .then(() => {
          this.getClassEventData();
        });
    }
  };

  virtualURLChecker = (virtualLink) => {
    if (
      virtualLink.startsWith('https://') ||
      virtualLink.startsWith('http://')
    ) {
      return virtualLink;
    }
    return `https://${virtualLink}`;
  };

  render() {
    //const { history } = this.props;
    const {
      invite,
      isLoading,
      isRegistered,
      userData: teacher,
      classInfo,
      isSoldOut,
      locMapUrl,
      placeId,
      classid,
      defaultTimeZoneValue,
    } = this.state;
    const showInviteCurtainCover =
      !isRegistered &&
      (!!(invite && !invite.date_opened && !invite.date_registered) ||
        (classInfo && classInfo.private && !invite));

    // const styleClasses = this.props.classes || {};
    // console.log('incoming state', this.state);
    // console.log('incoming props', this.props);
    const { location: navLocation = {}, context: { userAuthenticated } = {} } =
      this.props || {};

    if (isLoading) {
      return <Loading />;
    }
    if (!classInfo) {
      return <p>This class/event is not available.</p>;
    }

    const { personal_registration_uid, uid: teacherId } = classInfo;
    if (personal_registration_uid) {
      // its already registered.
      if (userAuthenticated?.uid) {
        if (userAuthenticated.uid !== personal_registration_uid) {
          return (
            <Grid container justify="center">
              <Typography
                style={{ marginTop: 20 }}
                paragraph
                variant="subtitle2"
              >
                This is a private class.
              </Typography>
              {
                userAuthenticated.uid === teacherId && (
                  <Typography
                    style={{ marginTop: 'auto' }}
                    paragraph
                    variant="body1"
                  >
                    You are the owner of this class. Please
                    <a href="favyogis://">open the app</a> to manage details.
                  </Typography>
                )
                // ?urleventid=classesdetail&teacherid=${}&classid=${} could direct to class. maybe security issue.
              }
            </Grid>
          );
        }
      } else {
        return (
          <Grid container justify="center">
            <Typography style={{ marginTop: 20 }} paragraph variant="subtitle2">
              This is a private class.
              <br /> If you are already registered, login to view details.
            </Typography>
            <LoginSignupForgotAuthUI {...this.props} chosenUI="login" minimal />
          </Grid>
        );
      }
    }

    //important for payment stuff. expecting
    classInfo.cid = classid;

    const appUrl = `favyogis://?urleventid=classesdetail&teacherid=${
      teacher.uid
    }&classid=${classid}&eventorclass=${'class'}`;
    const viewingUserHasInstalledApp =
      userAuthenticated && userAuthenticated.app_version;
    const supportedMobilePhoneDetected =
      navigator && /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    let startTimeDescription;
    const expected_date = UtilFX.getExpectedDateForClassOrEvent(classInfo);
    const expected_date_end = moment(expected_date).add(
      classInfo.duration,
      'minutes'
    );

    const edm = moment(expected_date);

    const calculatedBuyerTotal = UtilFX.getBuyerTotal({
      price: classInfo.price,
      fees_absorb: classInfo.fees_absorb,
      teacher: teacher,
    });

    startTimeDescription = edm.format('dddd MMMM Do @ h:mm a');

    let daysByNickname = [];
    let recurrence_desc = '';
    const isPersonal = classInfo.class_type === 'personal';
    const isRecurring = Boolean(classInfo.recurrence_rule);
    if (classInfo.recurrence_rule) {
      const { byday, interval } = UtilFX.getRecurrenceRuleParts(
        classInfo.recurrence_rule
      );
      daysByNickname = byday.split(',').map((n) => n[0] + n[1].toLowerCase());
      recurrence_desc = `${
        Number(interval) === 1 ? '| every week' : '| every other week'
      }`;
    }
    const start_date_for_json_snippet = edm.format('YYYY-MM-DD');
    const end_date_end_for_json_snippet = edm
      .add(classInfo && classInfo.duration, 'minute')
      .format('YYYY-MM-DD');

    
    return (
      <Grid container justify="center">
        <Grid item xs={12} md={12} style={{ flex: 1 }}>
          {!classInfo.private && (
            <Helmet>
              <script type="application/ld+json">
                {`{
                                    '@context': 'http://schema.org',
                                    '@type': 'Event',
                                    'name': '${teacher.fullname}'s ${
                  classInfo && classInfo.title
                } - FavYogis',
                                    'description': '${
                                      (classInfo && classInfo.description) || ''
                                    }',
                                    'image': 'http://favyogis.com/wp-content/uploads/2017/03/iTunesArtwork.png',
                                    'startDate': '${
                                      start_date_for_json_snippet || ''
                                    }',
                                    'endDate': '${
                                      end_date_end_for_json_snippet || ''
                                    }',
                                    'duration': 'PT${classInfo.duration}M',
                                    'organizer': '${teacher.fullname}',
                                    'contributor': '${teacher.username}',
                                    'location':{
                                        '@type': 'Place',
                                        'name': '${
                                          classInfo &&
                                          classInfo.location &&
                                          classInfo.location.name
                                        }',
                                        'sameAs': 'favyogis.com',
                                        'address': '${
                                          classInfo &&
                                          classInfo.location &&
                                          classInfo.location.address
                                        }',
                                        'geo': {
                                            '@type': 'GeoCoordinates',
                                            'latitude': '${
                                              classInfo &&
                                              classInfo.location &&
                                              classInfo.location.lat
                                            }',
                                            'longitude': '${
                                              classInfo &&
                                              classInfo.location &&
                                              classInfo.location.lng
                                            }'    
                                        }
                                    }
                                }`}
              </script>
            </Helmet>
          )}
          <div>
            {!teacher ? (
              <Loading />
            ) : (
              <div style={{ marginTop: '56px', marginLeft: 0, marginRight: 0 }}>
                {classInfo && classInfo.private && (
                  <Typography style={{ marginTop: 15 }} variant="subtitle2">
                    You've been invited:
                  </Typography>
                )}
                <div
                  style={{
                    borderRadius: 10,
                    paddingTop: '40px',
                    paddingBottom: '40px',
                    backgroundColor: '#fff',
                    color: '#000',
                  }}
                >
                  <div
                    style={{
                      top: 40,
                      position: 'relative',
                      // width: 300,
                      // textAlign: 'center',
                      marginRight: '10px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        position: 'absolute',
                        top: '-85px',
                        left: '5px',
                        cursor: 'pointer',
                        display: 'inline-block',
                      }}
                      onClick={() => {
                        // console.log('open modal for donation');
                        if (userAuthenticated && userAuthenticated.uid) {
                          this.handleOpenDonationModal();
                        } else {
                          this.addTeacherToHistoryStorage({
                            teacherId: teacher.uid,
                          });
                          window &&
                            window.alert(
                              'You must be signed in to send money to teachers.'
                            );
                        }
                      }}
                    >
                      <IconButton
                        color="primary"
                        aria-label="send money"
                        // style={{ fontSize: '1rem' }}
                      >
                        <Icon>monetization_on</Icon>
                      </IconButton>
                      <Typography
                        style={{ display: 'block', marginTop: '-15px' }}
                        color="primary"
                        variant="caption"
                      >
                        donate/tip
                      </Typography>
                    </div>
                  </div>
                  <Link to={`/${teacher && teacher.username}`}>
                    <AvatarDisplay
                    imgSrc={teacher.profile_photo_url}
                    imgClassName={'yogi-photo'}
                    imgAlt={'teacher'}
                    imgStyles={{
                      borderRadius: 80,
                      height: 160,
                      width: 160,
                    }}
                    userName={teacher.fullname}
                    textStyles={{fontSize: '14px'}}
                    />
                  
                   
                  </Link>
                  <div style={{ marginTop: '16px' }}>
                    <Typography
                      variant="h1"
                      style={{ fontSize: '28px', color: Colors.grey1 }}
                    >
                      {teacher.fullname}
                    </Typography>
                    <Button
                      onClick={() => {
                        const { userData: teacher } = this.state;
                        const { history } = this.props;
                        // const { userAuthenticated: student } = context || {};
                        history.push('/chat', {
                          recipientId: teacher?.uid || null,
                          recipientPhotoUrl: teacher?.profile_photo_url || null,
                        });
                      }}
                      // style={{ padding: '5px 20px', minWidth: '64px' }}
                      size="medium"
                      variant="contained"
                      style={{
                        ...GlobalStyles.bodyRegular,
                        backgroundColor: '#ECF5FE',
                        color: Colors.pri_color,
                        boxShadow: 'none',
                        marginTop: '16px',
                        borderRadius: '12px',
                        padding: '10px 26px 10px 16px',
                      }}
                      startIcon={<img src={ImgMessage} alt="message-action" />}
                    >
                      Send message
                    </Button>
                  </div>
                  {userAuthenticated?.uid === teacherId && (
                    <React.Fragment>
                      <hr style={{ border: '1px solid #E0E0E0' }} />
                      <Grid container>
                        <Grid item style={{ marginBottom: 10 }} xs={12}>
                          <div
                            style={{
                              marginTop: 30,
                              marginBottom: 5,
                              paddingBottom: 10,
                              paddingLeft: 20,
                              paddingRight: 20,
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                              }}
                            >
                              <span
                                className="material-icons"
                                style={{
                                  color: Colors.primaryColor,
                                  alignSelf: 'center',
                                  fontSize: 20,
                                }}
                              >
                                info
                              </span>
                              <Typography
                                style={{
                                  ...GlobalStyles.bodyRegular,
                                  marginLeft: '11px',
                                  color: Colors.primaryColor,
                                }}
                              >
                                Pssst!! Only you can see this section.{' '}
                              </Typography>
                            </div>
                            <Typography
                              style={{
                                ...GlobalStyles.titleBold,
                                marginTop: '8px',
                                color: Colors.grey1,
                              }}
                            >
                              Visit the class group
                            </Typography>
                            <Typography
                              style={{
                                ...GlobalStyles.bodyRegular,
                                marginTop: '8px',
                                color: Colors.grey1,
                              }}
                            >
                              Students will get a button to visit your group
                              after they register.
                            </Typography>
                            <Button
                              onClick={() => {
                                const {
                                  userData: teacher,
                                  classInfo,
                                } = this.state;
                                const { history } = this.props;
                                history.push('/chat', {
                                  teacherId: teacher.uid,
                                  profilePhotoUrl: teacher.profile_photo_url,
                                  classTitle: classInfo.title,
                                  classId: classInfo.cid,
                                });
                              }}
                              size="medium"
                              variant="contained"
                              style={{
                                backgroundColor: Colors.primaryColor,
                                boxShadow: 'none',
                                borderRadius: '12px',
                                color: Colors.white,
                                padding: '10px',
                                width: '158px',
                                marginTop: '20px',
                              }}
                            >
                              <Icon
                                style={{
                                  fontSize: '1.0rem',
                                  marginRight: '5px',
                                }}
                              >
                                people
                              </Icon>
                              Visit group
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                  <hr style={{ border: '1px solid #E0E0E0' }} />
                  <Grid container>
                    <Grid style={{ marginBottom: 10 }} item xs={12}>
                      <div
                        style={{
                          marginTop: 30,
                          marginBottom: 5,
                          paddingBottom: 10,
                          paddingLeft: 20,
                          paddingRight: 20,
                        }}
                      >
                        <Typography
                          color="primary"
                          style={{
                            color: Colors.grey1,
                            ...GlobalStyles.titleBold,
                            fontSize: '20px',
                          }}
                        >
                          {classInfo.title}
                        </Typography>
                        {Boolean(classInfo.status) && (
                          <Typography
                            style={{
                              backgroundColor: Colors.pri_color,
                              color: '#fff',
                              padding: '5px',
                              margin: '20px 0px',
                              borderRadius: '8px',
                            }}
                            variant="caption"
                          >
                            Status:
                            {(classInfo.status === 'cancel' && 'Canceled') ||
                              (classInfo.status === 'sub' && 'Subbed Out') ||
                              'Active'}
                          </Typography>
                        )}
                        {
                          <div style={{ marginTop: '8px' }}>
                            <Typography
                              style={{
                                color: Colors.grey1,
                                ...GlobalStyles.bodyRegular,
                              }}
                            >
                              {startTimeDescription}
                            </Typography>
                            <Typography
                              style={{
                                color: Colors.grey1,
                                ...GlobalStyles.bodyRegular,
                              }}
                            >
                              {classInfo.time_zone || defaultTimeZoneValue}
                            </Typography>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                marginTop: '8px',
                              }}
                            >
                              <span
                                className="material-icons"
                                style={{
                                  color: Colors.primaryColor,
                                  alignSelf: 'center',
                                  fontSize: 20,
                                }}
                              >
                                timelapse
                              </span>
                              <Typography
                                style={{
                                  marginLeft: '11px',
                                  color: Colors.grey1,
                                  ...GlobalStyles.bodyRegular,
                                }}
                              >
                                {classInfo.duration}
                                min
                              </Typography>
                            </div>
                            {Boolean(isPersonal && isRecurring) && (
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ fontStyle: 'italic', fontWeight: 100 }}
                              >
                                {daysByNickname.map((i) => (
                                  <div
                                    key={i}
                                    style={{
                                      backgroundColor: 'rgba(0,0,0,0.3)',
                                      color: '#fff',
                                      borderRadius: 10,
                                      marginRight: 5,
                                      height: 20,
                                      width: 20,
                                      lineHeight: '20px',
                                      textAlign: 'center',
                                      display: 'inline-block',
                                    }}
                                  >
                                    <span>{i}</span>
                                  </div>
                                ))}
                                {recurrence_desc}
                              </Typography>
                            )}
                          </div>
                        }
                        {
                          //can put price stuff here

                          Boolean(classInfo && classInfo.price) && (
                            <Typography
                              style={{
                                color: Colors.grey1,
                                ...GlobalStyles.bodyRegular,
                              }}
                            >
                              {'$' + calculatedBuyerTotal}
                              {isPersonal &&
                                isRecurring &&
                                ` / payment repeats ${classInfo.price_billing_cycle}`}
                            </Typography>
                          )
                        }
                        <Typography
                          style={{
                            color: Colors.grey1,
                            ...GlobalStyles.bodyRegular,
                          }}
                        >
                          {classInfo.description}
                        </Typography>

                        {this.state.is_processing_registration ? (
                          <Loading />
                        ) : isSoldOut && !isRegistered ? (
                          <Typography
                            variant="subtitle2"
                            style={{
                              padding: '5px 10px',
                              color: '#fff',
                              borderRadius: 5,
                              display: 'inline-block',
                              margin: '5px auto',
                              backgroundColor: Colors.sec_color,
                            }}
                          >
                            Sold Out
                          </Typography>
                        ) : (
                          !(isRegistered && classInfo.price) && (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                marginTop: '20px',
                              }}
                            >
                              <Button
                                onClick={this.registerForClassOrEvent_Begin}
                                size="medium"
                                variant="contained"
                                style={{
                                  backgroundColor: !isRegistered
                                    ? Colors.primaryColor
                                    : '#FCEAEA',
                                  boxShadow: 'none',
                                  borderRadius: '12px',
                                  color: isRegistered
                                    ? '#FF4646'
                                    : Colors.white,
                                  padding: '10px',
                                  width: '158px',
                                }}
                              >
                                {/* {!isRegistered && <img src={ImgMessage} alt='message-action' />} */}
                                {!isRegistered && (
                                  <Icon style={{ paddingRight: 5 }}>
                                    check_circle_outline
                                  </Icon>
                                )}
                                {!isRegistered
                                  ? 'Register now'
                                  : 'Cancel Registration'}
                              </Button>
                              {isRegistered && (
                                <Button
                                  onClick={() => {
                                    const {
                                      userData: teacher,
                                      classInfo,
                                    } = this.state;
                                    const { history } = this.props;
                                    history.push('/chat', {
                                      teacherId: teacher.uid,
                                      profilePhotoUrl:
                                        teacher.profile_photo_url,
                                      classTitle: classInfo.title,
                                      classId: classInfo.cid,
                                    });
                                  }}
                                  size="medium"
                                  variant="contained"
                                  style={{
                                    backgroundColor: Colors.primaryColor,
                                    boxShadow: 'none',
                                    borderRadius: '12px',
                                    color: Colors.white,
                                    padding: '10px',
                                    width: '158px',
                                    marginLeft: '20px',
                                  }}
                                >
                                  <Icon
                                    style={{
                                      fontSize: '1.0rem',
                                      marginRight: '5px',
                                    }}
                                  >
                                    person
                                  </Icon>
                                  Class group
                                </Button>
                              )}
                            </div>
                          )
                        )}
                        {Boolean(isRegistered && classInfo.price) && (
                          <div
                            style={{
                              // borderWidth: 1,
                              // borderColor: Colors.pri_color,
                              // borderStyle: 'solid',
                              padding: 10,
                            }}
                          >
                            {isPersonal && isRecurring && (
                              <div>
                                <div>
                                  <Typography
                                    style={{
                                      padding: '10px auto 5px auto',
                                      color: Colors.pri_color,
                                    }}
                                  >
                                    Subscription is{' '}
                                    {classInfo.paused ? 'paused' : 'active'}.
                                  </Typography>
                                  <Icon
                                    style={{ color: Colors.pri_color }}
                                    fontSize="large"
                                  >
                                    {!classInfo.paused
                                      ? 'play_circle_outline'
                                      : 'pause_circle_outline'}
                                  </Icon>
                                </div>
                                <Button
                                  size="medium"
                                  variant="contained"
                                  style={{
                                    backgroundColor: Colors.primaryColor,
                                    color: Colors.white,
                                    boxShadow: 'none',
                                    borderRadius: '8px',
                                  }}
                                  onClick={this.togglePause}
                                >
                                  {classInfo.paused
                                    ? 'Re-activate Subscription'
                                    : 'Pause Subscription'}
                                </Button>
                              </div>
                            )}
                            {!(isPersonal && isRecurring) && (
                              <Typography style={{ color: Colors.pri_color }}>
                                <Icon
                                  style={{ paddingRight: 5, color: 'green' }}
                                >
                                  check_circle_outline
                                </Icon>
                                <br />
                                Registration confirmed
                              </Typography>
                            )}
                          </div>
                        )}
                      </div>
                      <hr style={{ border: '1px solid #E0E0E0' }} />
                      <div>
                        <div
                          style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {classInfo.virtual_link && (
                            <div>
                              <span
                                className="material-icons"
                                style={{
                                  color: Colors.primaryColor,
                                  fontSize: 40,
                                  marginTop: '31px',
                                }}
                              >
                                videocam
                              </span>
                              <Typography
                                style={{
                                  color: Colors.grey1,
                                  ...GlobalStyles.titleBold,
                                  fontSize: '20px',
                                }}
                              >
                                Online
                              </Typography>
                              {isRegistered && (
                                <a
                                  href={this.virtualURLChecker(
                                    classInfo.virtual_link
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    textDecoration: 'none',
                                    color: '#ffffff',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <div
                                    style={{
                                      width: '130px',
                                      padding: '10px',
                                      backgroundColor: Colors.primaryColor,
                                      borderRadius: '8px',
                                      margin: 'auto',
                                    }}
                                  >
                                    <Typography
                                      style={{ ...GlobalStyles.bodyRegular }}
                                    >
                                      Join
                                    </Typography>
                                  </div>
                                </a>
                              )}
                              {!isRegistered && (
                                <Typography
                                  style={{
                                    color: Colors.grey1,
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    ...GlobalStyles.bodyRegular,
                                  }}
                                >
                                  A link to the online class will be visible
                                  here after you register.
                                </Typography>
                              )}
                            </div>
                          )}
                          {classInfo.location && classInfo.location.address && (
                            <div>
                              <span
                                className="material-icons"
                                style={{
                                  color: Colors.primaryColor,
                                  fontSize: 40,
                                  marginTop: '31px',
                                }}
                              >
                                location_on
                              </span>
                              <Typography
                                style={{
                                  color: Colors.grey1,
                                  ...GlobalStyles.titleBold,
                                  fontSize: '20px',
                                }}
                              >
                                Physical
                              </Typography>
                              <Typography
                                style={{
                                  color: Colors.grey3,
                                  ...GlobalStyles.bodyRegular,
                                }}
                              >
                                {' '}
                                {classInfo.location &&
                                  classInfo.location.name}{' '}
                              </Typography>
                              <Typography
                                style={{
                                  color: Colors.grey3,
                                  ...GlobalStyles.bodyRegular,
                                }}
                              >
                                {' '}
                                {classInfo.location &&
                                  classInfo.location.address}{' '}
                              </Typography>
                            </div>
                          )}
                        </div>
                        <div style={{ marginTop: '16px' }}>
                          {classInfo.location &&
                            classInfo.location.address &&
                            locMapUrl &&
                            placeId && (
                              <Link
                                to={{
                                  pathname: `/place/${placeId}`,
                                  state: {
                                    // placeData: null,
                                    from: navLocation.pathname,
                                  },
                                }}
                                style={{
                                  display: 'block',
                                  border: `1px solid ${Colors.pri_color}`,
                                }}
                              >
                                <img
                                  style={{ borderRadius: '10px' }}
                                  src={locMapUrl}
                                  alt="class location"
                                />
                                <div style={{ marginTop: 10 }}>
                                  <Typography
                                    style={{ display: 'inline-block' }}
                                    color="textSecondary"
                                    variant="subtitle2"
                                  >
                                    {classInfo.location &&
                                      classInfo.location.name}
                                  </Typography>
                                  <Typography
                                    style={{ display: 'inline-block' }}
                                    color="textSecondary"
                                  >
                                    {classInfo.location &&
                                      classInfo.location.address}
                                  </Typography>
                                </div>
                              </Link>
                            )}
                        </div>
                      </div>
                      {/* </Grid> */}

                      <div style={{ marginLeft: '29px', marginRight: '29px' }}>
                        <Typography
                          style={{
                            ...GlobalStyles.titleBold,
                            color: Colors.grey1,
                            textAlign: 'center',
                            fontSize: '18px',
                          }}
                        >
                          {!isRegistered
                            ? 'RSVP to add this to your preferred calendar'
                            : 'Add this class to your preferred calendar.'}
                        </Typography>
                      </div>

                      <div
                        style={{
                          // display: 'flex',
                          // flexDirection: 'column',
                          // backgroundColor: !isRegistered && 'rgba(0,0,0,0.25)',
                          cursor: !isRegistered && '',
                          // justifyContent: 'center',
                          textAlign: 'left',
                          // position: 'relative',
                          marginTop: '27px',
                          marginBottom: '27px',
                          // padding: '50px 10px 10px 10px',
                        }}
                        className="calendar-option-wrapper"
                      >
                        <div
                          onClick={() => {
                            if (isRegistered) {
                              const calUrl = this.getCalendarHref({
                                classInfo,
                                expected_date,
                                expected_date_end,
                                calendarType: 'google',
                              });
                              //create link, then open new window
                              const win = window.open(calUrl, '_blank');
                              win.focus();
                            }
                          }}
                          // className='touchable-element'
                          style={{
                            padding: '5px',
                            width: '20%',
                            display: 'inline-block',
                            cursor: isRegistered && 'pointer',
                            textAlign: 'center',
                          }}
                        >
                          {isRegistered ? (
                            <img
                              alt="calendar google"
                              style={{
                                paddingRight: 5,
                                verticalAlign: 'middle',
                              }}
                              width="40px"
                              src={google_calendar_icon}
                            />
                          ) : (
                            <img
                              alt="calendar google"
                              style={{
                                paddingRight: 5,
                                verticalAlign: 'middle',
                              }}
                              width="40px"
                              src={google_grey_icon}
                            />
                          )}
                          <span
                            style={{
                              fontFamily: 'Gill Sans MT',
                              fontStyle: 'normal',
                              fontWeight: 'normal',
                              fontSize: '18px',
                              color: !isRegistered && Colors.grey4,
                            }}
                          >
                            Google
                          </span>
                        </div>
                        <div
                          onClick={() => {
                            if (isRegistered) {
                              const calUrl = this.getCalendarHref({
                                classInfo,
                                expected_date,
                                expected_date_end,
                                calendarType: 'ical',
                              });
                              //create link, then open new window
                              window.open(calUrl, '_blank');
                              //win.focus();
                            }
                          }}
                          // className='touchable-element'

                          style={{
                            padding: '5px',
                            width: '20%',
                            display: 'inline-block',
                            cursor: isRegistered && 'pointer',
                            textAlign: 'center',
                          }}
                        >
                          {isRegistered ? (
                            <img
                              alt="calendar ical"
                              style={{
                                paddingRight: 5,
                                verticalAlign: 'middle',
                              }}
                              width="40px"
                              src={ical_calendar_icon}
                            />
                          ) : (
                            <img
                              alt="calender ical"
                              style={{
                                paddingRight: 5,
                                verticalAlign: 'middle',
                              }}
                              width="40px"
                              src={calender_grey_icon}
                            />
                          )}
                          <span
                            style={{
                              fontFamily: 'Gill Sans MT',
                              fontStyle: 'normal',
                              fontWeight: 'normal',
                              fontSize: '18px',
                              color: !isRegistered && Colors.grey4,
                            }}
                          >
                            Download (ics)
                          </span>
                          {/* <span>(ics)</span> */}
                        </div>
                        {/* <div onClick={() => {
                                                    const calUrl = this.getCalendarHref({
                                                        classInfo, expected_date, expected_date_end, calendarType: 'outlook'
                                                    });
                                                    //create link, then open new window
                                                    window.open(calUrl, '_blank');
                                                    // win.focus();
                                                }}

                                                    style={{ padding: '5px' }}
                                                >
                                                    <img alt='calendar outlook' style={{ paddingRight: 5, verticalAlign: 'middle' }} width='40px' src={outlook_calendar_icon} />
                                                    <span>Outlook</span>
                                                </div> */}
                        <div
                          onClick={() => {
                            if (isRegistered) {
                              const calUrl = this.getCalendarHref({
                                classInfo,
                                expected_date,
                                expected_date_end,
                                calendarType: 'yahoo',
                              });
                              //create link, then open new window
                              const win = window.open(calUrl, '_blank');
                              win.focus();
                            }
                          }}
                          // className='touchable-element'
                          style={{
                            padding: '5px',
                            width: '20%',
                            display: 'inline-block',
                            cursor: isRegistered && 'pointer',
                            textAlign: 'center',
                          }}
                          // style={{ padding: '5px' }}
                        >
                          {isRegistered ? (
                            <img
                              alt="calendar yahoo"
                              style={{
                                paddingRight: 5,
                                verticalAlign: 'middle',
                              }}
                              width="40px"
                              src={yahoo_calendar_icon}
                            />
                          ) : (
                            <img
                              alt="calendar yahoo"
                              style={{
                                paddingRight: 5,
                                verticalAlign: 'middle',
                              }}
                              width="40px"
                              src={yahoo_grey_icon}
                            />
                          )}
                          <span
                            style={{
                              fontFamily: 'Gill Sans MT',
                              fontStyle: 'normal',
                              fontWeight: 'normal',
                              fontSize: '18px',
                              color: !isRegistered && Colors.grey4,
                            }}
                          >
                            Yahoo
                          </span>
                        </div>
                      </div>
                      <hr style={{ border: '1px solid #E0E0E0' }} />
                      {viewingUserHasInstalledApp &&
                        supportedMobilePhoneDetected && (
                          <Typography
                            style={{ marginTop: '20px', textAlign: 'center' }}
                          >
                            <a
                              href={appUrl}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textDecorationLine: 'none',
                                border: `1px solid ${Colors.sec_color}`,
                                margin: '0 20px',
                                padding: 10,
                                borderRadius: 10,
                              }}
                            >
                              Open in the FavYogis mobile app.
                              <Icon>exit_to_app</Icon>
                            </a>
                          </Typography>
                        )}
                      {userAuthenticated && !viewingUserHasInstalledApp && (
                        <div
                          style={{
                            marginTop: '24px',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            style={{
                              color: Colors.grey1,
                              ...GlobalStyles.bodyMedium,
                            }}
                          >
                            Get the app for more options
                          </Typography>
                          <Button
                            size="medium"
                            variant="contained"
                            style={{
                              backgroundColor: Colors.white,
                              color: Colors.primaryColor,
                              boxShadow: 'none',
                              marginTop: '16px',
                              borderRadius: '8px',
                            }}
                            startIcon={<GetAppIcon />}
                          >
                            Download
                          </Button>
                        </div>
                      )}
                      {/*
                                            <Typography style={{ marginTop: '20px', textAlign: 'center' }}>
                                                <Link
                                                    to={{
                                                        pathname: `/${teacher.username}`,
                                                        state: {
                                                            from: navLocation.pathname,
                                                        },
                                                    }}
                                                    style={{
                                                        // display: 'flex',
                                                        // alignItems: 'center',
                                                        textDecorationLine: 'none',
                                                    }}
                                                >
                                                    <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Icon>arrow_back_ios</Icon>
                                                    </span>
                                                </Link>
                                            </Typography> */}
                      <div
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Link
                          to={{
                            pathname: `/${teacher.username}`,
                            state: {
                              from: navLocation.pathname,
                            },
                          }}
                          style={{
                            textDecorationLine: 'none',
                          }}
                        >
                          <Button
                            size="large"
                            variant="contained"
                            style={{
                              backgroundColor: Colors.primaryColor,
                              color: Colors.white,
                              boxShadow: 'none',
                              marginTop: '40px',
                              borderRadius: '8px',
                            }}
                            startIcon={<ArrowBackIosIcon />}
                          >
                            Back to {teacher.username}
                            's profile.
                          </Button>
                        </Link>
                      </div>
                    </Grid>
                  </Grid>
                </div>

                <Modal
                  aria-labelledby="confirm payment"
                  aria-describedby="Confirm payment to complete RSVP."
                  className="payment-confirmation-modal center-modal"
                  open={this.state.openDonationModal}
                  onClose={() => {
                    this.handleCloseDonationModal();
                  }}
                >
                  <div
                    style={{ position: 'absolute', ...UtilFX.getModalStyle() }}
                  >
                    <PayConfirmation
                      {...this.props}
                      isDonation
                      handleCloseDonationModal={this.handleCloseDonationModal}
                      handleDonationSuccess={this.handleDonationSuccess}
                      handleDonationError={this.handleDonationError}
                      noteToRecipient=""
                      description="--donate / tip--"
                      recipientId={teacher && teacher.uid}
                      amount={0}
                    />
                  </div>
                </Modal>

                <Modal
                  aria-labelledby="confirm payment"
                  aria-describedby="Confirm payment to complete RSVP."
                  className="center-modal payment-confirmation-modal"
                  open={this.state.openPayModal}
                  onClose={() => {
                    this.handleClosePayModal();
                  }}
                >
                  <div
                    style={{ position: 'absolute', ...UtilFX.getModalStyle() }}
                  >
                    <PayConfirmation
                      finishRegistrationPostPayment={
                        this.finishRegistrationPostPayment
                      }
                      classInfo={classInfo}
                      handleClosePayModal={this.handleClosePayModal}
                      isRegistered={isRegistered}
                      noteToRecipient=""
                      description={`--class-- ${classInfo.title}`}
                      recipientId={teacher && teacher.uid}
                      amount={Number(calculatedBuyerTotal || 0)}
                      {...this.props}
                    />
                  </div>
                </Modal>

                <Modal
                  aria-labelledby="invite cover"
                  aria-describedby="Open invite to see details."
                  className="center-modal invite-cover-modal"
                  open={showInviteCurtainCover || false}
                  onClose={() => {
                    // this.handleCloseInviteModal();
                  }}
                >
                  <div
                    style={{ position: 'absolute', ...UtilFX.getModalStyle() }}
                  >
                    <div
                      style={{
                        padding: 20,
                        margin: 20,
                        backgroundColor: Colors.pri_color,
                        color: '#fff',
                        // borderRadius: 10,
                        textAlign: 'center',
                        overflow: 'hidden',
                        height: '100vh',
                        display: 'table-cell',
                        verticalAlign: 'middle',
                        // backgroundColor: '#fff',
                        // color: Colors.pri_color
                      }}
                    >
                      <Typography color="inherit" variant="subtitle1">
                        Invitation from
                      </Typography>
                      <Typography variant="subtitle2">
                        @{teacher.username}
                      </Typography>
                     
                     <AvatarDisplay
                      imgSrc={teacher.profile_photo_url}
                      imgClassName={'yogi-photo'}
                      imgAlt={'teacher'}
                      imgStyles={{
                        borderRadius: '50%',
                        height: 98,
                        width: 98,
                        border: '2px white solid',
                        position: 'relative',
                      }}
                      userName={teacher.fullname}
                      textStyles={{fontSize: '14px'}}
                    />

                      <Button
                        fullWidth
                        style={{
                          margin: '10px 5px',
                          backgroundColor: '#0e81ee',
                          border: 'none',
                          borderColor: 'none',
                        }}
                        onClick={() => {
                          this.openInvitation();
                        }}
                      >
                        Open
                      </Button>
                      <Typography>to view details.</Typography>
                      <Button
                        className="touchable-element"
                        variant="caption"
                        color="inherit"
                        style={{
                          margin: '30px 5px 5px 5px',
                          backgroundColor: 'transparent',
                          borderColor: '#fff',
                          borderRadius: 5,
                          borderWidth: 1,
                        }}
                        onClick={() => {
                          // handleClosePayModal && handleClosePayModal();
                          this.props.history.push(`/${teacher.username}`);
                        }}
                      >
                        Exit
                      </Button>
                    </div>
                  </div>
                </Modal>
                <Modal
                  aria-labelledby="class registration success"
                  aria-describedby="show success modal for class registration"
                  className="center-modal"
                  open={this.state.openClassRegistrationModal}
                  onClose={() =>
                    this.setState({ openClassRegistrationModal: false })
                  }
                  disableAutoFocus
                  disableEnforceFocus
                >
                  <div
                    className="modal-content-wrapper"
                    style={{
                      backgroundColor: Colors.white,
                      width: '410px',
                      borderRadius: '12px',
                    }}
                  >
                    {!this.state.showModalToFavoriteTeacher && (
                      <img
                        src={ImgTickSucccess}
                        alt="tick-success"
                        style={{ marginTop: '30px' }}
                      />
                    )}
                    <Typography
                      style={{
                        ...GlobalStyles.titleBold,
                        color: Colors.grey1,
                        textAlign: 'center',
                        marginTop: this.state.showModalToFavoriteTeacher
                          ? '35px'
                          : '26px',
                        marginBottom: '25px',
                      }}
                    >
                      Registration complete!
                    </Typography>
                    {this.state.showModalToFavoriteTeacher && (
                      <React.Fragment>
                        <hr style={{ border: `1px solid${Colors.grey6}` }} />
                        <div style={{ marginTop: '20px' }}>

                          <AvatarDisplay
                          imgSrc={teacher.profile_photo_url}
                          imgStyles={{
                            borderRadius: '50%',
                            height: 68,
                            width: 68,
                          }}
                          imgAlt={'teacher-in-class-registration-modal'}
                          userName={teacher.fullname}
                          />
                        </div>
                        <Typography
                          style={{
                            ...GlobalStyles.bodyMedium,
                            color: Colors.grey3,
                            marginTop: '14px',
                          }}
                        >
                          {teacher.fullname}
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: '18px',
                            justifyContent: 'center',
                          }}
                        >
                          <CheckBoxComponent
                            onChange={(e) =>
                              this.setState({
                                [e.target.name]: e.target.checked,
                              })
                            }
                            name="favorite"
                            color="primary"
                            checked={this.state.favorite}
                          />
                          <Typography
                            style={{
                              ...GlobalStyles.bodyRegular,
                              color: Colors.grey1,
                              alignSelf: 'center',
                            }}
                          >
                            Add them to your favorite teachers.
                          </Typography>
                        </div>
                      </React.Fragment>
                    )}
                    <ButtonComponent
                      value="Continue"
                      onClick={() => {
                        if (this.state.favorite) {
                          this.addTeacherToFavorite();
                        } else {
                          this.setState({ openClassRegistrationModal: false });
                        }
                      }}
                      buttonStyle={{
                        backgroundColor: Colors.pri_color,
                        marginTop: '18px',
                        marginBottom: '32px',
                        width: '134px',
                        borderRadius: '12px',
                      }}
                    />
                  </div>
                </Modal>
                {this.state.openSnackBar && (
                  <SnackBarComponent text='Added to favorites' closeSnackBar={() => this.setState({ openSnackBar: null })} />
                )}
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    );
  }
}
