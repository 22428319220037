import React from 'react';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import imgUndo from './GroupChat/svg/undo.svg';
import {
    Colors,
    GlobalStyles
} from '../../utils';

const TextFieldComponent = (props) => {
    const { placeholder, name, value, onChange, inputStyle, reply, replyName, replyMessage, replyViewStyle, cancelReplyMessage, type, startAdornment = null, endAdornment = null } = props;
    return (
        <div style={{ width: '100%' }}>
            {
                reply && (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px', ...replyViewStyle }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <img src={imgUndo} style={{ marginRight: '14px', marginTop: '5px', marginLeft: '13px' }} alt='message-undo' />
                            <div style={{ textAlign: 'left' }}>
                                <Typography style={{ color: Colors.primaryColor, ...GlobalStyles.bodyMedium }}>{replyName}</Typography>
                                <Typography style={{ color: Colors.grey2, ...GlobalStyles.bodyRegular }}>{replyMessage}</Typography>
                            </div>
                        </div>
                        <span onClick={() => cancelReplyMessage()} className="material-icons" style={{ color: Colors.grey3, fontSize: '18px', cursor: 'pointer' }}>
                            clear
                </span>
                    </div>
                )}
            <Input
                disableUnderline
                name={name}
                value={value}
                autoComplete="on"
                type={type}
                onChange={onChange}
                placeholder={placeholder}
                startAdornment={startAdornment}
                endAdornment={endAdornment}
                style={{ ...inputStyle, padding: '10px', width: '100%' }}
            />
        </div>
    );
};

export default TextFieldComponent;
