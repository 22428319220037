// this file shows the the list of smaller item card used in classesedit
import React from 'react';
import Typography from '@material-ui/core/Typography';
// import { Colors } from "./Colors";
import { Colors, GlobalStyles } from '../../utils';
export const CLASS_MODE_EDIT = 'edit';
/*
the view card shows a single row of the an item that contains an icon, text and an edit button
*/
export const ViewCard = ({ iconName, title, eventHandler }) => (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', }}>
        <span className="material-icons" style={{ color: '#DADADA', marginRight: '20px', alignSelf: 'center', fontSize: 20 }}>
            {iconName}
        </span>
        <div style={{ width: '100%' }}>
            <div style={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <Typography style={{ ...GlobalStyles.bodyRegular, color: Colors.grey1, textAlign: 'left', marginTop: '15px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '200px' }}>
                    {title}
                </Typography>
                <div style={{ backgroundColor: 'rgb(31, 163, 255, 0.05)', color: Colors.primaryColor, boxShadow: 'none', cursor: 'pointer', padding: '8px', borderRadius: '8px'  }} onClick={eventHandler}>
                    <span className="material-icons">
                        create
                    </span>
                </div>
            </div>
            <hr style={{ border: '1px solid rgba(189,189,189, 0.15)' }} />
        </div>
    </div>
);
