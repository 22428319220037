import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ImgMute from './GroupChat/svg/mute.svg';
import ImgDelete from './GroupChat/svg/delete_image.svg';
import ImgBlock from './GroupChat/svg/block_user.svg';
import ImgUnblockSuccess from './GroupChat/svg/unblock.svg';
import ImgBlockSuccess from './GroupChat/svg/block_successfully.svg';
// import ArchiveSnackbar from './GroupChat/ArchiveSnackbar';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ButtonComponent from './ButtonComponent';
import { Colors, GlobalStyles, UtilFX, ConvoMessages, DataFX, ConvoMetadata } from '../../utils';
import * as Algolia from '../../utils/algolia';
import { Link } from 'react-router-dom';
import {
  InstantSearch,
  SearchBox,
  Configure,
  Index,
} from 'react-instantsearch/dom';
import { GroupChatHitsYogis } from '../Explore';
import ClearCard from './ClearCard';
import Switch from '@material-ui/core/Switch';
import {
  firebase,
  firestore,
} from '../../fire';
import ImgCopyLink from './GroupChat/svg/copy_link.svg';
import ImgSearch from './GroupChat/svg/quick_search.svg';
import ImgAddMember from './GroupChat/svg/add_member.svg';
import ImgLeaveGroup from './GroupChat/svg/leave_group.svg';
import ImgUnmuteNotification from './GroupChat/svg/unmute_notification.svg';
import GroupChatMemberCard from './GroupChat/GroupChatMemberCard';
import GroupChatPendingInviteCard from './GroupChat/GroupChatPendingInviteCard';
import SnackBarComponent from './SnackBarComponent';
import AvatarDisplay from '../AvatarDisplay';
import '../../chat_algolia.css';

const IconAndText = ({ iconName, text, onClick, viewStyle }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        width: '100%',
        ...viewStyle,
      }}
      onClick={onClick}
    >
      <span
        className="material-icons outlined"
        style={{ color: Colors.grey3, alignSelf: 'center' }}
      >
        {iconName}
      </span>
      <Typography
        style={{
          ...GlobalStyles.bodyRegular,
          color: Colors.black,
          marginLeft: '17px',
        }}
      >
        {text}
      </Typography>
    </div>
  );
};

const TitleCardOptions = ({
  setShowIcon,
  showIcon,
  messagePopover,
  titlePopover,
  classTeacherPopover,
  messageHeaderPopover,
  arrowDown,
  groupMessagePopover,
  setPerformAction,
  performAction,
  replyHandler,
  messageDetails,
  bookmarkMessageHandler,
  convo,
  uid,
  username,
  user,
  archive = false,
  handlePinnedConvoIds,
  pinnedConvoIds,
  handleMutedConvoIds,
  mutedConvoIds,
  handleArchiveConvoIds,
  archiveConvoIds,
  deleteDMConvo,
  deletedConvoIds,
  groupInfoPopover,
  newMutedMembers,
  newHandleMutedMembers,
  groupMemberDocumentId,
  dmEntryPoint,
  recipientId,
  convoId,
  imgUrl,
  name,
  initialUserIdList = [],
  classId,
  props,
  pendingGroupChatMembers = [],
  acceptedGroupChatMembers = [],
  mutedMembers = [],
  hideGroupChatMembers = [],
  setPendingGroupChatMembers,
  setAcceptedGroupChatMembers,
  setHideGroupChatMembers,
  setMutedMembers,
  chatBookmarkNavigator,
  chatOwnerId,
  openNewMemberSnackBar,
  listOfAllMessages,
  teacherId,
  currentChatToggleHeader,
  dmChatType,
  updateChatMembershipStatus,
  updateClearAllCurrentConversationChat,
  pinnedMessages,
  listOfOwnerBlockedUsers = [],
  handleBlockedUsersModal,
  convos,
  groupMembers
}) => {
  const [showPendingRequest, setShowPendingRequest] = useState(false);
  const [groupChatProfileInfo, setGroupChatProfileInfo] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [showGroupChatAddMemberOptions, setShowGroupChatAddMemberOptions] = useState(false);
  const [cardProperties, setCardProperties] = useState({
    anchor: null,
    deleteModal: false,
    archiveSnackbar: false,
    copyMessage: false,
    clearChatModal: false,
    replyMessage: false,
    pinMessage: false,
    removeMemberModal: false,
    unmuteNotification: false,
    yogiPickerModal: false,
    leaveGroupModal: false,
    blockDMMessageModal: false,
    blockDMSuccessModal: false
  });
  const [muteProperties, setMuteProperties] = useState({ open: false, value: null, unmute: false });
  // const [block, setBlockUser] = useState({ openModal: false, });
  const [selectedYogi, setSelectedYogi] = useState(null);
  const [memberStatus, setMemberStatus] = useState('')
  const handlePopover = (e) => {
    setCardProperties({ ...cardProperties, anchor: e.currentTarget });
  };

  const closeGroupChatProfileInfo = () => {
    setGroupChatProfileInfo(false);
  };

  const persistedUserIdList = Object.keys(props?.students || {});
  let filterString = `NOT objectID:${uid}`;

  if (initialUserIdList.length) {
    filterString = initialUserIdList.map(uid => `objectID:${uid}`).join(' OR ');
  }

  if (persistedUserIdList.length) {
    filterString = persistedUserIdList.map(uid => `objectID:${uid}`).join(' OR ');
  }

  const handleMutedMembers = (data) => {
    setMutedMembers(data);
  };

  const handleMuteValue = (e) => {
    let date = new Date();
    if (e.currentTarget.value === '8hours') {
      date = date.setHours(date.getHours() + 8);
    } else if (e.currentTarget.value === '1week') {
      date = date.setDate(date.getDate() + 7);
    } else {
      date = date.setFullYear(date.getFullYear() + 1);
    }
    setMuteProperties({ ...muteProperties, value: date });
  };

  const handleMuteModal = () => {
    setMuteProperties({ ...muteProperties, open: true });
  };

  const handlePinChatIcon = () => {
    firebase
      .database()
      .ref(`convobyusers/${uid}/${convo.convo_id}`)
      .update({
        date_pinned: Date.now()
      });
    return setCardProperties({ ...cardProperties, anchor: null });
  };

  const handleDeleteModal = () => {
    setCardProperties({
      ...cardProperties,
      anchor: null,
      deleteModal: !cardProperties.deleteModal,
    });
  };

  const handleArchive = () => {
    if (!(archiveConvoIds.includes(convo.convo_id)) && !archive) {
      firebase
        .database()
        .ref(`convobyusers/${uid}/${convo.convo_id}`)
        .update({
          date_archived: Date.now()
        });
      archiveConvoIds.push(convo.convo_id);
    } else {
      firebase
        .database()
        .ref(`convobyusers/${uid}/${convo.convo_id}`)
        .update({
          date_archived: null
        });
      const index = archiveConvoIds.indexOf(convo.convo_id);
      if (index > -1) {
        archiveConvoIds.splice(index, 1);
      }
    }
    handleArchiveConvoIds(archiveConvoIds);
    return setCardProperties({ ...cardProperties, anchor: null });
  };

  const handleCopyMessage = () => {
    setCardProperties({ ...cardProperties, copyMessage: true, anchor: null });
    const text = messageDetails.message_text;
    UtilFX.copyToClipboard(text);
  };

  const handleBookmarkMessage = () => {
    bookmarkMessageHandler(messageDetails);
  };

  const handleClearChatModal = () => {
    setCardProperties({
      ...cardProperties,
      clearChatModal: !cardProperties.clearChatModal,
    });
  };

  const handleRemoveGroupMemberModal = () => {
    setCardProperties({
      ...cardProperties,
      removeMemberModal: !cardProperties.removeMemberModal,
      anchor: null
    });
  };

  const handleReplyMessage = () => {
    setCardProperties({ ...cardProperties, replyMessage: true, anchor: null });
    setPerformAction({ ...performAction, reply: true });
    replyHandler(messageDetails);
  };

  const handlePinMessage = () => {
    firestore.collection('ConvoMessages').doc(messageDetails.message_id).update({
      date_pinned: Date.now()
    });
  };

  const openPinMessageModal = () => {
    setCardProperties({ ...cardProperties, pinMessage: true, anchor: null });
  };

  const closePinMessageModal = () => {
    setCardProperties({ ...cardProperties, pinMessage: false, anchor: null });
  };

  const filterConvoMessages = () => {
   const filteredConvos = convos && convos.filter(message => (message.dm_members || message.teacher_id) && message.last_message_id)
    .map( convo => convo.last_message_id);
    return filteredConvos;
  }
 
  const filterGroupMemberToAdd = () => {  
   return groupMembers?.map((member) => member?.uid);
   
  }

  const getStatus = (item) => {
    const member = groupMembers?.find(member => member?.uid === item?.objectID);
    setMemberStatus(member?.status);
    setSelectedYogi(item)
  }

  return (
    <div>
      <span
        className="material-icons"
        style={{ color: messageHeaderPopover || groupMessagePopover ? Colors.white : Colors.grey3, cursor: 'pointer' }}
        onClick={handlePopover}
      >
        {(arrowDown && 'keyboard_arrow_down') || 'more_vert'}
      </span>
      <Popover
        open={Boolean(cardProperties.anchor)}
        anchorEl={cardProperties.anchor}
        onClose={() => setCardProperties({ ...cardProperties, anchor: null })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {
          groupInfoPopover && (
            <div style={{ backgroundColor: Colors.white, width: '165px' }}>
              <Link
                to={
                  {
                    pathname: `/${user.username}`,
                  }
                }
                target="_blank"
                style={{ textDecoration: 'none' }}
              >
                <IconAndText text='View Profile'
                  viewStyle={{ padding: '18px 0 12px 12px' }}
                />
              </Link>
              {
                uid === teacherId && (
                  <IconAndText text={
                    (newMutedMembers.includes(groupMemberDocumentId) && 'Unmute') || 'Mute'
                  }
                    viewStyle={{ padding: '8px 0 12px 12px' }}
                    onClick={async () => {
                      if (newMutedMembers.includes(groupMemberDocumentId)) {
                        setMuteProperties({ open: false, value: null });
                        const docRef = await firestore
                          .collection('ConvoMembers')
                          .doc(groupMemberDocumentId);
                        docRef.update({ muted: false });
                        setCardProperties({ ...cardProperties, anchor: null });
                        const data = newMutedMembers.filter(data => data !== groupMemberDocumentId);
                        newHandleMutedMembers(data);
                        return;
                      }
                      setCardProperties({ ...cardProperties, anchor: null });
                      handleMuteModal();
                    }}
                  />
                )}
              <IconAndText text='Message member'
                viewStyle={{ padding: '8px 0 12px 12px' }}
                onClick={() => {
                  closeGroupChatProfileInfo();
                  setCardProperties({ ...cardProperties, anchor: null });
                  dmEntryPoint({ recipientId, uid });
                }}
              />
              {
                uid === teacherId && (
                  <IconAndText text='Remove member'
                    viewStyle={{ padding: '8px 0 12px 12px' }}
                    onClick={() => handleRemoveGroupMemberModal()}
                  />
                )}
            </div>
          )}
        {titlePopover && (
          <div style={{ backgroundColor: Colors.white, width: '165px' }}>
            <IconAndText
              iconName={'notifications_off'}
              text={(mutedConvoIds.includes(convo.convo_id) && 'Unmute') || 'Mute'}
              onClick={() => {
                if (mutedConvoIds.includes(convo.convo_id)) {
                  setMuteProperties({ open: false, value: null });
                  firebase
                    .database()
                    .ref(`convobyusers/${uid}/${convo.convo_id}`)
                    .update({
                      muted: null
                    });
                  setCardProperties({ ...cardProperties, anchor: null });
                  const index = mutedConvoIds.indexOf(convo.convo_id);
                  if (index > -1) {
                    mutedConvoIds.splice(index, 1);
                  }
                  return handleMutedConvoIds(mutedConvoIds);
                }
                setCardProperties({ ...cardProperties, anchor: null });
                handleMuteModal();
              }}
              viewStyle={{ padding: '18px 0 12px 12px' }}
            />
            <IconAndText
              iconName={(pinnedConvoIds.includes(convo.convo_id)) ? 'location_off' : 'push_pin'}
              text={(pinnedConvoIds.includes(convo.convo_id)) ? 'Unpin chat' : 'Pin chat'}
              viewStyle={{ padding: '18px 0 12px 12px' }}
              onClick={() => {
                if (pinnedConvoIds.includes(convo.convo_id)) {
                  firebase
                    .database()
                    .ref(`convobyusers/${uid}/${convo.convo_id}`)
                    .update({
                      date_pinned: null
                    });
                  setCardProperties({ ...cardProperties, anchor: null });
                  const index = pinnedConvoIds.indexOf(convo.convo_id);
                  if (index > -1) {
                    pinnedConvoIds.splice(index, 1);
                  }
                  return handlePinnedConvoIds(pinnedConvoIds);
                }
                pinnedConvoIds.push(convo.convo_id);
                handlePinnedConvoIds(pinnedConvoIds);
                handlePinChatIcon();
              }}
            />
            <IconAndText
              iconName={
                archiveConvoIds.includes(convo.convo_id) || archive ? 'unarchive' : 'archive'
              }
              text={archiveConvoIds.includes(convo.convo_id) || archive ? 'Unarchive chat' : 'Archive chat'}
              viewStyle={{ padding: '12px 40px 12px 12px' }}
              onClick={handleArchive}
            />

            {classTeacherPopover && (
              <IconAndText
                iconName={'delete_outline'}
                text={'Delete chat'}
                viewStyle={{ padding: '12px 0 18px 12px' }}
                onClick={() => handleDeleteModal()}
              />
            )}
          </div>
        )}
        {messageHeaderPopover && (
          <div style={{ backgroundColor: Colors.white }}>
            <Link
              to={
                {
                  pathname: `/${username}`,
                }
              }
              target="_blank"
              style={{ textDecoration: 'none' }}
            >
              <IconAndText
                iconName={'person'}
                text={'View Profile'}
                viewStyle={{ padding: '18px 0 12px 12px' }}
              />
            </Link>
            <IconAndText
              iconName={'notifications_off'}
              text={(mutedConvoIds?.includes(convoId) && 'Unmute') || 'Mute'}
              onClick={() => {
                if (mutedConvoIds?.includes(convoId)) {
                  return setCardProperties({ ...cardProperties, unmuteNotification: true, anchor: null });
                }
                setCardProperties({ ...cardProperties, anchor: null });
                handleMuteModal();
              }}
              viewStyle={{ padding: '12px 0 12px 12px' }}
            />
            <IconAndText
              iconName={'star_outline'}
              text={'View bookmarks'}
              onClick={chatBookmarkNavigator}
              viewStyle={{
                padding: '12px 20px 12px 12px',
                backgroundColor: cardProperties.archiveSnackbar && Colors.shade,
              }}
            />
            <IconAndText
              iconName={'block'}
              text={(listOfOwnerBlockedUsers.includes(recipientId) && 'Unblock') || 'Block'}
              viewStyle={{ padding: '12px 0 18px 12px' }}
              onClick={() => {
                if (listOfOwnerBlockedUsers.includes(recipientId)) {
                  firebase
                    .database()
                    .ref(`users/${uid}/blocks`)
                    .update({
                      [recipientId]: null
                    }).then(() => {
                      setCardProperties({ ...cardProperties, blockDMSuccessModal: true, anchor: null });
                      const result = listOfOwnerBlockedUsers.filter(id => id !== recipientId);
                      handleBlockedUsersModal(result);
                    });
                  return;
                }
                setCardProperties({ ...cardProperties, blockDMMessageModal: true, anchor: null });
              }}
            />
          </div>
        )}
        {messagePopover && (
          <div style={{ backgroundColor: Colors.white, padding: '16px 24px' }}>
            <Typography
              style={{ color: Colors.black, cursor: 'pointer' }}
              onClick={handleReplyMessage}
            >
              Reply message
            </Typography>
            {/* <Typography style={{ color: Colors.black, marginTop: '16px' }}>
              Add reaction
            </Typography> */}
            <Typography
              style={{
                color: Colors.black,
                marginTop: '16px',
                cursor: 'pointer',
              }}
              onClick={handleCopyMessage}
            >
              Copy message
            </Typography>
            {
              (currentChatToggleHeader !== 'dm') && (teacherId === uid) &&
              <Typography style={{ color: Colors.black, marginTop: '16px', cursor: 'pointer' }} onClick={() => {
                if (pinnedMessages.length) {
                  openPinMessageModal();
                } else {
                  handlePinMessage();
                }
              }}>Pin message</Typography>
            }
            {/* <Typography style={{ color: Colors.black, marginTop: '16px' }}>
              Forward message
            </Typography> */}
            <Typography
              onClick={() => {
                setCardProperties({ ...cardProperties, anchor: null });
                handleBookmarkMessage();
              }}
              style={{
                color: Colors.black,
                marginTop: '16px',
                cursor: 'pointer',
              }}
            >
              Bookmark message
            </Typography>
            {
              ((uid === teacherId) ||  (dmChatType)) && (
                <Typography
                  style={{
                    color: Colors.black,
                    marginTop: '16px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleDeleteModal()}
                >
                  Delete message
                </Typography>
            )}
          </div>
        )}
        {groupMessagePopover && (
          <div style={{ backgroundColor: Colors.white }}>
            <IconAndText
              iconName={'person'}
              text={'Group info'}
              viewStyle={{ padding: '18px 0 12px 12px' }}
              onClick={() => {
                setGroupChatProfileInfo(true);
                setCardProperties({ ...cardProperties, anchor: null });
              }}
            />
            <IconAndText
              iconName={'notifications_off'}
              text={(mutedConvoIds?.includes(convoId) && 'Unmute') || 'Mute'}
              onClick={() => {
                if (mutedConvoIds?.includes(convoId)) {
                  return setCardProperties({ ...cardProperties, unmuteNotification: true, anchor: null });
                }
                setCardProperties({ ...cardProperties, anchor: null });
                handleMuteModal();
              }}
              viewStyle={{ padding: '12px 0 12px 12px' }}
            />
            <IconAndText
              iconName={'star_outline'}
              text={'View bookmarks'}
              onClick={chatBookmarkNavigator}
              viewStyle={{
                padding: '12px 20px 12px 12px',
                backgroundColor: cardProperties.archiveSnackbar && Colors.shade,
              }}
            />
            {
              uid !== chatOwnerId && (
                <IconAndText
                  iconName={'group'}
                  text={'Leave group'}
                  viewStyle={{ padding: '12px 0 12px 12px' }}
                  onClick={() => setCardProperties({ ...cardProperties, leaveGroupModal: true, anchor: null })}
                />
              )
            }
            {
              uid === chatOwnerId && (
                <IconAndText
                  iconName={'cleaning_services'}
                  text={'Clear Chat'}
                  viewStyle={{ padding: '12px 0 12px 12px' }}
                  onClick={() => {
                    setCardProperties({ ...cardProperties, anchor: null });
                    handleClearChatModal();
                  }}
                />
              )
            }
          </div>
        )}
      </Popover>
      <Modal
        disableAutoFocus
        disableEnforceFocus
        aria-labelledby="remove memeber modal"
        aria-describedby="remove member entirely from group"
        className="center-modal"
        open={Boolean(cardProperties.removeMemberModal)}
        onClose={handleRemoveGroupMemberModal}
      >
        <div
          style={{
            width: '356px',
            backgroundColor: Colors.white,
            margin: 'auto',
            marginTop: '70px',
            borderRadius: '8px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <div style={{ margin: '20px 24px 24px 24px', textAlign: 'center' }}>
              <Typography
                style={{
                  ...GlobalStyles.titleBold,
                  fontSize: '18px',
                  color: Colors.grey1,
                }}
              >
                Remove member
              </Typography>
              <Typography
                style={{
                  ...GlobalStyles.bodyRegular,
                  color: Colors.grey2,
                  marginTop: '8px',
                }}
              >
                Are you sure you want to remove this member completely from this group?
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '24px',
                }}
              >
                <ButtonComponent
                  value="CANCEL"
                  buttonStyle={{
                    backgroundColor: Colors.grey6,
                    color: Colors.grey2,
                    borderRadius: '5px',
                    marginRight: '10px',
                    border: `1px solid ${Colors.grey6}`
                  }}
                  onClick={handleRemoveGroupMemberModal}
                />
                <ButtonComponent
                  value="Delete"
                  buttonStyle={{
                    backgroundColor: Colors.red,
                    color: Colors.white,
                    borderRadius: '5px',
                    border: `1px solid ${Colors.red}`
                  }}
                  onClick={() => {
                    const data = acceptedGroupChatMembers.filter(data => data.documentId !== groupMemberDocumentId);
                    DataFX.removeStudentInClassConvo({ uid, classId, studentId: recipientId });
                    setAcceptedGroupChatMembers(data);
                    handleRemoveGroupMemberModal();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        disableAutoFocus
        disableEnforceFocus
        aria-labelledby="mute modal"
        aria-describedby="mute modal or cancel"
        className="center-modal"
        open={Boolean(muteProperties.open)}
        onClose={handleMuteModal}
      >
        <div
          style={{
            width: '356px',
            backgroundColor: Colors.white,
            margin: 'auto',
            marginTop: '70px',
            borderRadius: '8px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <img
              src={ImgMute}
              alt="mute_user"
              style={{ borderRadius: '8px' }}
            />
            <div style={{ margin: '20px 24px 24px 24px' }}>
              <Typography
                style={{
                  ...GlobalStyles.bodyMedium,
                  fontSize: '16px',
                  color: Colors.grey1,
                  textAlign: groupInfoPopover ? 'center' : 'left'
                }}
              >
                {groupInfoPopover ? 'Mute member' : 'Mute notifications for'}
              </Typography>
              {
                !groupInfoPopover && (
                  <RadioGroup
                    aria-label="mute"
                    name="mute"
                    onChange={handleMuteValue}
                  >
                    <FormControlLabel
                      value="8hours"
                      control={<Radio style={{ color: Colors.grey3 }} />}
                      label="8 hours"
                      style={{ ...GlobalStyles.bodyRegular, color: Colors.grey1 }}
                    />
                    <FormControlLabel
                      value="1week"
                      control={<Radio style={{ color: Colors.grey3 }} />}
                      label="1 week"
                      style={{ ...GlobalStyles.bodyRegular, color: Colors.grey1 }}
                    />
                    <FormControlLabel
                      value="1year"
                      control={<Radio style={{ color: Colors.grey3 }} />}
                      label="1 year"
                      style={{ ...GlobalStyles.bodyRegular, color: Colors.grey1 }}
                    />
                  </RadioGroup>
                )}
              {
                groupInfoPopover && (
                  <Typography style={{ ...GlobalStyles.bodyRegular, color: Colors.grey2, textAlign: 'center' }}>They won’t be able to send messages to this group but won’t be removed either.</Typography>
                )
              }
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '24px',
                }}
              >
                <ButtonComponent
                  value="CANCEL"
                  buttonStyle={{
                    backgroundColor: Colors.grey6,
                    color: Colors.grey1,
                    borderRadius: '5px',
                    marginRight: '10px',
                    border: 0,
                  }}
                  onClick={() => {
                    setMuteProperties({ open: false, value: null });
                  }}
                />
                <ButtonComponent
                  value="MUTE"
                  buttonStyle={{
                    backgroundColor: Colors.red,
                    color: Colors.white,
                    borderRadius: '5px',
                    border: 0,
                  }}
                  onClick={async () => {
                    if (groupInfoPopover) {
                      const docRef = await firestore
                        .collection('ConvoMembers')
                        .doc(groupMemberDocumentId);
                      docRef.update({ muted: true });
                      setCardProperties({ ...cardProperties, anchor: null });
                      setMuteProperties({ ...muteProperties, open: false });
                      const data = [...newMutedMembers, groupMemberDocumentId];
                      newHandleMutedMembers(data);
                      return;
                    }
                    if (muteProperties.value) {
                      firebase
                        .database()
                        .ref(`convobyusers/${uid}/${convo?.convo_id || convoId}`)
                        .update({
                          muted: {
                            start_date: Date.now(),
                            end_date: muteProperties.value
                          }
                        });
                      setCardProperties({ ...cardProperties, anchor: null });
                      setMuteProperties({ ...muteProperties, open: false });
                      mutedConvoIds.push(convo?.convo_id || convoId);
                      return handleMutedConvoIds(mutedConvoIds);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        aria-labelledby="unmute group notification"
        aria-describedby="show unmute group notification modal"
        className="center-modal"
        open={Boolean(cardProperties.unmuteNotification)}
        onClose={() => setCardProperties({ ...cardProperties, unmuteNotification: false })}
        disableAutoFocus
        disableEnforceFocus
      >
        <div
          className="modal-content-wrapper"
          style={{
            backgroundColor: Colors.white,
            width: '356px',
            borderRadius: '10px',
          }}
        >
          <img
            src={ImgUnmuteNotification}
            alt="unmute_group_notification"
            style={{ borderRadius: '8px' }}
          />
          <div style={{ margin: '20px 24px 24px 24px', textAlign: 'center' }}>
            <Typography
              style={{
                ...GlobalStyles.titleBold,
                fontSize: '18px',
                color: Colors.grey1,
              }}
            >
              Unmute notifications
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '24px',
              }}
            >
              <ButtonComponent
                value="CANCEL"
                buttonStyle={{
                  backgroundColor: Colors.grey6,
                  color: Colors.grey1,
                  borderRadius: '5px',
                  marginRight: '10px',
                  border: 0,
                }}
                onClick={() => setCardProperties({ ...cardProperties, unmuteNotification: false })}
              />
              <ButtonComponent
                value="UNMUTE"
                buttonStyle={{
                  backgroundColor: Colors.pri_color,
                  color: Colors.white,
                  borderRadius: '5px',
                  border: 0,
                }}
                onClick={() => {
                  firebase
                    .database()
                    .ref(`convobyusers/${uid}/${convoId}`)
                    .update({
                      muted: null
                    });
                  setCardProperties({ ...cardProperties, unmuteNotification: false });
                  const index = mutedConvoIds.indexOf(convoId);
                  if (index > -1) {
                    mutedConvoIds.splice(index, 1);
                  }
                  return handleMutedConvoIds(mutedConvoIds);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        disableAutoFocus
        disableEnforceFocus
        aria-labelledby="delete modal"
        aria-describedby="delete modal or cancel"
        className="center-modal"
        open={Boolean(cardProperties.deleteModal)}
        onClose={handleDeleteModal}
      >
        <div
          style={{
            width: '356px',
            backgroundColor: Colors.white,
            margin: 'auto',
            marginTop: '70px',
            borderRadius: '8px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <img
              src={ImgDelete}
              alt="delete_user"
              style={{ borderRadius: '8px' }}
            />
            <div style={{ margin: '20px 24px 24px 24px', textAlign: 'center' }}>
              <Typography
                style={{
                  ...GlobalStyles.titleBold,
                  fontSize: '18px',
                  color: Colors.grey1,
                }}
              >
                Delete chat
              </Typography>
              <Typography
                style={{
                  ...GlobalStyles.bodyRegular,
                  color: Colors.grey1,
                  marginTop: '6px',
                }}
              >
                This action can’t be undone. Are you sure?
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '24px',
                }}
              >
                <ButtonComponent
                  value="CANCEL"
                  buttonStyle={{
                    backgroundColor: Colors.grey6,
                    color: Colors.grey1,
                    borderRadius: '5px',
                    marginRight: '10px',
                    border: 0,
                  }}
                  onClick={handleDeleteModal}
                />
                <ButtonComponent
                  value="Delete"
                  buttonStyle={{
                    backgroundColor: Colors.red,
                    color: Colors.white,
                    borderRadius: '5px',
                    border: 0,
                  }}
                  onClick={() => {
                    if (titlePopover) {
                      const data = {};
                      data[`convobyusers/${uid}/${convo.convo_id}`] = null;
                      return firebase.database()
                        .ref()
                        .update(data).then(function () {
                          deleteDMConvo(deletedConvoIds, convo.convo_id);
                          handleDeleteModal();
                        });
                    }
                    if(filterConvoMessages().includes(messageDetails.message_id)){
                      const metaRef = firestore
                        .collection(ConvoMetadata)
                        .doc(messageDetails?.convo_id);
                        const messageRef = firestore
                        .collection(ConvoMessages)
                        .doc(messageDetails.message_id);
                      return messageRef
                        .update({
                          date_deleted: Date.now(),
                        })
                        .then(function () {
                          return metaRef
                          .update({
                            last_message_date_deleted: Date.now()
                          }).then(() => {
                            handleDeleteModal();
                          })
                        
                        })
                        .catch(function (error) {
                          console.error('Error updating document: ', error);
                        });
                     
                    } else {
                      const messageRef = firestore
                      .collection(ConvoMessages)
                      .doc(messageDetails.message_id);
                    return messageRef
                      .update({
                        date_deleted: Date.now(),
                      })
                      .then(function () {
                        handleDeleteModal();
                      })
                      .catch(function (error) {
                        console.error('Error updating document: ', error);
                      });
                    }
                   
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        disableAutoFocus
        disableEnforceFocus
        aria-labelledby="clear chat modal"
        aria-describedby="clear entire chat or cancel"
        className="center-modal"
        open={Boolean(cardProperties.clearChatModal)}
        onClose={handleClearChatModal}
      >
        <div
          style={{
            width: '356px',
            backgroundColor: Colors.white,
            margin: 'auto',
            marginTop: '70px',
            borderRadius: '8px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <img
              src={ImgDelete}
              alt="delete_chat"
              style={{ borderRadius: '8px' }}
            />
            <div style={{ margin: '20px 24px 24px 24px', textAlign: 'center' }}>
              <Typography
                style={{
                  ...GlobalStyles.titleBold,
                  fontSize: '18px',
                  color: Colors.grey1,
                }}
              >
                Clear entire chat
              </Typography>
              <Typography
                style={{
                  ...GlobalStyles.bodyRegular,
                  color: Colors.grey1,
                  marginTop: '6px',
                }}
              >
                This action can’t be undone. Are you sure?
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '24px',
                }}
              >
                <ButtonComponent
                  value="CANCEL"
                  buttonStyle={{
                    backgroundColor: Colors.grey6,
                    color: Colors.grey1,
                    borderRadius: '5px',
                    marginRight: '10px',
                    border: `1px solid ${Colors.grey6}`,
                  }}
                  onClick={handleClearChatModal}
                />
                <ButtonComponent
                  value="Delete"
                  buttonStyle={{
                    backgroundColor: Colors.red,
                    color: Colors.white,
                    borderRadius: '5px',
                    border: `1px solid ${Colors.red}`,
                  }}
                  onClick={() => {
                    const dateDeleted = Date.now();
                    Promise.all(listOfAllMessages.filter(item => !item.date_deleted).map(item => {
                      const messageRef = firestore
                        .collection(ConvoMessages)
                        .doc(item.message_id);
                      return messageRef
                        .update({
                          date_deleted: dateDeleted,
                        });
                    })).then(() => {
                      updateClearAllCurrentConversationChat();
                      handleClearChatModal();
                    }).catch((err) => {
                      console.error('error deleteing messages', err);
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        disableAutoFocus
        disableEnforceFocus
        aria-labelledby="block or unblock success modal"
        aria-describedby="block"
        className="center-modal"
        open={Boolean(cardProperties.blockDMSuccessModal)}
        onClose={() => setCardProperties({ ...cardProperties, blockDMSuccessModal: false, anchor: null })}
      >
        <div
          style={{
            width: '309px',
            height: '309px',
            backgroundColor: Colors.white,
            borderRadius: '8px',
            margin: 'auto',
            marginTop: '70px',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <span
            onClick={() => setCardProperties({ ...cardProperties, blockDMSuccessModal: false, anchor: null })}
            className="material-icons"
            style={{
              textAlign: 'right',
              margin: '10px',
              color: Colors.grey1,
              cursor: 'pointer',
            }}
          >
            clear
          </span>
          <img
            alt="block_modal"
            src={listOfOwnerBlockedUsers.includes(recipientId) ? ImgBlockSuccess : ImgUnblockSuccess}
            style={{ marginTop: '24px', justifyContent: 'center' }}
          />
          <Typography
            style={{
              ...GlobalStyles.bodyRegular,
              marginTop: '32px',
              marginBottom: '82px',
              color: Colors.grey2,
              textAlign: 'center',
            }}
          >
            {listOfOwnerBlockedUsers.includes(recipientId) ? 'Successfully blocked!' : 'Successfully unblocked!'}
          </Typography>
        </div>
      </Modal>
      <Modal
        disableAutoFocus
        disableEnforceFocus
        aria-labelledby="block modal"
        aria-describedby="block user or cancel"
        className="center-modal"
        open={Boolean(cardProperties.blockDMMessageModal)}
        onClose={() => setCardProperties({ ...cardProperties, blockDMMessageModal: false, anchor: null })}
      >
        <div
          style={{
            width: '356px',
            backgroundColor: Colors.white,
            borderRadius: '8px',
            margin: 'auto',
            marginTop: '70px',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              backgroundColor: Colors.white,
              margin: 'auto',
              borderRadius: '8px',
            }}
          >
            <img
              src={ImgBlock}
              alt="delete_all"
              style={{ borderRadius: '8px', marginTop: '26px' }}
            />
            <div
              style={{ margin: '20px 24px 24px 24px', textAlign: 'center' }}
            >
              <Typography
                style={{
                  ...GlobalStyles.titleBold,
                  fontSize: '18px',
                  color: Colors.grey1,
                }}
              >
                Block this person
              </Typography>
              <Typography
                style={{
                  ...GlobalStyles.bodyRegular,
                  color: Colors.grey1,
                  marginTop: '6px',
                }}
              >
                They will no longer be able to contact you.
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '24px',
                }}
              >
                <ButtonComponent
                  value="CANCEL"
                  buttonStyle={{
                    backgroundColor: Colors.grey6,
                    color: Colors.grey1,
                    borderRadius: '5px',
                    marginRight: '10px',
                    border: 0
                  }}
                  onClick={() => setCardProperties({ ...cardProperties, blockDMMessageModal: false, anchor: null })}
                />
                <ButtonComponent
                  value="Block"
                  buttonStyle={{
                    backgroundColor: Colors.primaryColor,
                    color: Colors.white,
                    borderRadius: '5px',
                    border: 0
                  }}
                  onClick={() => {
                    firebase
                      .database()
                      .ref(`users/${uid}/blocks`)
                      .update({
                        [recipientId]: true
                      }).then(() => {
                        const result = [...listOfOwnerBlockedUsers, recipientId];
                        handleBlockedUsersModal(result);
                        setCardProperties({ ...cardProperties, blockDMMessageModal: false, blockDMSuccessModal: true, anchor: null });
                      });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        disableAutoFocus
        disableEnforceFocus
        aria-labelledby="leave group modal"
        aria-describedby="leave group or cancel"
        className="center-modal"
        open={Boolean(cardProperties.leaveGroupModal)}
        onClose={() => setCardProperties({ ...cardProperties, leaveGroupModal: false })}
      >
        <div
          style={{
            width: '356px',
            backgroundColor: Colors.white,
            borderRadius: '8px',
            margin: 'auto',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              backgroundColor: Colors.white,
              margin: 'auto',
              borderRadius: '8px',
              marginTop: '70px',
            }}
          >
            <img
              src={ImgLeaveGroup}
              alt="delete_all"
              style={{ borderRadius: '8px', }}
            />
            <div
              style={{ margin: '20px 24px 24px 24px', textAlign: 'center' }}
            >
              <Typography
                style={{
                  ...GlobalStyles.bodyMedium,
                  fontSize: '16px',
                  color: Colors.grey1,
                }}
              >
                Leave group?
              </Typography>
              <Typography
                style={{
                  ...GlobalStyles.bodyRegular,
                  color: Colors.grey2,
                  marginTop: '16px',
                }}
              >
                You can request to join again in the future if you change your mind.
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '24px',
                }}
              >
                <ButtonComponent
                  value="CANCEL"
                  buttonStyle={{
                    backgroundColor: Colors.grey6,
                    color: Colors.grey1,
                    borderRadius: '5px',
                    marginRight: '10px',
                    border: `1px solid ${Colors.grey6}`
                  }}
                  onClick={() => setCardProperties({ ...cardProperties, leaveGroupModal: false })}
                />
                <ButtonComponent
                  value="LEAVE"
                  buttonStyle={{
                    backgroundColor: Colors.red,
                    color: Colors.white,
                    borderRadius: '5px',
                    border: `1px solid ${Colors.red}`
                  }}
                  onClick={async () => {
                    const member = await UtilFX.getChatMembershipDetails({ convoId, uid });
                    if (member.documentId) {
                      firestore.collection('ConvoMembers').doc(member.documentId).delete();
                    }
                    const updateData = {};
                    updateData[`convobyusers/${uid}/${convoId}`] = null;
                    firebase.database()
                      .ref()
                      .update(updateData);
                    setCardProperties({ ...cardProperties, leaveGroupModal: false });
                    updateChatMembershipStatus();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        aria-labelledby="show group info"
        aria-describedby="show memebers of a group chat and other information."
        className="center-modal"
        open={(groupMessagePopover && groupChatProfileInfo) || false}
        onClose={closeGroupChatProfileInfo}
        disableAutoFocus
        disableEnforceFocus
      >
        <div
          className="modal-content-wrapper"
          style={{
            backgroundColor: Colors.white,
            width: '356px',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', position: 'fixed', width: 'inherit', backgroundColor: Colors.white, zIndex: 1000 }}>
            <Typography style={{ ...GlobalStyles.titleBold, color: Colors.black, fontSize: '18px', margin: '10px' }}>Edit</Typography>
            <ClearCard cancelEvent={closeGroupChatProfileInfo} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '70px' }}>
          
            <AvatarDisplay
            imgSrc={imgUrl}
            imgStyles={{ width: '122px', height: '122px', borderRadius: '61px'}}
            imgAlt={'group-avatar'}
            userName={name}
            textStyles={{
              ...GlobalStyles.bodyMedium,
              color: Colors.grey2,
              textAlign: 'center',
              borderRadius: '50px',
              border: '2px solid',
              display: 'flex',
              alignItems: 'center',
              height: '60px',
              width: '60px',
              justifyContent: 'center',
              margin: 'auto',
              marginTop: '5px',
              fontSize: '24px'
          }}
          />
          </div>
          <Typography style={{ ...GlobalStyles.bodyMedium, marginTop: '24px', marginBottom: '-8px', textAlign: 'center', color: Colors.grey1, fontWeight: 'bold' }}>Description</Typography>
          <Typography style={{ ...GlobalStyles.bodyRegular, margin: '10px 16px', textAlign: 'justify', color: Colors.grey2 }}>A world of yoga, built around music and fun memories. This is the official online group for the {currentChatToggleHeader}.</Typography>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '14px 16px' }}>
            <Typography style={{ ...GlobalStyles.bodyMedium, color: Colors.grey2, alignSelf: 'center' }}>Notifications</Typography>
            <FormControlLabel
              control={
                <Switch checked={mutedConvoIds?.includes(convoId)} onChange={() => {
                  if (mutedConvoIds?.includes(convoId)) {
                    return setCardProperties({ ...cardProperties, unmuteNotification: true });
                  }
                  handleMuteModal();
                }} name='notification' color="primary" />
              }
              style={{ textAlign: 'right', marginRight: '-15px' }}
            />
          </div>
          {
            uid === chatOwnerId && (
              <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                <div style={{ flex: 1, backgroundColor: !showPendingRequest ? Colors.pri_color : Colors.white, padding: 15, cursor: 'pointer' }} onClick={() => setShowPendingRequest(false)}>
                  <Typography style={{ ...GlobalStyles.bodyMedium, color: !showPendingRequest ? Colors.white : Colors.grey3 }}>Members</Typography>
                </div>
                <div style={{ flex: 1, padding: 15, cursor: 'pointer', backgroundColor: showPendingRequest ? Colors.pri_color : Colors.white, }} onClick={() => setShowPendingRequest(true)}>
                  <Typography style={{ ...GlobalStyles.bodyMedium, color: showPendingRequest ? Colors.white : Colors.grey3 }}> Requests &nbsp;
                    {
                      pendingGroupChatMembers.length && !showPendingRequest && (
                        <span style={{ backgroundColor: Colors.pri_color, color: Colors.white, borderRadius: '16px', padding: '2px 6px', marginLeft: '4px' }}>{pendingGroupChatMembers.length}</span>
                      )}
                  </Typography>
                </div>
              </div>
            )}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '16px', backgroundColor: Colors.brightGrey }}>
            <Typography style={{ ...GlobalStyles.bodyMedium, color: Colors.grey2 }}>{showPendingRequest ? `${pendingGroupChatMembers.length} requests` : `${acceptedGroupChatMembers.length} Members`}</Typography>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {/* <span class="material-icons" style={{ color: Colors.grey2 }}>
                  search
                </span>
                <span style={{ alignSelf: 'center', color: Colors.grey2 }}>Search</span> */}
            </div>
          </div>
          {
            !showPendingRequest && (
              <React.Fragment>
                {
                  uid === chatOwnerId && (
                    <div onClick={() => setShowGroupChatAddMemberOptions(true)}
                      style={{ display: 'flex', flexDirection: 'row', padding: '16px', cursor: 'pointer' }}>
                      <img src={ImgAddMember} alt='add-member' />
                      <Typography style={{ ...GlobalStyles.bodyRegular, marginLeft: '29px', color: Colors.pri_color, alignSelf: 'center' }}>Add Member</Typography>
                    </div>
                  )}
                {
                  acceptedGroupChatMembers.sort(data => !(data.uid === uid)).map((data, index) => {
                    return (
                      <GroupChatMemberCard data={data} key={index} recipientId={data.uid} convoId={convoId} mutedMembers={mutedMembers}
                        handleMutedMembers={handleMutedMembers} dmEntryPoint={dmEntryPoint} uid={uid} closeGroupChatProfileInfo={closeGroupChatProfileInfo} acceptedGroupChatMembers={acceptedGroupChatMembers}
                        setAcceptedGroupChatMembers={setAcceptedGroupChatMembers} classId={classId} teacherId={chatOwnerId} />
                    );
                  })
                }
              </React.Fragment>
            )}
          {
            showPendingRequest && pendingGroupChatMembers.filter(data => !hideGroupChatMembers.includes(data.documentId)).map((data, index) => {
              return (<GroupChatPendingInviteCard key={index} data={data} setHideGroupChatMembers={setHideGroupChatMembers} acceptedGroupChatMembers={acceptedGroupChatMembers} setAcceptedGroupChatMembers={setAcceptedGroupChatMembers} hideGroupChatMembers={hideGroupChatMembers} recipientId={data.uid} pendingGroupChatMembers={pendingGroupChatMembers} setPendingGroupChatMembers={setPendingGroupChatMembers} />);
            })
          }
        </div>
      </Modal>
      <Modal
        aria-labelledby="show group info"
        aria-describedby="show memebers of a group chat and other information."
        className="center-modal"
        open={showGroupChatAddMemberOptions}
        onClose={() => setShowGroupChatAddMemberOptions(false)}
        disableAutoFocus
        disableEnforceFocus
      >
        <div
          className="modal-content-wrapper"
          style={{
            backgroundColor: Colors.white,
            width: '356px',
            borderRadius: '10px',
          }}
        >
          <ClearCard cancelEvent={() => setShowGroupChatAddMemberOptions(false)} />
          <Typography style={{ ...GlobalStyles.titleBold, fontSize: '18px', textAlign: 'center', color: Colors.black, marginTop: '48px' }}>Add member</Typography>
          <div style={{ display: 'flex', flexDirection: 'row', margin: '36px 32px 48px 32px' }}>
            <div onClick={() => {
              setOpenSnackBar(true);
              const baseUrl = UtilFX.getWebsiteBaseUrl();
              const url = baseUrl + `join/${convoId}`;
              UtilFX.copyToClipboard(url);
            }} style={{ backgroundColor: Colors.lightBlue, borderRadius: '25px', width: '139px', flexDirection: 'column', height: '157px', justifyContent: 'center', alignItems: 'center', display: 'flex', cursor: 'pointer' }}>
              <img src={ImgCopyLink} alt='copy-link' />
              <Typography style={{ ...GlobalStyles.bodyMedium, marginTop: '16px', color: Colors.grey1 }}>Copy link</Typography>
            </div>
            <div onClick={() => {
              setGroupChatProfileInfo(false);
              setShowGroupChatAddMemberOptions(false);
              setCardProperties({ ...cardProperties, yogiPickerModal: true });
            }} style={{ backgroundColor: Colors.lightYellow, borderRadius: '25px', width: '139px', height: '157px', justifyContent: 'center', marginLeft: '16px', alignItems: 'center', flexDirection: 'column', display: 'flex', cursor: 'pointer' }}>
              <img src={ImgSearch} alt='search-member' />
              <Typography style={{ ...GlobalStyles.bodyMedium, marginTop: '16px', color: Colors.grey1 }}>Search</Typography>
            </div>
          </div>
          {
            openSnackBar && (
              <SnackBarComponent text='Link copied to clipboard' closeSnackBar={() => setOpenSnackBar(false)} showCancelIcon={false} />
            )
          }
        </div>
      </Modal>
      <Modal
        aria-labelledby="search users"
        aria-describedby="Search for a user to send a message to."
        className="center-modal"
        open={Boolean(cardProperties.yogiPickerModal)}
        onClose={() => setCardProperties({ ...cardProperties, yogiPickerModal: false })}
        disableAutoFocus
        disableEnforceFocus
      >
        <div
          className="modal-content-wrapper group-chat"
          style={{
            backgroundColor: Colors.white,
            width: '356px',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)' }}>
            <Typography style={{ ...GlobalStyles.titleBold, color: Colors.black, fontSize: '18px', margin: '10px' }}>Add member</Typography>
            {
              !selectedYogi && (
                <ClearCard back={true} cancelEvent={() => setCardProperties({ ...cardProperties, yogiPickerModal: false })} />
              )}
            {
              selectedYogi && (
      
                <div onClick={async () => {
                  if(filterGroupMemberToAdd().includes(selectedYogi.objectID)){
                    if(memberStatus === 'invited'){
                      UtilFX.toastError('There is a pending invite already', {toastId: 'pending invite'})
                      return;
                      
                    }
                     else if(memberStatus === 'pending'){
                      UtilFX.toastError('There is a pending request for this user.', {toastId: 'pending request'})
                      return;
                    } else {
                      if(memberStatus === 'accepted'){
                        UtilFX.toastError('User is a member of the group.', {toastId: 'existing request'})
                        return

                      }
                    } 
                  } else {
                     const dataCbu = {};
                  dataCbu[`convobyusers/${selectedYogi.objectID
                    }/${convoId}`] = true;
                  await firebase.database().ref().update(dataCbu);
                  const memberDataCm = {
                    uid: selectedYogi.objectID,
                    date_added: Date.now(),
                    convo_id: convoId,
                    status: 'invited'
                  };
                  await firestore.collection('ConvoMembers').add(memberDataCm);
                  setSelectedYogi(null)
                  setCardProperties({ ...cardProperties, yogiPickerModal: false });
                  UtilFX.toastSuccess('Invite sent successfully.', {toastId: 'success request'})
                  openNewMemberSnackBar();
                  }          
                }} style={{ cursor: 'pointer' }}>
                  <Typography style={{ ...GlobalStyles.titleBold, color: Colors.pri_color, fontSize: '18px', margin: '10px' }}>Add</Typography>
                </div>
              )}
          </div>
          <InstantSearch
            appId={Algolia.ApplicationId}
            apiKey={Algolia.SearchApiKey}
            indexName="yogis"
            onSearchStateChange={(ossc_props) => { }}
          >
            {/* Use widgets here */}
            {
              !selectedYogi && (
                <SearchBox
                  translations={{
                    placeholder: 'Search by name...',
                  }}
                />
              )}
            {
              selectedYogi && (
                <div style={{ display: 'flex', flexDirection: 'row', padding: '16px', borderBottom: `1px solid ${Colors.grey4}` }}>
                  <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: Colors.pri_color, borderRadius: '20px', marginRight: '10px' }}>
                    <img src={selectedYogi.profile_photo_url} style={{ width: '32px', height: '32px', borderRadius: '50%', marginRight: '6px' }} alt='yogi-profile' />
                    <Typography style={{ ...GlobalStyles.bodyRegular, color: Colors.white, alignSelf: 'center', marginLeft: '3px', fontSize: '10px', marginRight: '6px' }}>{selectedYogi.fullname}</Typography>
                  </div>
                  <Typography
                    onClick={() => setSelectedYogi(null)}
                    style={{
                      color: Colors.grey3,
                      fontFamily: 'Roboto',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '14px',
                      cursor: 'pointer',
                      marginLeft: '16px',
                      alignSelf: 'center'
                    }}
                  >
                    Cancel
                  </Typography>
                </div>
              )}
            {
              !selectedYogi && (
                <Index indexName="yogis">
                  <Configure
                    hitsPerPage={6}
                    filters={filterString}
                  />
                  <div style={{ marginTop: 15 }}>
                    <GroupChatHitsYogis
                      {...props}
                      onMark={(item) => {
                        getStatus(item)
                      }}
                    />
                  </div>
                </Index>
              )}
          </InstantSearch>
        </div>
      </Modal>
      <Modal
        disableAutoFocus
        disableEnforceFocus
        aria-labelledby="pin-message-modal"
        aria-describedby="pin-message-modal-or-cancel"
        className="center-modal"
        open={cardProperties.pinMessage}
        onClose={closePinMessageModal}
      >
        <div
          style={{
            width: '356px',
            backgroundColor: Colors.white,
            margin: 'auto',
            marginTop: '70px',
            borderRadius: '8px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                margin: '20px 58px 24px 58px',
                textAlign: 'center',
              }}
            >
              <Typography
                style={{
                  ...GlobalStyles.titleBold,
                  fontSize: '18px',
                  color: Colors.grey1,
                }}
              >
                Replace pinned message
              </Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '24px',
                }}
              >
                <ButtonComponent
                  value="CANCEL"
                  buttonStyle={{
                    backgroundColor: Colors.grey6,
                    color: Colors.grey1,
                    borderRadius: '10px',
                    marginRight: '10px',
                    border: 0,
                  }}
                  onClick={closePinMessageModal}
                />
                <ButtonComponent
                  value="REPLACE"
                  buttonStyle={{
                    backgroundColor: Colors.pri_color,
                    color: Colors.white,
                    borderRadius: '10px',
                    border: 0,
                  }}
                  onClick={() => {
                    const messageId = pinnedMessages[0];
                    firestore.collection('ConvoMessages').doc(messageId).update({
                      date_pinned: null
                    }).then(() => {
                      handlePinMessage();
                      closePinMessageModal();
                    }).catch(err => {
                      console.error('Error updating document: ', err);
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Snackbar
        open={cardProperties.copyMessage}
        autoHideDuration={2000}
        onClose={() =>
          setCardProperties({
            ...cardProperties,
            copyMessage: !cardProperties.copyMessage,
          })
        }
      >
        <MuiAlert
          elevation={6}
          style={{ color: Colors.white, backgroundColor: Colors.pinkClosest }}
        >
          {(cardProperties.copyMessage && 'Message copied') || ''}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={cardProperties.archiveSnackbar}
        autoHideDuration={2000}
        onClose={() =>
          setCardProperties({
            ...cardProperties,
            archiveSnackbar: false,
          })
        }
      >
        <MuiAlert
          elevation={6}
          style={{ color: Colors.white, backgroundColor: Colors.pinkClosest }}
        >
          Successfully archived
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default TitleCardOptions;
