import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Colors, GlobalStyles } from '../../../utils';
import ArchiveIcon from './svg/archive.svg';
import BookmarkIcon from './svg/star_outline_white_36dp.svg';
// import StarOutlineIcon from '@material-ui/icons/StarOutline';
import '../../../App.css';

const InboxHeader = ({
  title,
  mobileFirstView,
  archive,
  goBack,
  navigation
}) => {
  return (
    <div className={(mobileFirstView && 'chat-mobile-view') || null}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: Colors.primaryColor2,
          justifyContent: 'space-between',
          padding: '16px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {archive && (
            <span
              className="material-icons"
              style={{ marginRight: '20px', cursor: 'pointer' }}
              onClick={goBack}
            >
              arrow_back
            </span>
          )}
          <Typography style={{ ...GlobalStyles.titleBold, fontSize: '18px' }}>
            {(archive && 'Archived chats') || title}
          </Typography>
        </div>
        {!archive && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
              <img src={ArchiveIcon} style={{ cursor: 'pointer', marginRight: '24px', width: '25px', height: '25px' }} onClick={() => navigation('/chat/archive')} alt='archive' />
              <img src={BookmarkIcon} style={{ cursor: 'pointer', width: '25px', height: '25px' }} onClick={() => navigation('/chat/bookmark')} alt='bookmark' />
              {/* <StarOutlineIcon style={{ cursor: 'pointer' }} onClick={() => navigation('/chat/bookmark')} /> */}
              {/* <span class="material-icons" style={{ width: '25px', height: '25px' }}>
                star_outline
              </span> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default InboxHeader;
