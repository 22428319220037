// this file shows the the list of smaller item card used in classesmanage
import React from 'react';
import Typography from '@material-ui/core/Typography';
import ShareIcon from '@material-ui/icons/Share';
import CreateIcon from '@material-ui/icons/Create';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PeopleIcon from '@material-ui/icons/People';
import { Colors, GlobalStyles } from '../../utils';
import styled from 'styled-components';

/*
the single card shows a single row of the an item that contains an icon and a text for each card
*/
export const SingleCard = ({ iconName, title, color, }) => (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', marginLeft: '23px', marginRight: '23px', marginTop: '8px' }}>
        <span className="material-icons" style={{ color: Colors.grey4, marginRight: '17px', fontSize: 20 }}>
            {iconName}
        </span>
        <Typography style={{ ...GlobalStyles.bodyRegular, color: color || Colors.grey1, textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
            {' '}
            {title}
            {' '}
        </Typography>
    </div>
);

/*
the bottom card shows a single row of the an item that contains an icon, text that contains the status message (STUBBED or CANCELED)
*/
export const BottomCard = ({ iconName, iconColor, title, backgroundColor }) => (
    <div style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: backgroundColor,
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px'
    }}
    >
        <span className="material-icons" style={{ marginRight: '17px', alignSelf: 'center', fontSize: 20, color: iconColor }}>
            {
                iconName
            }
        </span>
        <Typography style={{ color: Colors.white, padding: '13px', ...GlobalStyles.bodyMedium }}>
            {
                title
            }
        </Typography>
    </div>
);

/*
the icon card shows a single row of the an item that contains an icon and text customised to be used in other areas
*/

const ButtonIcon = styled.div`
    display: flex;
    flex-direction: row; 
    width: ${props => props.size || '95px'};
    justify-content: center;
    background-color: #F6F6F6; 
    border-radius: 12px;
    padding: 5px 0;
    .icon{
        margin-right: 10px;
        font-size: 20px;
        color: #4F4F4F;
    };
    &:hover .icon {
        color: #1FA3FF;
    }
`;

export const IconCard = (props) => {
    const { iconName, eventHandler, title, size } = props;

    return (
        <ButtonIcon size={size} onClick={eventHandler} >
            {iconName === 'share' &&
                <ShareIcon className='icon' />
            }
            {
                iconName === 'edit' &&
                <CreateIcon className='icon' />
            }
            {
                iconName === 'clone' &&
                <FileCopyIcon className='icon' />
            }
            {
                iconName === 'people' &&
                <PeopleIcon className='icon' />
            }
            <Typography style={{ ...GlobalStyles.bodyRegular, color: Colors.grey2 }}>{title}</Typography>
        </ButtonIcon>
    );
};
