import React, { Component } from 'react';

import { Link, Redirect } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';

// import { withStyles, withTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import TextFieldComponent from './common/TextFieldComponent';
// import IconButton from '@material-ui/core/IconButton';
// import Icon from '@material-ui/core/Icon';
// import SaveIcon from '@material-ui/icons/Save';
// import MdStarBorder from '@material-ui/icons/StarBorder';
// import MdEmail from '@material-ui/icons/Email';
// import MdLock from '@material-ui/icons/Lock';
import ImgProfilePhotoLoader from '../profile_image_loader.svg';
// import { SocialIcon } from 'react-social-icons';
// import TextField from '@material-ui/core/TextField';
import FileUploader from './common/FileUploader';
// import axios from 'axios';
// import moment from 'moment';
import {
  UtilFX,
  DataFX,
  // Colors,
  GlobalContext,
  // GlobalStyles,
  // AlertBox,
  DefaultProfilePhotoUrl,
  GifLoader,
  NumberOfLimitedCommunityClasses,
} from '../utils';
import { firebase, database } from '../fire';
// import { LoginSignupForgotAuthUI } from "../App";
import { Colors, GlobalStyles } from '../utils/index';
import ButtonComponent from './common/ButtonComponent';
import '../App.css';
import AvatarDisplay from './AvatarDisplay';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.generatedUsername = this.userNameGenerator();
    this.state = {
      pendingYogisResults: [],
      pendingClassOrEventResults: [],
      invites_obj: {},
      invites_arr: [],
      invites_count: 0,
      invites_unseen_count: 0,
      username: null,
      fullname: '',
      fileUploadProgress: false,
    };
  }

  componentDidMount() {
    this._mounted = true;
    const { context: { userAuthenticated: fyu } = {} } = this.props;
    this.determinePendingItems();
    this.tallyInviteData();
    if (fyu && fyu.uid) {
      UtilFX.setupTeacherGroupChat({ uid: fyu.uid, username: fyu.fullname, profilePhotoUrl: fyu.profile_photo_url });
      UtilFX.getUserContentShownHistory(fyu.uid).then((response) =>
        this.setState({ showAddAClassModal: response && response.add_a_class })
      );
      UtilFX.getClassesAndEventsCount(fyu.uid).then((response) =>
        this.setState({ classCount: response })
      );
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidUpdate(oldProps, oldState) {
    const { context: { userAuthenticated: fyu } = {} } = this.props;
    const { context: { userAuthenticated: oldFyu } = {} } = oldProps;

    // console.log("the states checked", fyu, oldFyu);
    if (
      fyu &&
      fyu.uid &&
      !(oldFyu && oldFyu.uid) // user/student data chagned. update registration
    ) {
      this.tallyInviteData();
    }
  }

  tallyInviteData = () => {
    const { context: { userAuthenticated } = {} } = this.props;
    const { uid } = userAuthenticated || {};
    if (uid) {
      firebase
        .database()
        .ref(`invitesbystudentid/${uid}`)
        .orderByChild('date_created')
        .once('value', (snap) => {
          let invites_count = 0;
          let invites_unseen_count = 0;
          snap.forEach((v) => {
            const _i = v.val();
            if (!(_i.date_registered || _i.date_deleted)) {
              invites_count += 1;
            }
            if (
              _i.date_created > (userAuthenticated.date_invites_last_seen || 0)
            ) {
              invites_unseen_count += 1;
            }
          });
          this._mounted &&
            this.setState({ invites_count, invites_unseen_count });
        });
    }
  };

  determinePendingAddClass = async (uid) => {
    const pendingAddClass =
      (window &&
        window.localStorage &&
        window.localStorage.getItem('pendingAddClass')) ||
      '';
    if (pendingAddClass.length) {
      window && window.localStorage.setItem('pendingAddClass', '');
      await UtilFX.getFullCommunityClassesCount(uid).then((result) =>
        this.setState({ communityClassCount: result })
      );
      await UtilFX.getFullPersonalClassesCount(uid).then((result) =>
        this.setState({ personalClassCount: result })
      );
      await UtilFX.getFYuser(uid).then((fyu = {}) => {
        const { config = {} } = fyu;
        this.setState({
          isTeacherPro: !!(config.paid_plan || '').includes('teacherpro'),
        });
      });
      const classData = JSON.parse(pendingAddClass);
      classData.uid = uid;
      classData.date_created = firebase.database.ServerValue.TIMESTAMP;
      if (
        this.state.communityClassCount < NumberOfLimitedCommunityClasses ||
        this.state.isTeacherPro ||
        this.state.personalClassCount === 0
      ) {
        await firebase
          .database()
          .ref()
          .child('classesbyuserid/' + uid)
          .push(classData)
          .then(() => {
            UtilFX.logAnalytics('pending class created', { ...classData });
            this.props.history.push('/classesmanage');
            // UtilFX.toastSuccess('New class listing saved!', { toastId: 'New class listing saved!' });
          })
          .catch((err) => {
            console.warn('issue creating class', err);
            UtilFX.toastError(
              'There was a problem saving your class. Please try again',
              {
                toastId:
                  'There was a problem saving your class. Please try again',
              }
            );
          });
      }
    }
  };

  determinePendingItems = async () => {
    const { history } = this.props;
    //class registrations get bigger priority. if that was first go there first and register
    const classesOrEventsToAdd =
      (window &&
        window.localStorage &&
        window.localStorage.getItem('pendingAddClassOrEvent')) ||
      '';

    if (classesOrEventsToAdd.length) {
      // console.info("There are yogis to add for this user since take last and send, also need to remove later", classesOrEventsToAdd);
      const coeArr = classesOrEventsToAdd
        .split(',')
        .filter((v) => v && v.length);
      // console.log("cleaned up pyogi id arr", coeArr);

      if (coeArr.length) {
        const coeItemArr = await Promise.all(
          coeArr.map(async (id) => {
            const isClassMarker = id.indexOf('_CLASS-ID_=') === 0;
            // const isEventMarker = id.indexOf("_EVENT-ID_=") === 0;
            let classInfo;
            let classId;
            let teacherId;
            if (isClassMarker) {
              //setup data for api call for the classInfo
              classId = id.split('_CLASS-ID_=')[1].split('_TEACHER-ID_=')[0];
              teacherId = id.split('_TEACHER-ID_=')[1];
            }
            UtilFX.getIsDevEnv();
            // && console.log("id and teacherId and event/classid", id, teacherId, classId);

            if (!teacherId || !classId) {
              console.warn(
                'missing some data, not ading this in',
                teacherId,
                classId,
                id
              );
              return;
            }

            const teacherUsername = await UtilFX.getUsername(teacherId);
            classInfo = {
              class_id: classId,
              teacher_username: teacherUsername,
              teacher_id: teacherId,
            };

            return classInfo || {};
          })
        );

        const [item = {}] = coeItemArr;
        // console.log({ item, coeItemArr });

        if (item.class_id && item.teacher_username) {
          const classOrEventUrl = `/${item.teacher_username}/class/${item.class_id}`;
          // console.log("the state will have this item set", coeItemArr);
          //clear out the storage as we are about to redirect you there with state
          window && window.localStorage.setItem('pendingAddClassOrEvent', '');
          return history.push(classOrEventUrl, { action: 'register' });
        }
      }
    }

    //if didnt redirect already then try for yogi To Add. This can be the revisit erhaps
    const yogisToAdd =
      (window &&
        window.localStorage &&
        window.localStorage.getItem('pendingAddYogis')) ||
      '';
    if (yogisToAdd.length) {
      // console.info("There are yogis to add for this user since take last and send, also need to remove later", yogisToAdd);
      const pyArr = yogisToAdd.split(',').filter((v) => v && v.length);
      // console.log("cleaned up pyogi id arr", pyArr);

      if (pyArr.length) {
        const pyItemArr = await Promise.all(
          pyArr.map(async (id) => {
            const uname = await UtilFX.getUsername(id);
            const profile_photo_url = await UtilFX.getProfilePhotoUrl(id);
            // console.log("whats teh uname and pohoto url stuff", uname, profile_photo_url);
            return {
              username: uname,
              profile_photo_url,
              uid: id,
            };
          })
        );

        // console.log("the state after setting", pyItemArr);
        this._mounted &&
          this.setState({
            pendingYogisResults: pyItemArr,
          });
      }
    }
  };

  PendingYogisMarkup = (props) => {
    const { pendingYogisResults } = this.state;
    const markup = pendingYogisResults.map((item, i) => (
      // console.log("uanme and pofile phot url", item);
      <div
        key={`py_${i}_${item.uid}`}
        style={{
          padding: 5,
          marginBottom: 2,
          backgroundColor: '#fff',
          color: Colors.pri_color,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Link
          style={{
            display: 'inline-flex',
            flex: 1,
          }}
          to={`/${item.username}`}
          onClick={(e) => {
            e.preventDefault();
            const { pendingYogisResults = [] } = this.state || {};
            const { history } = this.props;
            // console.log("now get rid of localStorage val and push", pendingYogisResults, item, this.state, this.props);
            const newPendingAddYogis = pendingYogisResults.filter(
              (v) => v.uid !== item.uid
            );
            const updatedStorageOfPendingAddYogis = newPendingAddYogis
              .map((r) => r.uid)
              .join(',');
            window &&
              window.localStorage.setItem(
                'pendingAddYogis',
                updatedStorageOfPendingAddYogis
              );
            this.setState(
              {
                pendingYogisResults: newPendingAddYogis,
              },
              () => {
                history.push(`/${item.username}`);
              }
            );
          }}
        >
          <div
            style={{
              display: 'inline-block',
            }}
          >
            <img
              alt="your home face"
              style={{
                display: 'inline-block',
                width: 30,
                borderRadius: '50%',
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: Colors.pri_color,
                verticalAlign: 'middle',
                marginRight: 10,
              }}
              src={item.profile_photo_url}
            />
            <span style={{ display: 'inline-block' }}>
              Revisit @{item.username}
            </span>
          </div>
        </Link>
        <div style={{ display: 'inline-flex', alignSelf: 'right' }}>
          <button
            onClick={() => {
              // console.log("remove this id from storage and object from state (cause refresh", item);
              const { pendingYogisResults = [] } = this.state || {};
              // console.log("now get rid of localStorage val and push", pendingYogisResults, item);
              const newPendingAddYogis = pendingYogisResults.filter(
                (v) => v.uid !== item.uid
              );
              const updatedStorageOfPendingAddYogis = newPendingAddYogis
                .map((r) => r.uid)
                .join(',');
              window &&
                window.localStorage.setItem(
                  'pendingAddYogis',
                  updatedStorageOfPendingAddYogis
                );
              this.setState({
                pendingYogisResults: newPendingAddYogis,
              });
            }}
            size="small"
          >
            <span role="img" aria-label="dismiss revisit option">
              ❌
            </span>
          </button>
        </div>
      </div>
    ));

    return <div>{markup}</div>;
  };

  _HomeButtonStyle = {
    borderTop: '1px #fff solid',
    borderRight: '1px #fff solid',
    borderLeft: '1px #fff solid',
    borderBottom: '1px #fff solid',
    borderRadius: 5,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    paddingBottom: 15,
    paddingTop: 15,
  };

  gotoProfileUrl = (e) => {
    // e.preventDefault();
    this.props.history.push('/profile');
  };

  handleChange = (e) => {
    const incomingVal = e.target.value;
    const targetName = e.target.name;

    const isFullname = targetName === 'fullname';
    const isUsername = targetName === 'username';

    this.setState({
      [targetName]: UtilFX.handleProfileValidation({
        isUsername,
        isFullname,
        incomingVal,
      }),
    });
  };

  userNameGenerator = () => {
    const userName =
      'fy_' +
      Date.now().toString().substring(0, 8) +
      Math.random().toString(36).substring(2, 10);
    return userName;
  };

  render() {
    return (
      <GlobalContext.Consumer>
        {(ctx) => {
          const {
            userAuthenticated = {},
            pendingInstagramEmail,
            inbox_unread_count,
            pristine,
          } = ctx || {};
          const { context: { refreshUserAuthenticated } = {}, history } =
            this.props || {};
          const {
            dissolveTeacherToolsButton,
            invites_count,
            invites_unseen_count,
            classCount,
          } = this.state || {};
          const firstName = (
            (userAuthenticated && userAuthenticated.fullname) ||
            ''
          ).split(' ')[0];
          let timeOfDayGreeting = `Good morning 🌤️ ${firstName} :)`;
          const hour = new Date().getHours();
          
          const onSignUpProfileUrl = DefaultProfilePhotoUrl === userAuthenticated?.profile_photo_url;
          // if (hour > 6)
          //     timeOfDayGreeting = "morning 🌅🌤️;
          if (hour > 12) { timeOfDayGreeting = `Good afternoon ☀️ ${firstName} :)`; }
          if (hour > 17) timeOfDayGreeting = `Good evening 🌙 ${firstName} :)`;
          if (hour < 4) { timeOfDayGreeting = `Hi ${firstName}! Enjoy the late night or early morning 🌙`; }

          if (
            Object.keys(userAuthenticated || {}).length < 1 &&
            pendingInstagramEmail
          ) {
            return <Redirect to="/login" />;
          }

          if (pristine) {
            return (
              <div>
                <GifLoader transparentBg />
              </div>
            );
          }
          if (userAuthenticated && userAuthenticated?.fullname && userAuthenticated?.username && userAuthenticated?.profile_photo_url) {
            const convoId = (window && window.localStorage && window.localStorage.getItem('inviteConvoId')) || '';
            if (convoId) {
              window && window.localStorage && window.localStorage.setItem('inviteConvoId', '');
              return <Redirect to={`/join/${convoId}`} />;
            }
          }
          return (
            <Grid container justify="center">
              {userAuthenticated && (
                <React.Fragment>
                  {(!userAuthenticated.fullname ||
                    !userAuthenticated.username ||
                    !userAuthenticated.profile_photo_url) && (
                      <div className="custom-modal">
                        <div
                          className="custom-modal-wrapper"
                          style={{
                            backgroundColor: Colors.pri_color,
                            padding: '16px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <div>
                             
                             <AvatarDisplay
                                imgSrc={userAuthenticated &&
                                  userAuthenticated.profile_photo_url}
                                imgAlt={'user_profile_photo'} 
                                imgStyles={{
                                  borderRadius: '50%',
                                  height: '90px',
                                  width: '90px',
                                }}
                              textStyles={{color: 'inherit'}}
                              userName={userAuthenticated?.fullname}
                              />
                              
                            </div>

                            {this.state.fileUploadProgress &&
                              !(
                                userAuthenticated &&
                                userAuthenticated.profile_photo_url
                              ) ? (
                              <img
                                src={ImgProfilePhotoLoader}
                                alt="profile_photo_upload_loader"
                                style={{ marginTop: '14px' }}
                              />
                            ) : (
                              <label
                                style={{
                                  ...GlobalStyles.bodyRegular,
                                  color: Colors.white,
                                  fontFamily: 'Roboto',
                                  border: '1px solid #258DF0',
                                  textAlign: 'center',
                                  backgroundColor: '#258DF0',
                                  borderRadius: '20px',
                                  marginTop: '14px',
                                  padding: '8px 17px',
                                  cursor: 'pointer',
                                }}
                              >
                                {userAuthenticated &&
                                  userAuthenticated.profile_photo_url
                                  ? 'Upload another image'
                                  : 'Upload a profile image'}
                                <FileUploader
                                  handleFileUploadProgress={(val) =>
                                    this.setState({ fileUploadProgress: val })
                                  }
                                  userAuthenticated={userAuthenticated}
                                  refreshUserAuthenticated={
                                    refreshUserAuthenticated
                                  }
                                />
                              </label>
                            )}
                            {userAuthenticated &&
                              userAuthenticated.profile_photo_url && (
                                <div
                                  onClick={() => {
                                    const dataToUpdate = {};
                                    dataToUpdate[
                                      `users/${userAuthenticated.uid}/profile_photo_url`
                                    ] = null;
                                    return database
                                      .ref()
                                      .update(dataToUpdate)
                                      .then(
                                        () =>
                                          refreshUserAuthenticated &&
                                          refreshUserAuthenticated()
                                      );
                                  }}
                                  style={{
                                    backgroundColor: 'transparent',
                                    borderRadius: '20px',
                                    marginTop: '10px',
                                    padding: '5px 40px',
                                    cursor: 'pointer',
                                    border: '1px solid white',
                                  }}
                                >
                                  <Typography
                                    style={{
                                      ...GlobalStyles.bodyRegular,
                                      fontFamily: 'Roboto',
                                      color: Colors.white,
                                      textAlign: 'center',
                                    }}
                                  >
                                    Remove image
                                  </Typography>
                                </div>
                              )}
                          </div>
                          <div style={{ marginTop: '24px' }}>
                            <div>
                              <Typography
                                style={{
                                  ...GlobalStyles.bodyRegular,
                                  textAlign: 'left',
                                  color: '#9BDBFF',
                                }}
                              >
                                Full name (required)
                              </Typography>
                              <TextFieldComponent
                                onChange={this.handleChange}
                                name="fullname"
                                value={
                                  this.state.fullname ||
                                  userAuthenticated.fullname ||
                                  ''
                                }
                                inputStyle={{
                                  ...GlobalStyles.bodyRegular,
                                  border: '2px solid #187DC4',
                                  borderRadius: '10px',
                                  color: '#ffffff',
                                  marginTop: '8px',
                                }}
                              />
                            </div>
                            <div style={{ marginTop: '12px' }}>
                              <Typography
                                style={{
                                  ...GlobalStyles.bodyRegular,
                                  textAlign: 'left',
                                  color: '#9BDBFF',
                                }}
                              >
                                Username (required)
                              </Typography>
                              <TextFieldComponent
                                onChange={this.handleChange}
                                name="username"
                                value={
                                  this.state.username === '' ||
                                    this.state.username
                                    ? this.state.username
                                    : userAuthenticated.username ||
                                    this.generatedUsername
                                }
                                startAdornment={
                                  <InputAdornment
                                    position="start"
                                    style={{ color: '#FFFFFF' }}
                                  >
                                    <AlternateEmailIcon
                                      style={{ fontSize: '15px' }}
                                    />
                                    <span
                                      style={{
                                        color: '#187DC4',
                                        fontSize: '25px',
                                        marginLeft: '10px',
                                        marginTop: '-5px',
                                      }}
                                    >
                                      |
                                    </span>
                                  </InputAdornment>
                                }
                                inputStyle={{
                                  ...GlobalStyles.bodyRegular,
                                  border: '2px solid #187DC4',
                                  borderRadius: '10px',
                                  color: '#ffffff',
                                  marginTop: '8px',
                                }}
                              />
                            </div>
                          </div>
                          <ButtonComponent
                            onClick={async () => {
                              const username =
                                this.state.username === null
                                  ? this.generatedUsername
                                  : this.state.username;
                              if (!username || !this.state.fullname) {
                                return UtilFX.toastError(
                                  <Typography>
                                    Username and full name are required.
                                  </Typography>,
                                  {
                                    toastId: 'username and fullname update error',
                                  }
                                );
                              }
                              const usernameCheck = await UtilFX.checkForExistingUsername(
                                username
                              );
                              if (usernameCheck) {
                                return UtilFX.toastError(
                                  <Typography>
                                    Username already exists
                                  </Typography>,
                                  { toastId: 'username exists' }
                                );
                              }
                              DataFX.updateYogiProfileInfo({
                                uid: userAuthenticated.uid,
                                username: username,
                              })
                                .then(() =>
                                  DataFX.updateYogiProfileInfo({
                                    uid: userAuthenticated.uid,
                                    fullname: this.state.fullname,
                                  }).then(() => {
                                    if (!userAuthenticated.profile_photo_url) {
                                      DataFX.updateYogiProfileInfo({
                                        uid: userAuthenticated.uid,
                                        profile_photo_url: DefaultProfilePhotoUrl,
                                      });
                                    }
                                    return (
                                      refreshUserAuthenticated &&
                                      refreshUserAuthenticated()
                                    );
                                  })
                                )
                                .then(() => {
                                  this.determinePendingAddClass(
                                    userAuthenticated.uid
                                  );
                                })
                                .catch((error) =>
                                  console.error('error message', error)
                                );
                            }}
                            value="Save and continue"
                            buttonStyle={{
                              ...GlobalStyles.bodyRegular,
                              backgroundColor: '#258DF0',
                              borderRadius: '10px',
                              marginTop: '24px',
                              border: '1px solid #258DF0',
                              padding: '15px',
                              boxShadow: 'none',
                            }}
                          />
                        </div>
                      </div>
                    )}
                  {userAuthenticated.email &&
                    userAuthenticated.fullname &&
                    userAuthenticated.username && (
                      <Grid item xs={12}>
                        {classCount === 0 && !this.state.showAddAClassModal && (
                          <Grid container className="custom-modal">
                            <Grid
                              item
                              md={12}
                              xs={12}
                              className="custom-modal-wrapper"
                              style={{
                                margin: '10px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                              }}
                            >
                              <div
                                style={{
                                  borderRadius: '10px',
                                  backgroundColor: Colors.white,
                                  width: '350px',
                                  margin: 'auto',
                                  overflow: 'hidden',
                                }}
                              >
                                <div className="cta-add-a-class"></div>
                                <Typography
                                  style={{
                                    ...GlobalStyles.titleBold,
                                    color: Colors.grey1,
                                    marginTop: '19px',
                                  }}
                                >
                                  Welcome to FavYogis!
                                </Typography>
                                <Typography
                                  style={{
                                    ...GlobalStyles.bodyRegular,
                                    color: Colors.grey3,
                                    marginTop: '19px',
                                  }}
                                >
                                  Make it easy for your students to find and{' '}
                                  <br /> register for your classes.
                                </Typography>
                                <div style={{ margin: '20px' }}>
                                  <ButtonComponent
                                    onClick={async () => {
                                      await firebase
                                        .database()
                                        .ref(
                                          `showcontentbyuserid/${userAuthenticated.uid}/add_a_class`
                                        )
                                        .set(true);
                                      this.setState({
                                        showAddAClassModal: true,
                                      });
                                    }}
                                    value="START ADDING YOUR CLASSES"
                                    buttonStyle={{
                                      ...GlobalStyles.bodyMedium,
                                      backgroundColor: '#258DF0',
                                      borderRadius: '10px',
                                      border: '1px solid #258DF0',
                                      padding: '15px',
                                      boxShadow: 'none',
                                      marginBottom: '20px',
                                    }}
                                  />
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        )}
                        <this.PendingYogisMarkup {...this.props} />
                        <Typography
                          style={{
                            color: '#fff',
                            padding: '10px',
                            marginBottom: 20,
                            fontSize: '2.125rem',
                          }}
                          variant="h1"
                        >
                          {timeOfDayGreeting}
                        </Typography>
                        <div className="home-cta-list-wrapper">
                          {/* <this.TeacherList {...this.props} userData={userAuthenticated} /> */}
                          <div style={{ marginBottom: 20 }}>
                            <Link
                              to={userAuthenticated.username || '/'}
                              onClick={(e) => {
                                e.preventDefault();
                                if (userAuthenticated.username) {
                                  history.push(
                                    `/${userAuthenticated.username}`
                                  );
                                } else {
                                  window &&
                                    window.alert(
                                      'You must create a username to have a dedicated profile page.'
                                    );
                                }
                              }}
                              style={{textDecoration: "none", border: onSignUpProfileUrl ?? "2px solid", color: '#fff', }}
                            >

                          <AvatarDisplay
                                imgSrc={userAuthenticated.profile_photo_url}
                                imgClassName={'yogi-photo'} 
                                imgStyles={{
                                  borderRadius: '50%',
                                  height: 82,
                                  border: '4px white solid',
                                  position: 'relative',
                                  display: 'inline-block',
                                }}
                              textStyles={{
                                color: '#fff',
                                letterSpacing: '2px',
                                borderRadius:'50px',
                                width: '60px',
                                height: '60px',
                                border: '2px solid',
                                margin: 'auto',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '18px'                       
                              }}
                              userName={userAuthenticated?.fullname}
                              />
                              
                            </Link>
                          </div>
                          {Boolean(inbox_unread_count) && (
                            <div
                              id="home-inbox-btn"
                              style={{
                                ...this._HomeButtonStyle,
                                backgroundColor:
                                  inbox_unread_count > 0
                                    ? 'orange'
                                    : 'transparent',
                              }}
                              className="touchable-element"
                              // size="large"
                              onClick={() => {
                                history.push('/chat');
                              }}
                            >
                              <Typography
                                variant="h1"
                                style={{ fontSize: '1.5rem' }}
                              >
                                Chat
                                {/* ({inbox_unread_count}) */}
                                <Icon>inbox</Icon>
                              </Typography>
                            </div>
                          )}
                          {
                            <div
                              style={this._HomeButtonStyle}
                              // className="glow-effect"
                              className="touchable-element"
                              // size="large"
                              onClick={() => {
                                if (
                                  userAuthenticated.username &&
                                  userAuthenticated.profile_photo_url
                                ) {
                                  history.push('/classesmanage');
                                } else {
                                  UtilFX.toastSend(
                                    <Typography style={{ padding: 20 }}>
                                      You must have a username and photo to
                                      create a class. Tap here to setup your
                                      profile info.
                                    </Typography>,
                                    {
                                      toastId: 'set up your info',
                                      autoClose: 15000,
                                      onClick: this.gotoProfileUrl,
                                    }
                                  );
                                }
                              }}
                            >
                              <Typography
                                // className='cta-item'
                                variant="h1"
                                style={{ fontSize: '1.5rem' }}
                              >
                                {'Manage Classes'}
                              </Typography>
                            </div>
                          }
                          {
                            <div
                              id="home-schedule-btn"
                              style={this._HomeButtonStyle}
                              className="touchable-element"
                              // size="large"
                              onClick={() => {
                                history.push('/schedule');
                              }}
                            >
                              <Typography
                                variant="h1"
                                style={{ fontSize: '1.5rem' }}
                              >
                                Schedule
                              </Typography>
                            </div>
                          }

                          {userAuthenticated.teachers ? (
                            <div
                              id="home-teachers-btn"
                              style={this._HomeButtonStyle}
                              className="touchable-element"
                              // size="large"
                              onClick={() => {
                                history.push('/teachers');
                                // console.log("Send to teachers listing page");
                                // console.log("Page should also allow access to full text search and results to facilitate exploration of places and yogis");
                              }}
                            >
                              <Typography
                                variant="h1"
                                style={{ fontSize: '1.5rem' }}
                              >
                                Instructors
                              </Typography>
                            </div>
                          ) : null}
                          {Boolean(invites_count) && (
                            <div
                              id="home-invites-btn"
                              style={{
                                ...this._HomeButtonStyle,
                                backgroundColor:
                                  invites_unseen_count > 0
                                    ? 'green'
                                    : 'transparent',
                              }}
                              className="touchable-element"
                              // size="large"
                              onClick={() => {
                                history.push('/invites');
                              }}
                            >
                              <Typography
                                variant="h1"
                                style={{ fontSize: '1.5rem' }}
                              >
                                Invites ({invites_count})
                              </Typography>
                            </div>
                          )}
                          {false &&
                            (userAuthenticated.students ||
                              userAuthenticated.classes) ? (
                            <div
                              id="home-students-btn"
                              style={this._HomeButtonStyle}
                              className="touchable-element"
                              // size="large"
                              onClick={() => {
                                //history.push("/signup")
                                // console.log("Send to students listing page", dissolveTeacherToolsButton);
                                if (!dissolveTeacherToolsButton) {
                                  this.setState({
                                    dissolveTeacherToolsButton: true,
                                  });
                                }

                                // console.log("Page should also allow access to full text search and results to facilitate exploration of places and yogis");
                              }}
                            >
                              {dissolveTeacherToolsButton ? (
                                <div
                                  style={{
                                    flexDirection: 'row',
                                    display: 'flex',
                                  }}
                                  id="teacher-tools-dissolved-wrapper"
                                >
                                  <div
                                    onClick={() => {
                                      // console.log("Clicked me onceA")
                                      history.push('/students');
                                    }}
                                    style={{
                                      display: 'flex',
                                      flex: 1,
                                      flexDirection: 'column',
                                      border: '1px white dashed',
                                      paddingBottom: 15,
                                      paddingTop: 10,
                                    }}
                                  >
                                    <Typography
                                      variant="h1"
                                      style={{ fontSize: '1.5rem' }}
                                    >
                                      Students
                                    </Typography>
                                    <small>&nbsp;</small>
                                  </div>
                                  <div
                                    onClick={() => {
                                      // console.log("Clicked me onceB")
                                    }}
                                    style={{
                                      display: 'flex',
                                      flex: 1,
                                      flexDirection: 'column',
                                      border: '1px white dashed',
                                      paddingBottom: 15,
                                      paddingTop: 10,
                                    }}
                                  >
                                    <Typography
                                      variant="h1"
                                      style={{ fontSize: '1.5rem' }}
                                    >
                                      Classes
                                    </Typography>
                                    <small>&nbsp;</small>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{ paddingBottom: 15, paddingTop: 10 }}
                                >
                                  <Typography
                                    variant="h1"
                                    style={{ fontSize: '1.5rem' }}
                                  >
                                    Teacher Tools
                                  </Typography>
                                  <small>
                                    Manage classes, events & students.
                                  </small>
                                </div>
                              )}
                            </div>
                          ) : null}
                        </div>
                      </Grid>
                    )}
                </React.Fragment>
              )}

              {!userAuthenticated && <Redirect to="/signup" />}
            </Grid>
          );
        }}
      </GlobalContext.Consumer>
    );
  }
}
