import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const CheckBoxComponent = (props) => {
    const { onChange, name, color, disabled, checked, label, addMarginStyle = true, style } = props;
    return (<FormControlLabel
    control={
        <Checkbox
            onChange={onChange}
            name={name}
            color={color}
            disabled={disabled}
            checked={checked}
            style={{ padding: 0 }}
        />
    }
    style={{ margin: ((addMarginStyle && '10px') || null), ...style }}
    label={label}
    />);
};
export default CheckBoxComponent;
