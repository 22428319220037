import React, { Component } from 'react';

// import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import {
    UtilFX,
    Loading,
    // DataFX,
    Colors,
    // GlobalContext,
    // GlobalStyles,
    // AlertBox,
} from '../utils';
import { firebase } from '../fire';
import Conversation from './Conversation';
import YogiPicker from './YogiPicker';
import Profile from './Profile';

export default class Inbox extends Component {
    state = {
        pristine: true,
        isLoading: false,
        fyuser: null,
        user_teachers_obj: {},
        user_teachers_arr: [],
        convo_member_user_obj: {},
        convos: [],
        profile_complete: false,
        refreshing_conversations: false,
        openSearchModal: false,
    };

    componentDidMount() {
        this._mounted = true;
        this._getData();
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    componentDidUpdate(oldProps, oldState) {
        // this.determineYogiRelation();
        const { context = {} } = this.props || {};
        const newUserAuthenticated = (context && context.userAuthenticated) || {};
        const { context: oldContext = {} } = oldProps || {};
        const oldUserAuthenticated = (oldContext && oldContext.userAuthenticated) || {};

        // console.info("profile outisde cdu called. userAuthed? oldProps oldState, new Props & state", oldProps, oldState, this.props, this.state);

        const newUID = Object.keys(newUserAuthenticated || {}).uid;
        const oldUID = Object.keys(oldUserAuthenticated || {}).uid;
        if (!this.state.pristine &&
            !this.state.error &&
            (oldUID !== newUID)
        ) {
            // console.log("MADe it to update here!");
            this._getData();
        }
    }

    setupConvos = () => {
        //setup convos
        const { context = {} } = this.props || {};
        const _u = (context && context.userAuthenticated) || {};
        return firebase.database()
            .ref(`convobyusers/${_u.uid}`)
            // .orderByChild("date_created")
            // .limitToLast(25)
            .once('value', snap_cbu => {
                //let cm = snap_cm.val()
                //let existing_convo_id
                //search for a convo with this recipient
                // if(!this.state.recipient_id)
                //   return alert("no recip id found!")
                const cm_id_arr = [];
                const cm_id_obj = {};
                const convos_obj = {};
                const convos_by_this_user_obj = snap_cbu.val() || {};
                const convoids = Object.keys(convos_by_this_user_obj);
                // cm_id_arr = Object.keys(cm_id_obj)
                // console.log("members", cm_id_arr)
                // let proms = cm_id_arr.map(v=>{
                //   let profile_photo_url
                //   let username
                //   return firebase.database().ref("user/"+v+"/profile_photo_url").once('value',snap_cm_ppu=>{
                //     profile_photo_url = snap_cm_ppu.val()
                //   }).then(()=>{
                //     firebase.database().ref("user/"+v+"/username").once('value',snap_cm_un=>{
                //       username = snap_cm_un.val()
                //     })
                //   }).then(()=>{
                //     let ud={}
                //     return ud[v]={
                //       profile_photo_url:profile_photo_url,
                //       username:username,
                //     }
                //   })
                // })
                const proms_last_convomessages = convoids.map(v => {
                    return firebase.database()
                        .ref('convomessages/' + v)
                        .orderByKey()
                        .limitToLast(1)
                        .once('value');
                });
                const proms_convomembers = convoids.map(v => {
                    return firebase.database()
                        .ref('convomembers/' + v)
                        .once('value');
                });
                const proms = convoids.map(v => {
                    return firebase.database()
                        .ref('convometadata/' + v)
                        .once('value');
                });

                return Promise.all(proms_last_convomessages).then(async (snap_convo_last_msg) => {
                    return Promise.all(proms_convomembers).then(async (snap_cm) => {
                        return Promise.all(proms).then(async convo_md => {
                            //console.log(conv o_md)
                            convo_md = convo_md.map(v => {
                                const x = v.val();
                                if (x) {
                                    x.convoid = v.key;
                                    x.date_last_read =
                                        convos_by_this_user_obj[v.key].date_last_read;
                                    convos_obj[v.key] = x;
                                }
                                return x;
                            });

                            snap_convo_last_msg.forEach(clm => {
                                const lm = Object.values(clm.val() || {})[0] || {};
                                //console.log("last message VAL and KEY", lm, clm.key)
                                if (lm.date_deleted) {
                                    // console.log("about to update object ", convos_obj[clm.key]);
                                    convos_obj[clm.key].date_last_message_deleted =
                                        lm.date_deleted;
                                }
                            });

                            snap_cm.forEach(s => {
                                Object.keys(s.val() || {}).forEach(cmid => {
                                    //cm_id_obj[cmid]=true
                                    if (cm_id_arr.indexOf(cmid) < 0) { cm_id_arr.push(cmid); }

                                    //console.log(cm_id_obj)
                                    if (cmid !== _u.uid && convos_obj[s.key]) {
                                        convos_obj[s.key].other_uid = cmid;
                                    }
                                });
                            });
                            //console.log("convo member id array", cm_id_arr)
                            for (const cmid of cm_id_arr) {
                                const cm_userinfo = {
                                    username: await UtilFX.getUsername(cmid),
                                    profile_photo_url: await UtilFX.getProfilePhotoUrl(cmid)
                                };
                                cm_id_obj[cmid] = cm_userinfo;
                                //console.log("the stuff saved", cm_userinfo, cmid)
                            }

                            let convos_arr = Object.keys(convos_obj).map(k => {
                                return convos_obj[k];
                            });

                            //console.log("CONVO ARR", convos_arr)

                            convos_arr = convos_arr.sort((a, b) => {
                                //to make the larger time values the most recent so they show up first
                                return (b.date_created - a.date_created);
                            });

                            // console.log("CONVO ARR", convos_arr)

                            const firstConvoItem = convos_arr[0];
                            this.setState(
                                {
                                    convos: firstConvoItem ? [firstConvoItem] : [],
                                    convos_shadow: convos_arr,
                                    convo_member_user_obj: cm_id_obj
                                },
                                () => {
                                    this.setState({ pristine: false });
                                    //async staggered load for better performance
                                    {
                                        const ticker = () => {
                                            const tick = this.state.convos.length;
                                            const tickGrab = tick;
                                            const tickItem = this.state.convos_shadow[tickGrab];
                                            const oldConvos = this.state.convos;
                                            // console.info({ tick, tickItem });
                                            if (tickItem && !oldConvos.filter(v => v.convoid === tickItem.convoid).length) {
                                                const newConvos = oldConvos || [];
                                                newConvos.push(tickItem);
                                                this.setState({
                                                    convos: newConvos
                                                }, () => {
                                                    window && window.setTimeout(ticker, 250);
                                                });
                                            }
                                        };
                                        ticker();
                                    }
                                }
                            );
                        });
                    });
                }
                );
            })
            .then(() => {
                this.setState({ refreshing_conversations: false });
            }) //after checking for user
            .catch(err => {
                console.warn('error fetching msg data', err);
            });
    }

    _getData = async () => {
        const { context } = this.props || {};
        const { userAuthenticated: _u } = context || {};
        // console.log("_u", _u);
        if (!(_u && _u.uid)) {
            return;
        }

        // this._checkCompleteProfile();

        this.setupConvos();
        firebase.database()
            .ref(`convobyusers/${_u.uid}`)
            .on('value', snap_cbu => {
                this.setupConvos();
            });
    }

    _onRefresh_conversations = () => {
        this.setState({ refreshing_conversations: true });
        this.setupConvos();
    }

    _handleCloseSearchModal = () => {
        this.setState({ openSearchModal: false });
    }

    _handleOpenSearchModal = () => {
        this.setState({ openSearchModal: true });
    }

    renderRow_InboxEntry = raw_item => {
        const { context } = this.props || {};
        const { userAuthenticated: _u } = context || {};
        if (!raw_item) {
            return null;
        }
        const v = raw_item;
        const from_me = v.uid === _u.uid;
        const is_unread =
            (!from_me && v.date_created - v.date_last_read > 0) || !v.date_last_read;
        // let is_last_message_deleted = v.date_last_message_deleted ? true : false;
        return (
            <div
                key={raw_item.convoid}
                className="inbox-conversation-item"
                onClick={() => {
                    // console.log("implement click an item", { raw_item });
                    this.setState({
                        recipientId: v.other_uid,
                        recipientPhotoUrl: (this.state.convo_member_user_obj[v.other_uid] || {})
                            .profile_photo_url,
                        isActiveConvo: true,
                    });
                }}
            >
                <div
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        padding: 10,
                        // paddingRight: 2,
                        //marginBottom: 1,
                        marginHorizontal: 0,
                        //marginRight: (!from_me?100:5),
                        //marginLeft: (from_me?100:5),
                        backgroundColor: is_unread ? Colors.sec_color : Colors.pri_color, //:'#fff'),
                        //borderRadius:20,
                        borderColor: '#fff',
                        borderWidth: 0, // is_unread ? 2 : 0,
                        borderBottomWidth: 1,
                        borderStyle: 'solid',
                        textAlign: 'left',
                        justifyContent: 'center'
                        // borderBottomRightRadius:from_me?0:20,
                        // borderTopRightRadius:from_me?0:20,
                        // borderTopLeftRadius:!from_me?0:20,
                        // borderBottomLeftRadius:!from_me?0:20,
                    }}
                >
                    <div
                        style={{
                            display: 'inline-block',
                            backgroundColor: 'transparent',
                            marginRight: 5,
                            width: 80,
                            overflow: 'hidden'
                        }}
                    >
                        <div>
                            <img
                                className="yogi-photo"
                                style={{
                                    borderRadius: 25,
                                    height: 48,
                                    width: 48,
                                    border: '2px white solid',
                                    position: 'relative',
                                    // display: "block",
                                }}
                                src={(this.state.convo_member_user_obj[v.other_uid] || {}).profile_photo_url || 'https://placehold.it/100'}
                                alt="" />
                        </div>
                        <div
                            style={{
                                borderWidth: 0,
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Typography style={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                                @{//console.log("convo_member_user_obj", this.state.convo_member_user_obj)
                                    (this.state.convo_member_user_obj[v.other_uid] || {})
                                        .username || ''}
                            </Typography>
                            <Typography style={{ fontSize: '0.7rem' }}>
                                {UtilFX.getLoquaciousDate(v.date_created) /*.split(',')[0]*/}
                            </Typography>
                        </div>
                    </div>

                    <div style={{
                        display: 'inline-block',
                        width: 200,
                        overflow: 'hidden',
                        verticalAlign: 'top',
                        //height: "100%"
                    }}>

                        <Typography
                            style={{
                                //color:colors.sec_color,
                                fontSize: '1.25rem'
                            }}
                        >
                            {(from_me ? 'You: ' : '') +
                                (v.date_last_message_deleted ? '[Not Available]' : v.message)}
                        </Typography>
                    </div>
                    <div style={{ justifyContent: 'center' }}>
                        <Icon>arrow_forward</Icon>
                    </div>
                </div>
            </div >
        );
    };

    render() {
        const { pristine, convos } = this.state;
        let recipientId = this.state.recipientId;
        let recipientPhotoUrl = this.state.recipientPhotoUrl;
        let isActiveConvo = this.state.isActiveConvo;
        const { location, context } = this.props;
        const locState = location && location.state;
        const _u = context && context.userAuthenticated;
        if (locState && locState.recipientId) {
            recipientId = locState.recipientId;
            recipientPhotoUrl = locState.recipientPhotoUrl;
            isActiveConvo = true;
        }

        if (!(_u && _u.username && _u.profile_photo_url && _u.email)) {
            return (
                <div style={{ margin: 25 }}>
                    <Icon style={{ fontSize: '64px' }}>account_circle</Icon>
                    <Typography variant="subtitle2">Fill in your profile to interact with others via your inbox.</Typography>
                    <ul
                        style={{
                            listStyleType: 'none',
                            margin: 0,
                            padding: 0
                        }}
                    >
                        <li><Icon>alternate_email</Icon><br />username</li>
                        <li><Icon>photo</Icon><br />photo</li>
                        <li><Icon>mail</Icon><br />email</li>
                    </ul>

                    <Profile {...this.props} />
                </div>
            );
        }
        if (isActiveConvo) {
            return (
                <Conversation
                    {...this.props}
                    recipientId={recipientId}
                    recipientPhotoUrl={recipientPhotoUrl}
                    onGoBack={() => {
                        if (locState && locState.recipientId) {
                            const { history } = this.props;
                            history && history.goBack();
                        } else {
                            this.setState({ isActiveConvo: false });
                        }
                    }
                    }
                />
            );
        }
        return (
            <div>
                <Typography variant="h1" className="page-title" style={{ fontSize: '1.5rem' }}>Inbox</Typography>
                {
                    <div>
                        <Button
                            onClick={this._handleOpenSearchModal}
                            style={{ padding: '5px 20px' }}
                        ><Icon>mail</Icon>&nbsp;Send a message</Button>
                        <hr />
                    </div>
                }
                {
                    (!convos || !convos.length)
                    && !pristine
                    &&
                    (
                        <div>
                            <Icon style={{ fontSize: 100, color: '#fff' }}>inbox</Icon>
                            <Typography>When you have conversations, you will see them here.</Typography>
                        </div>
                    )
                }
                {
                    !!pristine &&
                    <Loading>Fetching conversations.</Loading>
                }
                {
                    Boolean(convos && convos.length) &&
                    (
                        <div id="inbox-conversation-list">
                            <Typography paragraph>Conversations</Typography>
                            {
                                convos.map(c => this.renderRow_InboxEntry(c))
                            }
                        </div>
                    )
                }
                <Modal
                    aria-labelledby="search users"
                    aria-describedby="Search for a user to send a message to."
                    className="center-modal"
                    open={this.state.openSearchModal}
                    onClose={() => {
                        this._handleCloseSearchModal();
                    }}
                >
                    <div style={{ margin: '20px auto' }}>
                        <YogiPicker
                            {...this.props}
                            maxResults={3}
                            onSelected={(item) => {
                                UtilFX.getIsDevEnv() && console.info('picked ------>', item);
                                this.setState({
                                    isActiveConvo: true,
                                    recipientId: item.objectID,
                                    recipientPhotoUrl: item.profile_photo_url,
                                }, () => { this._handleCloseSearchModal(); });
                            }}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}
