import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonComponent from './common/ButtonComponent';
import TextField from '@material-ui/core/TextField';
import CheckBoxComponent from './common/CheckBoxComponent';

import {
    Colors,
    GlobalStyles,
    UtilFX
} from '../utils';
import { firebase } from '../fire';


const DeleteAccount = (props) => {
    const { context } = props || {};
    const { userAuthenticated: _u } = context || {};
    const uid = _u && _u.uid;

    //state

const [checkValue, setCheckChange] = useState(false);
const [description, setDecription] = useState('');

return<Grid container>
    <Grid item xs={12}>
        <Typography style={{ ...GlobalStyles.bodyRegular, fontSize: '28px', color: Colors.white }} className="page-title">Delete Account</Typography>
        <Typography style={{ ...GlobalStyles.bodyMedium, textAlign: 'center', marginTop: '28px' }}>Are you sure you want to delete your account?</Typography>

        <div style={{ marginTop: '40px' }}>
            <div style={{ textAlign: 'left'}}>
                <CheckBoxComponent
                    onChange={() => setCheckChange(!checkValue)}
                    checked={checkValue}
                    label="I understand that deleted accounts aren’t recoverable"
                    addMarginStyle= {false}
                    style= {{ marginLeft: '.1px'}}
                />
            </div>
            <TextField
                placeholder="Let us know why you are leaving"
                style={{ borderColor: 'red'}}
                name="description"
                autoComplete="on"
                fullWidth={true}
                color='primary'
                value={description}
                onChange={(e) => setDecription(e.target.value)} 
                id="outlined-multiline-static"
                multiline
                rows="5"
                variant="filled"
            />
        </div>
        <div style={{ marginTop: '18px', display: 'flex', flexDirection: 'row' }}>
            <span style={{ color: Colors.white }} className="material-icons">
                info
            </span>
            <Typography style={{ fontSize: '12px', marginLeft: '3px', fontFamily: 'Rubik', fontWeight: '400', textAlign: 'left', fontStyle: 'italic' }}>Deleting an account require that the user has recently signed in. If you perform this action, and the user signed in too long ago, the action fails with an error. </Typography>
        </div>
        <div
            style={{
                flexDirection: 'row',
                display: 'flex',
                marginTop: '40px',
                justifyContent: 'center'
            }}
            >
            <ButtonComponent
                value= 'Cancel'
                buttonStyle={{
                    backgroundColor: Colors.grey6,
                    color: Colors.grey2,
                    borderRadius: '10px',
                    border: 0,
                    width: '102px',
                    padding: '10px 24px',
                    marginRight: '18px'
                }}
                onClick={() => props.history.push(`/settings`) }
            />
            <ButtonComponent
                value="Delete"
                buttonStyle={{
                    backgroundColor: checkValue ? Colors.red : Colors.grey3,
                    color: Colors.white,
                    borderRadius: '10px',
                    border: 0,
                    width: '102px',
                    padding: '10px 24px'
                }}
                onClick={() => {
                    if(checkValue){
                        if(description) {
                            firebase.database().ref(`users/${uid}`)
                            .update({
                                reason_for_account_deletion: description,
                            });
                        }
                        const user = firebase.auth().currentUser;
                        user.delete().then(() => {
                            console.log('user deleted');
                            UtilFX.logAnalytics('Account successfully deleted', { ...user });
                        }).catch((error) => {
                            console.error('cant delete user', error);
                            alert("Can't delete user account. This operation is sensitive and requires recent authentication, Log in again before retrying this request.")
                        });
                    }
                }}
            />
        </div>
    </Grid>
</Grid>
}

export default DeleteAccount;
