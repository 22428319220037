import React from 'react';

import {
    Link,
} from 'react-router-dom';

import ReactGA from 'react-ga';

// import Grid from '@material-ui/core/Grid';
import {
    Typography,
    Grid,
    Button,
} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
/* import {
    CreditCard,
    AttachMoney,
    MonetizationOn,
    Schedule,
    CalendarToday,
} from '@material-ui/icons';
import MdCreditCard from 'react-icons/lib/md/credit_card';
import MdAttachMoney from 'react-icons/lib/md/attach_money';
import MdMonetizationOn from 'react-icons/lib/md/monetization_on';
import MdSchedule from 'react-icons/lib/md/schedule';
import MdCalendarToday from 'react-icons/lib/md/calendar_today'; */

import {
    // UtilFX,
    // DataFX,
    Colors,
    // GlobalContext,
    // GlobalStyles,
    // AlertBox,
} from '../utils';

// import Button from '@material-ui/core/Button';


const LearnMoreTeacher = (props) => {
    const { context: { userAuthenticated } = {} } = props;
    const supportedMobilePhoneDetected = navigator && /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    window && ReactGA.pageview(window.location.pathname + window.location.search);
    window && window.fbq('track', 'ViewContent', {
        content_ids: 'learn-more-teacher',
    });
    // const { history, theme } = props;
    // console.log("incoming props:", props);
    return (
        <Grid container justify="center">
            <Grid item xs={12}>
                <h2 className="page-title" style={{ marginTop: 65 }}>Handle your business.</h2>
                <p style={{ marginTop: 'auto' }}>Paying made simple.</p>
                <div
                    className="explore-wrapper"
                    style={{
                        backgroundColor: '#fff',
                        color: Colors.pri_color,
                        marginTop: 40,
                        borderRadius: 18,
                        paddingRight: 15,
                        paddingLeft: 15,
                        paddingBottom: 35,
                        paddingTop: 40,
                    }}
                >

                    {/* <div>
                        <Typography variant="display1" color="primary" >Simplify your yoga</Typography>
                        <span>with the FavYogis app.</span>
                    </div> */}
                    <div
                        className="learn-more-box learn-more-box--padded"
                    >

                        {/* https://firebasestorage.googleapis.com/v0/b/favyogis-prod.appspot.com/o/marketing_assets%4Finfographic_findteachers_icon.png?alt=media&token=ce76987f-a016-4be4-aba9-5fb3ca705f2f */}
                        {/* https://firebasestorage.googleapis.com/v0/b/favyogis-prod.appspot.com/o/marketing_assets%2Finfographic_connected_icon.png?alt=media&token=79d44536-a4d6-416d-913d-7245e58a7da8 */}
                        <Typography variant="headline" color="primary" style={{ textAlign: 'center' }} >
                            <div style={{ width: 30, height: 30, marginRight: 10, textAlign: 'center', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', borderColor: Colors.pri_color, borderWidth: 1, borderRadius: 15, borderStyle: 'solid' }}>
                                1
                            </div>
                            <span style={{ display: 'inline-block', lineHeight: '30px', verticalAlign: 'middle' }}>Add a class.</span>
                        </Typography>
                        <Typography variant="body1" color="textSecondary" style={{ marginTop: 5, textAlign: 'center' }} >Setup private and public classes. <br />Manage ticket availability and pricing.</Typography>
                        <p>
                            <img
                                // style={{ verticalAlign: "middle" }}
                                width={100}
                                alt="Create classes and events."
                                src="https://firebasestorage.googleapis.com/v0/b/favyogis-prod.appspot.com/o/marketing_assets%2Finfographic_class_icon.png?alt=media&token=472289ef-0f2e-42c4-a6de-c8127b3bf0e3" />
                        </p>


                        <Typography variant="headline" color="primary" style={{ textAlign: 'center' }} >
                            <div style={{ width: 30, height: 30, marginRight: 10, textAlign: 'center', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', borderColor: Colors.pri_color, borderWidth: 1, borderRadius: 15, borderStyle: 'solid' }}>
                                2
                            </div>
                            <span style={{ display: 'inline-block', lineHeight: '30px', verticalAlign: 'middle' }}>Send invites.</span>
                        </Typography>
                        <Typography variant="body1" color="textSecondary" style={{ marginTop: 5, textAlign: 'center' }} >Share class links. <br />Students can register in less than 30 seconds.</Typography>
                        <p>
                            <img
                                // style={{ verticalAlign: "middle" }}
                                width={100}
                                alt="Create classes and events."
                                src="https://firebasestorage.googleapis.com/v0/b/favyogis-prod.appspot.com/o/marketing_assets%2Finfographic_share_megaphone_icon.png?alt=media&token=9685c1b1-21fe-4ca1-8814-20e5a8e79b83" />
                        </p>



                        <Typography variant="headline" color="primary" style={{ textAlign: 'center' }} >
                            <div style={{ width: 30, height: 30, marginRight: 10, textAlign: 'center', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', borderColor: Colors.pri_color, borderWidth: 1, borderRadius: 15, borderStyle: 'solid' }}>
                                3
                            </div>
                            <span style={{ display: 'inline-block', lineHeight: '30px', verticalAlign: 'middle' }}>Get paid.</span>
                        </Typography>
                        <Typography variant="body1" color="textSecondary" style={{ marginTop: 5, textAlign: 'center' }} >Accept repeating or one-time payments from students.</Typography>
                        <p>
                            <img
                                // style={{ verticalAlign: "middle" }}
                                width={100}
                                alt="Create classes and events."
                                src="https://firebasestorage.googleapis.com/v0/b/favyogis-prod.appspot.com/o/marketing_assets%2Finfographic_getpaid_icon.png?alt=media&token=05cd9870-7da6-430b-ab6a-cf6cf9190a2a" />
                        </p>
                    </div>
                    {
                        <Button
                            size="large"
                            variant="contained"
                            style={{
                                backgroundColor: Colors.primaryColor, color: Colors.white, borderRadius: '12px', width: '80%',
                                // marginTop: '24px', 
                                boxShadow: '0 0 0 0',
                                marginBottom: '42px'
                            }}
                            onClick={() => props.history.push('/add-class')}
                        >
                            ADD A CLASS
                        </Button>
                    }
                    <div
                        className="learn-more-box" >
                        <div className="top-half-image-wrapper">
                            <img
                                className="top-half-image"
                                src="https://firebasestorage.googleapis.com/v0/b/encoded-ensign-158201.appspot.com/o/assets%2Flearn_more_privates.png?alt=media&token=2eb93e5e-36d7-4bbe-9053-5c288b9c2881"
                                alt=""
                                style={{

                                }} />
                        </div>
                        <div className="bottom-half-wrapper">
                            <Typography
                                className="learn-more-subheader"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                variant="headline"
                                color="primary">
                                <Icon style={{ marginRight: 5, marginLeft: 5, }}>event</Icon>
                                Manage privates.</Typography>
                            <Typography variant="caption" color="textSecondary"><a
                                style={{
                                    color: 'rgba(0,0,0,0.4)',
                                    textDecorationLine: 'none'
                                }}
                                href="/explore/teachers">Yoga teachers</a> create personal classes for 1-on-1 instruction. You set the tickets and pricing.</Typography>
                        </div>
                    </div>

                    <div
                        className="learn-more-box" >
                        <div className="top-half-image-wrapper">
                            <img
                                className="top-half-image"
                                src="https://firebasestorage.googleapis.com/v0/b/encoded-ensign-158201.appspot.com/o/assets%2Flearn_more_syncschedule.png?alt=media&token=9895f721-56e8-435f-9c90-09c03c8b7636"
                                alt=""
                                style={{

                                }} />
                        </div>
                        <div className="bottom-half-wrapper">
                            <Typography
                                className="learn-more-subheader"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                variant="headline"
                                color="primary">
                                <Icon style={{ marginRight: 5, marginLeft: 5, }}>schedule</Icon>
                                Sync schedules.
                                </Typography>
                            <Typography variant="caption" color="textSecondary" >Managing your schedule in one place makes life a little easier. Schedule changes automatically update your website and Google calendar.</Typography>
                        </div>
                    </div>

                    {/*                     <div
                        className="learn-more-box" >
                        <div className="top-half-image-wrapper">
                            <img
                                className="top-half-image"
                                src="https://firebasestorage.googleapis.com/v0/b/encoded-ensign-158201.appspot.com/o/assets%2Flearn_more_track_notebooks.png?alt=media&token=23526ca7-a1c6-4aae-94fd-cd28c7720c34"
                                alt=""
                                style={{

                                }} />
                        </div>
                        <div className="bottom-half-wrapper">
                            <Typography
                                className="learn-more-subheader" style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                variant="headline"
                                color="primary">
                                <Icon style={{ marginRight: 5, marginLeft: 5, }}>poll</Icon>
                                Track stats.
                                </Typography>
                            <Typography variant="caption" color="textSecondary" >Keep track of how many hours your instructing as a teacher. This can be useful for those pursuing a ceritfication programs that require time tracking.</Typography>
                        </div>
                    </div> */}

                    {
                        <Button
                            size="large"
                            variant="contained"
                            style={{
                                backgroundColor: Colors.primaryColor, color: Colors.white, borderRadius: '12px', width: '80%',
                                // marginTop: '24px', 
                                boxShadow: '0 0 0 0',
                                marginBottom: '42px'
                            }}
                            onClick={() => props.history.push('/add-class')}
                        >
                            ADD A CLASS
                        </Button>
                    }

                    {
                        !userAuthenticated
                        &&
                        <Link
                            style={{
                                color: Colors.pri_color,
                                borderColor: Colors.pri_color,
                                borderWidth: 2,
                                borderStyle: 'solid',
                                borderRadius: 20,
                                textDecorationLine: 'none',
                                fontSize: '2rem',
                                // fontWeight:"bolder",
                                display: 'block',
                                margin: 20,
                                padding: 10,
                                backgroundColor: 'transparent',
                            }}
                            to="/signup"
                        >Sign Up
                    </Link>
                    }

                    {
                        userAuthenticated
                        && !userAuthenticated.app_version
                        &&
                        <Typography style={{ marginTop: '20px', textAlign: 'center' }}>
                            <a
                                target="blank"
                                href="http://favyogis.com/app/"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '18px 5px',
                                    margin: 'auto 20px',
                                    border: `2px solid ${Colors.pri_color}`,
                                    color: Colors.pri_color,
                                    backgroundColor: '#fff',
                                    textDecoration: 'none',
                                    fontSize: '2rem',
                                    textDecorationLine: 'none',
                                    // fontWeight: "bold",
                                    borderRadius: 20,
                                }}
                            >
                                Get the app <Icon>get_app</Icon>
                            </a>
                        </Typography>
                    }

                    {
                        userAuthenticated
                        && userAuthenticated.app_version
                        && supportedMobilePhoneDetected
                        &&
                        <Typography style={{ marginTop: '20px', textAlign: 'center' }}>
                            <a
                                target="blank"
                                href="favyogis://"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '18px 5px',
                                    margin: 'auto 20px',
                                    border: `2px solid ${Colors.pri_color}`,
                                    color: Colors.pri_color,
                                    backgroundColor: '#fff',
                                    textDecoration: 'none',
                                    fontSize: '2rem',
                                    textDecorationLine: 'none',
                                    // fontWeight: "bold",
                                    borderRadius: 20,
                                }}
                            >
                                Open the app.
                                                        <Icon>exit_to_app</Icon>
                            </a>
                        </Typography>
                    }
                </div >
            </Grid >
        </Grid >
    );
}



export default LearnMoreTeacher;
