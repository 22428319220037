import React from 'react';
import {
    withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker
} from 'react-google-maps';
import Autocomplete from 'react-google-autocomplete';
import TextField from '@material-ui/core/TextField';
import Geocode from 'react-geocode';
// import { GlobalStyles } from '../../utils'
Geocode.setApiKey('AIzaSyBon9Qo0FRSuJLHUD2IP2WVc2p2oq0rxb4');
Geocode.enableDebug();
class Map extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: props.address,
            city: props.city,
            area: props.area,
            state: props.state,
            mapPosition: {
                lat: props.center.lat,
                lng: props.center.lng
            },
            markerPosition: {
                lat: props.center.lat,
                lng: props.center.lng
            },
            show_map: false
        };
    }

    /**
      * Get the current address from the default map position and set those values in the state
      */
    componentDidMount() {
        Geocode.fromLatLng(this.state.mapPosition.lat, this.state.mapPosition.lng).then(
            response => {
                const address = response.results[0].formatted_address;
                const addressArray = response.results[0].address_components;
                const city = this.getCity(addressArray);
                const area = this.getArea(addressArray);
                const state = this.getState(addressArray);

                this.setState({
                    address,
                    area,
                    city,
                    state,
                    show_map: true
                }, () => this.onPlaceSelected(response));
            },
            error => {
                console.warn('error fetching data', { error });
            }
        );
    }
    /**
      * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
      *
      * @param nextProps
      * @param nextState
      * @return {boolean}
      */
    // shouldComponentUpdate(nextProps, nextState) {
    //     if (
    //         this.state.markerPosition.lat !== this.props.center.lat ||
    //         this.state.address !== nextState.address ||
    //         this.state.city !== nextState.city ||
    //         this.state.area !== nextState.area ||
    //         this.state.state !== nextState.state
    //     ) {
    //         return true
    //     } else if (this.props.center.lat === nextProps.center.lat) {
    //         return false
    //     }
    //     else if(this.props.error !== nextProps.error) {
    //         console.log('-----', nextProps)
    //         return true
    //     }

    // }
    /**
      * Get the city and set the city input value to the one selected
      *
      * @param addressArray
      * @return {string}
      */
    getCity = (addressArray) => {
        let city = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && addressArray[i].types[0] === 'administrative_area_level_2') {
                    city = addressArray[i].long_name;
                    return city;
                }
            }
        }
        return city;
    };

    /**
      * Get the area and set the area input value to the one selected
      *
      * @param addressArray
      * @return {string}
      */
    getArea = (addressArray) => {
        let area = '';
        if (addressArray) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0]) {
                    for (let j = 0; j < addressArray[i].types.length; j++) {
                        if (addressArray[i].types[j] === 'sublocality_level_1' || addressArray[i].types[j] === 'locality') {
                            area = addressArray[i].long_name;
                            return area;
                        }
                    }
                }
            }
        }
        return area;
    };

    /**
      * Get the address and set the address input value to the one selected
      *
      * @param addressArray
      * @return {string}
      */
    getState = (addressArray) => {
        let state = '';
        if (addressArray.length) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] === 'administrative_area_level_1') {
                    state = addressArray[i].long_name;
                    return state;
                }
            }
        }
        return state;
    };

    /**
      * And function for city,state and address input
      * @param event
      */
    /**
      * This Event triggers when the marker window is closed
      *
      * @param event
      */
    onInfoWindowClose = (event) => { };

    /**
      * When the user types an address in the search box
      * @param place
      */
    onPlaceSelected = (place) => {
        if (place.formatted_address) {
            const address = place.formatted_address;
            const addressArray = place.address_components;
            const city = this.getCity(addressArray);
            const area = this.getArea(addressArray);
            const state = this.getState(addressArray);
            const latValue = place.geometry.location.lat();
            const lngValue = place.geometry.location.lng();
            // Set these values in the state.
            this.setState({
                address: address || '',
                area: area || '',
                city: city || '',
                state: state || '',
                markerPosition: {
                    lat: latValue,
                    lng: lngValue
                },
                mapPosition: {
                    lat: latValue,
                    lng: lngValue
                },
                show_map: true
            }, () => this.props.onChange(this.state));
        }
    };

    /**
      * When the marker is dragged you get the lat and long using the functions available from event object.
      * Use geocode to get the address, city, area and state from the lat and lng positions.
      * And then set those values in the state.
      *
      * @param event
      */
    onMarkerDragEnd = (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        let addressArray = [];
        Geocode.fromLatLng(newLat, newLng).then(
            response => {
                const address = response.results[0].formatted_address;
                addressArray = response.results[0].address_components;
                const city = this.getCity(addressArray);
                const area = this.getArea(addressArray);
                const state = this.getState(addressArray);
                this.setState({
                    address: (address) || '',
                    area: (area) || '',
                    city: (city) || '',
                    state: (state) || '',
                    markerPosition: {
                        lat: newLat,
                        lng: newLng
                    },
                    mapPosition: {
                        lat: newLat,
                        lng: newLng
                    },
                });
                // this.setState({markerPosition})
            },
            error => {
                console.error(error);
            }
        );
    };

    AsyncMap = withScriptjs(
        withGoogleMap(
            props => (
                <GoogleMap
                    {...this.props}
                    google={props.google}
                    defaultZoom={props.zoom}
                    defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
                    style={{ display: 'flex', flex: 1, width: '100%' }}
                >
                    {/*Marker*/}
                    <Marker google={props.google}
                        // name={'Dolores park'}
                        // style={{color:'black'}}
                        draggable={false}
                        // onDragEnd={this.onMarkerDragEnd}
                        position={{ lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng }}
                    />
                    <Marker />
                    {/* InfoWindow on top of marker */}
                    <InfoWindow
                        onClose={this.onInfoWindowClose}
                        position={{ lat: (this.state.markerPosition.lat + 0.0018), lng: this.state.markerPosition.lng }}

                    >
                        <div>
                            <span style={{ padding: 0, margin: 0, color: 'black' }}>{this.state.address}</span>
                        </div>
                    </InfoWindow>
                </GoogleMap>
            )
        )
    );

    handleCancelLocation = (e) => {
        this.setState({ address: e.target.value }, () => {
            if (this.state.address === '') {
                this.setState({
                    address: '',
                    area: '',
                    city: '',
                    state: '',
                    markerPosition: {
                        lat: '',
                        lng: ''
                    },
                    mapPosition: {
                        lat: '',
                        lng: ''
                    },
                    show_map: false
                }, () => this.props.onChange(this.state));
            }
        });
    }

    render() {
        return (
            <div>
                <div style={{
                    height: 40,
                    borderWidth: 0,
                    borderColor: '#ffffff77',
                    backgroundColor: 'rgba(255,255,255,0.25)',
                    display: 'flex',
                    flex: 1,
                    fontSize: 18,
                    color: '#fff',
                    paddingBottom: '5px'
                }}
                >
                    {!this.state.address ? (
                        <Autocomplete
                            style={{
                                font: 'inherit',
                                color: '#FFFFFF',
                                padding: '12px',
                                width: '100%',
                                border: this.props.error ? '1px solid red' : '1px solid #1e88e5',
                                height: '1.1875em',
                                margin: 0,
                                display: 'block',
                                minWidth: 0,
                                background: 'none',
                                boxSizing: 'content-box',
                                backgroundColor: 'transparent',
                                flex: 1,
                            }}
                            onPlaceSelected={this.onPlaceSelected}
                            types={['address']}
                            title={this.state.address}
                        />
                    )
                        : (
                            <TextField
                                placeholder="Select your location"
                                variant="outlined"
                                style={{ display: 'flex', flex: 1, border: 0 }}
                                name='address'
                                value={this.state.address || ''}
                                type='text'
                                onChange={this.handleCancelLocation}
                                // error={this.state.location.address ? false : this.state.error}
                            />
                        )}
                </div>
                {
                    this.state.show_map
                    && (
                        <this.AsyncMap
                            googleMapURL="---"
                            loadingElement={
                                <div style={{ height: '100%' }} />
                            }
                            containerElement={
                                <div style={{ height: this.props.height }} />
                            }
                            mapElement={
                                <div style={{ height: '100%' }} />
                            }
                        />
                    )
                }
            </div>
        );
    }
}
export default Map;
