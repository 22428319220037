// CommonJS Modules:
// const firebase = require('firebase/app');
// require('firebase/<PACKAGE>');

// ES Modules:
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/messaging';
// importScripts('https://www.gstatic.com/firebasejs/8.2.0/firebase-app.js');
// importScripts('https://www.gstatic.com/firebasejs/8.2.0/firebase-messaging.js');

let isDev = true; //(cant import as this is imorted in order to build the utils/index file itself) AllUtils.UtilFX.getIsDevEnv(); //process.env.NODE_ENV === 'development';
const host = window.location.host && window.location.host.toLowerCase();
const isOnStaging =
  host &&
  (host.startsWith('orun.favyogis.com') || host.includes('.stage.favyogis.'));
if (process.env.REACT_APP_ENV_FILE === 'production' && !isOnStaging) {
  isDev = false;
}
if (isDev) {
  // console.log("environment vars---> DEV", process.env);
}

// console.log("environment vars---> DEV", process.env);

const config = {
  apiKey: process.env.REACT_APP_GFB_apiKey,
  authDomain: process.env.REACT_APP_GFB_authDomain,
  databaseURL: process.env.REACT_APP_GFB_databaseURL,
  storageBucket: process.env.REACT_APP_GFB_storageBucket,
  messagingSenderId: process.env.REACT_APP_GFB_messagingSenderId,
  projectId: process.env.REACT_APP_GFB_projectId,
  appId: process.env.REACT_APP_GFB_appId,
  locationId: process.env.REACT_APP_GFB_locationId,
  measurementId: process.env.REACT_APP_GFB_measurementId,
};
firebase.initializeApp(config);
const auth = firebase.auth();
const database = firebase.database();
const storage = firebase.storage();
const firestore = firebase.firestore();
// const messaging = firebase.messaging();
const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

// messaging.onBackgroundMessage(function(payload) {
//     console.log('Received background message ', payload);

//     const notificationTitle = payload.notification.title;
//     const notificationOptions = {
//       body: payload.notification.body,
//     };

//     // self.registration.showNotification(notificationTitle,
//     //   notificationOptions);
//   });
// TODO: Fix bug with firebase analytics browser env - https://github.com/firebase/firebase-js-sdk/issues/2336
const analytics = process.env.CI
  ? { logEvent: () => {} }
  : firebase.analytics();
export { auth, database, storage, firebase, analytics, firestore, messaging };
